import { decorate, observable, action, computed } from "mobx"

// 定义数据结构
class Store {
  progressOpen = false
  progressNumber = 0
  current = {
    user: {},
    farm: {},
    listFarm: [],
    organization: [],
    notify: 0,
  }
  dashboard = {
    isExtend: false,
    menuActive: '',
    navbarLeft: 'DTS',
    navbarRight: '',
    navbar_type: '',
  }
  farmer = {
    chartSyncCIP: false,
    notification: false,
  }
  showFarmList = false
  listDevice = []
  listMessageTypeDisable = [
    'test-event',
    'Get Config',
    'System Status',
    'CF',
    'Error: Invalid Command',
    'Sending I2C reset command',
    'Reset I2C',
    'System will reboot in 5 seconds',
    'Software Reset',
    'System power-up',
    'TestMode=vat',
  ]
  dtslive_dashboard = {
    milk: false,
    water: false,
    effluent: false,
    land: false,
    weather: false,
    track: false,
  }

  @computed get getListFarm() {
    // let listAllowed = [77657, 75013, 72011, 37367, 72159, 72923]
    let list = this.current.listFarm.slice()
      // .filter(item => listAllowed.includes(parseInt(item.fonterra_id)))
      .sort((a, b) => a.fonterra_id - b.fonterra_id)
    return list
  }

  @computed get getListMenuFarmer() {
    let list = [];
    if (this.current.user.is_herd_enabled)
      list.push({ name: 'Herd-Man', icon: 'herdman', url: 'https://herdman.co.nz/login.aspx?t_id=' + this.current.user.token_id, })

    /*list.push({ name: 'Dashboard', icon: 'widgets', url: '/dashboard', })
    if (this.dtslive_dashboard.milk)
      list.push({ name: 'Milk', icon: 'opacity', url: '/dts/dashboard', })
    if (this.dtslive_dashboard.water)
      list.push({ name: 'Water', icon: 'waves', url: 'https://app.dtslive.co.nz/water', })
    if (this.dtslive_dashboard.effluent)
      list.push({ name: 'Effluent', icon: 'delete', url: 'https://app.dtslive.co.nz/effluent', })
    if (this.dtslive_dashboard.land)
      list.push({ name: 'Land', icon: 'grass', url: 'https://app.dtslive.co.nz/land', })
    if (this.dtslive_dashboard.weather)
      list.push({ name: 'Weather', icon: 'ac_unit', url: 'https://app.dtslive.co.nz/weather', })
    if (this.dtslive_dashboard.track)
      list.push({ name: 'Track', icon: 'two_wheeler', url: '', })*/
    
      list.push({ name: 'Divider', })
    // list.push({ name: 'Alerts', icon: 'notifications_active', url: '/dts/notification', })
    return list
  }
}


decorate(Store, {
  progressOpen: observable,
  progressNumber: observable,
  current: observable,
  dashboard: observable,
  farmer: observable,
  listDevice: observable,
  listMessageTypeDisable: observable,
  dtslive_dashboard: observable,
})

export default new Store()