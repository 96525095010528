import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import emitter from "../../utility/EventEmitter";
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import BaseDashboard from "./BasePromotion"


class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {})
  }


  render() {


    return (

      <div className='container-fluid bg-white container-login mt-5'>
        <Container component="main" maxWidth="xs">

          <CssBaseline/>
          <div>
            <div className='py-3' style={{lineHeight: '2'}}>

            </div>

          </div>
        </Container>
      </div>

    )
  }


}

export default observer(Login)
