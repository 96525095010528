import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"

import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import store from "../../../store/Store";
import { Opacity, Schedule, Apps, NotificationsActive, Settings } from '@material-ui/icons';
import Utils from "../../../utility/Utils";


class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {})
        this.initData()
    }

    initData = () => {
        const params = new URLSearchParams();
        params.append('type', 'FARMER_NOTIFICATION_STATUS');
        params.append('farm', Utils.farmGet());
        Utils.httpPost(Utils.urlList.farmer_notification, params, data => {
            // console.log(data)
            store.farmer.notification = data == 'YES'
        })
    }

    componentDidMount = () => {
    }

    farmNavi = (event, newValue) => {
        switch (newValue) {
            case 0:
                this.props.history.push('/dts/dashboard')
                break
            case 1:
                this.props.history.push('/dts/milking')
                break
            case 2:
                this.props.history.push('/dts/equipment')
                break
            case 3:
                this.props.history.push('/dts/notification')
                break
            case 4:
                this.props.history.push('/dts/setting/alert')
                break
        }
    }

    render() {
        let nf = store.farmer.notification ? 'mb-3 farmer-tab' : 'mb-3'
        return (
            <Paper square className={nf} style={{ flexGrow: 1, borderBottom: '1px solid #ddd' }}>
                {/* <AppBar position="static" color="inherit" className='mb-3'> */}
                <Tabs
                    value={this.props.tabval}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    onChange={this.farmNavi}
                    centered
                >
                    {/* style={{ width: '20%' }} */}
                    <Tab icon={<Opacity />} aria-label="Dashboard" />
                    <Tab icon={<Schedule />} aria-label="Milking" />
                    <Tab icon={<Apps />} aria-label="Equipment" />
                    <Tab icon={<NotificationsActive />} aria-label="Notifications" />
                    <Tab icon={<Settings />} aria-label="Settings" />

                    {/* <Tab label="Dashboard" />
                    <Tab label="Milking" />
                    <Tab label="Equipment" />
                    <Tab label="Notifications" />
                    <Tab label="Settings" /> */}
                </Tabs>
                {/* </AppBar> */}
            </Paper>
        )
    }
}

export default withRouter(observer(Component))
