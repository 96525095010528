import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {

    let listStorage = [], listCooler = []
    if (this.props.listStorage && this.props.listStorage.length) {
      listStorage = this.props.listStorage
    }
    if (this.props.listCooler && this.props.listCooler.length) {
      listCooler = this.props.listCooler
    }

    let listDataVat = []
    let listDataInlet = []
    let listDataPlate = []
    let listPlotX = []
    let listBandX = []
    let listSeries = []

    let listTempCooler1 = [], listTempCooler2 = [];

    if (this.props.alert) {
      listPlotX.push({
        color: '#FF0000',
        // dashStyle: 'longdashdot',
        width: 3,
        zIndex: 2,
        value: moment.utc(this.props.alert.data.published_at).local().toDate(),
      })
    }

    let previous = null

    for (let item of listStorage) {

      let x = moment.utc(item.published_at).local().toDate()

      if (item.temp1 === undefined || item.temp1 === null) {
        continue
      }

      // if (item.state_machine === 'som' || item.state_machine === 'eom') {
      //   item.state_machine = 'milking'
      // }

      let state_machine_cur = item.state_machine
      let state_machine_pre = null

      if (state_machine_cur === 'som' || state_machine_cur === 'eom') {
        state_machine_cur = 'milking'
      }

      if (previous) {
        state_machine_pre = previous.state_machine
        if (state_machine_pre === 'som' || state_machine_pre === 'eom') {
          state_machine_pre = 'milking'
        }
      }

      listDataVat.push({
        x: x,
        y: parseFloat(item.temp1),
        marker: global.constants.highcharts.marker,
      })

      if (item.temp2 !== undefined && item.temp2 !== null) {
        listDataInlet.push({
          x: x,
          y: parseFloat(item.temp2),
          marker: global.constants.highcharts.marker,
        })
      }

      if (item.temp3 !== undefined && item.temp3 !== null) {
        listDataPlate.push({
          x: x,
          y: parseFloat(item.temp3),
          marker: global.constants.highcharts.marker,
        })
      }
      if (previous && state_machine_pre !== 'milking' && state_machine_cur === 'milking') {
        listPlotX.push({
          color: '#1300ab',
          dashStyle: 'longdashdot',
          width: 1,
          value: x,
        })
      } else if (previous && state_machine_pre === 'milking' && state_machine_cur !== 'milking') {
        let x_previous = moment.utc(previous.published_at).local().toDate()
        listPlotX.push({
          color: '#ab4d2d',
          dashStyle: 'longdashdot',
          width: 1,
          value: x_previous,
        })
      }


      previous = item
    } // end data loop

    for (let item of listCooler) {
      let x = moment.utc(item.published_at).local().toDate()
      listTempCooler1.push({
        x: x,
        y: parseFloat(item.milk_in),
        marker: global.constants.highcharts.marker,
      })
      listTempCooler2.push({
        x: x,
        y: parseFloat(item.water_in),
        marker: global.constants.highcharts.marker,
      })
    }

    if (listDataVat.length) {
      listSeries.push({
        name: 'Vat (°C)',
        turboThreshold: 10000,
        data: listDataVat,
      })
    }

    if (listDataInlet.length) {
      listSeries.push({
        name: 'Inlet (°C)',
        turboThreshold: 10000,
        data: listDataInlet,
        visible: false,
      })
    }

    if (listDataPlate.length) {
      listSeries.push({
        name: 'Plate (°C)',
        turboThreshold: 10000,
        data: listDataPlate,
        visible: false,
      })
    }

    // CIP
    if (this.props.cip && this.props.cip.activity) {
      listBandX.push({
        from: moment.utc(this.props.cip.activity.start.published_at).local().toDate(),
        to: moment.utc(this.props.cip.activity.end.published_at).local().toDate(),
        color: global.constants.color.volume,
      })
      let listCIP = []
      for (let item of this.props.cip.detailData) {
        listCIP.push({
          x: moment.utc(item.published_at).local().toDate(),
          y: item.temp5,
          marker: global.constants.highcharts.marker,
        })
      }

      if (listCIP.length) {
        listSeries.push({
          id: 'series-cip',
          name: 'CIP (°C)',
          turboThreshold: 10000,
          data: listCIP,
          visible: store.farmer.chartSyncCIP,
        })
      }
    }


    if (listTempCooler1.length) {
      listSeries.push({
        name: 'Milk In (°C)',
        turboThreshold: 10000,
        data: listTempCooler1,
        visible: false,
      })
    }

    if (listTempCooler2.length) {
      listSeries.push({
        name: 'Water In (°C)',
        turboThreshold: 10000,
        data: listTempCooler2,
        visible: false,
      })
    }

    // ------------------

    let plotOptions = {
      // column: {colorByPoint: true},
      series: {
        // color: '#2594b7',
        // cursor: 'pointer',
        events: {
          legendItemClick: function () {
            // if (this.name === 'CIP') {
            //   store.farmer.chartSyncCIP = !store.farmer.chartSyncCIP
            //   return false
            // }
          },
        }
      },
    }

    let listPlotY = [{
      color: '#FF0000',
      dashStyle: 'longdash',
      width: 1,
      value: 10,
    }, {
      color: '#FF0000',
      dashStyle: 'longdash',
      width: 1,
      value: 6,
    }]

    let result = {
      credits: false,
      chart: {
        type: 'line',
        height: 300,
        zoomType: '',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        plotLines: listPlotX,
        plotBands: listBandX,
        title: { text: null },
      },
      yAxis: {
        title: { text: '' },
        plotLines: listPlotY,
        tickInterval: 2,
        gridLineWidth: 0,
        lineWidth: 1,
        min: 0,
        // max: 15,
        labels: {
          formatter: function () {
            if (this.value === 10 || this.value === 6) {
              return this.value + ' °C';
            } else {
              return null
            }
          }
        }
      },
      plotOptions: plotOptions,
      series: listSeries,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment.utc(this.x).local().format(global.constants.date_format_week)
          switch (this.key) {
            default:
              return `<div>${xx} / <strong>${DTS.getTemperature(this.y)} °C</strong></div>`;
          }
        }
      }
    }
    return result
  }

  cipShowHide = () => {
    let cip = this.refChart.current.chart.get('series-cip')
    if (cip.visible) {
      cip.hide()
    } else {
      cip.show()
    }
  }


  render() {
    return (
      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />
    )
  }


}

export default observer(Component)
