import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import './utility/Constants'
import './utility/env'
import Emitter from './components/common/Events'

// import {Helmet} from "react-helmet";

import LayoutDashboard from "./components/LayoutDashboard";
import LayoutPromotion from "./components/LayoutPromotion";
import LayoutDTSLive from "./components/LayoutDTSLive";
import Test from "./components/Test";
import FonterraTesting from "./components/FonterraTesting";
import FonterraDashboard from "./components/fonterra/FonterraDashboard";
import WestlandsDashboard from "./components/westlands/Dashboard";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import style from './style/style.scss';
import CssBaseline from "@material-ui/core/CssBaseline";
import TagManager from 'react-gtm-module'
import store from "./store/Store";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dashboard from "./components/dtslive/Dashboard";
import Logout from "./components/promotion/Logout";
import Redirect from "./components/promotion/Redirect";

TagManager.initialize(global.constants.google_tag_manager)

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: style.primary
    },
    secondary: {
      main: style.secondary
    },
  },
  shadows: Array(25).fill('none'),
  shape: {
    borderRadius: 0,
  },
});


const Index = () => <div className='text-center'>Home</div>;

const AppRouter = () => (
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      {/* <Helmet>
        <title>{global.constants.appname}</title>
      </Helmet> */}
      <CssBaseline />
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/test" exact component={Test} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/redirect" exact component={Redirect} />
        <Route path="/fonterra/testing" exact component={FonterraTesting} />
        <Route path="/dts/fonterra/*" component={FonterraDashboard} />
        <Route path="/dts/westlands/*" component={WestlandsDashboard} />
        <Route path="/dts/(login|register|password|permission)" component={LayoutPromotion} />
        <Route path="/dts/group/:organization_id?/(o)?/:parent_id?" component={LayoutDashboard} />
        <Route path="/dts/user/setting" component={LayoutDashboard} />
        <Route path="/dts/user/profile/:verification?" component={LayoutDashboard} />
        <Route path="/dts/*" component={LayoutDashboard} />
        <Route path="/dashboard" component={LayoutDashboard} />
        <Route
          path="/admin"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={LayoutPromotion} exact />
              <Route path={`${url}/home`} component={LayoutPromotion} />
              <Route path={`${url}/users`} component={LayoutPromotion} />
            </>
          )}
        />
      </Switch>
      <Emitter />
    </MuiThemeProvider>
  </BrowserRouter>
);

export default AppRouter;
