import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, Button, MuiThemeProvider } from '@material-ui/core';
import theme from "./theme";
import Utils from "../../utility/Utils";

function DTSPopoverNE(props) {
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (Utils.getMTDialog() === "true") {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  }, []); // Empty dependency array means this runs once when the component mounts

  const routeChange = () => {
    let path = 'https://dts.co.nz/products-and-services/dairy-automation/auto-drafter/'; 
    window.location.href = path;
  };

  const closeDialog = () => {
    setDialogOpen(false);
    Utils.setMTDialog();
    //let curhref = window.location.href;
    //window.location.reload();
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isDialogOpen}>
        <DialogTitle id="simple-dialog-title">Important Announcement</DialogTitle>
        <List>
          <ListItem autoFocus>
            <ListItemText primary={props.text} secondary={props.text2}/>
          </ListItem>
          <ListItem>
            <Button color="primary" onClick={routeChange}>Learn More</Button>
            <Button color="primary" onClick={closeDialog}>Close</Button>
          </ListItem>
        </List>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default DTSPopoverNE;
