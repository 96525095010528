import React from 'react'
import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import Utils from "../../../utility/Utils";

import {
  PieChart, Pie, Cell,
} from 'recharts';
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import DTS from "../../../utility/DTS";


class DeviceCurrentLevels extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      listLevel: [],
      listVat: [],
      listInlet: [],
      listPlate: [],
      labelLevel: '',
      labelVat: '',
      labelInlet: '',
      labelPlate: '',
      isShaftRunning: false,
    })
    // console.log(props)
    this.initData()


  }

  initData = () => {
    let device_id = this.props.match.params.device_id
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_ONE');
    params.append('device_id', device_id);
    Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      this.dataAction(data)
    })
  }

  dataAction = (dataList) => {
    let data = dataList.data
    let device = dataList.device

    let list, value

    this.props.setPageTitle(device.name, DTS.getFonterraId(device.farm_id) + '-' + (device.vat_number ? device.vat_number : 'N/A'))


    if (data)
      value = parseFloat(data.milk_level)
    else
      value = 0
    // if (!value) value = 0
    list = [
      { name: 'Group A', value: value, color: '#1fee26' },
      { name: 'Group B', value: device.vat_max_height / 10 - value, color: '#ddd' },
    ];
    this.listLevel = list
    this.labelLevel = value + 'mm'

    
    if (data)
      value = parseFloat(data.temp1)
    else
      value = 0
    // value = parseFloat(data.temp1);
    // if (!value) value = 0
    list = [
      { name: 'Group A', value: value - (-16), color: '#1fee26' },
      { name: 'Group B', value: 16 - value, color: '#ddd' },
    ];
    this.listVat = list
    this.labelVat = value + '°C'

    
    if (data)
      value = parseFloat(data.temp2)
    else
      value = 0
    // value = parseFloat(data.temp2);
    // if (!value) value = 0
    list = [
      { name: 'Group A', value: value - (-15), color: '#1fee26' },
      { name: 'Group B', value: 45 - value, color: '#ddd' },
    ];
    this.listInlet = list
    this.labelInlet = value + '°C'

    if (data)
      value = parseFloat(data.temp3)
    else
      value = 0
    // value = parseFloat(data.temp3);
    // if (!value) value = 0
    list = [
      { name: 'Group A', value: value - (-15), color: '#1fee26' },
      { name: 'Group B', value: 45 - value, color: '#ddd' },
    ];
    this.listPlate = list
    this.labelPlate = value + '°C'

    
    if (data)
      value = parseInt(data.shaft_rpm)
    else
      value = 0
    this.isShaftRunning = value > 0
  }

  render() {
    return (
      <div className='container-fluid bg-white'>

        <div className='row my-3'>
          <div className='col py-3 text-center'>
            <PieChart width={170} height={80} className="m-auto">
              <Pie
                data={this.listLevel}
                cx={80}
                cy={80}
                startAngle={180}
                endAngle={0}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
              >
                {
                  this.listLevel.map((entry, index) => <Cell key={`cell-${index}`}
                    fill={entry.color} />)
                }
              </Pie>
            </PieChart>
            Milk Level<br />
            {this.labelLevel}
          </div>
          <div className='col py-3 text-center'>
            <PieChart width={170} height={80} onMouseEnter={this.onPieEnter} className="m-auto">
              <Pie
                data={this.listVat}
                cx={80}
                cy={80}
                startAngle={180}
                endAngle={0}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
              >
                {
                  this.listVat.map((entry, index) => <Cell key={`cell-${index}`}
                    fill={entry.color} />)
                }
              </Pie>
            </PieChart>
            Vat Temp<br />
            {this.labelVat}
          </div>
          <div className='col py-3 text-center'>
            <PieChart width={170} height={80} onMouseEnter={this.onPieEnter} className="m-auto">
              <Pie
                data={this.listInlet}
                cx={80}
                cy={80}
                startAngle={180}
                endAngle={0}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
              >
                {
                  this.listInlet.map((entry, index) => <Cell key={`cell-${index}`}
                    fill={entry.color} />)
                }
              </Pie>
            </PieChart>
            Inlet Temp<br />
            {this.labelInlet}
          </div>
          {/* <div className='col py-3 text-center'>
            <PieChart width={170} height={80} onMouseEnter={this.onPieEnter} className="m-auto">
              <Pie
                data={this.listPlate}
                cx={80}
                cy={80}
                startAngle={180}
                endAngle={0}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
              >
                {
                  this.listPlate.map((entry, index) => <Cell key={`cell-${index}`}
                    fill={entry.color} />)
                }
              </Pie>
            </PieChart>
            Plate Temp<br />
            {this.labelPlate}
          </div> */}
          {/*<div className='col py-3 text-center' style={{paddingTop: '50px'}}>*/}
          {/*    {this.isShaftRunning ?*/}
          {/*        <span>*/}
          {/*            <i className="material-icons green">loop</i><br/>*/}
          {/*            Shaft is running*/}
          {/*        </span> :*/}
          {/*        <span>*/}
          {/*            <i className="material-icons red">stop</i><br/>*/}
          {/*            Shaft is stopped*/}
          {/*        </span>}*/}
          {/*</div>*/}
        </div>

      </div>
    )
  }


}

export default observer(DeviceCurrentLevels)
