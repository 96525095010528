import React from 'react'
import {observer} from "mobx-react"
import {extendObservable} from "mobx"
import {withRouter} from "react-router-dom"
import store from "../../store/Store";
import BaseDashboardFarmer from "./BaseDashboardFarmer";

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
    })
    this.initData()
    store.dashboard.menuActive = 'Telemetry'
    store.dashboard.navbarLeft = 'Telemetry'
  }

  initData = () => {

  }


  render() {
    // console.log('render one time')
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>
            123
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Component))
