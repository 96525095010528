import React from 'react'
import { BrowserRouter, Route, Link, Switch, withRouter } from "react-router-dom";

import store from "../store/Store";
import LinearProgress from "@material-ui/core/LinearProgress";
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Utils from "../utility/Utils";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import FarmerEquipment from "./dashboard/FarmerEquipment";
import StaffDeviceList from "./dashboard/StaffDeviceList";
import StaffDeviceInfo from "./dashboard/StaffDeviceInfo";
import { Icon } from 'antd';
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import emitter from "../utility/EventEmitter";
import StaffDeviceListVirtual from "./dashboard/StaffDeviceListVirtual";
import FarmerDashboard from "./dashboard/FarmerDashboard";
import FarmerMilkingList from "./dashboard/FarmerMilkingList";
import FarmerCollectionList from "./dashboard/FarmerCollectionList";
import StaffFarmList from "./dashboard/StaffFarmList";
import FarmerSettings from "./dashboard/FarmerSettings";
import FarmerMilkingInfo from "./dashboard/FarmerMilkingInfo";
import Scrollbar from "react-scrollbars-custom";
import FarmerNotification from "./dashboard/FarmerNotification";
import FarmerUserAlert from "./dashboard/FarmerUserAlert";
import FarmerAlertSettings from "./dashboard/FarmerAlertSettings";
import FarmerUserProfile from "./dashboard/FarmerUserProfile";
import StaffUserList from "./dashboard/StaffUserList";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./common/theme";
import Blank from "./dashboard/Blank";
import { Select } from 'antd';
import FarmerAgitator from "./dashboard/FarmerAgitator";
import FarmerStorage from "./dashboard/FarmerStorage";
import StaffOrganization from "./dashboard/StaffOrganization";
import FarmerOrganization from "./dashboard/FarmerOrganization";
import FarmerEquipmentBuilder from "./dashboard/FarmerEquipmentBuilder";
import FarmerLogFonterra from "./dashboard/FarmerLogFonterra";
import InstallerConfig from "./dashboard/InstallerConfig";
import InstallerNew from "./dashboard/InstallerNew";
import InstallerLog from "./dashboard/InstallerLog";
import InstallerHelp from "./dashboard/InstallerHelp";
import InstallerCheck from "./dashboard/InstallerCheck";
import AddPickup from "./dashboard/AddPickup";
import Button from "@material-ui/core/Button";
import DTS from '../utility/DTS';
import Tools from '../utility/Tools';
import Divider from '@material-ui/core/Divider';
import Calibration from "./dashboard/Calibration";
import FarmerDashboardData from './dashboard/FarmerDashboardData';
import Dashboard from "./dtslive/Dashboard";
import SvgIcon from '@material-ui/core/SvgIcon';

const { Option } = Select;

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      isMenuAnchorEl: null,
      isMenuOpen: false,
      isRetired: false,
    })
    this.listMenuSystem = []
    this.listMenuFarmer = []
    this.initCurrentUser()
    setTimeout(() => {
      if (typeof createInlineManualPlayer !== 'undefined')
        createInlineManualPlayer(window.inlineManualPlayerData)
    }, 3000);
  }

  @computed get getNotifyNumber() {
    return store.current.notify ? store.current.notify : 0
  }

  @computed get getDemoFarmName() {
    return DTS.getDemoFarmName(store.current.user)
  }

  // init current user infor
  initCurrentUser = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_ONE_GET_BY_TOKEN');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.user, params, data => {
      // console.log(data)
      if (!data || !data.user) {
        // console.log('Normally run here')
        let reurl = window.location.href
        reurl = reurl.substr(reurl.indexOf('/', 8))
        reurl = encodeURIComponent(reurl)
        // console.log(curUrl)
        window.location.href = '/dts/login/' + reurl
        return
      }
      this.initData(data)
    })
  }

  initData = (data) => {
    // step 1: read from db, step 2: read from client
    // set current farm
    // first set from DB
    // second check local, if has local, then check local is in DB list, if in, then set current farm, if not then use DB
    data.farm = {}
    let localStorageFarm = JSON.parse(Utils.farmGet())
    if (data.listFarm.length > 0) {
      if (!localStorageFarm._id) {
        Utils.farmSet({ '_id': data.listFarm[0]._id })
      } else {
        let flag = false
        for (let item of data.listFarm) {
          if (item._id === localStorageFarm._id) {
            data.farm = localStorageFarm
            flag = true
            break;
          }
        }
        if (!flag) {
          Utils.farmSet({ '_id': data.listFarm[0]._id })
          data.farm = { '_id': data.listFarm[0]._id }
        }
      }
    }

    let list = [];
    this.listMenuSystem = list
    store.current = data
  }

  getNavbarRightCompenent = () => {
    let result = null
    switch (store.dashboard.navbar_type) {
      case 'DEVICE_STATUS_ONLINE':
        result = <>
          <Button color={this.isRetired ? 'default' : 'primary'} onClick={() => {
            this.isRetired = false
            emitter.emit("DEVICE_STATUS_CHANGED", this.isRetired)
          }}>
            Active
          </Button>
          <Button color={this.isRetired ? 'primary' : 'default'} onClick={() => {
            this.isRetired = true
            emitter.emit("DEVICE_STATUS_CHANGED", this.isRetired)
          }}>
            Inactive
          </Button>
        </>
        break
    }
    return result
  }

  userMenuOpen = (e) => {
    if (!this.isMenuAnchorEl) this.isMenuAnchorEl = e.currentTarget
    this.isMenuOpen = !this.isMenuOpen
  }

  profile = () => {
    this.userMenuOpen()
  }

  settings = () => {
    this.userMenuOpen()
  }

  logoutDialogOpen = () => {
    this.isMenuOpen = false
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to logout?',
      body: 'Once you quit, you will need to sign in again to use the DTS service.',
      callbackCancel: true,
      callbackOk: () => {
        Utils.authRemove()
        window.location.href = "/dts/login"
      },
      forceSelect: false,
    })
  }

  render() {
    // console.log('Dashboard Render', store.current.user)
    return (<>
      {store.current.user && store.current.user._id ?
        <BrowserRouter>
          {store.progressOpen ? <LinearProgress className='progress' /> : null}
          <div className={`${store.dashboard.isExtend ? 'dashboard extend' : 'dashboard'}`}>

            <AppBar>
              <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={e => {
                  store.dashboard.isExtend = !store.dashboard.isExtend
                }}>
                  <i className="material-icons">menu</i>
                </IconButton>
                <Typography className='flex-grow-1'>
                  {global.constants.appname}
                </Typography>

                {/* <Link style={{ color: 'white' }} to={`/dts/notification`}>
                  <IconButton
                    component={IconButton}
                    aria-label="show 17 new"
                    onClick={e => {
                      // this.props.history.push('/dts/notifications')
                    }}
                    color="inherit">
                    <Badge badgeContent={this.getNotifyNumber} color="secondary">
                      <i className="material-icons">notification_important</i>
                    </Badge>
                  </IconButton>
                </Link> */}

                {<IconButton
                  // edge="end"
                  aria-label="refresh current page"
                  aria-haspopup="true"
                  onClick={() => { location.reload(); }}
                  color="inherit"
                >
                  <i className="material-icons">refresh</i>
                </IconButton>}

                {!this.getDemoFarmName && store.current.organization ? <Link style={{ color: 'white' }} to={`/dts/group/${store.current.organization._id}`}>
                  <IconButton component={IconButton} color="inherit">
                    <i className="material-icons">group</i>
                  </IconButton>
                </Link> : null}

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={this.userMenuOpen}
                  color="inherit"
                >
                  <i className="material-icons">person</i>
                </IconButton>
              </Toolbar>
            </AppBar>

            <Toolbar variant="dense" className='navbar'>
              <div className='navbar-logo'>
                <img width='32' height='32' alt="DTS" src={global.constants.api_server_resource + `/common/images/logo.png`} />
              </div>
              <div className='navbar-content px-3'>
                <div className='flex-grow-1' style={{ lineHeight: '36px' }}>
                  {store.dashboard.navbarLeft}
                </div>


                {store.showFarmList ? <div className='d-flex align-items-center'>
                  <Typography variant="subtitle1" className='mr-1'>
                    Farm
                  </Typography>
                  <Select
                    showSearch
                    value={store.current.farm._id}
                    style={{ width: 150 }}
                    size='default'
                    placeholder="Select a farm"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={farmid => {
                      // console.log(farmid)
                      let item = null
                      for (let i of store.getListFarm) {
                        if (i._id === farmid) {
                          item = i
                          break
                        }
                      }
                      Utils.farmSet({ '_id': item._id })
                      window.location.reload() // = '/dts/dashboard'
                    }}
                  >
                    {store.getListFarm.map((item, index) =>
                      <Option key={index} value={item._id}>{this.getDemoFarmName ? this.getDemoFarmName : item.fonterra_id}</Option>
                    )}
                  </Select>
                </div> : null}
                {store.dashboard.navbarRight}
                {this.getNavbarRightCompenent()}
              </div>
            </Toolbar>

            <div className='content'>

              <div className='siderbar'>
                <List component="nav" aria-label="main mailbox folders" className='menu'>
                  {this.listMenuSystem.map((item, index) => {
                    switch (item.name) {
                      case 'Divider':
                        return <div
                          key={index}
                          style={{ height: 0, overflow: 'hidden', borderBottom: '1px solid #ccc' }}>
                        </div>
                        break
                      case 'System':
                        return <div
                          key={index}
                          className='text-center'
                          style={{ width: '57px', color: '#999', paddingTop: '5px' }}>
                          {item.name}
                        </div>
                        break
                      case 'Farmer':
                        return <div
                          key={index}
                          className='text-center'
                          style={{ width: '57px', color: '#999', paddingTop: '10px' }}>
                          {item.name}
                        </div>
                        break
                      default:
                        return <ListItem key={index} component={Link} to={item.url}>
                          <ListItemIcon className={item.name === store.dashboard.menuActive ? 'active' : null}>
                            <Badge badgeContent={0} color="secondary">
                              <i className="material-icons">{item.icon}</i>
                            </Badge>
                          </ListItemIcon>
                          <ListItemText primary={item.name} className='nowrap' />
                        </ListItem>
                        break
                    }

                  })}
                  {store.current.farm && store.current.farm._id ? store.getListMenuFarmer.map((item, index) => {
                    let badge = 0
                    switch (item.name) {
                      case 'Alerts':
                        badge = this.getNotifyNumber
                        break
                    }
                    let url = item.url
                    if (item.name === 'Herd-Man') {
                      return <ListItem button key={index} component="a" href={url}>
                        <ListItemIcon className={item.name === store.dashboard.menuActive ? 'active' : null}>
                          <Badge badgeContent={badge} color="secondary">
                            <SvgIcon viewBox="0 0 40 40">
                              {/* <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /> */}
                              <svg >
                                <g>
                                  <path className="st0" d="M9.89,30.59v-8.7H6.41v8.7H0.65V9.41h5.76v7.86h3.48V9.41h5.79v21.18H9.89z" />
                                  <path className="st0" d="M34.04,30.59l-0.21-11.91c-0.03-1.02,0-2.97,0.06-4.11h-0.15c-0.12,1.17-0.24,2.79-0.42,4.2l-1.74,11.82h-6.12
		L23.9,18.95l-0.48-4.38H23.3c0.03,1.17,0.06,2.82,0.03,4.2l-0.21,11.82h-4.98L18.5,9.41h8.58l1.17,8.55
		c0.15,1.44,0.33,3.39,0.48,5.22h0.09c0.15-1.8,0.3-3.66,0.48-5.25l1.11-8.52h8.61l0.33,21.18H34.04z"/>
                                </g>
                              </svg>
                            </SvgIcon>
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary={item.name} className='nowrap' />
                      </ListItem>
                    }
                    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && !url.startsWith('http')) {
                      url = 'https://' + constants.doamin_vatman + url
                    }
                    return item.name !== 'Dashboard' ? <ListItem button key={index} component="a" href={url}>
                      <ListItemIcon className={item.name === store.dashboard.menuActive ? 'active' : null}>
                        <Badge badgeContent={badge} color="secondary">
                          <i className="material-icons">{item.icon}</i>
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary={item.name} className='nowrap' />
                    </ListItem> : <ListItem key={index} component={Link} to={item.url}>
                        <ListItemIcon className={item.name === store.dashboard.menuActive ? 'active' : null}>
                          <Badge badgeContent={badge} color="secondary">
                            <i className="material-icons">{item.icon}</i>
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary={item.name} className='nowrap' />
                      </ListItem>
                  }) : null}
                </List>
                <div className='bottom help-launcher'>
                  <Icon type="question-circle" className='d-none-1' />
                </div>

              </div>

              <Scrollbar mobileNative={true} className='body'>

                {!store.current.user ? null : <Switch>

                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/dts/group/:organization_id?/(o)?/:parent_id?" render={() => (<FarmerOrganization {...this.props} />)} />
                  <Route path="/dts/user/setting" render={() => (<FarmerUserAlert {...this.props} />)} />
                  <Route path="/dts/user/profile/:verification?" render={() => (<FarmerUserProfile {...this.props} />)} />

                </Switch>}

              </Scrollbar>

            </div>

          </div>
          <MuiThemeProvider theme={theme}>
            <Menu
              anchorEl={this.isMenuAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.isMenuOpen}
              onClose={this.userMenuOpen}
            >
              <MenuItem onClick={this.profile} component={Link} to='/dts/user/profile' className="py-2">
                <ListItemIcon>
                  <i className="material-icons">person</i>
                </ListItemIcon>
                {store.current.user.firstName} Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={this.settings} component={Link} to='/dts/user/setting' className="py-2">
                <ListItemIcon>
                  <i className="material-icons">notification_important</i>
                </ListItemIcon>
                Notification Settings
              </MenuItem>
              <Divider />
              <MenuItem onClick={this.logoutDialogOpen} className="py-2">
                <ListItemIcon>
                  <i className="material-icons">block</i>
                </ListItemIcon>
                {/*{store.current.user && store.current.user.firstName ? store.current.user.firstName : ''} Logout*/}
                Logout
              </MenuItem>
            </Menu>
          </MuiThemeProvider>
        </BrowserRouter>
        : null}
    </>)
  }

}

export default withRouter(observer(Component))
