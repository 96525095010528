import React from 'react'
import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      recaptcha: null,
    })
  }

  componentDidMount() {
    // console.log(ReCaptcha, loadReCaptcha)
    // loadReCaptcha(global.constants.google_recaptcha_key);
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    // this.recaptcha.execute();
  }

  verifyCallback = (recaptchaToken) => {
    // console.log(recaptchaToken)
    // Here you will get the final recaptchaToken!!!
    // console.log(recaptchaToken, "<= your recaptcha token")
    if (this.props.verifyCallback)
      this.props.verifyCallback(recaptchaToken)
  }

  render() {
    return (
      // <ReCaptcha
      //   ref={ref => this.recaptcha = ref}
      //   sitekey={global.constants.google_recaptcha_key}
      //   verifyCallback={this.verifyCallback}
      // />
      // <GoogleReCaptchaProvider
      //   reCaptchaKey="global.constants.google_recaptcha_key"
      //   scriptProps={{
      //     async: false, // optional, default to false,
      //     defer: false, // optional, default to false
      //     appendTo: "head", // optional, default to "head", can be "head" or "body",
      //     nonce: undefined // optional, default undefined
      //   }}
      // >
      //   <div></div>
      // </GoogleReCaptchaProvider>
      <GoogleReCaptchaProvider reCaptchaKey={global.constants.google_recaptcha_key}>
        <GoogleReCaptcha onVerify={this.verifyCallback} />
      </GoogleReCaptchaProvider>
    )
  }

}

export default observer(Component)
