import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import Link from "@material-ui/core/Link";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { DatePicker, Select } from "antd";
import moment from "moment";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import ActivityMilkingListArea from "./farmer/ActivityMilkingListArea";
import { Button, CardContent, CardHeader } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import FarmerNavi from './farmer/FarmerNavi';


class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      listData: []
    })
    this.initData()

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Milking'
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_MILKING_LIST');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      // return;
      this.listData = data.sort((a, b) => {
        if (a.device.vat_number > b.device.vat_number) {
          return 1;
        } else {
          return -1;
        }
      })
    })
  }

  // @computed get getData() {
  //   let list = data.sort((a, b) => {
  //     if (a.device.vat_number > b.device.vat_number) {
  //       return date.start - date.end
  //     } else {
  //       return -1;
  //     }
  //   })
  //   return list
  // }

  render() {
    document.body.classList.add('milk-nav');
    return (
      <div className='container-fluid my-3'>

        <div style={{ position: 'fixed', top: '96px', zIndex: 1, width: '100%' }}>
          <FarmerNavi tabval={1} />
        </div>

        <div className='row fix-subnva'>
          <div className='col'>

            {this.listData.map((item, index) => <div key={index}>

              <Card className='mb-3'>
                <CardHeader
                  avatar={<Avatar aria-label="recipe">{item.device.vat_number}</Avatar>}
                  title="Milking volume (L)"
                />
                <CardContent className='p-x-0'>
                  <ActivityMilkingListArea storageList={item.detailData} typeYAxis='LEVEL' {...this.props} />
                </CardContent>
              </Card>

              <Card className='mb-3'>
                <CardHeader
                  avatar={<Avatar aria-label="recipe">{item.device.vat_number}</Avatar>}
                  title="Milking temperature (°C)"
                />
                <CardContent className='p-x-0'>
                  <ActivityMilkingListArea storageList={item.detailData} typeYAxis='TEMP' {...this.props} />
                </CardContent>
              </Card>

            </div>)}

            {/*<Card className='mb-3 p-3 d-flex flex-row'>*/}
            {/*  <div className='flex-grow-1'>*/}
            {/*    <Button variant="outlined" color="primary">*/}
            {/*      Previous*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*  <Button variant="outlined" color="primary">*/}
            {/*    Next*/}
            {/*  </Button>*/}
            {/*</Card>*/}

          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(observer(Component))
