import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {
            data: {
                success: { value: '' },
                resultMessage: { value: '' },
                redirect: { value: '' },

                imei: { value: '' },
                dairySupplier: { value: 'FON' }, // Defaults to Fonterra
                supplyNumber: { value: '' },
                productVariant: { value: 'Vat-Man Lite'},

                vat1Height: { value: '' },
                vat1OClock: { value: '' },
                vat1RadarSerial: { value: '' },
                vat1Manufacturer: { value: ''},
                vat1Serial: { value: ''},

                vat2Height: { value: '' },
                vat2OClock: { value: '' },
                vat2RadarSerial: { value: '' },
                vat2Manufacturer: { value: ''},
                vat2Serial: { value: ''},

                vat3Height: { value: '' },
                vat3OClock: { value: '' },
                vat3RadarSerial: { value: '' },
                vat3Manufacturer: { value: ''},
                vat3Serial: { value: ''},

                vat4Height: { value: '' },
                vat4OClock: { value: '' },
                vat4RadarSerial: { value: '' },
                vat4Manufacturer: { value: ''},
                vat4Serial: { value: ''},
            },
        })
        this.state = {
            error: false, resultDialog: false,
            tab: 0, numVats: 1, numDisplays: 0, lowConnectivity: false,
            vat1Diameter: "", vat1Volume: "", vat1ManualVatInput: false, vat1ManualRadarInput: false, vat1Auto: false,
            vat2Diameter: "", vat2Volume: "", vat2ManualVatInput: false, vat2ManualRadarInput: false, vat2Auto: false,
            vat3Diameter: "", vat3Volume: "", vat3ManualVatInput: false, vat3ManualRadarInput: false, vat3Auto: false,
            vat4Diameter: "", vat4Volume: "", vat4ManualVatInput: false, vat4ManualRadarInput: false, vat4Auto: false,
        }
        store.dashboard.menuActive = 'New Install'
        store.dashboard.navbarLeft = 'New Install'
        store.showFarmList = false
    }

    submitNewInstall = () => {

        // Set Heights
        if (!this.state.vat1ManualVatInput && this.state.vat1Diameter != '' && this.state.vat1Volume != ''){
            this.data.vat1Height.value = this.getKnownVatHeight(this.state.vat1Diameter,this.state.vat1Volume)
        }
        if (!this.state.vat2ManualVatInput && this.state.vat2Diameter != '' && this.state.vat2Volume != ''){
            this.data.vat2Height.value = this.getKnownVatHeight(this.state.vat2Diameter,this.state.vat2Volume)
        }
        if (!this.state.vat3ManualVatInput && this.state.vat3Diameter != '' && this.state.vat3Volume != ''){
            this.data.vat3Height.value = this.getKnownVatHeight(this.state.vat3Diameter,this.state.vat3Volume)
        }
        if (!this.state.vat4ManualVatInput && this.state.vat4Diameter != '' && this.state.vat4Volume != ''){
            this.data.vat4Height.value = this.getKnownVatHeight(this.state.vat4Diameter,this.state.vat4Volume)
        }

        // Data Validation
        var valid = true;

        if (this.data.imei.value == "" || this.data.dairySupplier.value == "" || this.data.supplyNumber.value == "" || this.data.productVariant.value == "" || this.state.numVats == "") valid = false
        if (this.state.numVats > 0 && (this.state.vat1Diameter == "" || this.state.vat1Volume == "" || this.data.vat1Height.value == "" || this.data.vat1RadarSerial.value == "")) valid = false
        if (this.state.numVats > 1 && (this.state.vat2Diameter == "" || this.state.vat2Volume == "" || this.data.vat2Height.value == "" || this.data.vat2RadarSerial.value == "")) valid = false
        if (this.state.numVats > 2 && (this.state.vat3Diameter == "" || this.state.vat3Volume == "" || this.data.vat3Height.value == "" || this.data.vat3RadarSerial.value == "")) valid = false
        if (this.state.numVats > 3 && (this.state.vat4Diameter == "" || this.state.vat4Volume == "" || this.data.vat4Height.value == "" || this.data.vat4RadarSerial.value == "")) valid = false

        if (valid){
            // Params
            const params = new URLSearchParams()
            params.append('type', 'NEW_INSTALL')
    
            // Device
            params.append('imei', this.data.imei.value)
            params.append('dairySupplier', this.data.dairySupplier.value)
            params.append('supplyNumber', this.data.supplyNumber.value)
            params.append('productVariant', this.data.productVariant.value)
            params.append('numVats', this.state.numVats)
            params.append('numDisplays', this.state.numDisplays)
            params.append('lowConnectivity',this.state.lowConnectivity)
            params.append('timezone',moment.tz.guess())

            // Vat 1
            params.append('vat1Diameter', this.state.vat1Diameter)
            params.append('vat1Volume', this.state.vat1Volume.replace(' Sidewall',''))
            params.append('vat1Height', this.data.vat1Height.value)
            params.append('vat1Manufacturer', this.data.vat1Manufacturer.value)
            params.append('vat1Serial', this.data.vat1Serial.value)
            params.append('vat1OClock', this.data.vat1OClock.value)
            params.append('vat1RadarSerial', this.data.vat1RadarSerial.value)
            params.append('vat1Auto', this.state.vat1Auto)
    
            // Vat 2
            if (this.state.numVats > 1){
                params.append('vat2Diameter', this.state.vat2Diameter)
                params.append('vat2Volume', this.state.vat2Volume.replace(' Sidewall',''))
                params.append('vat2Height', this.data.vat2Height.value)
                params.append('vat2Manufacturer', this.data.vat2Manufacturer.value)
                params.append('vat2Serial', this.data.vat2Serial.value)
                params.append('vat2OClock', this.data.vat2OClock.value)
                params.append('vat2RadarSerial', this.data.vat2RadarSerial.value)
                params.append('vat2Auto', this.state.vat2Auto)
            }
    
            // Vat 3
            if (this.state.numVats > 2){
                params.append('vat3Diameter', this.state.vat3Diameter)
                params.append('vat3Volume', this.state.vat3Volume.replace(' Sidewall',''))
                params.append('vat3Height', this.data.vat3Height.value)
                params.append('vat3Manufacturer', this.data.vat3Manufacturer.value)
                params.append('vat3Serial', this.data.vat3Serial.value)
                params.append('vat3OClock', this.data.vat3OClock.value)
                params.append('vat3RadarSerial', this.data.vat3RadarSerial.value)
                params.append('vat3Auto', this.state.vat3Auto)
            }
    
            // Vat 4
            if (this.state.numVats > 3){
                params.append('vat4Diameter', this.state.vat4Diameter)
                params.append('vat4Volume', this.state.vat4Volume.replace(' Sidewall',''))
                params.append('vat4Height', this.data.vat4Height.value)
                params.append('vat4Manufacturer', this.data.vat4Manufacturer.value)
                params.append('vat4Serial', this.data.vat4Serial.value)
                params.append('vat4OClock', this.data.vat4OClock.value)
                params.append('vat4RadarSerial', this.data.vat4RadarSerial.value)
                params.append('vat4Auto', this.state.vat4Auto)
            }
    
            // Post
            Utils.httpPost(Utils.urlList.installer, params, data => {
                console.log(data)

                this.data.success.value = data['success']
                this.data.resultMessage.value = data['result']
                this.data.redirect.value = data['redirect']

                // Popup on success or fail, then redirect on success to Fonterra form or device config
                this.setState({resultDialog: true})
            })
        }
        else this.setState({error: true})
    }

    getKnownVatHeight(diameter,volume) {
        if (diameter == '2337'){
            if (volume == '4500') return '1219'
            else if (volume == '5700') return '1500'
            else if (volume == '6800') return '1750'
            else if (volume == '7800') return '2000'
            else if (volume == '9100') return '2438'
            else if (volume == '11000') return '2719'
            else if (volume == '12000') return '3000'
            else if (volume == '14000 Sidewall') return '3500'
            else if (volume == '16000 Sidewall') return '4000'
            else if (volume == '18000') return '4438'
        }
        else if (diameter == '2875'){
            if (volume == '11500') return '2000'
            else if (volume == '14000') return '2438'
            else if (volume == '16000') return '2719'
            else if (volume == '18000') return '3000'
            else if (volume == '18000 Sidewall') return '2750'
            else if (volume == '21500 Sidewall') return '3500'
            else if (volume == '24000 Sidewall') return '4000'
            else if (volume == '26000 Sidewall') return '4438'
            else if (volume == '30000 Sidewall') return '4719'
        }
        else return ''
    }

    handleDialogClose = () => {
        this.setState({resultDialog: false})

        // Handle success or error
        if (this.data.redirect.value){
            this.redirect()
        }
    }

    redirect = () => {
        // After successful submit on Fonterra farm, redirect to Fonterra form
        // If non-Fonterra farm or particle devices already exist, redirect to check install
        
        if (this.data.success.value){
            if (this.data.dairySupplier.value == "FON"){
                window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSeTX3ROcAjEJYyPM3FffzpVHSK-WKFZkU8MvTOmZAHfLaBsDQ/viewform"
            }
            else{
                window.location.href = "/dts/installer/check"
            }
        }
        else {
            window.location.href = "/dts/installer/check"
        }
    }

    render() {
        return (
            <div className='container-fluid bg-white'>
                <Dialog
                    open={this.state.resultDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.data.success.value == true && "Vat-Man Install Submitted Successfully"}
                        {this.data.success.value == false && "Error Submitting Vat-Man Install"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{this.data.resultMessage.value}</DialogContentText>
                    </DialogContent>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogClose}>
                        Ok
                    </Button>
                </Dialog>
                <Tabs
                    value={this.state.tab}
                    onChange={(e, newValue) => {
                        this.setState({ tab: newValue })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Device" />
                    <Tab label="Vat 1" />
                    {this.state.numVats > 1 && <Tab label="Vat 2" />}
                    {this.state.numVats > 2 && <Tab label="Vat 3" />}
                    {this.state.numVats > 3 && <Tab label="Vat 4" />}
                </Tabs>
                <Container component="main" maxWidth="xs">
                    {this.state.tab == 0 &&
                        <div className='container-fluid my-3'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="imei"
                                label="IMEI"
                                id="imei"
                                value={this.data.imei.value}
                                helperText={this.data.imei.value == '' && this.state.error && "Required Field"}
                                error={this.data.imei.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.imei.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select
                                name="dairySupplier"
                                label="Farm Dairy Supplier"
                                id="dairySupplier"
                                value={this.data.dairySupplier.value}
                                helperText={this.data.dairySupplier.value == '' && this.state.error  && "Required Field"}
                                error={this.data.dairySupplier.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.dairySupplier.value = e.target.value
                                }}
                            >
                                <MenuItem key="FON" value="FON">Fonterra</MenuItem>
                                <MenuItem key="OCD" value="OCD">Open Country Dairy</MenuItem>
                                <MenuItem key="SYN" value="SYN">Synlait</MenuItem>
                                <MenuItem key="MIR" value="MIR">Miraka</MenuItem>
                                <MenuItem key="WES" value="WES">Westlands</MenuItem>
                                <MenuItem key="TAT" value="TAT">Tatua</MenuItem>
                                <MenuItem key="MAT" value="MAT">Mataura Valley Milk</MenuItem>
                                <MenuItem key="GRE" value="GRE">Green Valley Milk</MenuItem>
                                <MenuItem key="DGC" value="DGC">Dairy Goat Cooperative</MenuItem>
                                <MenuItem key="WAI" value="WAI">Waiu</MenuItem>
                                <MenuItem key="OTH" value="OTH">Other</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="supplyNumber"
                                label="Supply Number"
                                type="number"
                                id="supplyNumber"
                                value={this.data.supplyNumber.value}
                                helperText={this.data.supplyNumber.value == '' && this.state.error  && "Required Field"}
                                error={this.data.supplyNumber.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.supplyNumber.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select
                                name="productVariant"
                                label="Product Variant"
                                id="productVariant"
                                value={this.data.productVariant.value}
                                helperText={this.data.productVariant.value == '' && this.state.error  && "Required Field"}
                                error={this.data.productVariant.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.productVariant.value = e.target.value
                                }}
                            >
                                <MenuItem key="Vat-Man Lite" value="Vat-Man Lite">Vat-Man Lite</MenuItem>
                                <MenuItem key="Vat-Man Pro" value="Vat-Man Pro">Vat-Man Pro</MenuItem>
                                <MenuItem key="Vat-Man Auto" value="Vat-Man Auto">Vat-Man Auto</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select
                                name="numVats"
                                label="Number of Vats Monitored"
                                type="number"
                                id="numVats"
                                value={this.state.numVats}
                                helperText={this.state.numVats == '' && this.state.error  && "Required Field"}
                                error={this.state.numVats == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ numVats: e.target.value })
                                }}
                            >
                                <MenuItem key="1" value="1">1</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                                <MenuItem key="3" value="3">3</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select
                                name="numDisplays"
                                label="Number of Displays Installed"
                                type="number"
                                id="numDisplays"
                                value={this.state.numDisplays}
                                helperText={this.state.numDisplays == '' && this.state.error  && "Required Field"}
                                error={this.state.numDisplays == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ numDisplays: e.target.value })
                                }}
                            >
                                <MenuItem key="0" value="0">0</MenuItem>
                                <MenuItem key="1" value="1">1</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                                <MenuItem key="3" value="3">3</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                            </TextField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.lowConnectivity}
                                        onChange={ () => {this.setState({ lowConnectivity: !this.state.lowConnectivity })} }
                                        name="checkedLowConnectivity"
                                        color="primary"
                                    />
                                }
                                label="Select If Low Connectivity Install"
                            />
                            <div>Please fill in all required (*) fields. The submit button is at the end of Vat {this.state.numVats}.</div>
                        </div>
                    }
                    {this.state.tab == 1 &&
                        <div className='container-fluid my-3'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat1ManualVatInput}
                                        onChange={e => {
                                            this.setState({ vat1ManualVatInput: e.target.checked, vat1Volume: '',})
                                        }}
                                    />}
                                label="Toggle Manual Vat Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat1ManualVatInput}
                                name="vat1Diameter"
                                label="Vat Diameter (mm)"
                                type="number"
                                id="vat1Diameter"
                                value={this.state.vat1Diameter}
                                helperText={this.state.vat1Diameter == '' && this.state.error  && "Required Field"}
                                error={this.state.vat1Diameter == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.setState({ vat1Diameter: e.target.value })
                                }}
                            >
                                <MenuItem key="2337" value="2337">2337</MenuItem>
                                <MenuItem key="2875" value="2875">2875</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat1ManualVatInput}
                                name="vat1Volume"
                                label="Vat Capacity (L)"
                                type="number"
                                id="vat1Volume"
                                value={this.state.vat1Volume}
                                helperText={this.state.vat1Volume == '' && this.state.error  && "Required Field"}
                                error={this.state.vat1Volume == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ vat1Volume: e.target.value })
                                }}
                            >
                                { this.state.vat1Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="6800" value="6800">6800</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                
                                { this.state.vat1Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                { this.state.vat1Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                            </TextField>
                            {this.state.vat1ManualVatInput &&
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat1Height"
                                    label="Height (mm)"
                                    type="number"
                                    id="vat1Height"
                                    value={this.data.vat1Height.value}
                                    helperText={this.data.vat1Height.value == '' && this.state.error  && "Required Field"}
                                    error={this.data.vat1Height.value == '' && this.state.error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.data.vat1Height.value = e.target.value
                                    }}
                                />
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat1Manufacturer"
                                label="Vat Manufacturer (if available)"
                                id="vat1Manufacturer"
                                value={this.data.vat1Manufacturer.value}
                                onChange={e => {
                                    this.data.vat1Manufacturer.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat1Serial"
                                label="Vat Serial Number (if available)"
                                id="vat1Serial"
                                value={this.data.vat1Serial.value}
                                onChange={e => {
                                    this.data.vat1Serial.value = e.target.value
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.vat1Auto}
                                        onChange={() => {this.setState({ vat1Auto: !this.state.vat1Auto })} }
                                        name="checkedVat1Auto"
                                        color="primary"
                                    />
                                }
                                label="Select if this Vat has Vat-Man Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat1ManualRadarInput}
                                        onChange={e => {
                                            this.setState({ vat1ManualRadarInput: e.target.checked })
                                        }}
                                    />}
                                label="Toggle Manual Radar Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="vat1RadarSerial"
                                label="Radar Serial Number"
                                id="vat1RadarSerial"
                                value={this.data.vat1RadarSerial.value}
                                helperText={this.data.vat1RadarSerial.value == '' && this.state.error  && "Required Field"}
                                error={this.data.vat1RadarSerial.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.vat1RadarSerial.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                select={!this.state.vat1ManualRadarInput}
                                name="vat1OClock"
                                label="Radar O'Clock Position"
                                type="number"
                                id="vat1OClock"
                                value={this.data.vat1OClock.value}
                                onChange={e => {
                                    if (e.target.value >= 0 && e.target.value <= 12) this.data.vat1OClock.value = e.target.value
                                }}
                            >
                                <MenuItem key="12" value="12">12</MenuItem>
                                <MenuItem key="10" value="10">10</MenuItem>
                                <MenuItem key="8" value="8">8</MenuItem>
                                <MenuItem key="6" value="6">6</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                            </TextField>
                            <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            {this.state.numVats == 1 &&
                                <div className='container-fluid my-3'>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.submitNewInstall}>
                                        Submit New Install
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                    {this.state.tab == 2 &&
                        <div className='container-fluid my-3'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat2ManualVatInput}
                                        onChange={e => {
                                            this.setState({ vat2ManualVatInput: e.target.checked, vat2Volume: '',})
                                        }}
                                    />}
                                label="Toggle Manual Vat Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat2ManualVatInput}
                                name="vat2Diameter"
                                label="Vat Diameter (mm)"
                                type="number"
                                id="vat2Diameter"
                                value={this.state.vat2Diameter}
                                helperText={this.state.vat2Diameter == '' && this.state.error  && "Required Field"}
                                error={this.state.vat2Diameter == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.setState({ vat2Diameter: e.target.value})
                                }}
                            >
                                <MenuItem key="2337" value="2337">2337</MenuItem>
                                <MenuItem key="2875" value="2875">2875</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat2ManualVatInput}
                                name="vat2Volume"
                                label="Vat Capacity (L)"
                                type="number"
                                id="vat2Volume"
                                value={this.state.vat2Volume}
                                helperText={this.state.vat2Volume == '' && this.state.error  && "Required Field"}
                                error={this.state.vat2Volume == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ vat2Volume: e.target.value })
                                }}
                            >
                                { this.state.vat2Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="6800" value="6800">6800</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                
                                { this.state.vat2Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                { this.state.vat2Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                            </TextField>
                            {this.state.vat2ManualVatInput &&
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat2Height"
                                    label="Height (mm)"
                                    type="number"
                                    id="vat2Height"
                                    value={this.data.vat2Height.value}
                                    helperText={this.data.vat2Height.value == '' && this.state.error  && "Required Field"}
                                    error={this.data.vat2Height.value == '' && this.state.error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.data.vat2Height.value = e.target.value
                                    }}
                                />
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat2Manufacturer"
                                label="Vat Manufacturer (if available)"
                                id="vat2Manufacturer"
                                value={this.data.vat2Manufacturer.value}
                                onChange={e => {
                                    this.data.vat2Manufacturer.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat2Serial"
                                label="Vat Serial Number (if available)"
                                id="vat2Serial"
                                value={this.data.vat2Serial.value}
                                onChange={e => {
                                    this.data.vat2Serial.value = e.target.value
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.vat2Auto}
                                        onChange={() => {this.setState({ vat2Auto: !this.state.vat2Auto })} }
                                        name="checkedVat2Auto"
                                        color="primary"
                                    />
                                }
                                label="Select if this Vat has Vat-Man Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat2ManualRadarInput}
                                        onChange={e => {
                                            this.setState({ vat2ManualRadarInput: e.target.checked })
                                        }}
                                    />}
                                label="Toggle Manual Radar Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="vat2RadarSerial"
                                label="Radar Serial Number"
                                id="vat2RadarSerial"
                                value={this.data.vat2RadarSerial.value}
                                helperText={this.data.vat2RadarSerial.value == '' && this.state.error  && "Required Field"}
                                error={this.data.vat2RadarSerial.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.vat2RadarSerial.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                select={!this.state.vat2ManualRadarInput}
                                name="vat2OClock"
                                label="Radar O'Clock Position"
                                type="number"
                                id="vat2OClock"
                                value={this.data.vat2OClock.value}
                                onChange={e => {
                                    if (e.target.value >= 0 && e.target.value <= 12) this.data.vat2OClock.value = e.target.value
                                }}
                            >
                                <MenuItem key="12" value="12">12</MenuItem>
                                <MenuItem key="10" value="10">10</MenuItem>
                                <MenuItem key="8" value="8">8</MenuItem>
                                <MenuItem key="6" value="6">6</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                            </TextField>
                            <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            {this.state.numVats == 2 &&
                                <div className='container-fluid my-3'>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.submitNewInstall}>
                                        Submit New Install
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                    {this.state.tab == 3 &&
                        <div className='container-fluid my-3'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat3ManualVatInput}
                                        onChange={e => {
                                            this.setState({ vat3ManualVatInput: e.target.checked, vat3Volume: '',})
                                        }}
                                    />}
                                label="Toggle Manual Vat Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat3ManualVatInput}
                                name="vat3Diameter"
                                label="Vat Diameter (mm)"
                                type="number"
                                id="vat3Diameter"
                                value={this.state.vat3Diameter}
                                helperText={this.state.vat3Diameter == '' && this.state.error  && "Required Field"}
                                error={this.state.vat3Diameter == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.setState({ vat3Diameter: e.target.value })
                                }}
                            >
                                <MenuItem key="2337" value="2337">2337</MenuItem>
                                <MenuItem key="2875" value="2875">2875</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat3ManualVatInput}
                                name="vat3Volume"
                                label="Vat Capacity (L)"
                                type="number"
                                id="vat3Volume"
                                value={this.state.vat3Volume}
                                helperText={this.state.vat3Volume == '' && this.state.error  && "Required Field"}
                                error={this.state.vat3Volume == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ vat3Volume: e.target.value })
                                }}
                            >
                                { this.state.vat3Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="6800" value="6800">6800</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                
                                { this.state.vat3Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                { this.state.vat3Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                            </TextField>
                            {this.state.vat3ManualVatInput &&
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat3Height"
                                    label="Height (mm)"
                                    type="number"
                                    id="vat3Height"
                                    value={this.data.vat3Height.value}
                                    helperText={this.data.vat3Height.value == '' && this.state.error  && "Required Field"}
                                    error={this.data.vat3Height.value == '' && this.state.error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.data.vat3Height.value = e.target.value
                                    }}
                                />
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat3Manufacturer"
                                label="Vat Manufacturer (if available)"
                                id="vat3Manufacturer"
                                value={this.data.vat3Manufacturer.value}
                                onChange={e => {
                                    this.data.vat3Manufacturer.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat3Serial"
                                label="Vat Serial Number (if available)"
                                id="vat3Serial"
                                value={this.data.vat3Serial.value}
                                onChange={e => {
                                    this.data.vat3Serial.value = e.target.value
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.vat3Auto}
                                        onChange={() => {this.setState({ vat3Auto: !this.state.vat3Auto })} }
                                        name="checkedVat3Auto"
                                        color="primary"
                                    />
                                }
                                label="Select if this Vat has Vat-Man Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat3ManualRadarInput}
                                        onChange={e => {
                                            this.setState({ vat3ManualRadarInput: e.target.checked })
                                        }}
                                    />}
                                label="Toggle Manual Radar Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="vat3RadarSerial"
                                label="Radar Serial Number"
                                id="vat3RadarSerial"
                                value={this.data.vat3RadarSerial.value}
                                helperText={this.data.vat3RadarSerial.value == '' && this.state.error  && "Required Field"}
                                error={this.data.vat3RadarSerial.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.vat3RadarSerial.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                select={!this.state.vat3ManualRadarInput}
                                name="vat3OClock"
                                label="Radar O'Clock Position"
                                type="number"
                                id="vat3OClock"
                                value={this.data.vat3OClock.value}
                                onChange={e => {
                                    if (e.target.value >= 0 && e.target.value <= 12) this.data.vat3OClock.value = e.target.value
                                }}
                            >
                                <MenuItem key="12" value="12">12</MenuItem>
                                <MenuItem key="10" value="10">10</MenuItem>
                                <MenuItem key="8" value="8">8</MenuItem>
                                <MenuItem key="6" value="6">6</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                            </TextField>
                            <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            {this.state.numVats == 3 &&
                                <div className='container-fluid my-3'>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.submitNewInstall}>
                                        Submit New Install
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                    {this.state.tab == 4 &&
                        <div className='container-fluid my-3'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat4ManualVatInput}
                                        onChange={e => {
                                            this.setState({ vat4ManualVatInput: e.target.checked, vat4Volume: ''})
                                        }}
                                    />}
                                label="Toggle Manual Vat Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat4ManualVatInput}
                                name="vat4Diameter"
                                label="Vat Diameter (mm)"
                                type="number"
                                id="vat4Diameter"
                                value={this.state.vat4Diameter}
                                helperText={this.state.vat4Diameter == '' && this.state.error  && "Required Field"}
                                error={this.state.vat4Diameter == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.setState({ vat4Diameter: e.target.value})
                                }}
                            >
                                <MenuItem key="2337" value="2337">2337</MenuItem>
                                <MenuItem key="2875" value="2875">2875</MenuItem>
                            </TextField>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                select={!this.state.vat4ManualVatInput}
                                name="vat4Volume"
                                label="Vat Capacity (L)"
                                type="number"
                                id="vat4Volume"
                                value={this.state.vat4Volume}
                                helperText={this.state.vat4Volume == '' && this.state.error  && "Required Field"}
                                error={this.state.vat4Volume == '' && this.state.error}
                                onChange={e => {
                                    this.setState({ vat4Volume: e.target.value })
                                }}
                            >
                                { this.state.vat4Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                { this.state.vat1Diameter == "2337" && <MenuItem key="6800" value="6800">6800</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                
                                { this.state.vat4Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                { this.state.vat4Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                            </TextField>
                            {this.state.vat4ManualVatInput &&
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat4Height"
                                    label="Height (mm)"
                                    type="number"
                                    id="vat4Height"
                                    value={this.data.vat4Height.value}
                                    helperText={this.data.vat4Height.value == '' && this.state.error  && "Required Field"}
                                    error={this.data.vat4Height.value == '' && this.state.error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.data.vat4Height.value = e.target.value
                                    }}
                                />
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat4Manufacturer"
                                label="Vat Manufacturer (if available)"
                                id="vat4Manufacturer"
                                value={this.data.vat4Manufacturer.value}
                                onChange={e => {
                                    this.data.vat4Manufacturer.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="vat4Serial"
                                label="Vat Serial Number (if available)"
                                id="vat4Serial"
                                value={this.data.vat4Serial.value}
                                onChange={e => {
                                    this.data.vat4Serial.value = e.target.value
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.vat4Auto}
                                        onChange={() => {this.setState({ vat4Auto: !this.state.vat4Auto })} }
                                        name="checkedVat4Auto"
                                        color="primary"
                                    />
                                }
                                label="Select if this Vat has Vat-Man Auto"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.vat4ManualRadarInput}
                                        onChange={e => {
                                            this.setState({ vat4ManualRadarInput: e.target.checked })
                                        }}
                                    />}
                                label="Toggle Manual Radar Input"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="vat4RadarSerial"
                                label="Radar Serial Number"
                                id="vat4RadarSerial"
                                value={this.data.vat4RadarSerial.value}
                                helperText={this.data.vat4RadarSerial.value == '' && this.state.error  && "Required Field"}
                                error={this.data.vat4RadarSerial.value == '' && this.state.error}
                                onChange={e => {
                                    this.data.vat4RadarSerial.value = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                select={!this.state.vat4ManualRadarInput}
                                name="vat4OClock"
                                label="Radar O'Clock Position"
                                type="number"
                                id="vat4OClock"
                                value={this.data.vat4OClock.value}
                                onChange={e => {
                                    if (e.target.value >= 0 && e.target.value <= 12) this.data.vat4OClock.value = e.target.value
                                }}
                            >
                                <MenuItem key="12" value="12">12</MenuItem>
                                <MenuItem key="10" value="10">10</MenuItem>
                                <MenuItem key="8" value="8">8</MenuItem>
                                <MenuItem key="6" value="6">6</MenuItem>
                                <MenuItem key="4" value="4">4</MenuItem>
                                <MenuItem key="2" value="2">2</MenuItem>
                            </TextField>
                            <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            {this.state.numVats == 4 &&
                                <div className='container-fluid my-3'>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.submitNewInstall}>
                                        Submit New Install
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </Container>
            </div>
        )
    }
}

export default withRouter(observer(Component))