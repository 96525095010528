import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, Button, MuiThemeProvider, Divider } from '@material-ui/core';
import theme from "./theme";
import Utils from "../../utility/Utils";

function DTSPopoverHalo(props) {
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setDialogOpen(true);
  }, []); // Empty dependency array means this runs once when the component mounts

  const routeChange = () => {
    let path = 'https://www.halosystems.co.nz/welcome-dts'; 
    window.location.href = path;
  };

  const closeDialog = () => {
    setDialogOpen(false);
    Utils.setHaloDialog();
    //let curhref = window.location.href;
    //window.location.reload();
  };

  const handleClose = () => {
    closeDialog();
  };

  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };

  const svgWidth = 500; // Example width of the SVG image

//https://static.herdman.co.nz/common/images/logo-halo.svg
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog maxWidth={400} onClose={handleClose} aria-labelledby="simple-dialog-title" open={isDialogOpen}>
        <DialogTitle id="simple-dialog-title">Please Select</DialogTitle>
        <List style={flexContainer}>
          <ListItem>
            <ListItemText primary={props.text2}/>
          </ListItem>
          <Divider orientation="vertical" flexItem />
          <ListItem>
            <ListItemText primary={props.text}/>
          </ListItem>
        </List>
        <List style={flexContainer}>
          <ListItem>
            <Button style={{margin: 'auto'}} height={'200'} width={500} startIcon={<img src={'https://static.herdman.co.nz/common/images/MilkHub-Logo.png'} style={{ maxWidth: '100%', height: 'auto', width: svgWidth }} />} color="primary" onClick={closeDialog}></Button>
          </ListItem>
          <Divider orientation="vertical" flexItem />
          <ListItem>
            <Button style={{margin: 'auto'}} height={'200'} width={500} startIcon={<img src={'https://static.herdman.co.nz/common/images/logo-halo.svg'}  style={{ maxWidth: '100%', height: 'auto', width: svgWidth }} />} color="primary" onClick={routeChange}></Button>
          </ListItem>
        </List>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default DTSPopoverHalo;
