import React from 'react'
import BaseDashboard from "./BaseDashboard";
import {Button} from "@material-ui/core";
import store from "../../store/Store";
import Utils from "../../utility/Utils";

class Component extends BaseDashboard {

  constructor(props) {
    super(props)
    // this.navbarSet()
    store.showFarmList = true
    store.dashboard.menuActive = null
    store.dashboard.navbarRight = null
  }

  // Obsolete by Jack Yang
  navbarSet = () => {
    let Farm = JSON.parse(Utils.farmGet())
    if (Farm && Farm._id) {
      store.dashboard.navbarRight = <Button color="primary">{Farm.fonterra_id}</Button>
    } else {
      store.dashboard.navbarRight = null
    }
  }

}

export default Component
