import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";
import Utils from "../../utility/Utils";

import {AutoSizer, List} from 'react-virtualized';
import store from "../../store/Store";
import {withRouter} from "react-router-dom";
import BaseDashboardAdmin from "./BaseDashboardAdmin";

class Component extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    this.state = {
      listDevice: [],
    }
    extendObservable(this, {
      httpCancel: null,
    })
    this.initData()
    store.dashboard.menuActive = 'Devices'
    store.dashboard.navbarLeft = 'DTS Dashboard'
  }

  componentWillUnmount() {
    if (this.httpCancel) {
      this.httpCancel.cancel()
    }
  }

  initData = () => {
    if (store.listDevice.length > 0) {
      return
    }
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_LIST');
    this.httpCancel = Utils.httpPost(Utils.urlList.admin_device, params, data => {
      console.log(data)
      store.listDevice = data
      this.setState({listDevice: data});

    })
  }

  rowRenderer = ({key, index, style}) =>
    <div key={key} style={style}>
      {store.listDevice[index].name}
    </div>


  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3' style={{width: '100%', height: '200px'}}>


          <AutoSizer>
            {({width, height}) => (
              <List
                height={height}
                rowCount={store.listDevice.length}
                rowHeight={30}
                rowRenderer={this.rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>


        </div>
      </div>
    )
  }


}

export default withRouter(observer(Component))
