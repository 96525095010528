import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from '@material-ui/styles';
import Styles from '../common/Styles'
import ActivityMilkingListScatterVolume from "./farmer/ActivityMilkingListScatterVolume";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import * as moment from "moment";
import ActivityMilkingOneLineTemp from "./farmer/ActivityMilkingOneLineTemp";
import { Spin } from "antd";
import DTS from "../../utility/DTS";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import theme from "../common/theme";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiThemeProvider } from "@material-ui/core";

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      milkingOneDetail: {
        // listHerd: [],
      },
      milkingListBrief: [],
      isEditHerd: false,
    })
    this.initData(this.props.match.params.activity_id)

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Milking'
  }

  @computed get getAgitator() {
    let agitator = {}
    if (this.milkingOneDetail.detailData && this.milkingOneDetail.detailData.length) {
      agitator = DTS.getAgitatorStopStat(this.milkingOneDetail.detailData)
    }
    return agitator
  }

  initData = (activity_id) => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_MILKING_ONE');
    params.append('activity_id', activity_id);
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      this.milkingOneDetail = data
      // this.getMilkingListBrief(activity_id)
    })
  }

  getMilkingListBrief = (activity_id) => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_MILKING_LIST_BRIEF');
    params.append('activity_id', activity_id);
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      this.milkingListBrief = data
    })
  }

  updateHerd = (herd) => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_MILKING_ONE_HEAD');
    params.append('activity_id', this.props.match.params.activity_id);
    params.append('herd', JSON.stringify(herd));
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
    })
  }

  getValueStart = (fieldName) => {
    let result = <Spin />
    if (!this.milkingOneDetail.activity) {
      return result
    }
    switch (fieldName) {
      case 'published_at':
        result = moment.utc(this.milkingOneDetail.activity.start.published_at).local().format(global.constants.date_format_week)
        break
      case 'temp1':
        let temp1 = DTS.getTemperature(this.milkingOneDetail.activity.start.temp1)
        result = temp1 ? (temp1 + ' ℃') : 'N/A'
        break
      case 'temp2':
        let temp2 = DTS.getTemperature(this.milkingOneDetail.activity.start.temp2)
        result = temp2 ? (temp2 + ' ℃') : 'N/A'
        break
      case 'temp3':
        let temp3 = DTS.getTemperature(this.milkingOneDetail.activity.start.temp3)
        result = temp3 ? (temp3 + ' ℃') : 'N/A'
        break
      case 'milk_level':
        result = this.milkingOneDetail.activity.start.milk_level
        break
      case 'milk_volume':
        let volume = this.milkingOneDetail.activity.start.milk_volume
        let size = this.milkingOneDetail.config.vat_size
        let scale = volume / size
        if (scale < 0.05) {
          volume = 0
        }
        volume = DTS.getVolumeRound(volume)
        let e = <div className='d-inline-block border border-primary rounded-circle px-4 py-2'>
          {`${volume} L`}
        </div>
        result = e
        break
      case 'shaft_rpm':
        result = this.milkingOneDetail.activity.start.shaft_rpm > 0 ? 'Running' : 'Stopped'
        break

    }
    return result
  }

  getValueEnd = (fieldName) => {
    let result = <Spin />
    if (!this.milkingOneDetail.activity) {
      return result
    }
    switch (fieldName) {
      case 'published_at':
        result = moment.utc(this.milkingOneDetail.activity.end.published_at).local().format(global.constants.date_format_week)
        break
      case 'temp1':
        let temp1 = DTS.getTemperature(this.milkingOneDetail.activity.end.temp1)
        result = temp1 ? (temp1 + ' ℃') : 'N/A'
        break
      case 'temp2':
        let temp2 = DTS.getTemperature(this.milkingOneDetail.activity.end.temp2)
        result = temp2 ? (temp2 + ' ℃') : 'N/A'
        break
      case 'temp3':
        let temp3 = DTS.getTemperature(this.milkingOneDetail.activity.end.temp3)
        result = temp3 ? (temp3 + ' ℃') : 'N/A'
        break
      case 'milk_level':
        result = this.milkingOneDetail.activity.end.milk_level
        break
      case 'milk_volume':
        let e = <div className='d-inline-block border border-primary rounded-circle px-4 py-2'>
          {`${DTS.getVolumeRound(this.milkingOneDetail.activity.end.milk_volume)} L`}
        </div>
        result = e
        break
      case 'shaft_rpm':
        result = this.milkingOneDetail.activity.end.shaft_rpm > 0 ? 'Running' : 'Stopped'
        break

    }
    return result
  }

  getDuration = () => {
    let result = <Spin />
    if (this.milkingOneDetail.activity) {
      let start = moment.utc(this.milkingOneDetail.activity.start.published_at)
      let end = moment.utc(this.milkingOneDetail.activity.end.published_at)
      result = moment.utc(end.diff(start)).format("HH:mm")
    }
    return result
  }

  getTakings = () => {
    let result = <Spin />
    if (this.milkingOneDetail.activity) {
      let volume = this.milkingOneDetail.activity.start.milk_volume
      let size = this.milkingOneDetail.config.vat_size
      let scale = volume / size
      if (scale < 0.05) {
        volume = 0
      }
      // volume = Math.round(volume / 100) * 100
      volume = DTS.getVolumeRoundNumber(volume) // Math.round(volume / 100) * 100

      let end = this.milkingOneDetail.activity.end.milk_volume
      // end = Math.round(end / 100) * 100
      end = DTS.getVolumeRoundNumber(end) // Math.round(end / 100) * 100

      result = parseInt(end) - parseInt(volume)
      result = DTS.getVolumeRound(result) // Math.round(end / 100) * 100
    }
    return result
  }

  getTakingsVal = () => {
    let result = 0
    if (this.milkingOneDetail.activity) {
      let volume = this.milkingOneDetail.activity.start.milk_volume
      let end = this.milkingOneDetail.activity.end.milk_volume
      result = parseInt(end) - parseInt(volume)
    }
    return result
  }

  getSpeed = () => {
    let result = <Spin />
    if (this.milkingOneDetail.activity) {
      let start = moment.utc(this.milkingOneDetail.activity.start.published_at)
      let end = moment.utc(this.milkingOneDetail.activity.end.published_at)
      let duration = moment.utc(end.diff(start, 'minutes'))

      let taking = this.getTakingsVal()
      result = parseInt(taking / duration)
    }
    return result
  }

  // go to another milking
  reloadData = (activity_id) => {
    this.initData(activity_id)
  }

  render() {
    const { classes } = this.props;
    document.body.classList.add('milk-nav');

    return (
      <div className='container-fluid'>

        {/* <Card className='mt-3'>
          <div className='container-fluid'>
            <div className='row my-3'>
              {this.milkingOneDetail.activity && this.milkingOneDetail.activity.special
                ? <div className='col'>
                  Herd: <span className='text-primary'>{this.milkingOneDetail.activity.special.herd.name}</span>
                  <Button className='ml-3' color="secondary" onClick={e => {
                    this.isMenuAnchorEl = e.currentTarget
                    this.isEditHerd = true
                  }}>Edit</Button>
                  <MuiThemeProvider theme={theme}>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.isMenuAnchorEl}
                      keepMounted
                      open={this.isEditHerd}
                      onClose={() => {
                        this.isEditHerd = false
                      }}
                    >
                      {this.milkingOneDetail.listHerd.map((item, index) => <MenuItem key={index} onClick={() => {
                        this.milkingOneDetail.activity.special.herd.name = item.name
                        this.updateHerd(item)
                        this.isEditHerd = false
                      }}>{item.name}</MenuItem>)}
                    </Menu>
                  </MuiThemeProvider>
                </div>
                : <div className='col'>No Herd information</div>}
            </div>
          </div>
        </Card> */}

        <Card className='mt-3'>
          <CardHeader subheader={`Overall ${this.milkingOneDetail.config ? '(Vat ' + this.milkingOneDetail.config.vat_number + ')' : ''}`} />
          <CardContent className='text-center'>
            <div className='row mb-3 font-weight-bold'>
              <div className='col'>DateTime</div>
              <div className='col'>Duration</div>
              <div className='col'>Productivity</div>
              <div className='col'>Speed</div>
            </div>
            <div className='row'>
              <div className='col'>{this.getValueStart('published_at')}</div>
              <div className='col'>{this.getDuration()}</div>
              <div className='col'>{this.getTakings()} L</div>
              <div className='col'>{this.getSpeed()} L/min</div>
            </div>
          </CardContent>
        </Card>

        <div className='row mt-3'>
          <div className='col mb-3'>

            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className='bg-info'>
                    <i className="material-icons">
                      play_arrow
                    </i>
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                title={this.getValueStart('published_at')}
                subheader="Milking Start"
                classes={{ subheader: classes.title }}
              />

              <CardContent>

                <table className='table mb-0'>
                  <tbody>
                    <tr>
                      <th>Plate</th>
                      <th>Inlet</th>
                      <th>Vat</th>
                    </tr>
                    <tr>
                      <td>{this.getValueStart('temp3')}</td>
                      <td>{this.getValueStart('temp2')}</td>
                      <td>{this.getValueStart('temp1')}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className='text-center px-4 pt-4'>
                        {this.getValueStart('milk_volume')}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </CardContent>

              <CardActions>
                {/*<Button size="small">CIP</Button>*/}
              </CardActions>
            </Card>

          </div>
          <div className='col mb-3'>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className='bg-danger'>
                    <i className="material-icons">
                      stop
                    </i>
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                title={this.getValueEnd('published_at')}
                subheader="Milking Stop"
                classes={{ subheader: classes.title }}
              />
              <CardContent>

                <table className='table mb-0'>
                  <tbody>
                    <tr>
                      <th>Plate</th>
                      <th>Inlet</th>
                      <th>Vat</th>
                    </tr>
                    <tr>
                      <td>{this.getValueEnd('temp3')}</td>
                      <td>{this.getValueEnd('temp2')}</td>
                      <td>{this.getValueEnd('temp1')}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className='text-center px-4 pt-4'>
                        {this.getValueEnd('milk_volume')}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </CardContent>
              <CardActions>
                <div className='flex-grow-1'></div>
                {/*<Button variant="contained" size="small">Already Collection</Button>*/}
              </CardActions>
            </Card>
          </div>
        </div>

        <Card className='mb-3'>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">
                C
              </Avatar>
            }
            action={

              <div className='d-flex flex-row'>
                <div style={{ width: 25, backgroundColor: global.constants.color.agitator_stop }}></div>
                <div style={{
                  fontSize: '0.7rem',
                  padding: '2px 5px',
                  border: '1px solid ' + global.constants.color.agitator_stop
                }}>Agitator Stopped
                </div>
              </div>
            }
            title="Milking cooling trends"
            classes={{ title: classes.title }}
          />

          <CardContent className='p-x-0'>

            <ActivityMilkingOneLineTemp storageObject={this.milkingOneDetail}
              agitator={this.getAgitator} />

          </CardContent>
        </Card>

        <Card className='mb-3'>
          <CardHeader subheader='Raw milk must (NZCP1-5.15)' />
          <CardContent>
            <ol type="a">
              <li>be cooled to 10ºC or below within four hours of the commencement of milking; and</li>
              <li>
                be cooled to 6ºC or below within the sooner of:
                <ol type="i">
                  <li>six hours from the commencement of milking, or</li>
                  <li>two hours from the completion of milking; and</li>
                </ol>
              </li>
              <li>be held at or below 6ºC without freezing until collection or the next milking; and</li>
              <li>must not exceed 10ºC during subsequent milkings.</li>
            </ol>
          </CardContent>
        </Card>


        {/*<Card className='my-3'>*/}
        {/*  <CardHeader*/}
        {/*    avatar={*/}
        {/*      <Avatar aria-label="recipe">*/}
        {/*        C*/}
        {/*      </Avatar>*/}
        {/*    }*/}
        {/*    action={*/}
        {/*      <IconButton aria-label="settings">*/}
        {/*      </IconButton>*/}
        {/*    }*/}
        {/*    title="Milking cooling comparison"*/}
        {/*    subheader="The temperature cooled to 4 hours after the start of milking"*/}
        {/*    classes={{title: classes.title}}*/}
        {/*  />*/}
        {/*  <CardContent>*/}
        {/*    <MilkingCoolingComparison {...this.props} current={true}/>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}


        {/*<Card className='my-3'>*/}
        {/*  <CardHeader*/}
        {/*    avatar={*/}
        {/*      <Avatar aria-label="recipe">*/}
        {/*        C*/}
        {/*      </Avatar>*/}
        {/*    }*/}
        {/*    action={*/}
        {/*      <IconButton aria-label="settings">*/}
        {/*      </IconButton>*/}
        {/*    }*/}
        {/*    title="Milking volume comparison (L)"*/}
        {/*    subheader="Milking speed and capacity comparison"*/}
        {/*    classes={{title: classes.title}}*/}
        {/*  />*/}
        {/*  <CardContent className='p-x-0'>*/}
        {/*    <ActivityMilkingListScatterVolume storageObject={this.milkingOneDetail}*/}
        {/*                                      milkingListBrief={this.milkingListBrief}*/}
        {/*                                      reloadData={this.reloadData}*/}
        {/*                                      {...this.props}/>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}


      </div>
    )
  }

}

// export default withStyles(Styles)(withRouter(observer(Component)))
export default withRouter(withStyles(Styles)(observer(Component)))
