import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import BaseDashboardAdmin from "./BaseDashboardAdmin";
import Utils from "../../utility/Utils";
import Card from "@material-ui/core/Card";
import { CardContent, CardHeader } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Button as ButtonAnt } from 'antd';
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import store from "../../store/Store";
import { Select, DatePicker } from 'antd';
import FileDownload from "js-file-download"
import Picker from 'react-month-picker'
import SharedElement from 'react-shared-element'
import Pagination from '@material-ui/lab/Pagination';
import { Input } from 'antd';
import emitter from "../../utility/EventEmitter"

const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const monthFormat = 'MMM YYYY';

const makeText = m => {
  if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
  return '?'
}

class Component extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        listFarm: [],
        listDevice: [],
      },
      month_start: moment(),
      month_end: moment(),
      dairy_com: ['ALL', 'FON', 'NONE-FON', 'OCD', 'SYN', 'MIR', 'WES', 'TAT', 'MAT', 'GRE', 'DGC'],
      dairy_finance: ['ALL', 'FON', 'NONE-FON', 'OCD', 'SYN', 'MIR', 'WES', 'TAT', 'MAT', 'GRE', 'DGC'],
      dairy_current: 'ALL',
      farm_type: '',
      farm_id: '',
      farm_finance: '',
      list_range_date: [null, null],
      supply_id: '',
      pagesize: 30,
      pagecurrent: 1,
      selectedFile: {},
      farms_settings: [],
    })
    this.ref_farms = []
    // this.initMonth()
    this.initData()
    store.dashboard.menuActive = 'Farms'
    store.dashboard.navbarLeft = 'Farms'
    // this.setNavbarRight()
  }

  setRef = ref => {
    // debugger
    this.ref_farms.push(ref)
  }

  // setNavbarRight = () => {
  //   store.dashboard.navbarRight = store.current.farm && store.current.farm._id ?
  //     <Button color="primary" onClick={() => {
  //       Utils.farmRemove()
  //       store.current.farm = {}
  //     }}>
  //       Exit the farm
  //     </Button> : null
  // }
  initMonth = () => {
    let month_current = moment().format('MMM YYYY');
    console.log(month_current)
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARM_LIST');
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      // console.log(data)
      let l = data.listFarm.map((farm, index) => {
        let list = []
        for (let item of data.listDevice) {
          if (item.farm_id === farm._id) {
            list.push(item)
          }
        }
        farm.devices = list
        return farm
      })
      this.data = data
      this.pagecurrent = 1


      this.getFarmsSettings()
    })
  }

  billingOutput = () => {
    if (!this.month_start || !this.month_end) {
      return
    }
    const params = new URLSearchParams();
    params.append('type', 'FARM_BILLING_OUTPUT');
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      // console.log(data)
      // debugger
      let csvContent = 'SupplyNo,Product Code,Date\n'

      let month_temp = this.month_end.clone()
      // month_temp = month_temp.set("date", this.month_start.date());
      month_temp.set("month", this.month_start.month());
      month_temp.set("year", this.month_start.year());
      // console.log(month_temp)
      do {
        let date = month_temp.format('YYYYMMDD')
        // console.log(date)

        data.forEach(item => {
          let suffix = '-NF'
          if (item.fonterra_id.startsWith('FON')) {
            suffix = ''
          }

          // if (item.install_packege && item.install_numVats) {
          //   let install_date = moment(item.install_date)
          //   console.log(install_date)
          //   console.log(this.month_start)
          //   console.log(this.month_end)

          //   csvContent += item.fonterra_id + ',' + 'DTSVMINSTALL' + item.vat_number + suffix + ',' + date + '\n'
          //   switch (item.install_packege) {
          //     case 'Vat-Man Lite':
          //       // already
          //       break
          //     case 'Vat-Man Pro':
          //       csvContent += item.fonterra_id + ',' + 'DTSVMINSTALLPRO' + item.vat_number + suffix + ',' + date + '\n'
          //       break
          //     case 'Vat-Man Auto':
          //       csvContent += item.fonterra_id + ',' + 'DTSVMINSTALLAUTO' + item.vat_number + suffix + ',' + date + '\n'
          //       break
          //   }
          // }

          switch (item.package) {
            case 'Vat-Man Lite':
              csvContent += item.fonterra_id + ',' + 'DTSVMLITE' + item.vat_number + suffix + ',' + date + '\n'
              break
            case 'Vat-Man Pro':
              csvContent += item.fonterra_id + ',' + 'DTSVMPRO' + item.vat_number + suffix + ',' + date + '\n'
              break
            case 'Vat-Man Auto':
              csvContent += item.fonterra_id + ',' + 'DTSVMAUTO' + item.vat_number + suffix + ',' + date + '\n'
              break
          }
        })

        month_temp.add(1, 'month')
        // console.log(month_temp, this.month_end)
      } while (this.month_end.isSameOrAfter(month_temp))


      // console.log(this.list_range_date)


      data.forEach(item => {
        let suffix = '-NF'
        if (item.fonterra_id.startsWith('FON')) {
          suffix = ''
        }

        if (item.install_packege && item.install_numVats) {
          let install_date = moment(item.install_date)
          // console.log(install_date)
          // console.log(this.month_start)
          // console.log(this.month_end)
          // debugger
          if (install_date.isAfter(this.list_range_date[1]) || install_date.isBefore(this.list_range_date[0])) {
            return
          }

          let date = install_date.format('YYYYMMDD')

          csvContent += item.fonterra_id + ',' + 'DTSVMINSTALL' + item.vat_number + suffix + ',' + date + '\n'
          switch (item.install_packege) {
            case 'Vat-Man Lite':
              // already
              break
            case 'Vat-Man Pro':
              csvContent += item.fonterra_id + ',' + 'DTSVMINSTALLPRO' + item.vat_number + suffix + ',' + date + '\n'
              break
            case 'Vat-Man Auto':
              csvContent += item.fonterra_id + ',' + 'DTSVMINSTALLAUTO' + item.vat_number + suffix + ',' + date + '\n'
              break
          }
        }
      })

      FileDownload(csvContent, 'Billing-Output.csv');
    })
  }

  setFonterraApiStatus = (_id, fonterra_api_status) => {
    const params = new URLSearchParams();
    params.append('type', 'FARM_FONTERRA_API_STATUS');
    params.append('_id', _id);
    params.append('fonterra_api_status', fonterra_api_status);
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      // console.log(data)
    })
  }

  setHarvestApiStatus = (_id, harvest_api_status) => {
    const params = new URLSearchParams();
    params.append('type', 'FARM_HARVEST_API_STATUS');
    params.append('_id', _id);
    params.append('harvest', harvest_api_status);
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      // console.log(data)
    })
  }

  setAutoCheckStatus = (_id, auto_check_status) => {
    const params = new URLSearchParams();
    params.append('type', 'FARM_AUTO_CHECK_STATUS');
    params.append('_id', _id);
    params.append('auto_check', auto_check_status);
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      // console.log(data)
    })
  }

  getFarmsSettings = (_id) => {
    const params = new URLSearchParams();
    params.append('type', 'FARMS_SETTINGS_GET');
    params.append('dairy_current', this.dairy_current);
    params.append('farm_type', this.farm_type);
    params.append('farm_id', this.farm_id);
    params.append('farm_finance', this.farm_finance);
    Utils.httpPost(Utils.urlList.admin_farm, params, data => {
      console.log(data)
      let lst = []
      for (const [key, value] of Object.entries(data)) {
        lst.push([key, value[0], value[1]])
      }
      this.farms_settings = lst
    })
  }

  @computed get getListFarm() {
    // console.log((this.pagecurrent - 1) * this.pagesize, this.pagesize)
    let list = this.getListFarmFilter.slice((this.pagecurrent - 1) * this.pagesize, this.pagecurrent * this.pagesize).sort(function (a, b) {
      return a.fonterra_id - b.fonterra_id;
    })
    return list
  }

  @computed get getListFarmFilter() {
    return this.data.listFarm.filter(item => item.devices.length !== 0 && this.getFilter(item))
  }

  @computed get getPageCount() {
    let pagetotal = Math.ceil(this.getListFarmFilter.length / this.pagesize)
    return pagetotal
  }

  getDevices = (farmId) => {
    // debugger
    let list = []
    for (let item of this.data.listDevice) {
      if (item.farm_id === farmId) {
        list.push(item)
      }
    }
    return list
  }

  getFilter = (farm) => {
    if (this.supply_id != '') {
      if (this.supply_id == 'fonterra') return farm.fonterra_api_status
      else if (this.supply_id == '!fonterra') return !farm.fonterra_api_status
      else return farm.fonterra_id.toLowerCase().includes(this.supply_id.toLowerCase())
    } else {
      return true
    }
  }

  onFileChange = (event) => {
    this.selectedFile = event.target.files[0]
    // const formData = new FormData();
    // formData.append(
    //   "farms_settings",
    //   this.selectedFile,
    //   this.selectedFile.name
    // );
    // Utils.httpPost(Utils.urlList.admin_farm, formData, data => {
    //   console.log(data)
    // })
  }

  onFileUpload = (event) => {
    if (!this.selectedFile.name) return
    const formData = new FormData();
    formData.append(
      "farms_settings",
      this.selectedFile,
      this.selectedFile.name
    );
    Utils.httpPost(Utils.urlList.admin_farm, formData, data => {
      // console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success!')
      this.getFarmsSettings()
    })
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='row mt-3'>
          <div className='col'>
            <Card>
              {/* <CardHeader
                title={`Search Farms`}
              // subheader={moment(item.updated_at).format(global.constants.date_format_date)}
              // action={
              //   <IconButton aria-label="settings" onClick={e => {

              //   }}>
              //     <i className='material-icons'>info</i>
              //   </IconButton>
              // }
              /> */}
              <CardContent className='pb-0'>
                <div className="row">
                  <div className='col-md-3 mb-3'>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      value={this.dairy_current}
                      size='default'
                      placeholder="Select a farm"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={value => {
                        // // console.log(farmid)
                        // let item = null
                        // for (let i of store.getListFarm) {
                        //   if (i._id === farmid) {
                        //     item = i
                        //     break
                        //   }
                        // }
                        // Utils.farmSet({ '_id': item._id })
                        // window.location.href = '/dts/dashboard'
                        this.dairy_current = value
                        this.initData()
                      }}
                    >
                      {this.dairy_com.map((item, index) =>
                        <Option key={index} value={item}>{item}</Option>
                      )}
                    </Select>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <div className='row d-none'>
                      <div className='col'>
                        <RangePicker
                          style={{ width: '100%' }}
                          onChange={(date, dateString) => {
                            // console.log(date, dateString)
                            // debugger
                            Object.assign(this.month_start, date[0].clone());
                            if (this.month_start.date() > 15) {
                              this.month_start = this.month_start.add(1, 'M')
                            }
                            Object.assign(this.month_end, date[1].clone());
                            if (this.month_end.date() <= 15) {
                              this.month_end = this.month_end.add(-1, 'M')
                            }
                            this.list_range_date = date
                            // console.log(this.month_start.format())
                            // console.log(this.month_end.format())
                          }}
                          value={this.list_range_date} />
                        {/* <MonthPicker placeholder='Start Month' style={{ width: '100%' }} format={monthFormat} value={this.month_start} onChange={(date, dateString) => {
                          // console.log(date, dateString)
                          if (date && date.isSameOrAfter(this.month_end)) {
                            this.month_start = this.month_end
                          } else {
                            this.month_start = date
                          }
                        }} /> */}
                      </div>
                      {/* ~ */}
                      {/* <div className='col'>
                        <MonthPicker placeholder='End Month' style={{ width: '100%' }} format={monthFormat} value={this.month_end} onChange={(date, dateString) => {
                          // console.log(date, dateString)
                          if (this.month_start && this.month_start.isSameOrAfter(date)) {
                            this.month_end = this.month_start
                          } else {
                            this.month_end = date
                          }
                        }} />
                      </div> */}
                    </div>
                  </div>
                  <div className='col mb-3'>
                    {/* <Button variant="contained" color="primary" onClick={this.billingOutput}>Billing output</Button> */}
                    <ButtonAnt type="primary" onClick={this.billingOutput} className='d-none'>Billing output</ButtonAnt>
                  </div>
                  <div className='col mb-3'>
                    <Input placeholder="Supply Number" value={this.supply_id} onChange={e => { this.supply_id = e.target.value; this.pagecurrent = 1 }} />
                  </div>
                </div>
              </CardContent>
              {/* <CardActions className='d-flex'>
                <div className='flex-grow-1'></div>

              </CardActions> */}
            </Card>
          </div>
        </div>
        <div className='row my-3'>
          {this.getListFarm.map((item, index) => {
            if (typeof item.auto_check === 'undefined') {
              item.auto_check = true
            }
            // let divices = this.getDevices(item._id)
            return <div className='col-md-4 col-sm-6 col-xs-12 my-2' key={index}>
              <SharedElement ref={this.setRef} id={'shared-element-' + item._id}>
                <Card>
                  <CardHeader
                    // avatar={<Avatar aria-label="recipe">
                    //   F
                    // </Avatar>}
                    title={item.fonterra_id}
                    // subheader={moment(item.updated_at).format(global.constants.date_format_date)}
                    action={
                      <div>
                        <IconButton aria-label="settings" onClick={e => {
                          item.auto_check = !item.auto_check
                          this.setAutoCheckStatus(item._id, item.auto_check)
                        }}><div>Watch</div>
                          {['5db2179321ca93000843a001', '5d59cd0ebdff9200070a66d2', '5d59dd61bdff9200087fbe62', '5dd1e93421ca9350b647caa7', '5d50ac1ffdce27067578a302', '5f56b40a21ca9360653410ca', '60b55cfe8ba1606b920b3dd0', '61f88e12a893a779fa3818ac'].includes(store.current.user._id) ? <>
                            {item.auto_check ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>}
                          </> : <></>}
                          {/* {item.fonterra_api_status ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>} */}
                        </IconButton>
                        <IconButton aria-label="settings" onClick={e => {
                          item.fonterra_api_status = !item.fonterra_api_status
                          this.setFonterraApiStatus(item._id, item.fonterra_api_status)
                        }}><div>Fonterra</div>
                          {['5db2179321ca93000843a001', '5d59cd0ebdff9200070a66d2', '5d59dd61bdff9200087fbe62', '5dd1e93421ca9350b647caa7', '5d50ac1ffdce27067578a302', '5f56b40a21ca9360653410ca', '60b55cfe8ba1606b920b3dd0', '61f88e12a893a779fa3818ac'].includes(store.current.user._id) ? <>
                            {item.fonterra_api_status ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>}
                          </> : <></>}
                          {/* {item.fonterra_api_status ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>} */}
                        </IconButton>

                        <IconButton aria-label="settings" onClick={e => {
                          item.harvest = !item.harvest
                          this.setHarvestApiStatus(item._id, item.harvest)
                        }}><div>Harvest</div>
                          {['5db2179321ca93000843a001', '5d59cd0ebdff9200070a66d2', '5d59dd61bdff9200087fbe62', '5dd1e93421ca9350b647caa7', '5d50ac1ffdce27067578a302', '5f56b40a21ca9360653410ca', '60b55cfe8ba1606b920b3dd0', '61f88e12a893a779fa3818ac'].includes(store.current.user._id) ? <>
                            {item.harvest ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>}
                          </> : <></>}
                          {/* {item.fonterra_api_status ? <i className='material-icons text-primary'>check_box</i> : <i className='material-icons'>check_box_outline_blank</i>} */}
                        </IconButton>
                      </div>
                    }
                  />
                  {/* <CardContent>
                  <div>DTS</div>
                  <div>Dashboard</div>
                  <div>Permission</div>
                </CardContent> */}
                  <CardActions className='nowrap'>
                    <div className='flex-grow-1 nowrap'>
                      <Button color='primary' onClick={e => {
                        Utils.farmSet({ '_id': item._id })
                        store.current.farm = item
                        this.props.history.push('/dts/dashboard')
                      }}>Dashboard</Button>
                    </div>
                    <Button color='primary' onClick={e => {
                      // debugger
                      this.ref_farms[index].redirect(() => {
                        this.props.history.push('/dts/organization/' + item._id + '/o/')
                      })
                    }}>Permission</Button>
                    {/* {divices.length === 0 ?
                    <div>
                      <Button color='secondary'>No device</Button>
                    </div> :
                    divices.map((item, index) => (<div key={index}>
                      <Button color='primary' onClick={e => {
                        this.props.history.push('/dts/device/' + item._id)
                      }}>{item.name ? item.name : 'NONAME'}</Button>
                    </div>))} */}
                  </CardActions>
                </Card>
              </SharedElement>
            </div>
          })}
        </div>


        <div className='p-3 mb-3 bg-white'>

          <Pagination count={this.getPageCount} page={this.pagecurrent} color="primary" onChange={(event, value) => this.pagecurrent = value} />

        </div>

        <div className='row my-3'>
          <div className='col'>
            <Card>
              <CardHeader
                title={`Import Milking time window buffer time`}
                // subheader={moment(item.updated_at).format(global.constants.date_format_date)}
                action={
                  <IconButton aria-label="settings" onClick={e => {

                  }}>
                    <i className='material-icons'>info</i>
                  </IconButton>
                }
              />
              <CardContent>

                <p>Choose the csv file must be below format:</p>
                <div>
                  <code>
                    <div>Farm, Buffer Duration, End of Milking</div>
                    <div>FON12345, 20, 15</div>
                    <div>FON23456, 30, 15</div>
                  </code>
                </div>

                <Button
                  variant="contained"
                  component="label"
                >
                  Select File
                  <input
                    type="file"
                    onChange={this.onFileChange}
                    hidden
                  />
                </Button>
                {this.selectedFile ? this.selectedFile.name : ''}
                <Button
                  className='mx-4'
                  variant="contained"
                  component="label"
                  onClick={this.onFileUpload}
                >
                  Upload File
                </Button>

              </CardContent>
            </Card>
          </div>
        </div>

        <div className='row my-3'>
          <div className='col'>
            <Card>
              <CardHeader
                title={`Current farms settings`}
                // subheader={moment(item.updated_at).format(global.constants.date_format_date)}
                action={
                  <IconButton aria-label="settings" onClick={e => {

                  }}>
                    <i className='material-icons'>info</i>
                  </IconButton>
                }
              />
              <CardContent>
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: '150px' }}>Farm</th>
                      <th style={{ width: '150px' }}>Buffer Duration</th>
                      <th style={{ width: '150px' }}>End of Milking</th>
                    </tr>
                    {this.farms_settings.map((x, i) => <tr key={i}>
                      <td>{x[0]}</td>
                      <td> {x[1]}</td>
                      <td> {x[2]}</td>
                    </tr>)}
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>
        </div>

      </div>
    )
  }


}

export default withRouter(observer(Component))
