import React from 'react'
import store from "../../store/Store";
import DTS from "../../utility/DTS"

// 继承
class Component extends React.Component {
  constructor(props) {
    super(props)
    document.body.classList.remove('milk-nav');
    DTS.domainRedirect(2)
    store.dashboard.isExtend = false
    store.showFarmList = false
    store.dashboard.navbar_type = ''
    store.dashboard.navbarRight = null
  }
}

export default Component
