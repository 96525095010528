import React from 'react'
import { observer } from "mobx-react";
import { extendObservable } from "mobx";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import { withRouter } from "react-router-dom";
import BaseDashboard from "./BasePromotion";
import Banner from "../common/Banner";
import DTSPopoverHalo from "../common/DTSPopoverHalo";

class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: { value: null, errorText: '' },
        password: { value: null, errorText: '' },
        remember: { value: false, errorText: '' },
      },
      recaptcha: React.createRef(),
      gRecaptchaResponse: null,
    })
    
    // Ff bearer token is defined check if user is still logged in
    if (Utils.authGet() != 'Bearer undefined'){

      const params = new URLSearchParams();
      params.append('type', 'USER_CHECK_LOGIN_STATUS');

      Utils.httpPost(Utils.urlList.user, params, data => {
        if (data == 'logged_in') window.location.href = '/dashboard'
      })
    }
  }

  handleChange = (successCallback) => {
    this.user.email.errorText = ''
    this.user.password.errorText = ''
    Validator.login.validate({
      email: this.user.email.value,
      password: this.user.password.value
    }, (errors) => {
      if (errors != null) {
        for (let o of errors) {
          switch (o.field) {
            case "email":
              this.user.email.errorText = o.message
              break;
            case "password":
              this.user.password.errorText = o.message
              break;
            default:
              break
          }
          break;
        }
      } else if (successCallback) {
        successCallback()
      }
    })
  }

  signIn = (e) => {
    this.handleChange(() => {
      const params = new URLSearchParams();
      params.append('type', 'USER_SIGN_IN');
      params.append('user', JSON.stringify(this.user));
      Utils.httpPost(Utils.urlList.user, params, data => {
        // console.log(data)
        Utils.authSet(data)

        let reurl = this.props.match.params.reurl
        if (reurl) {
          reurl = decodeURIComponent(reurl)

          // way 3: disable outside site link, easy
          // todo: only allowed vatman and knode domain
          // if (reurl.startsWith('http')) {
          //   reurl = null
          // }

          // way 1: recommendation way 
          // reurl = reurl.substr(reurl.indexOf('/', 8))

          // way 2: this is better way, but it is an experimental technology
          // disable for now by Jack
          // let url = new URL(reurl) 
          // reurl = url.pathname
        }

        if (!reurl) {
          reurl = "/dashboard"
        }

        // console.log(reurl)
        // let bol = reurl && (reurl.startsWith('https://vatman.co.nz') || reurl.startsWith('https://test.vatman.co.nz'))
        // if (!bol) {
        //   reurl = "/dts/dashboard"
        // }
        // console.log(reurl)
        window.location.href = reurl
      })
    })
    this.recaptcha.current.updateToken()
  }


  render() {


    return (
      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">

          <CssBaseline />
          <div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                helperText={this.user.email.errorText}
                error={this.user.email.errorText !== ''}
                onChange={e => {
                  this.user.email.value = e.target.value.trim()
                  this.handleChange()
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                helperText={this.user.password.errorText}
                error={this.user.password.errorText !== ''}
                onChange={e => {
                  this.user.password.value = e.target.value
                  this.handleChange()
                }}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    this.signIn()
                  }
                }}
              />
              {/*<FormControlLabel*/}
              {/*    control={<Checkbox value="remember" color="primary"/>}*/}
              {/*    label="Remember me"*/}
              {/*/>*/}
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className="my-3"
                onClick={this.signIn}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/dts/password/forgot" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/dts/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </div>

          </div>

        </Container>
      </div>

    )
  }


}

export default withRouter(observer(Login))
