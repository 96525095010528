import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import * as d3 from "d3"
import D3Tube from "../../utility/D3Tube";
import D3Elements from "../../utility/D3Elements";
import { withRouter } from "react-router-dom";
import D3Utils from "../../utility/D3Utils";
import D3Others from "../../utility/D3Others";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import Utils from '../../utility/Utils';
import { Fab } from '@material-ui/core';
import DTS from '../../utility/DTS';
import FarmerNavi from './farmer/FarmerNavi';


const listVat = [
  {
    vat: '1',
    refrigeration: [
      { name: 'Fridge1', value: '1' },
    ]
  },
  {
    vat: '2',
    refrigeration: [
      { name: 'Fridge1', value: '1' },
      { name: 'Fridge2', value: '2' },
      { name: 'Fridge3', value: '3' },
    ]
  },
]

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      svg: null,
      width: 1920,
      height: 1920,
      data: [],
      vat1: null,
      vat2: null,
    })


    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Equipment'

    this.initData()
    // this.interval = setInterval(this.syncData, 10000);

  }

  componentWillUnmount() {
    if (this.interval)
      clearInterval(this.interval);
  }

  initData = () => {
    const params = new URLSearchParams()
    params.append('type', 'EQUIPMENT_GET')
    params.append('farm', Utils.farmGet())
    Utils.httpPost(Utils.urlList.farmer_equipment, params, data => {
      // console.log(data)
      this.data = data
      this.drawEquipmentInit()
      this.syncData()
    })
  }

  syncData = () => {
    const params = new URLSearchParams()
    params.append('type', 'EQUIPMENT_DATA_GET')
    params.append('farm', Utils.farmGet())
    Utils.httpPost(Utils.urlList.farmer_equipment, params, data => {
      // console.log(data)
      if (this.vat1 && data.vat1) {
        this.vat1.name.text('Vat1')
        this.vat1.temp.text('Temp:' + DTS.getTemperature(data.vat1.last_updated.temp1) + '℃')
        this.vat1.agitator.text('Agitator:' + (data.vat1.last_updated.agitator_status === 1 ? 'Running' : 'Stopped'))
        this.vat1.volume.text('Volume:' + DTS.getVolumeRound(data.vat1.last_updated.milk_volume) + 'L')
        this.vat1.vat_size.text('Vat size:' + data.vat1.vat_size + 'L')
      }
      if (this.vat2 && data.vat2) {
        this.vat2.name.text('Vat2')
        this.vat2.temp.text('Temp: ' + DTS.getTemperature(data.vat2.last_updated.temp1) + '℃')
        this.vat2.agitator.text('Agitator: ' + (data.vat2.last_updated.agitator_status === 1 ? 'Running' : 'Stopped'))
        this.vat2.volume.text('Volume:' + DTS.getVolumeRound(data.vat2.last_updated.milk_volume) + 'L')
        this.vat2.vat_size.text('Vat size: ' + data.vat2.vat_size + 'L')
      }
    })
  }

  @computed get getPrecooling() {
    let result = 0
    for (let item of this.data) {
      if (item.type === 1) {
        result = item.value
        break
      }
    }
    return result
  }

  @computed get getVatList() {
    let result = []
    for (let item of this.data) {
      if (item.type === 2) {
        result.push(item)
      }
    }
    return result
  }

  drawEquipmentInit = () => {
    if (this.svg) this.svg.remove()

    this.svg = d3.select(".d3")
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .attr('class', 'd3-equipments')

    // draw background
    this.svg.append("rect")
      .attr("width", "100%")
      .attr("height", "100%")
      .style("fill", "#EEEEEE")

    // draw begin
    let g = this.svg.append('g').attr('transform', `matrix(3, 0, 0, 3, -280, -190)`)

    // resource
    D3Utils.resourceGradient(g)
    D3Utils.resourceGradientRotate(g)
    D3Utils.resourceArrow(g)

    let listVat = this.getVatList
    switch (listVat.length) {
      case 1:
        this.drawEquipmentOne(g)
        break
      case 2:
        this.drawEquipmentTwo(g)
        break
    }
  }

  // single vat 
  drawEquipmentOne = (g) => {

    // ----------- Begin draw -----------

    // cow
    D3Others.drawCows(g, 109, 245)

    // plate
    D3Tube.tubeWater(g, 200, 211)
    D3Tube.tubeMilk(g, 150, 270)

    if (this.getPrecooling !== 0) {
      D3Tube.tubeIce(g, 255, 121)
    }

    D3Elements.drawPlate(g, 250, 210)

    // cooling equipment
    if (this.getPrecooling === 2) {
      D3Elements.drawIce(g, 250, 110)
    } else if (this.getPrecooling === 1) {
      D3Elements.drawChilling(g, 205, 100)
    } else {

    }

    let vat = this.getVatList[0]

    // hot water
    if (vat.value === 1) {
      D3Tube.tubeHotwater(g, 425, 123)
    } else {
      D3Tube.tubeHotwater(g, 420, 80)
    }

    // always base refrigeration tube
    D3Tube.tubeRefrigerationBase(g, 500, 287)

    // refrigeration
    switch (vat.sub_value) {
      case 1:
        break
      case 2:
        D3Tube.tubeRefrigerationSidewall(g, 500, 243)
        D3Elements.drawRefrigeration(g, 600, 220)
        break
      case 3:
        D3Tube.tubeRefrigerationSidewall(g, 500, 256)
        break
    }

    // always base refrigeration unit
    D3Elements.drawRefrigeration(g, 600, 264)

    // vat
    if (vat.value === 1) {
      // debugger
      this.vat1 = D3Elements.drawVat(g, 400, 143)
    } else {
      this.vat1 = D3Elements.drawVatWithSidewall(g, 400, 100)
    }

  }


  // double vat 
  drawEquipmentTwo = (g) => {

    // ----------- Begin draw -----------
    g = g.append('g').attr('transform', `matrix(0.7, 0, 0, 0.7, 0, 30)`)

    // cow
    // D3Others.drawCows(g, 109, 65)

    // plate
    D3Tube.tubeWater(g, 200, 90)
    D3Tube.tubeMilkTwoVat(g, 150, 86)

    if (this.getPrecooling !== 0) {
      D3Tube.tubeIceTwoVat(g, 255, 150)
    }

    D3Elements.drawPlate(g, 250, 90)

    // cooling equipment
    if (this.getPrecooling === 2) {
      D3Elements.drawIce(g, 250, 260)
    } else if (this.getPrecooling === 1) {
      D3Elements.drawChilling(g, 205, 250)
    } else {

    }

    let vat1 = this.getVatList[0]

    // hot water
    if (vat1.value === 1) {
      D3Tube.tubeHotwater(g, 425, 123)
    } else {
      D3Tube.tubeHotwater(g, 420, 80)
    }

    // always base refrigeration tube
    D3Tube.tubeRefrigerationBase(g, 500, 287)

    // refrigeration
    switch (vat1.sub_value) {
      case 1:
        break
      case 2:
        D3Tube.tubeRefrigerationSidewall(g, 500, 243)
        D3Elements.drawRefrigeration(g, 600, 220)
        break
      case 3:
        D3Tube.tubeRefrigerationSidewall(g, 500, 256)
        break
    }

    // always base refrigeration unit
    D3Elements.drawRefrigeration(g, 600, 264)

    // vat
    if (vat1.value === 1) {
      this.vat1 = D3Elements.drawVat(g, 400, 143)
    } else {
      this.vat1 = D3Elements.drawVatWithSidewall(g, 400, 100)
    }


    g = g.append('g').attr('transform', `matrix(1, 0, 0, 1, 300, 0)`)


    let vat2 = this.getVatList[1]

    // hot water
    if (vat2.value === 1) {
      D3Tube.tubeHotwater(g, 425, 123)
    } else {
      D3Tube.tubeHotwater(g, 420, 80)
    }

    // always base refrigeration tube
    D3Tube.tubeRefrigerationBase(g, 500, 287)

    // refrigeration
    switch (vat2.sub_value) {
      case 1:
        break
      case 2:
        D3Tube.tubeRefrigerationSidewall(g, 500, 243)
        D3Elements.drawRefrigeration(g, 600, 220)
        break
      case 3:
        D3Tube.tubeRefrigerationSidewall(g, 500, 256)
        break
    }

    // always base refrigeration unit
    D3Elements.drawRefrigeration(g, 600, 264)

    // vat
    if (vat2.value === 1) {
      this.vat2 = D3Elements.drawVat(g, 400, 143)
    } else {
      this.vat2 = D3Elements.drawVatWithSidewall(g, 400, 100)
    }


  }

  render() {
    document.body.classList.add('milk-nav');
    return (
      <>
        <div className='container-fluid my-3'>

          <div style={{ position: 'fixed', top: '96px', zIndex: 1, width: '100%' }}>
            <FarmerNavi tabval={2} />
          </div>
        </div>
        <div className='fix-subnva' style={{ position: 'relative' }}>
          <div className='d3'>
          </div>
          <div className='control'>
            <Fab aria-label="set" size="small" onClick={() => {
              this.props.history.push('/dts/equipment/builder')
            }}>
              <i className='material-icons'>settings</i>
            </Fab>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(observer(Component))
