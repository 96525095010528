class D3Others {

  // draw a list tube
  static drawCows = (svg, currentX, currentY) => {

    let path = `M511.567,289.912l-14.627-48.359v-8.172c0-44.062-35.85-79.911-79.901-79.911H233.262
        c8.142-4.508,15.089-11.174,19.888-19.476c1.797-3.112,1.797-6.947-0.01-10.059c-9.286-16.043-26.553-26.001-45.066-26.001
        c-11.455,0-22.347,3.704-31.262,10.421c-0.512-0.472-1.034-0.924-1.576-1.355c11.013-9.698,17.97-23.893,17.97-39.675
        c0-5.552-4.497-10.039-10.039-10.039s-10.039,4.487-10.039,10.039c0,18.081-14.717,32.798-32.798,32.798h-26.172
        c-18.08,0-32.798-14.717-32.798-32.798c0-5.552-4.487-10.039-10.039-10.039c-5.542,0-10.039,4.487-10.039,10.039
        c0,15.792,6.957,29.987,17.97,39.675c-0.542,0.432-1.064,0.873-1.576,1.345c-8.915-6.706-19.807-10.411-31.252-10.411
        c-18.522,0-35.8,9.969-45.076,26.001c-1.797,3.112-1.797,6.937,0,10.049c9.276,16.053,26.543,26.021,45.076,26.021
        c8.433,0,16.635-2.048,23.963-5.853l6.586,47.766c-7.901,4.909-13.171,13.663-13.171,23.622v8.674
        c0,15.32,12.459,27.778,27.778,27.778h5.17v7.6c0,24.385,11.414,47.013,30.77,61.49l9.377,114.416
        c0.422,5.21,4.779,9.216,9.999,9.216h71.017c5.23,0,9.577-4.006,10.009-9.216l8.112-99.086h31.031
        c0.944,1.777,1.968,3.504,3.042,5.18v26.905c0,5.552,4.497,10.039,10.039,10.039c5.552,0,10.039-4.487,10.039-10.039v-6.305
        c4.658,3.162,9.678,5.783,14.978,7.78v16.424c0,5.542,4.487,10.039,10.039,10.039c5.542,0,10.039-4.498,10.039-10.039V384.41
        c1.797,0.13,12.218-0.05,15.551-0.522l-0.873,60.646c-0.03,2.69,1.004,5.281,2.891,7.188c1.887,1.917,4.467,2.992,7.148,2.992
        h72.924c4.929,0,9.126-3.574,9.909-8.443l14.557-90.262c0.09-0.532,0.13-1.064,0.13-1.596V230.279
        c0-11.143-2.339-21.905-6.957-31.975c-0.994-2.179-5.933-10.672-7.278-12.559c-3.102-4.337-6.636-8.322-10.531-11.916
        c30.338,2.861,54.151,28.471,54.151,59.552v8.172l-14.627,48.359c-0.924,3.042-0.351,6.335,1.546,8.885
        c1.897,2.55,4.879,4.056,8.061,4.056h30.117c3.172,0,6.164-1.506,8.061-4.056C511.917,296.246,512.489,292.954,511.567,289.912z
        M67.556,131.916c-5.793,5.13-13.302,8.011-21.132,8.011c-9.296,0-18.081-4.086-24.094-10.963
        c6.013-6.867,14.798-10.953,24.094-10.953c8.061,0,15.681,2.992,21.534,8.342C67.656,128.191,67.516,130.049,67.556,131.916z
        M208.073,118.012c9.286,0,18.07,4.086,24.084,10.953c-6.013,6.877-14.788,10.963-24.084,10.963
        c-7.831,0-15.34-2.881-21.142-8.001c0.04-1.877-0.1-3.745-0.402-5.572C192.382,121.003,200.011,118.012,208.073,118.012z
        M90.374,124.035c2.158-2.47,5.15-3.835,8.433-3.835h56.872c3.283,0,6.274,1.365,8.433,3.835c2.148,2.47,3.092,5.622,2.64,8.864
        l-1.877,13.643c-1.787-2.229-4.508-3.664-7.58-3.664c-5.341,0-9.678,4.327-9.678,9.668s4.337,9.668,9.678,9.668
        c2.118,0,4.076-0.693,5.672-1.847v0.01l-5.16,37.386H96.678l-5.16-37.396v-0.01c1.596,1.165,3.554,1.857,5.682,1.857
        c5.341,0,9.668-4.327,9.668-9.668c0-5.341-4.327-9.668-9.668-9.668c-3.072,0-5.803,1.436-7.58,3.664L87.734,132.9
        C87.282,129.657,88.225,126.505,90.374,124.035z M91.58,241.915c-4.247,0-7.7-3.453-7.7-7.7v-8.674c0-4.247,3.453-7.7,7.7-7.7
        h71.338c4.237,0,7.69,3.453,7.69,7.7v8.674c0,4.247-3.453,7.7-7.69,7.7H91.58z M156.15,434.636l-7.559-92.4
        c5.23,1.797,10.651,3.032,16.183,3.664l7.268,88.736H156.15z M347.708,361.671c-5.431,1.897-11.164,2.861-17.036,2.861
        c-15.49,0-29.766-6.796-39.464-18.121H345.7c3.704,3.895,7.82,7.379,12.248,10.38C354.725,358.78,351.292,360.427,347.708,361.671
        z M376.108,434.636h-15.962l0.813-56.701c5.793-2.69,11.224-6.144,16.123-10.27L376.108,434.636z M427.399,230.279V353.61
        l-13.071,81.026h-18.131l1.094-76.127c0.07-4.859-3.353-9.065-8.132-9.999c-3.192-0.612-6.415-1.566-9.577-2.821
        c-8.363-3.333-15.862-8.774-21.695-15.751c-1.907-2.289-4.728-3.604-7.7-3.604H226.787c-5.23,0-9.577,4.006-10.009,9.216
        l-8.112,99.086h-16.464l-8.122-99.086c-0.432-5.21-4.779-9.216-10.009-9.216h-0.06c-0.151-0.01-0.311-0.01-0.462-0.01
        c-11.033,0-21.725-3.172-30.921-9.166c-16.153-10.521-25.801-28.3-25.801-47.565v-7.6h46.09c15.31,0,27.768-12.459,27.768-27.778
        v-8.674c0-9.959-5.271-18.713-13.171-23.622l3.965-28.812c0.924,0.291,189.198,0.442,189.198,0.442
        c10.812,0,21.263,3.945,25.198,5.913c0.08,0.04,11.214,4.929,21.012,17.93C424.096,206.958,427.399,222.037,427.399,230.279z
        M482.132,285.113l4.769-15.761l4.769,15.761H482.132z`

    let gg = svg.append('g').attr('transform', `matrix(0.1, 0, 0, 0.1, ${currentX}, ${currentY})`)

    gg.append('path').attr('d', path)


  }

}

export default D3Others