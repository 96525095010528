import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import BaseDashboardAdmin from "./BaseDashboardAdmin";
import Utils from "../../utility/Utils";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../common/theme";
import store from "../../store/Store";
import emitter from "../../utility/EventEmitter"
import moment from "moment";
import Pagination from '@material-ui/lab/Pagination';
import { Input } from 'antd';

class Component extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    extendObservable(this, {
      list: [],
      keyword_user: '',
      pagesize: 10,
      pagecurrent: 1,
    })
    store.dashboard.menuActive = 'Users'
    store.dashboard.navbarLeft = 'Users'
    this.initData()
  }

  @computed get listAdmin() {
    let l = []
    for (let item of this.list) {
      if (item.role === 'Admin') {
        l.push(item)
      }
    }
    return l
  }

  @computed get listInstaller() {
    let l = []
    for (let item of this.list) {
      if (item.role === 'Installer') {
        l.push(item)
      }
    }
    return l
  }

  @computed get listUser() {
    let l = this.listUserAll
    // for (let item of this.list) {
    //   if (item.role !== 'Admin' && item.role !== 'Installer' && this.isIncludeUser(item)) {
    //     l.push(item)
    //   }
    // }
    return l.slice((this.pagecurrent - 1) * this.pagesize, this.pagecurrent * this.pagesize)
  }

  @computed get listUserAll() {
    let l = []
    for (let item of this.list) {
      if (item.role !== 'Admin' && item.role !== 'Installer' && this.isIncludeUser(item)) {
        l.push(item)
      }
    }
    return l
  }

  isIncludeUser = (user) => {
    if (!this.keyword_user) return true
    let k = this.keyword_user.toLowerCase().trim()
    if (user.firstName && user.firstName.toLowerCase().includes(k)) return true
    if (user.lastName && user.lastName.toLowerCase().includes(k)) return true
    if (user.email && user.email.toLowerCase().includes(k)) return true
    if (user.phone && user.phone.toLowerCase().includes(k)) return true
    return false
  }

  @computed get getPageCount() {
    // let l = []
    // for (let item of this.list) {
    //   if (item.role !== 'Admin' && item.role !== 'Installer' && this.isIncludeUser(item)) {
    //     l.push(item)
    //   }
    // }
    // let pagetotal = Math.ceil(l.length / this.pagesize)
    // return pagetotal
    let pagetotal = Math.ceil(this.listUserAll.length / this.pagesize)
    return pagetotal
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_LIST');
    Utils.httpPost(Utils.urlList.admin_user, params, data => {
      // console.log(data)
      this.list = data
    })
  }

  handleRoleEdit = (item, role) => {
    if (!store.current.user) {
      return
    }
    if (store.current.user._id === item._id) {
      emitter.emit("NOTIFY_SNACKBAR", 'You can not change your own role!')
      return
    }
    item.role = role
    item.open = false
    const params = new URLSearchParams();
    params.append('type', 'USER_ROLE_EDIT');
    params.append('userid', item._id);
    params.append('role', role);
    Utils.httpPost(Utils.urlList.admin_user, params, data => {
      // console.log(data)
    })
  }

  handleHerdmanEdit = (item, is_herd_enabled) => {
    if (!store.current.user) {
      return
    }
    item.is_herd_enabled = is_herd_enabled
    item.openHerd = false
    const params = new URLSearchParams();
    params.append('type', 'USER_HERD_EDIT');
    params.append('userid', item._id);
    params.append('is_herd_enabled', is_herd_enabled);
    Utils.httpPost(Utils.urlList.admin_user, params, data => {
      // console.log(data)
    })
  }

  render() {

    return (
      <div className={`container-fluid` + (!this.iOS ? ' d-none' : '')}>
        <div className='my-3'>

          <Card className='mb-3'>
            <CardHeader
              avatar={<Avatar aria-label="recipe">A</Avatar>}
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title="Admins"
              subheader='Have administrative rights'
            />
            <CardContent className='table-responsive'>
              <table className='table nowrap table-td-x-0'>
                <tbody>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Create Date</th>
                    <th>User Role</th>
                  </tr>
                  {this.listAdmin.map((item, index) => <tr key={index}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{moment.utc(item.created_at).local().format(global.constants.date_format_short)}</td>
                    <td>
                      {item.role ? item.role : 'User'}
                      <IconButton onClick={e => {
                        item.isMenuAnchorEl = e.currentTarget
                        item.open = true
                      }}>
                        <i className="material-icons font-size-base">
                          border_color
                      </i>
                      </IconButton>
                      <MuiThemeProvider theme={theme}>
                        <Menu
                          id="simple-menu"
                          anchorEl={item.isMenuAnchorEl}
                          keepMounted
                          open={item.open ? true : false}
                          onClose={() => {
                            item.open = false
                          }}
                        >
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Admin')
                          }}>Admin</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Installer')
                          }}>Installer</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'User')
                          }}>User</MenuItem>
                        </Menu>
                      </MuiThemeProvider>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              avatar={<Avatar aria-label="recipe">A</Avatar>}
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title="Installers"
              subheader='Have installer rights'
            />
            <CardContent className='table-responsive'>
              <table className='table nowrap table-td-x-0'>
                <tbody>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Create Date</th>
                    <th>User Role</th>
                  </tr>
                  {this.listInstaller.map((item, index) => <tr key={index}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{moment.utc(item.created_at).local().format(global.constants.date_format_short)}</td>
                    <td>
                      {item.role ? item.role : 'User'}
                      <IconButton onClick={e => {
                        item.isMenuAnchorEl = e.currentTarget
                        item.open = true
                      }}>
                        <i className="material-icons font-size-base">
                          border_color
                      </i>
                      </IconButton>
                      <MuiThemeProvider theme={theme}>
                        <Menu
                          id="simple-menu"
                          anchorEl={item.isMenuAnchorEl}
                          keepMounted
                          open={item.open ? true : false}
                          onClose={() => {
                            item.open = false
                          }}
                        >
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Admin')
                          }}>Admin</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Installer')
                          }}>Installer</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'User')
                          }}>User</MenuItem>
                        </Menu>
                      </MuiThemeProvider>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              avatar={<Avatar aria-label="recipe">U</Avatar>}
              action={
                <Input placeholder="name, phone, email" value={this.keyword_user} onChange={e => { this.keyword_user = e.target.value; this.pagecurrent = 1 }} />
              }
              title="Users"
              subheader='Ordinary or newly registered user'
            />
            <CardContent className='table-responsive'>
              <table className='table nowrap table-td-x-0'>
                <tbody>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>HerdMan</th>
                    <th>User Role</th>
                  </tr>
                  {this.listUser.map((item, index) => <tr key={index}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    {/* <td>{item.is_herd_enabled === 1 ? 'Herdman' : 'None'}</td> */}
                    <td>
                      {item.is_herd_enabled === 1 ? 'Herdman' : 'None'}
                      <IconButton onClick={e => {
                        item.isMenuAnchorElHerd = e.currentTarget
                        item.openHerd = true
                      }}>
                        <i className="material-icons font-size-base">
                          border_color
                      </i>
                      </IconButton>
                      <MuiThemeProvider theme={theme}>
                        <Menu
                          id="simple-menu"
                          anchorEl={item.isMenuAnchorElHerd}
                          keepMounted
                          open={item.openHerd ? true : false}
                          onClose={() => {
                            item.openHerd = false
                          }}
                        >
                          <MenuItem onClick={() => {
                            this.handleHerdmanEdit(item, 1)
                          }}>Herdman</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleHerdmanEdit(item, 0)
                          }}>None</MenuItem>
                        </Menu>
                      </MuiThemeProvider>
                    </td>
                    <td>
                      {item.role ? item.role : 'User'}
                      <IconButton onClick={e => {
                        item.isMenuAnchorEl = e.currentTarget
                        item.open = true
                      }}>
                        <i className="material-icons font-size-base">
                          border_color
                      </i>
                      </IconButton>
                      <MuiThemeProvider theme={theme}>
                        <Menu
                          id="simple-menu"
                          anchorEl={item.isMenuAnchorEl}
                          keepMounted
                          open={item.open ? true : false}
                          onClose={() => {
                            item.open = false
                          }}
                        >
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Admin')
                          }}>Admin</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'Installer')
                          }}>Installer</MenuItem>
                          <MenuItem onClick={() => {
                            this.handleRoleEdit(item, 'User')
                          }}>User</MenuItem>
                        </Menu>
                      </MuiThemeProvider>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </CardContent>
          </Card>


          <div className='p-3 mb-3 bg-white'>

            <Pagination count={this.getPageCount} page={this.pagecurrent} color="primary" onChange={(event, value) => this.pagecurrent = value} />

          </div>

        </div>
      </div>
    )
  }


}

export default withRouter(observer(Component))
