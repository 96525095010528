import React from 'react'
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

import store from "../store/Store";
import LinearProgress from "@material-ui/core/LinearProgress";
import { observer } from "mobx-react";
import { extendObservable } from "mobx";

import Login from "./promotion/Login";
import Register from "./promotion/Register";
import PasswordForgot from "./promotion/PasswordForgot";
import PasswordReset from "./promotion/PasswordReset";
import PasswordForgotFinished from "./promotion/PasswordForgotFinished";
import Permission from "./promotion/Permission";


const P404 = () => <div className='text-center'>Promotion 404</div>

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {})
    }


    render() {
        return (
            <BrowserRouter>
                <div className="container-master">
                    {store.progressOpen ? <LinearProgress className='progress' /> : null}
                    <div className="container-top">
                        <img src="https://static.herdman.co.nz/common/images/MilkHub-Logo.png" />
                    </div>
                    <div>
                        <Switch>
                            <Route path="/dts/login/:reurl?" component={Login} />
                            <Route path="/dts/register" component={Register} />
                            <Route path="/dts/password/forgot" component={PasswordForgot} exact />
                            <Route path="/dts/password/forgot/finished" component={PasswordForgotFinished} />
                            <Route path="/dts/password/reset/:token" component={PasswordReset} />
                            <Route path="/dts/permission" component={Permission} />
                            <Route component={P404} />
                        </Switch>
                    </div>
                    <div className="text-center text-muted p-3 container-bottom"><small>Phone: 0508 MILKHUB (0508 645 548)</small></div>
                </div>
            </BrowserRouter>
        )
    }

}

export default withRouter(observer(Component))
