import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter, Link } from "react-router-dom"
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboard from "./BaseDashboard";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Utils from "../../utility/Utils";
import moment from "moment";
import { CardHeader, BottomNavigationAction, BottomNavigation } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';
import DTS from '../../utility/DTS';
import emitter from "../../utility/EventEmitter"
import FarmerNavi from './farmer/FarmerNavi';

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      listMessage: [],
      expanded: -1,
      menuCurrent: 'Alerts',
    })
    this.initData()

    sleep(10).then(() => {
      // store.farmer.notification = true
    });

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Alerts'
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_NOTIFICATION_LIST');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_notification, params, data => {
      // console.log(data)
      this.listMessage = data.sort((a, b) => {
        if (a.device.vat_number > b.device.vat_number) {
          return 1;
        } else {
          return -1;
        }
      })
    })
  }

  handleChange = (item, statusTarget) => {
    if (item.status === statusTarget) return
    const params = new URLSearchParams();
    params.append('type', 'FARMER_NOTIFICATION_ONE_EDIT');
    params.append('farm', Utils.farmGet());
    params.append('item', JSON.stringify(item));
    params.append('status', statusTarget);
    Utils.httpPost(Utils.urlList.farmer_notification, params, data => {
      // console.log(data)
      item.status = statusTarget
      this.updateUnreadNumber()
    })
  }

  notifyDetail = (item) => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_NOTIFICATION_DETAIL');
    params.append('farm', Utils.farmGet());
    params.append('item', JSON.stringify(item));
    Utils.httpPost(Utils.urlList.farmer_notification, params, data => {
      // console.log(data)
      if (data && data._id) {
        const url = '/dts/storage/' + data._id + '/alert/' + item._id
        this.props.history.push(url)
        // const win = window.open(url);
        // win.focus();
      } else {
        emitter.emit("NOTIFY_SNACKBAR", 'No detail, please contact customer support!')
      }
    })
  }

  @computed get getListMessageFonterra() {
    let listNotification = []
    for (let item of this.listMessage.slice()) {
      let item2 = item.listNotification.filter(i => i.status !== 3 && i.type > 1000)
      listNotification.push({ device: item.device, listNotification: item2 })
    }
    return listNotification
  }

  @computed get getListMessageDTS() {
    let listNotification = []
    for (let item of this.listMessage.slice()) {
      let item2 = item.listNotification.filter(i => i.status !== 3 && i.type < 1000)
      listNotification.push({ device: item.device, listNotification: item2 })
    }
    return listNotification
  }

  @computed get getListMessageArchive() {
    let listNotification = []
    for (let item of this.listMessage.slice()) {
      let item2 = item.listNotification.filter(i => i.status === 3)
      listNotification.push({ device: item.device, listNotification: item2 })
    }
    return listNotification
  }

  @computed get getIsFonterraFarm() {
    let farm = JSON.parse(Utils.farmGet())
    let isFonterraFarm = DTS.isFonterraFarm(farm['_id'])
    return isFonterraFarm
    // console.log(farm)
    // console.log(isFonterraFarm)
  }

  getComponent = () => {
    switch (this.menuCurrent) {
      case 'Alert': // Fonterra
        return this.getComponentNotify(this.getListMessageFonterra)
      case 'Alerts':
        return this.getComponentNotify(this.getListMessageDTS)
      case 'Archive':
        return this.getComponentNotify(this.getListMessageArchive)
    }
  }

  getComponentNotify = (listmessage) => {
    return listmessage.map((item, i) =>
      <Card key={i}>
        <CardHeader
          subheader={`Vat ` + item.device.vat_number}
          className='border-bottom'
        />
        <CardContent className='bg-light px-0 pt-0'>
          {item.listNotification.map((item, index) => {
            let itemDetail = DTS.getNotificationByType(item.type)
            if (!item.quality) item.quality = {}
            return <Accordion expanded={this.expanded === i + '_' + index} key={index}
              onChange={() => {
                if (this.expanded === i + '_' + index) {
                  this.expanded = 0
                } else {
                  this.expanded = i + '_' + index
                }
                if (item.status === 3) return
                this.handleChange(item, 2)
              }}>
              <AccordionSummary
                expandIcon={<i className='material-icons d-none d-sm-block'>details</i>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='justify-content-center align-items-center px-3'
              >

                <Chip label={itemDetail.label} variant="outlined"
                  color={item.status === 1 ? 'primary' : 'default'}
                  className='mr-3'
                  style={{ width: 120 }}
                />

                {item.status !== 3 ?
                  <Tooltip title='Archive' placement='top'><i className='material-icons mr-3' style={{ lineHeight: '32px' }} onClick={e => {
                    e.stopPropagation()
                    this.expanded = 0
                    this.handleChange(item, 3)
                  }}>archive</i></Tooltip> : null}

                {/* <span className={`nowrap` + (item.status === 1 ? ' text-primary' : '')}
                style={{ lineHeight: '32px' }}>
                {itemDetail.description}
              </span> */}

                <span className='ml-3 nowrap text-muted d-none d-sm-block' style={{ lineHeight: '32px' }}>
                  {moment.utc(item.data.published_at).local().format(global.constants.date_format_short)}
                </span>

              </AccordionSummary>
              <AccordionDetails className='justify-content-center flex-column'>
                <div className="table-responsive">
                  <table className='table table-bordered text-center'>
                    <tbody>
                      {this.getNotifyDetailBody(item)}
                    </tbody>
                  </table>
                </div>
                {item.type < 1000 ? <div className="alert alert-danger" role="alert">{itemDetail.description}</div> : null}
              </AccordionDetails>
              {item.type < 1000 ? <>
                <Divider />
                <AccordionActions className='justify-content-center flex-column'>
                  <div>
                    {moment.utc(item.data.published_at).local().format(global.constants.date_format_short)}
                  </div>
                </AccordionActions>
              </> : null}
            </Accordion>
          }
          )}
        </CardContent>
      </Card>)
  }

  getNotifyDetailBody = (item) => {
    let result = null
    switch (item.type) {
      case 101:
      case 102:
      case 103:
      case 104:
      case 201:
      case 301:
      case 401:
      case 110:
      case 111:
      case 112:
      case 113:
      case 114:
      case 501:
      case 502:
      case 601:
      case 701:
        result = <>
          <tr>
            <td>Vat Temperature (°C)</td>
            <td>Volume (L)</td>
            <td>Agitator Status</td>
            <td>Show</td>
          </tr>
          <tr>
            <td> {DTS.getTemperature(item.data.temp1)}</td>
            <td> {DTS.getVolumeRound(item.data.milk_volume)}</td>
            <td> {item.data.agitator_status === 1 ? <i className='material-icons text-primary'>360</i> :
              <i className='material-icons text-danger'>stop</i>}</td>
            <td>
              <Button color="primary" onClick={() => { this.notifyDetail(item) }}>Detail</Button>
            </td>
          </tr>
        </>
        break
      case 1001:
      case 1002:
      case 1003:
      case 1004:
        result = <tr>
          <td>APC value</td>
          <td>{item.quality.pickupEstCFU}</td>
          <td>{moment.utc(item.quality.published_at).local().format(global.constants.date_format_short)}</td>
          {/* <td><Button color="primary" onClick={() => { this.alertDetail(item) }}>Detail</Button></td> */}
        </tr>
        break
      case 1102:
        result = <tr>
          <td>Agitator Outage</td>
          <td>{parseInt(item.quality.agitator.timeAgitatorStop * 100 / item.quality.agitator.timeInVat)} %</td>
          <td>{moment.utc(item.quality.published_at).local().format(global.constants.date_format_short)}</td>
          {/* <td><Button color="primary" onClick={() => { this.alertDetail(item) }}>Detail</Button></td> */}
        </tr>
        break
      case 1201:
        result = <tr>
          <td>Late Milking</td>
          <td>{item.lateMilking.end_time_current - item.lateMilking.end_time_schedule} mins</td>
          <td>{moment.utc(item.data.published_at).local().format(global.constants.date_format_short)}</td>
          {/* <td><Button color="primary" onClick={() => { this.alertDetail(item) }}>Detail</Button></td> */}
        </tr>
        break
    }

    return result

  }

  updateUnreadNumber = () => {
    let number = 0
    for (let item of this.listMessage) {
      for (let i of item.listNotification) {
        if (i.status === 1) {
          number++
        }
      }
    }
    store.current.notify = number
  }

  render() {
    document.body.classList.add('milk-nav');
    return (
      <div className='container-fluid'>
        <div className='my-3'>

          <div style={{ position: 'fixed', top: '96px', zIndex: 1, width: '100%' }}>
            <FarmerNavi tabval={3} />
          </div>

          <Card className='mb-0 border-bottom fix-subnva'>
            <CardHeader className='py-0' subheader={<BottomNavigation showLabels value={this.menuCurrent} onChange={(event, newValue) => {
              this.menuCurrent = newValue
            }}>
              {/* {this.getIsFonterraFarm ? <BottomNavigationAction label="Alerts" value="Alert" icon={<i className='material-icons'>error</i>} /> : null} */}
              <BottomNavigationAction label="Alerts" value="Alerts" icon={<i className='material-icons'>notification_important</i>} />
              <BottomNavigationAction label="Archive" value="Archive" icon={<i className='material-icons'>archive</i>} />
              {/* <BottomNavigationAction label="Settings" value="Settings" icon={<i className='material-icons' component={Link} to="/dts/setting/alert">settings_applications</i>} /> */}
            </BottomNavigation>} />
          </Card>

          {this.getComponent()}

        </div>
      </div>
    )
  }

}

export default withRouter(observer(Component))
