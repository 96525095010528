import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import ActivityStorageOneLineTemp from "./farmer/ActivityStorageOneLineTemp";
import ActivityStorageOneLineVolume from "./farmer/ActivityStorageOneLineVolume";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import DTS from "../../utility/DTS";
import ActivityStorageListArea from "./farmer/ActivityStorageListArea";
import ActivityStorageQuality from "./farmer/ActivityStorageQuality";
import { Spin } from "antd";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import AgitatorStatus from './farmer/AgitatorStatus';
import VatVolumeLevel from './farmer/VatVolumeLevel'
import ActivityStorageAll from './farmer/ActivityStorageAll';
import { Button } from '@material-ui/core';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import momentPropTypes from 'react-moment-proptypes';
import { DatePicker, Space } from 'antd';
import Tools from '../../utility/Tools';
import { CSVLink, CSVDownload } from "react-csv";

const { RangePicker } = DatePicker;

// const format = 'YYYY-MM-DDTHH:mm:ssZ'
const format = 'YYYY-MM-DD'


// Milk in
// Water in
// on off
const columns = [
  {
    label: 'DateTime',
    key: 'published_at',
  },
  {
    label: 'Volume',
    key: 'milk_volume', // String-based value accessors!
  },
  {
    label: 'Vat Temperature',
    key: 'temp1',
  },
  {
    label: 'Stirrer',
    key: 'agitator_status',
  },
  {
    label: 'milk_in',
    key: 'milk_in',
  },
  {
    label: 'water_in',
    key: 'water_in',
  },
]

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: [],
      page_index: 1,
      socket: null,
      isRealTime: false,
      date: {
        startDate: moment(new Date()).add(-2, 'days'),
        endDate: moment(new Date()),
      },
      csvData: [],
      refLink: React.createRef(),
      package: null,
    })
    this.initData()
    this.interval = setInterval(() => {
      this.initData()
    }, 2 * 60 * 1000);

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Milk'
    store.showFarmList = true

    if (this.isRealTime) this.startSocketData()
  }

  componentWillUnmount() {
    console.log('componentWillUnmount')
    clearInterval(this.interval)
  }

  initData = () => {
    if (!this.date.startDate || !this.date.endDate) return
    // console.log(this.page_index)
    const params = new URLSearchParams();
    params.append('type', 'FARMER_DASHBOARD_LAST_ALL');
    params.append('farm', Utils.farmGet());
    params.append('page_index', this.page_index);
    params.append('startDate', this.date.startDate.format(format));
    params.append('endDate', this.date.endDate.format(format));
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      console.log(data)
      this.data = data.sort((a, b) => {
        if (a.device.vat_number > b.device.vat_number) {
          return 1;
        } else {
          return -1;
        }
      })
      if (this.data.length && this.data[0].package) {
        this.package = this.data[0].package
      }
    })
  }

  downloadData = () => {
    if (!this.date.startDate || !this.date.endDate) return
    // console.log(this.page_index)
    const params = new URLSearchParams();
    params.append('type', 'FARMER_DASHBOARD_LAST_ALL');
    params.append('farm', Utils.farmGet());
    params.append('page_index', this.page_index);
    params.append('startDate', this.date.startDate.format(format));
    params.append('endDate', this.date.endDate.format(format));
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      if (data.length) {
        const res = []
        const dct_detail = {}
        if (data[0].detailData) {
          for (const item of data[0].detailData) {
            dct_detail[item.data_raw] = item
          }
          if (data[0].coolerData) {
            for (const item of data[0].coolerData) {
              if (item.data_raw in dct_detail) {
                dct_detail[item.data_raw] = Object.assign(dct_detail[item.data_raw], item)
              } else {
                dct_detail[item.data_raw] = item
              }
            }
          }

          Object.keys(dct_detail).forEach(function (key) {
            const item = dct_detail[key]
            if (item.milk_volume) {
              // console.log(item.agitator_status)
              switch (item.agitator_status) {
                case 1:
                  item.agitator_status = 'On'
                  break
                case 0:
                  item.agitator_status = 'Off'
                  break
                default:
                  item.agitator_status = 'None'
                  break
              }
              res.push(item)
            }
          })
          // console.log(res)
          this.csvData = res
          this.refLink.current.link.click()

        }
      }
    })
  }

  download = (event) => {
    const currentRecords = this.csvData
    var data_to_download = []
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {}
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
      }
      data_to_download.push(record_to_download)
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click()
    })
  }

  startSocketData = () => {
    if (!this.socket) {
      this.socket = new WebSocket('ws://dtslive.local:30102')
      this.socket.addEventListener('open', (event) => {
        console.log('open')
      });
      this.socket.addEventListener('close', (event) => {
        console.log('close')
      });
      this.socket.addEventListener('error', (event) => {
        console.log('error')
      });
      this.socket.addEventListener('message', (event) => {
        console.log('Message from server ', event.data);
      });
    }
  }

  sendSocketData = () => {
    if (!this.socket) {
      return
    }
    this.socket.send('Hello Server hi!');
  }

  render() {
    return (
      <div className='container-fluid mt-3'>


        {['Vat-Man Pro', 'Vat-Man Auto'].includes(this.package) ?
          <div className='bg-white mb-3 p-3 flex-row d-flex'>
            <div className='flex-grow-1'>

            </div>

            {/* <RangePicker /> */}
            <div className='d-none1'>
              <DateRangePicker
                orientation={Tools.isMobileDevice() ? 'vertical' : 'horizontal'}
                displayFormat={() => "DD/MM/YYYY"}
                startDate={this.date.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.date.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                // onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  this.date.startDate = startDate
                  if (this.date.endDate && this.date.endDate.isAfter(this.date.startDate.clone().add(30, 'days'))) {
                    this.date.endDate = this.date.startDate.clone().add(30, 'days')
                  } else {
                    this.date.endDate = endDate
                  }
                }} // PropTypes.func.isRequired,
                focusedInput={this.date.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                // onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                onFocusChange={focusedInput => {
                  this.date.focusedInput = focusedInput
                }} // PropTypes.func.isRequired,
                isOutsideRange={(day) => {
                  if (this.date.focusedInput == "endDate") {
                    // End date is not after today and not before start date
                    return day.isAfter(moment(new Date())) || day.isAfter(this.date.startDate.clone().add(30, 'days')) || day.isBefore(this.date.startDate);
                    // return day.isAfter(moment(new Date()))
                  }
                  if (this.date.focusedInput == "startDate") {
                    // Start date is not after today and not after end date and not before 2 years ago. 
                    // return day.isAfter(moment(new Date())) || day.isAfter(this.date.endDate) || day.isBefore(moment(new Date()).add(-30, 'days'));
                    return day.isAfter(moment(new Date()))
                  }

                  return false;
                }}
              />
            </div>

            <Button className='ml-3' variant="outlined" color="primary" onClick={() => {
              this.initData()
            }}>
              View
          </Button>

            <Button className='ml-3' variant="outlined" color="primary" onClick={() => {
              this.downloadData()
            }}>
              Download
          </Button>

            <CSVLink
              data={this.csvData}
              filename="data.csv"
              headers={columns}
              className="d-none"
              ref={this.refLink}
              target="_blank" />


          </div> : null}




        {this.data.map((item, index) => <div key={index} className='mb-3'>
          <Card className='my-3'>
            <CardHeader
              avatar={<Avatar aria-label="recipe">V</Avatar>}
              action={<IconButton aria-label="settings"></IconButton>}
              title={`Vat ` + item.device.vat_number}
            />
            <CardContent className='p-x-0'>
              <ActivityStorageAll detailData={item.detailData} coolerData={item.coolerData} />
            </CardContent>
          </Card>
        </div>)}

        {['Vat-Man Pro', 'Vat-Man Auto'].includes(this.package) ? null :
          <Card className='mb-3 p-3 d-flex flex-row'>
            <div className='flex-grow-1'>
              <Button variant="outlined" color="primary" onClick={() => {
                this.page_index++
                this.date.startDate.add(-2, 'days')
                this.date.endDate.add(-2, 'days')
                this.initData()
              }}>
                Previous
            </Button>
              {this.isRealTime ? <Button variant="outlined" color="primary" onClick={this.sendSocketData} className='ml-3'>Sync Data</Button> : null}
            </div>
            <Button
              variant="outlined"
              color="primary"
              disabled={this.page_index == 1}
              onClick={() => {
                this.page_index--
                if (this.page_index < 1) {
                  this.page_index = 1
                }
                this.date.startDate.add(2, 'days')
                this.date.endDate.add(2, 'days')
                this.initData()
              }}>
              Next
             </Button>
          </Card>}

      </div>
    )
  }

}

export default withRouter(observer(Component))
