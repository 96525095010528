import React from 'react'
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MuiThemeProvider, Popover } from '@material-ui/core';
import theme from "./theme";

class DTSPopover extends React.Component {
    render() {
      return <MuiThemeProvider theme={theme}>
        <Popover
          open={this.props.anchorEl !== null}
          anchorEl={this.props.anchorEl}
          onClose={() => { this.props.actionHide() }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ maxWidth: '800px' }}
        >
          <div className="p-3">{this.props.text}</div>
        </Popover>
      </MuiThemeProvider>
    }
  }

  export default DTSPopover
