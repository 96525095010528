import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { BrowserRouter, Link, withRouter } from "react-router-dom"
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import emitter from "../../utility/EventEmitter";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../common/theme";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import BaseDashboard from "./BaseDashboard";
import BasePromotion from "../promotion/BasePromotion";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { Breadcrumb } from 'antd';

class Component extends BasePromotion {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        currentFarm: {},
        listSub: [],
        listRole: [],
        listUser: [],
        listParent: [],
      },
      editFarm: {},
      editUser: { email: '' },
      listRelattion: [],
      listUserUnlinked: [],
      isEditNotAdd: false,
      crudType: '',
      isRightNew: false,
      isAddAUserByEmail: false,
      organization_tags: ['Group', 'Regional', 'Branch', 'Farm'],
      organization_package: ['Non-independent accounting', 'Vat-Man Lite', 'Vat-Man Pro', 'Vat-Man Auto'],
    })

    this.organization_id = props.match.params.organization_id
    this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = 'Organization'
  }

  componentDidUpdate() {
    if (this.organization_id !== this.props.match.params.organization_id) {
      this.organization_id = this.props.match.params.organization_id
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    }
  }

  @computed get getIsAdminRole() {
    if (!this.data.listRole.length) {
      return false
    }
    for (let item of this.data.listRole) {
      if (item.user_id === store.current.user._id) {
        return item.role === 'Admin'
      }
    }
    return false
  }

  initEditFarm = () => {
    this.editFarm = {
      tag: 'Group',
      name: '',
      contact: '',
      address: '',
      email: '',
      phone: '',
      package: 'Non-independent accounting',
    }
  }

  initData = (organization_id, parent_id) => {
    this.initEditFarm()
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_LIST');
    params.append('organization_id', organization_id);
    if (parent_id) {
      params.append('parent_id', parent_id);
    }
    Utils.httpPost(Utils.urlList.farmer_organization, params, data => {
      // console.log(data)
      if (organization_id && (!data.currentFarm || !data.currentFarm._id)) {
        this.props.history.push('/dts/dashboard')
      } else {
        data.listSub.map(item => { if (!item.tag) item.tag = 'Farm' })
        this.data = data
      }
    })
  }

  crudOrganization = () => {
    let organization_id = this.props.match.params.organization_id
    const params = new URLSearchParams();
    params.append('type', this.crudType);
    params.append('_id', this.editFarm._id);
    params.append('organization_id', organization_id);
    params.append('name', this.editFarm.name);
    params.append('tag', this.editFarm.tag);
    params.append('contact', this.editFarm.contact);
    params.append('address', this.editFarm.address);
    params.append('email', this.editFarm.email);
    params.append('phone', this.editFarm.phone);
    params.append('package', this.editFarm.package);
    // console.log(this.editFarm.contact == true)
    // return
    Utils.httpPost(Utils.urlList.farmer_organization, params, data => {
      // console.log(data)
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
      this.isRightNew = false
    })
  }

  addUserClose = () => {
    this.isAddAUserByEmail = false
  }

  addUserAction = () => {
    let organization_id = this.props.match.params.organization_id
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_ADD');
    params.append('organization_id', organization_id);
    params.append('email', this.editUser.email);
    Utils.httpPost(Utils.urlList.farmer_organization, params, data => {
      console.log(data)
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
      this.addUserClose()
      this.editUser.email = ''
    })
  }

  userOrganizationUnlink = () => {
    let organization_id = this.props.match.params.organization_id
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_UNLINK');
    params.append('organization_id', organization_id);
    params.append('user_id', this.editUser._id);
    Utils.httpPost(Utils.urlList.farmer_organization, params, data => {
      console.log(data)
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    })
  }

  deleteDialogOpen = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to delete it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {
        this.crudOrganization()
      },
      forceSelect: false,
    })
  }

  unlinkDialogOpenRelate = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to unlink it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {

      },
      forceSelect: false,
    })
  }

  unlinkDialogOpenUser = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to delete it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {
        this.userOrganizationUnlink()
      },
      forceSelect: false,
    })
  }

  getUserRole = (user_id) => {
    for (let item of this.data.listRole) {
      if (item.user_id === user_id) {
        return item.role
      }
    }
    return null
  }

  getParentId = () => {
    let organization_id = this.props.match.params.organization_id
    let parent_id = this.props.match.params.parent_id
    if (!parent_id) {
      parent_id = ''
    }
    if (organization_id) {
      parent_id += (parent_id ? '-' : '') + organization_id
    }
    return parent_id
  }

  @computed get getIsFinanceCustomer() {
    if (this.data.currentFarm && this.organization_package.includes(this.data.currentFarm.package) && this.data.currentFarm.package !== 'Non-independent accounting') {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <div className='container-fluid my-3'>

        {/*top operation bar sub*/}
        <div className='mb-3 bg-white'>
          <div className='flex-grow-1 d-flex align-items-center p-3 nowrap'>
            {this.data.currentFarm && (this.data.currentFarm.name || this.data.currentFarm.fonterra_id) ? <Breadcrumb className='mr-3' style={{ fontSize: '1.1rem' }}>
              {this.data.listParent.map((item, index) => <Breadcrumb.Item key={index}>{item.name ? item.name : item.fonterra_id}</Breadcrumb.Item>)}
              <Breadcrumb.Item key={9999}>{this.data.currentFarm.name ? this.data.currentFarm.name : this.data.currentFarm.fonterra_id}</Breadcrumb.Item>
            </Breadcrumb> : null}

            {this.data.currentFarm && this.data.currentFarm.tag
              ? <Chip size='small' label={this.data.currentFarm.tag} />
              : null}
          </div>
        </div>

        {/* Invoice */}
        {this.getIsFinanceCustomer
          ? <Card className='mt-3'>
            <CardHeader
              subheader="Summary"
            />
            <CardContent>

              <div className='row'>
                <div className='col-md-4'>
                  <Typography component="h1" variant="h4" align="center" className='text-primary' gutterBottom>
                    {this.data.currentFarm.package}
                  </Typography>
                  <Typography variant="h5" align="center" className='text-muted' paragraph>
                    {/* Status: Normal */}
                  </Typography>
                </div>
                <div className='col-md-4'>
                  <dl>
                    {/* <dt className='mb-2'>Service due date</dt>
                    <dd>31/12/2020</dd> */}
                  </dl>
                </div>
                <div className='col-md-4'>
                  <dl>
                    <dt className='mb-2'>Total number of SMS</dt>
                    <dd>{['Vat-Man Pro', 'Vat-Man Auto'].includes(this.data.currentFarm.package) ? 120 : 60}</dd>
                  </dl>
                </div>
              </div>
            </CardContent>
            {/* <CardActions className='d-flex'>
              <div className='flex-grow-1'></div>
              <Button color="primary" onClick={() => {

              }}>Invoice</Button>
            </CardActions> */}
          </Card>
          : null}


        {/*sub organization list*/}
        {this.data.listSub.length
          ? <Card className='mt-3'>
            <CardHeader
              className='border-bottom'
              subheader={!this.data.currentFarm ? "All Organization" : "Sub-organization"}
            />
            <CardContent>
              {this.data.listSub.map((item, index) => <div key={index}>
                <div className='d-flex align-items-center'>
                  <Link to={`/dts/group/${item._id}/o/${this.getParentId()}`} className='flex-grow-1 nowrap'>
                    <span className='mr-3 text-primary'>
                      {item.name ? item.name : item.fonterra_id}
                    </span>
                    {item.fonterra_id ? <span className='mr-3 text-muted'>{item.fonterra_id}</span> : null}
                    {item.tag ? <span className='mr-3 text-muted'>
                      <Chip className='mr-3' size='small' label={item.tag} /></span> : null}
                  </Link>
                  {this.getIsAdminRole ? <>
                    <Button color="primary" onClick={() => {
                      this.crudType = 'ORGANIZATION_EDIT'
                      Object.keys(item).map(function (key, index) {
                        item[key] = item[key] ? item[key] : '';
                      });
                      if (!item.package) {
                        item.package = this.organization_package[0]
                      }
                      // console.log(item)
                      this.editFarm = item
                      this.isRightNew = true
                    }}>Edit</Button>

                    <Button className='text-danger' onClick={() => {
                      this.crudType = 'ORGANIZATION_DELETE'
                      this.editFarm = item
                      this.deleteDialogOpen()
                    }}>Delete</Button></> : null}
                </div>
                <Divider className='my-3' />

              </div>)}
            </CardContent>
          </Card>
          : null
        }

        {/* user list */}
        {this.props.match.params.organization_id
          ? <Card className='mt-3'>
            <CardHeader
              // className='border-bottom'
              subheader="User list"
            />
            <CardContent className='table-responsive'>
              {this.data.listUser.length ?
                <table className='table border-bottom'>
                  <tbody>
                    {this.data.listUser.map((item, index) => <tr className='' key={index}>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{this.getUserRole(item._id)}</td>
                      <td className='text-right pr-0'>
                        {this.getIsAdminRole ?
                          <Button className='text-danger' onClick={() => {
                            this.editUser = item
                            this.unlinkDialogOpenUser()
                          }}>Delete</Button> : null}
                      </td>
                    </tr>)}
                  </tbody>
                </table> : null}
            </CardContent>
            <CardActions>
              {this.getIsAdminRole ?
                <Button color="secondary" onClick={() => {
                  this.isAddAUserByEmail = true
                }}>+ Add a user</Button> : null}
            </CardActions>
          </Card>
          : null}

        {/*top operation bar sub*/}

        {this.getIsAdminRole ? <div className='my-3 p-3 bg-white'>
          <div className='text-right'>
            <Button className='ml-3' color="secondary" onClick={() => {
              this.editFarm = this.data.currentFarm
              Object.keys(this.editFarm).map((key, index) => {
                this.editFarm[key] = this.editFarm[key] ? this.editFarm[key] : '';
              });
              this.isRightNew = true
              this.crudType = 'ORGANIZATION_EDIT'
            }}>Edit</Button>
          </div>
        </div> : null}

        {/* drawer add or edit a sub to an organization */}
        <Drawer anchor="right" open={this.isRightNew} onClose={() => {
          this.isRightNew = false
        }}>
          <div className='py-5 px-4 form-organization'>
            <div className='pb-3'>
              <MuiThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  disabled
                  select
                  label="The tag of this organization"
                  id="simple-start-adornment"
                  value={this.editFarm.tag}
                  onChange={e => {
                    this.editFarm.tag = e.target.value
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Tag: </InputAdornment>,
                  }}
                >
                  {(this.organization_tags).map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </MuiThemeProvider>
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.name}
                onChange={e => {
                  this.editFarm.name = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Name: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.contact}
                onChange={e => {
                  this.editFarm.contact = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Contact: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.address}
                onChange={e => {
                  this.editFarm.address = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Address: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.email}
                onChange={e => {
                  this.editFarm.email = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Email: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.phone}
                onChange={e => {
                  this.editFarm.phone = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Phone: </InputAdornment>,
                }}
              />
            </div>
            <Button fullWidth variant="contained" color="primary" onClick={() => {
              this.crudOrganization()
            }}>
              {this.crudType === 'ORGANIZATION_ADD' ? 'Add' : null}
              {this.crudType === 'ORGANIZATION_EDIT' ? 'OK' : null}
            </Button>
          </div>
        </Drawer>

        <Dialog open={this.isAddAUserByEmail} onClose={this.addUserClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add a user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a user's email address, the user must first register DTS system.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              value={this.editUser.email}
              onChange={e => {
                this.editUser.email = e.target.value
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addUserClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.addUserAction} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

export default withRouter(observer(Component))
