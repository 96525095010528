import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

const colorMilkingVolume = '#'
const colorMilkingTemp = '#'
const colorStorage = '#'

class Component extends React.Component {

  constructor(props) {
    super(props)
    Highcharts.setOptions({
      time: {
        useUTC: false
      },
    })
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {

    let storageList = []
    if (this.props.storageList && this.props.storageList.length) {
      storageList = this.props.storageList
    }

    let listData = []
    // debugger
    for (let storage of storageList) {
      // console.log(storageList)
      let listStorage = []
      let detail_length = storage.detailData.length
      for (let i = 0; i < detail_length; i++) {
        let item = storage.detailData[i]

        if (item.temp1 === undefined || item.temp1 === null) {
          continue
        }

        if (item.milk_volume === undefined || item.milk_volume === null) {
          continue
        }

        if (this.props.typeYAxis === 'TEMP' && storage.activity.type === 'storage' && (i < 2 || i > detail_length - 2)) {
          continue
        }

        let volume = item.milk_volume
        let temp1 = item.temp1
        if (i === detail_length - 1) {
          // change last volume and temp1 as zero
          volume = 0
          // temp1 = 0
        }
        listStorage.push({
          activity: storage.activity,
          x: moment.utc(item.published_at).local().toDate(),
          // y: parseFloat(this.props.typeYAxis === 'TEMP' ? temp1 : volume),
          y: this.props.typeYAxis === 'TEMP' ? parseFloat(temp1) : parseFloat(volume),
          marker: global.constants.highcharts.marker,
        })
      }

      listData.push({
        showInLegend: false,
        data: listStorage,
        color: this.getColor(this.props.typeYAxis, storage.activity.type),
        type: storage.activity.type === 'milking' ? 'area' : 'spline',
        zIndex: storage.activity.type === 'milking' ? 1 : 2,
        enableMouseTracking: storage.activity.type === 'milking' ? true : false,
      })
    }

    // console.log(listData)

    let plotOptions = {
      // area: {colorByPoint: true},
      series: {
        // color: '#2594b7',
        // enableMouseTracking: false,
        trackByArea: true,
        // stickyTracking: false,
        cursor: 'pointer',
        events: {
          click: event => {
            // console.log(event)
            if (event.point.activity && event.point.activity.type === 'milking') {
              this.props.history.push("/dts/milking/" + event.point.activity._id);
            }
          }
        }
      },
    }

    let unit = this.props.typeYAxis === 'TEMP' ? '°C' : 'L'

    let result = {
      credits: false,
      chart: {
        type: 'area',
        height: 300,
        // zoomType: 'x',
        renderTo: 'container',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        title: { text: null },
      },
      yAxis: {
        // title: {text: this.props.typeYAxis === 'TEMP' ? 'Celsius °C' : 'Milk volume (L)'},
        title: { text: '' },
        min: 0,
        gridLineWidth: 1,
        lineWidth: 1,
        labels: {
          formatter: e => {
            if (this.props.typeYAxis === 'TEMP') {
              return e.value + '°C'
            } else {
              return e.value / 1000 + 'k'
            }
          }
        }

      },
      plotOptions: plotOptions,
      series: listData,
      tooltip: {
        useHTML: true,
        formatter: (e) => {
          let point = e.chart.hoverPoint
          // debugger
          let xx = moment.utc(point.x).local().format(global.constants.date_format_week)
          let y = point.y
          switch (this.props.typeYAxis) {
            case 'TEMP':
              y = DTS.getTemperature(y)
              break
            default:
              y = DTS.getVolumeRound(y)
              break
          }

          switch (point.key) {
            default:
              return `<div>${xx} / ${y} ${unit}</div>`;
          }
        }
      }
    }
    return result
  }

  getColor = (tempVolume, activityType) => {
    let result = ''
    if (tempVolume === 'TEMP') {
      result = '#f782ac'
    } else {
      result = '#f7a35c'
    }
    return result
  }


  render() {

    return (

      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />

    )
  }


}

export default observer(Component)
