import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: [
        {
          title: 'E2E01 - Milking Scenario 1 - without Milking Window',
          url: '/v5/milkvattelemetry/72178/milkingevent',
          ist: 'Thu, 23 Jan 2020 01:25:30 GMT',
          request: {
            "TotalVolume": "27000",
            "MilkingDateTime": "2020-01-24T11:02:56.001Z",
            "VatMilkingDetails": [
              {
                "VatID": "vat39001-1",
                "VatVolume": 27000
              }
            ]
          },
          response: {
            "code": "200",
            "message": "MilkingEvent updated for SupplierID: 72178"
          },
          steps: null,
        },
        {
          title: 'E2E02 - Milking Scenario 2 - with Milking Window',
          url: '/v5/milkvattelemetry/72178/milkingevent',
          ist: 'Thu, 23 Jan 2020 01:28:26 GMT',
          request: {
            "TotalVolume": "27000",
            "MilkingDateTime": "2020-01-23T05:59:56.001Z",
            "VatMilkingDetails": [
              {
                "VatID": "vat39001-1",
                "VatVolume": 27000
              }
            ],
            "MilkingTimeWindow": {
              "UserID": "testmvtuser",
              "MilkingSchedule": {
                "CleanInPlaceTime": 15,
                "Confirmed": true,
                "MilkingCycle": "Twice per Day",
                "StartDate": "2020-01-25",
                "MilkingPeriods": [{
                  "StartTime": 282,
                  "Duration": 77
                }, {
                  "StartTime": 1136,
                  "Duration": 107
                }]
              }
            }
          },
          response: {
            "code": "200",
            "message": "MilkingEvent updated for SupplierID: 72178"
          },
        },
        {
          title: 'E2E03 - Milking Scenario 3 - Error Handling',
          steps: [{
            title: 'Step 1',
            url: '/v5/milkvattelemetry/99997/milkingevent',
            ist: 'Thu, 23 Jan 2020 01:36:37 GMT',
            request: {
              "TotalVolume": "27000",
              "MilkingDateTime": "2020-01-23T05:59:56.001Z",
              "VatMilkingDetails": [
                {
                  "VatID": "vat39001-1",
                  "VatVolume": 27000
                }
              ]
            },
            response: {
              "code": "400",
              "message": "Error 2 - Farm 99997 is invalid"
            },
          }, {
            title: 'Step 2',
            url: '/v5/milkvattelemetry/72178/milkingevent',
            ist: 'Thu, 23 Jan 2020 03:22:56 GMT',
            request: {
              "TotalVolume": "27000",
              "MilkingDateTime": "",
              "VatMilkingDetails": [
                {
                  "VatID": "vat39001-1",
                  "VatVolume": 27000
                }
              ]
            },
            response: {
              "code": 400,
              "message": "MilkingDateTime format is invalid"
            },
          }, {
            title: 'Step 3',
            url: '/v5/milkvattelemetry/72178/milkingevent',
            ist: 'Thu, 23 Jan 2020 01:44:18 GMT',
            request: {
              "TotalVolume": "27000",
              "MilkingDateTime": "2020-01-23T05:59:56.001Z",
              "VatMilkingDetails": [
                {
                  "VatID": "vat39001-1",
                  "VatVolume": 27000
                }
              ],
              "MilkingTimeWindow": {
                "UserID": "testmvtuser",
                "MilkingSchedule": {
                  "CleanInPlaceTime": 15,
                  "Confirmed": true,
                  "MilkingCycle": "Twice per Day",
                  "StartDate": "2020-01-20",
                  "MilkingPeriods": [{
                    "StartTime": 282,
                    "Duration": 77
                  }, {
                    "StartTime": 1136,
                    "Duration": 107
                  }]
                }
              }
            },
            response: {
              "code": "400",
              "message": "Error 1 - Requested start date and first milking period start time not available "
            },
          }],
        },
        {
          title: 'E2E04 - Collection Scenario 1 - with EOC Volume greater than 0',
          url: '/v5/milkvattelemetry/72178/collectionevent',
          ist: 'Thu, 23 Jan 2020 01:53:08 GMT',
          request: {
            "CollectionDateTime": "2019-11-02T11:02:56.001Z",
            "AverageTemperatureAtCollection": 9.6,
            "TotalVolumeBeforeCollection": 6200,
            "TotalVolumeAfterCollection": 620,
            "CollectionOccurredDuringMilking": true,
            "VatCollectionDetails": [
              {
                "VatID": "vat39001-1",
                "VatVolumeBeforeCollection": 6200,
                "VatVolumeAfterCollection": 620,
                "VatTemperature": 9.1
              }
            ]
          },
          response: {
            "code": "200",
            "message": "CollectionEvent updated for SupplierID: 72178"
          },
        },
        {
          title: 'E2E05 - Collection Scenario 2 - with EOC Volume _ 0',
          url: '/v5/milkvattelemetry/72178/collectionevent',
          ist: 'Thu, 23 Jan 2020 01:53:57 GMT',
          request: {
            "CollectionDateTime": "2019-11-02T11:02:56.001Z",
            "AverageTemperatureAtCollection": 9.6,
            "TotalVolumeBeforeCollection": 6200,
            "TotalVolumeAfterCollection": 0,
            "CollectionOccurredDuringMilking": true,
            "VatCollectionDetails": [
              {
                "VatID": "vat39001-1",
                "VatVolumeBeforeCollection": 6200,
                "VatVolumeAfterCollection": 0,
                "VatTemperature": 9.1
              }
            ]
          },
          response: {
            "code": "200",
            "message": "CollectionEvent updated for SupplierID: 72178"
          },

        },
        {
          title: 'E2E06 - Collection Scenario 3 - Error Handling',
          steps: [
            {
              title: 'Step 1',
              url: '/v5/milkvattelemetry/99997/collectionevent',
              ist: 'Thu, 23 Jan 2020 01:57:34 GMT',
              request: {
                "CollectionDateTime": "2019-11-02T11:02:56.001Z",
                "AverageTemperatureAtCollection": 9.6,
                "TotalVolumeBeforeCollection": 6200,
                "TotalVolumeAfterCollection": 0,
                "CollectionOccurredDuringMilking": true,
                "VatCollectionDetails": [
                  {
                    "VatID": "vat39001-1",
                    "VatVolumeBeforeCollection": 6200,
                    "VatVolumeAfterCollection": 0,
                    "VatTemperature": 9.1
                  }
                ]
              },
              response: {
                "code": "400",
                "message": "Error 2 - Farm 99997 is invalid"
              },
            },
            {
              title: 'Step 2',
              url: '/v5/milkvattelemetry/72178/collectionevent',
              ist: 'Thu, 23 Jan 2020 02:01:26 GMT',
              request: {
                "AverageTemperatureAtCollection": 9.6,
                "TotalVolumeBeforeCollection": 6200,
                "TotalVolumeAfterCollection": 0,
                "CollectionOccurredDuringMilking": true,
                "VatCollectionDetails": [
                  {
                    "VatID": "vat39001-1",
                    "VatVolumeBeforeCollection": 6200,
                    "VatVolumeAfterCollection": 0,
                    "VatTemperature": 9.1
                  }
                ]
              },
              response: {
                "code": 400,
                "message": "Field - CollectionDateTime missing; check all required fields are in request message"
              },
            },
            {
              title: 'Step 3',
              url: '/v5/milkvattelemetry/72178/collectionevent',
              ist: 'Thu, 23 Jan 2020 02:02:50 GMT',
              request: {
                "CollectionDateTime": "2019-11-02T11:02:56.001Z",
                "AverageTemperatureAtCollection": 9,
                "TotalVolumeBeforeCollection": 6200,
                "TotalVolumeAfterCollection": 0,
                "CollectionOccurredDuringMilking": true,
                "VatCollectionDetails": [
                  {
                    "VatID": "vat39001-1",
                    "VatVolumeBeforeCollection": 6200,
                    "VatVolumeAfterCollection": 0,
                    "VatTemperature": 9.1
                  }
                ]
              },
              response: {
                "code": "400",
                "message": "Error 1 - Temperature '9' is not a decimal to 1 decimal place"
              },
            },
          ],
        },
        {
          title: 'E2E07 - Start-of-Shift manually triggered file generation from Aspire to Genesis',
          description: 'N/A',

        },
        {
          title: 'E2E08 - Collection During Milking',
          url: '/v5/milkvattelemetry/72178/collectionevent',
          ist: 'Thu, 23 Jan 2020 02:05:59 GMT',
          request: {
            "CollectionDateTime": "2019-11-02T11:02:56.001Z",
            "AverageTemperatureAtCollection": 9.6,
            "TotalVolumeBeforeCollection": 6200,
            "TotalVolumeAfterCollection": 1000,
            "CollectionOccurredDuringMilking": true,
            "VatCollectionDetails": [
              {
                "VatID": "vat39001-1",
                "VatVolumeBeforeCollection": 6200,
                "VatVolumeAfterCollection": 1000,
                "VatTemperature": 9.1
              }
            ]
          },
          response: {
            "code": "200",
            "message": "CollectionEvent updated for SupplierID: 72178"
          },
        },
        {
          title: 'E2E09 - Bulk data',
          description: 'Will attach another attachment',
        },
        {
          title: 'E2E10 - Ensure the MVT Device can correctly send the different alerts through the interface to FarmSource',
          steps: [{
            title: 'Step 2',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:15:15 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "ApcLow",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "APC": 4.3
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          }, {
            title: 'Step 3',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:20:24 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "ApcModerate",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "APC": 4.9
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          }, {
            title: 'Step 4',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:21:26 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "ApcModerate",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "Agitator": "1:30:00"
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          }, {
            title: 'Step 5',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:22:37 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "ApcHigh",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "APC": 5.7
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          }, {
            title: 'Step 6',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:23:40 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "ApcMilkAcceptanceRiskExceeded",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "APC": 6.5
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          }, {
            title: 'Step 7',
            url: '/v5/milkvattelemetry/72178/vatexceptions',
            ist: 'Thu, 23 Jan 2020 02:24:15 GMT',
            request: {
              "VatID": "vat39001-2",
              "Type": "LateMilking",
              "DateTime": "2020-01-22T11:02:56.001Z",
              "Description": {
                "Limit": "01:00:00"
              }
            },
            response: {
              "code": "200",
              "message": "VatException updated for SupplierID:72178"
            },
          },],
        },
      ],
    })
  }

  renderItem = (item) => {
    return <>
      <p><strong>Url</strong> - <small>{item.url}</small></p>
      <p><strong>Method</strong> - <small>POST</small></p>
      <p><strong>Request</strong> - <small>{item.ist}</small></p>
      <SyntaxHighlighter language="json" style={vs2015}>
        {JSON.stringify(item.request, null, 4)}
      </SyntaxHighlighter>
      <p><strong>Response</strong></p>
      <SyntaxHighlighter language="json" style={vs2015}>
        {JSON.stringify(item.response, null, 4)}
      </SyntaxHighlighter>
    </>
  }

  render() {
    return (
      <>
        <div style={{ height: 35, backgroundColor: '#e8e8e8' }}></div>
        <div className="container-fluid bg-white py-3">
          <div className='row'>
            <div className='col'>
              <h1>Pre - Requisites</h1>
              <p>Endpoint: https://fonterratestapis.apimanagement.ap1.hana.ondemand.com:443</p>
              <p>Real Farm ID: 72178</p>
              <p>Fake Farm ID: 99997</p>
              {this.data.map((item, index) => <div key={index}>
                <div className='mb-3' style={{ height: 3, backgroundColor: '#eee' }}></div>
                <h1 className="mb-3">{item.title}</h1>
                {item.description ? <p>{item.description}</p> : null}
                {item.request && item.response ? this.renderItem(item) : null}
                {item.steps ? item.steps.map((item2, index2) => <div key={index2}>
                  <h3 className="mb-3 text-muted">{item2.title}</h3>
                  {this.renderItem(item2)}
                </div>) : null}
              </div>)}
            </div>
          </div>
        </div>
        <div style={{ height: 35, backgroundColor: '#e8e8e8' }}></div>
      </>
    )
  }
}

export default withRouter(observer(Component))
