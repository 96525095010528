import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      refChartVolume: null,
      refChartTemp: null,
      timeRange: { start: 0, end: 0, },
    })
  }

  @computed get optionsVolume() {

    if (this.refChartVolume) this.refChartVolume.zoomOut()
    if (this.refChartTemp) this.refChartTemp.zoomOut()

    let listStorage = []
    if (this.props.detailData && this.props.detailData.length) {
      listStorage = this.props.detailData
    }

    let listDataLevel = []

    for (let item of listStorage) {

      let x = moment.utc(item.published_at).local().toDate()

      if (item.milk_volume === undefined || item.milk_volume === null) {
        continue
      }

      listDataLevel.push({
        x: x,
        y: parseFloat(item.milk_volume),
        marker: global.constants.highcharts.marker,
      })
    }

    let listSeries = []
    listSeries.push({
      name: 'Milk Volume (L)',
      turboThreshold: 10000,
      data: listDataLevel,
    })

    // ------------------

    let plotOptions = {
      // column: {colorByPoint: true},
      series: {
        // color: '#2594b7',
        // cursor: 'pointer',
        events: {
          legendItemClick: function () {
            // if (this.name === 'CIP') {
            //   store.farmer.chartSyncCIP = !store.farmer.chartSyncCIP
            //   return false
            // }
          },
        }
      },
    }


    // let listPlotY = [{
    //   color: '#FF0000',
    //   dashStyle: 'longdash',
    //   width: 1,
    //   value: 10,
    // }, {
    //   color: '#FF0000',
    //   dashStyle: 'longdash',
    //   width: 1,
    //   value: 6,
    // }]

    let result = {
      credits: false,
      chart: {
        type: 'line',
        height: 300,
        zoomType: 'x',
        marginLeft: 60,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        title: { text: null },
        events: {
          afterSetExtremes: e => {
            this.timeRange.start = e.min
            this.timeRange.end = e.max
            this.refChartTemp.xAxis[0].setExtremes(e.min, e.max)
          }
        },
      },
      yAxis: {
        title: { text: 'Litres' },
        // plotLines: listPlotY,
        // tickInterval: 2,
        // gridLineWidth: 0,
        // lineWidth: 1,
        min: 0,
        // max: 15,
        // labels: {
        //   formatter: function () {
        //     if (this.value === 10 || this.value === 6) {
        //       return this.value + ' °C';
        //     } else {
        //       return null
        //     }
        //   }
        // }
      },
      plotOptions: plotOptions,
      series: listSeries,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment.utc(this.x).local().format(global.constants.date_format_week)
          switch (this.key) {
            default:
              return `<div>${xx} / <strong>${DTS.getVolumeRound(this.y)} L</strong></div>`;
          }
        }
      }
    }
    return result
  }

  @computed get optionsTemp() {

    let listStorage = [], listCooler = []
    if (this.props.detailData && this.props.detailData.length) {
      listStorage = this.props.detailData
    }
    if (this.props.coolerData && this.props.coolerData.length) {
      listCooler = this.props.coolerData
    }

    let listDataVat = []
    let listDataInlet = []
    let listDataPlate = []
    let listDataAmbient = []
    let listDataCIP = []

    let listDataMilkIn = []
    let listDataWaterIn = []

    for (let item of listStorage) {

      let x = moment.utc(item.published_at).local().toDate()

      if (item.temp1 === undefined || item.temp1 === null) {
        continue
      }

      listDataVat.push({
        x: x,
        y: parseFloat(item.temp1),
        marker: global.constants.highcharts.marker,
      })
      if (item.temp2 !== undefined && item.temp2 !== null) {
        listDataInlet.push({
          x: x,
          y: parseFloat(item.temp2),
          marker: global.constants.highcharts.marker,
        })
      }
      if (item.temp3 !== undefined && item.temp3 !== null) {
        listDataPlate.push({
          x: x,
          y: parseFloat(item.temp3),
          marker: global.constants.highcharts.marker,
        })
      }
      if (item.temp4 !== undefined && item.temp4 !== null) {
        listDataAmbient.push({
          x: x,
          y: parseFloat(item.temp4),
          marker: global.constants.highcharts.marker,
        })
      }
      if (item.temp5 !== undefined && item.temp5 !== null) {
        listDataCIP.push({
          x: x,
          y: parseFloat(item.temp5),
          marker: global.constants.highcharts.marker,
        })
      }
    }

    for (let item of listCooler) {
      let x = moment.utc(item.published_at).local().toDate()
      listDataMilkIn.push({
        x: x,
        y: parseFloat(item.milk_in),
        marker: global.constants.highcharts.marker,
      })
      listDataWaterIn.push({
        x: x,
        y: parseFloat(item.water_in),
        marker: global.constants.highcharts.marker,
      })
    }

    let listSeries = []
    if (listDataVat.length) {
      listSeries.push({
        name: 'Vat (°C)',
        turboThreshold: 10000,
        data: listDataVat,
      })
    }
    if (listDataInlet.length) {
      listSeries.push({
        name: 'Inlet (°C)',
        turboThreshold: 10000,
        data: listDataInlet,
        visible: true,
      })
    }

    if (listDataPlate.length) {
      listSeries.push({
        name: 'Plate (°C)',
        turboThreshold: 10000,
        data: listDataPlate,
        visible: true,
      })
    }

    if (listDataAmbient.length) {
      listSeries.push({
        name: 'Ambient (°C)',
        turboThreshold: 10000,
        data: listDataAmbient,
        visible: true,
      })
    }

    if (listDataCIP.length) {
      listSeries.push({
        name: 'CIP (°C)',
        turboThreshold: 10000,
        data: listDataCIP,
        visible: true,
      })
    }

    if (listDataMilkIn.length) {
      listSeries.push({
        name: 'Milk In (°C)',
        turboThreshold: 10000,
        data: listDataMilkIn,
        visible: true,
      })
    }

    if (listDataWaterIn.length) {
      listSeries.push({
        name: 'Water In (°C)',
        turboThreshold: 10000,
        data: listDataWaterIn,
        visible: true,
      })
    }
    // ------------------

    let plotOptions = {
      // column: {colorByPoint: true},
      series: {
        // color: '#2594b7',
        // cursor: 'pointer',
        events: {
          legendItemClick: function () {
            // if (this.name === 'CIP') {
            //   store.farmer.chartSyncCIP = !store.farmer.chartSyncCIP
            //   return false
            // }
          },
        }
      },
    }

    // let listPlotY = [{
    //   color: '#FF0000',
    //   dashStyle: 'longdash',
    //   width: 1,
    //   value: 10,
    // }, {
    //   color: '#FF0000',
    //   dashStyle: 'longdash',
    //   width: 1,
    //   value: 6,
    // }]

    let result = {
      credits: false,
      chart: {
        type: 'line',
        height: 300,
        zoomType: 'x',
        marginLeft: 60,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        title: { text: null },
        events: {
          afterSetExtremes: e => {
            this.timeRange.start = e.min
            this.timeRange.end = e.max
            this.refChartVolume.xAxis[0].setExtremes(e.min, e.max)
          }
        },
      },
      yAxis: {
        title: { text: 'Temperatures' },
        // plotLines: listPlotY,
        // tickInterval: 2,
        // gridLineWidth: 0,
        // lineWidth: 1,
        min: 0,
        // max: 15,
        // labels: {
        //   formatter: function () {
        //     if (this.value === 10 || this.value === 6) {
        //       return this.value + ' °C';
        //     } else {
        //       return null
        //     }
        //   }
        // }
      },
      plotOptions: plotOptions,
      series: listSeries,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment.utc(this.x).local().format(global.constants.date_format_week)
          switch (this.key) {
            default:
              return `<div>${xx} / <strong>${DTS.getTemperature(this.y)} °C</strong></div>`;
          }
        }
      }
    }
    return result
  }


  render() {
    return (
      <>
        <div className='mb-3'>
          <h5 style={{ marginLeft: '30px' }} className='mb-3'>Milk Volume</h5>
          <HighchartsReact
            // ref={this.refChartVolume}
            options={this.optionsVolume}
            callback={chart => {
              this.refChartVolume = chart
            }}
          />
        </div>

        <div className='mb-3'>
          <h5 style={{ marginLeft: '30px' }} className='mb-3'>Temperatures</h5>
          <HighchartsReact
            // ref={this.refChartTemp}
            options={this.optionsTemp}
            callback={chart => {
              this.refChartTemp = chart
            }}
          />
        </div>
      </>
    )
  }


}

export default observer(Component)
