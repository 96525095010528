import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { BrowserRouter, Link, withRouter } from "react-router-dom"
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import emitter from "../../utility/EventEmitter";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../common/theme";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import BaseDashboardAdmin from "./BaseDashboardAdmin";
import { Breadcrumb } from 'antd';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import SharedElement from 'react-shared-element'

class Component extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        currentFarm: {},
        listSub: [],
        listRole: [],
        listUser: [],
        listParent: [],
      },
      editFarm: {},
      listRelattion: [],
      listUserUnlinked: [],
      isEditNotAdd: false,
      crudType: '',
      isRightNew: false,
      isRightExists: false,
      isAddAUser: false,
      organization_tags: ['Group', 'Regional', 'Branch', 'Farm'],
      organization_tags_without_farm: ['Group', 'Regional', 'Branch'],
      organization_package: ['Non-independent accounting', 'Vat-Man Lite', 'Vat-Man Pro', 'Vat-Man Auto'],
      isInitFinished: false,
    })

    this.ref_current = React.createRef()
    this.organization_id = props.match.params.organization_id
    this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    store.dashboard.menuActive = 'Organizations'
    store.dashboard.navbarLeft = 'Organizations'
  }

  componentDidUpdate() {
    if (this.organization_id !== this.props.match.params.organization_id) {
      this.organization_id = this.props.match.params.organization_id
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    }
  }

  initEditFarm = () => {
    this.editFarm = {
      tag: 'Group',
      name: '',
      contact: '',
      address: '',
      email: '',
      phone: '',
      package: 'Non-independent accounting',
    }
  }

  setEditFarm = (item) => {
    this.editFarm._id = item._id
    if (item.tag) this.editFarm.tag = item.tag
    if (item.name) this.editFarm.name = item.name
    if (item.contact) this.editFarm.contact = item.contact
    if (item.address) this.editFarm.address = item.address
    if (item.email) this.editFarm.email = item.email
    if (item.phone) this.editFarm.phone = item.phone
    if (item.package) this.editFarm.package = item.package
  }

  initData = (organization_id, parent_id) => {
    this.initEditFarm()
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_LIST');
    if (organization_id) {
      params.append('organization_id', organization_id);
    }
    if (parent_id) {
      params.append('parent_id', parent_id);
    }
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      if (organization_id && (!data.currentFarm || !data.currentFarm._id)) {
        this.props.history.push('/dts/dashboard')
      } else {
        data.listSub.map(item => { if (!item.tag) item.tag = 'Farm' })
        this.data = data
      }
      this.isInitFinished = true
    })
  }

  crudOrganization = () => {
    console.log(this.editFarm)
    const params = new URLSearchParams();
    params.append('type', this.crudType);
    params.append('_id', this.editFarm._id);
    if (this.data.currentFarm && this.data.currentFarm._id) {
      params.append('organization_id', this.data.currentFarm._id);
    }
    params.append('name', this.editFarm.name);
    params.append('tag', this.editFarm.tag);
    params.append('contact', this.editFarm.contact);
    params.append('address', this.editFarm.address);
    params.append('email', this.editFarm.email);
    params.append('phone', this.editFarm.phone);
    // params.append('package', this.editFarm.package);
    // console.log(this.editFarm.contact == true)
    // return
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
      this.isRightNew = false
    })
  }

  relateOrganizationList = () => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_RELATE_LIST');
    params.append('organization_id', this.props.match.params.organization_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.listRelattion = data
    })
  }

  relateOrganizationAction = (son_id) => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_RELATE_LINK');
    params.append('organization_id', this.props.match.params.organization_id);
    params.append('son_id', son_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.isRightExists = false
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    })
  }

  relateOrganizationUnlink = (son_id) => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_RELATE_UNLINK');
    params.append('organization_id', this.props.match.params.organization_id);
    params.append('son_id', son_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.initData(this.props.match.params.organization_id)
    })
  }

  userOrganizationList = () => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_LIST');
    params.append('organization_id', this.props.match.params.organization_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.listUserUnlinked = data
    })
  }

  userOrganizationAction = (user_id) => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_LINK');
    params.append('organization_id', this.props.match.params.organization_id);
    params.append('user_id', user_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.isAddAUser = false;
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    })
  }

  userOrganizationUnlink = (user_id) => {
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_UNLINK');
    params.append('organization_id', this.props.match.params.organization_id);
    params.append('user_id', user_id);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      // console.log(data)
      this.initData(this.props.match.params.organization_id, this.props.match.params.parent_id)
    })

  }

  handleRoleEdit = (item, role) => {
    if (!store.current.user) {
      return
    }
    if (store.current.user._id === item._id) {
      emitter.emit("NOTIFY_SNACKBAR", 'You can not change your own role!')
      return
    }
    item.role = role
    item.open = false
    const params = new URLSearchParams();
    params.append('type', 'ORGANIZATION_USER_ROLE_EDIT');
    params.append('farm_id', this.data.currentFarm._id);
    params.append('user_id', item._id);
    params.append('role', role);
    Utils.httpPost(Utils.urlList.admin_organization, params, data => {
      console.log(data)
    })
  }

  deleteDialogOpen = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to delete it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {
        this.crudOrganization()
      },
      forceSelect: false,
    })
  }

  unlinkDialogOpenRelate = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to unlink it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {

      },
      forceSelect: false,
    })
  }

  unlinkDialogOpenUser = () => {
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to unlink it?',
      body: '',
      callbackCancel: true,
      callbackOk: () => {

      },
      forceSelect: false,
    })
  }

  getUserRole = (user_id) => {
    for (let item of this.data.listRole) {
      if (item.user_id === user_id) {
        return item.role
      }
    }
    return null
  }

  getParentId = () => {
    let organization_id = this.props.match.params.organization_id
    let parent_id = this.props.match.params.parent_id
    if (!parent_id) {
      parent_id = ''
    }
    if (organization_id) {
      parent_id += (parent_id ? '-' : '') + organization_id
    }
    return parent_id
  }

  @computed get getIsFinanceCustomer() {
    if (this.data.currentFarm && this.organization_package.includes(this.data.currentFarm.package) && this.data.currentFarm.package !== 'Non-independent accounting') {
      return true
    } else {
      return false
    }
  }


  render() {
    return (
      <div className='container-fluid my-3'>
        {/* {this.data.currentFarm && this.data.currentFarm.fonterra_id ? */}

        {this.isInitFinished ?
          <SharedElement ref={this.ref_current} id={'shared-element-' + (this.data.currentFarm ? this.data.currentFarm._id : '')}>

            {/*top operation bar sub*/}
            <div className='mb-3 bg-white'>
              <div className='flex-grow-1 d-flex align-items-center p-3 nowrap'>
                {this.data.currentFarm && (this.data.currentFarm.name || this.data.currentFarm.fonterra_id) ? <Breadcrumb className='mr-3' style={{ fontSize: '1.1rem' }}>
                  {this.data.listParent.map((item, index) => <Breadcrumb.Item key={index}>{item.name ? item.name : item.fonterra_id}</Breadcrumb.Item>)}
                  <Breadcrumb.Item key={9999}>{this.data.currentFarm.name ? this.data.currentFarm.name : this.data.currentFarm.fonterra_id}</Breadcrumb.Item>
                </Breadcrumb> : null}
                {this.data.currentFarm
                  ? <Chip
                    size='small'
                    label={this.data.currentFarm.tag ? this.data.currentFarm.tag : 'Farm'}
                    onClick={() => this.ref_current.current.redirect(() => this.props.history.push("/dts/farms"))}
                  />
                  : null}
              </div>
            </div>

            {this.getIsFinanceCustomer
              ? <Card className='mt-3'>
                <CardHeader
                  subheader="Summary"
                />
                <CardContent>

                  <div className='row'>
                    <div className='col-md-4'>
                      <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                        {this.data.currentFarm.package}
                      </Typography>
                      <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        {/* Status: Normal */}
                  </Typography>
                    </div>
                    <div className='col-md-4'>
                      <dl>
                        {/* <dt className='mb-2'>Service due date</dt>
                        <dd>31/12/2020</dd> */}
                      </dl>
                    </div>
                    <div className='col-md-4'>
                      <dl>
                        <dt className='mb-2'>Total number of SMS</dt>
                        <dd>{['Vat-Man Pro', 'Vat-Man Auto'].includes(this.data.currentFarm.package) ? 120 : 60}</dd>
                      </dl>
                    </div>
                  </div>
                </CardContent>
                {/* <CardActions className='d-flex'>
              <div className='flex-grow-1'></div>
              <Button color="primary" onClick={() => {

              }}>Invoice</Button>
            </CardActions> */}
              </Card>
              : null}


            {/*sub organization list*/}
            {this.data.listSub.length
              ? <Card className='mt-3'>
                <CardHeader
                  className='border-bottom'
                  subheader={!this.data.currentFarm ? "All Organization" : "Sub-organization"}
                />
                <CardContent>
                  {this.data.listSub.map((item, index) => <div key={index}>
                    <div className='d-flex align-items-center'>
                      <Link to={`/dts/organization/${item._id}/o/${this.getParentId()}`} className='flex-grow-1 nowrap'>
                        <span className='mr-3 text-primary'>
                          {item.name ? item.name : item.fonterra_id}
                        </span>
                        {item.fonterra_id ? <span className='mr-3 text-muted'>{item.fonterra_id}</span> : null}
                        {item.tag ? <span className='mr-3 text-muted'>
                          <Chip className='mr-3' size='small' label={item.tag} /></span> : null}
                      </Link>

                      <Button color="primary" onClick={() => {
                        this.crudType = 'ORGANIZATION_EDIT'
                        Object.keys(item).map(function (key, index) {
                          item[key] = item[key] ? item[key] : '';
                        });
                        if (!item.package) {
                          item.package = this.organization_package[0]
                        }
                        // console.log(item)
                        // this.editFarm = item
                        this.setEditFarm(item)
                        this.isRightNew = true
                      }}>Edit</Button>

                      {this.props.match.params.organization_id ?
                        <Button color="secondary" onClick={() => {
                          this.relateOrganizationUnlink(item._id)
                        }}>Unlink</Button> : null}

                      <Button className='text-danger' onClick={() => {
                        this.crudType = 'ORGANIZATION_DELETE'
                        // this.editFarm = item
                        this.setEditFarm(item)
                        this.deleteDialogOpen()
                      }}>Delete</Button>
                    </div>
                    <Divider className='my-3' />

                  </div>)}
                </CardContent>
              </Card>
              : null
            }

            {/* user list */}
            {this.props.match.params.organization_id
              ? <Card className='mt-3'>
                <CardHeader
                  // className='border-bottom'
                  subheader="User list"
                />
                <CardContent className='table-responsive'>
                  {this.data.listUser.length ?
                    <table className='table border-bottom'>
                      <tbody>
                        {this.data.listUser.map((item, index) => <tr className='' key={index}>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>
                            {this.getUserRole(item._id)}
                            {/*<IconButton onClick={e => {
                          item.isMenuAnchorEl = e.currentTarget
                          item.open = true
                        }}>
                          <i className="material-icons font-size-base">
                            border_color
                      </i>
                        </IconButton>
                        <MuiThemeProvider theme={theme}>
                          <Menu
                            id="simple-menu"
                            anchorEl={item.isMenuAnchorEl}
                            keepMounted
                            open={item.open ? true : false}
                            onClose={() => {
                              item.open = false
                            }}
                          >
                            <MenuItem onClick={() => {
                              this.handleRoleEdit(item, 'Admin')
                            }}>Admin</MenuItem>
                            <MenuItem onClick={() => {
                              this.handleRoleEdit(item, 'Viewer')
                            }}>Viewer</MenuItem>
                          </Menu>
                        </MuiThemeProvider> */}
                          </td>
                          <td className='text-muted text-right'>
                            <Button color="secondary" onClick={() => {
                              this.userOrganizationUnlink(item._id)
                            }}>Unlink</Button>
                          </td>
                        </tr>)}
                      </tbody>
                    </table> : null}
                </CardContent>
                <CardActions>
                  <Button color="secondary" onClick={() => {
                    this.isAddAUser = true
                    this.userOrganizationList()
                  }}>+ Add a user</Button>
                </CardActions>
              </Card>
              : null}

            {/*top operation bar sub*/}
            <div className='my-3 p-3 bg-white'>
              <div className='text-right'>
                {this.props.match.params.organization_id
                  ? <span><Button className='ml-3' color="secondary" onClick={() => {
                    this.initEditFarm()
                    this.isRightExists = true
                    this.relateOrganizationList()
                  }}>Add an exists</Button></span>
                  : null}
                <Button className='ml-3' color="primary" onClick={() => {
                  this.initEditFarm()
                  this.crudType = 'ORGANIZATION_ADD'
                  this.isRightNew = true
                }}>Add a new</Button>
              </div>
            </div>

          </SharedElement>
          : null}

        {/* drawer add or edit a sub to an organization */}
        <Drawer anchor="right" open={this.isRightNew} onClose={() => {
          this.isRightNew = false
        }}>
          <div className='py-5 px-4 form-organization'>

            <div className='pb-3'>
              <MuiThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  disabled={this.crudType === 'ORGANIZATION_EDIT'}
                  select
                  label="The tag of this organization"
                  id="simple-start-adornment"
                  value={this.editFarm.tag}
                  onChange={e => {
                    this.editFarm.tag = e.target.value
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Tag: </InputAdornment>,
                  }}
                >
                  {(this.crudType === 'ORGANIZATION_ADD' ? this.organization_tags_without_farm : this.organization_tags).map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </MuiThemeProvider>
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.name}
                onChange={e => {
                  this.editFarm.name = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Name: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.contact}
                onChange={e => {
                  this.editFarm.contact = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Contact: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.address}
                onChange={e => {
                  this.editFarm.address = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Address: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.email}
                onChange={e => {
                  this.editFarm.email = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Email: </InputAdornment>,
                }}
              />
            </div>
            <div className='pb-3'>
              <TextField
                fullWidth
                id="simple-start-adornment"
                value={this.editFarm.phone}
                onChange={e => {
                  this.editFarm.phone = e.target.value
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Phone: </InputAdornment>,
                }}
              />
            </div>
            {/* <div className='pb-3'>
              <MuiThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  select
                  id="simple-start-adornment"
                  value={this.editFarm.package}
                  onChange={e => {
                    this.editFarm.package = e.target.value
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Finances: </InputAdornment>,
                  }}
                >
                  {this.organization_package.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </MuiThemeProvider>
            </div> */}
            <Button fullWidth variant="contained" color="primary" onClick={() => {
              this.crudOrganization()
            }}>
              {this.crudType === 'ORGANIZATION_ADD' ? 'Add' : null}
              {this.crudType === 'ORGANIZATION_EDIT' ? 'OK' : null}
            </Button>
          </div>
        </Drawer>

        {/* drawer link a sub to an organization */}
        <Drawer anchor="left" open={this.isRightExists} onClose={() => {
          this.isRightExists = false
        }}>
          <div className='pb-5 form-organization'>
            <div className='pb-3'>
              <div className='pl-3 bg-secondary text-white' style={{ lineHeight: '48px' }}>
                Click below item add to Organization
              </div>

              <List component="nav" aria-label="secondary mailbox folders">
                {this.listRelattion.map((item, index) => <ListItem button key={index} onClick={() => {
                  this.relateOrganizationAction(item._id)
                }}>
                  <div className='text-primary col-4'>{item.name ? item.name : item.fonterra_id}</div>
                  <div className='text-muted col-8'>{item.fonterra_id}</div>
                </ListItem>)}
              </List>
            </div>
          </div>
        </Drawer>

        {/* drawer add a user to an organization*/}
        <Drawer anchor="left" open={this.isAddAUser} onClose={() => {
          this.isAddAUser = false
        }}>
          <div className='pb-5 form-organization'>
            <div className='pb-3'>
              <div className='pl-3 bg-secondary text-white' style={{ lineHeight: '48px' }}>
                Click below user add to Organization
              </div>

              <List component="nav" aria-label="secondary mailbox folders">
                {this.listUserUnlinked.map((item, index) => <ListItem button key={index} onClick={() => {
                  this.userOrganizationAction(item._id)
                }}>
                  <div className='text-primary col-3'>{item.firstName}</div>
                  <div className='text-primary col-3'>{item.lastName}</div>
                  <div className='text-muted col-6'>{item.email}</div>
                </ListItem>)}
              </List>
            </div>
          </div>
        </Drawer>

      </div>
    )
  }
}

export default withRouter(observer(Component))
