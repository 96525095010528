import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {
            vat: '1',
            datetime: '',
            volume: '',
            temperature: '',
        })
        this.state = {
            error: false,
            resultDialog: false
        }
        store.dashboard.menuActive = 'Submit Pickup'
        store.dashboard.navbarLeft = 'Submit Pickup'
    }

    submitPickup = () => {

        if (this.datetime != '' && this.volume != ''){
        
            const params = new URLSearchParams()
            params.append('type', 'ADD_PICKUP')
            params.append('farm_id',store.current.farm._id)
            params.append('vat', this.vat)
            params.append('datetime', this.datetime)
            params.append('volume', this.volume)
            params.append('temperature', this.temperature)
    
            Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
                if (data === 'success'){
                    this.setState({resultDialog: true})
                    this.datetime = ''
                    this.volume = ''
                    this.temperature = ''
                }
            })
        }
        else this.setState({error: true})
    }

    handleDialogClose = () => {
            this.setState({resultDialog: false})
    }

    render() {
        return (
            <div className='container-fluid bg-white'>
                <Dialog
                    open={this.state.resultDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Pickup Submitted
                    </DialogTitle>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogClose}>
                        Ok
                    </Button>
                </Dialog>
                <div className='my-3'>
                    <Container component="main" maxWidth="xs">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="vat"
                            label="Vat"
                            type="number"
                            id="vat"
                            value={this.vat}
                            error={this.vat == '' && this.state.error}
                            onChange={e => {
                                if (e.target.value >= 0) this.vat = e.target.value;
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="datetime"
                            label="Pickup Time"
                            type="datetime-local"
                            id="datetime"
                            value={this.datetime}
                            error={this.datetime == '' && this.state.error}
                            InputLabelProps={{
                               shrink: true
                            }}
                            onChange={e => {
                                this.datetime = e.target.value;
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="volume"
                            label="Volume (L)"
                            type="number"
                            id="volume"
                            value={this.volume}
                            error={this.volume == '' && this.state.error}
                            onChange={e => {
                                if (e.target.value >= 0) this.volume = e.target.value;
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="temperature"
                            label="Temperature (C)"
                            type="number"
                            id="temperature"
                            value={this.temperature}
                            onChange={e => {
                                if (e.target.value >= 0) this.temperature = e.target.value;
                            }}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="my-3"
                            onClick={this.submitPickup}>
                            Submit Pickup
                        </Button>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))
