import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboard from "./BaseDashboard";
import BasePromotion from "../promotion/BasePromotion";
import store from "../../store/Store";
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MuiThemeProvider, Dialog, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Input } from '@material-ui/core';
import theme from "../common/theme";
import Utils from "../../utility/Utils";
import emitter from "../../utility/EventEmitter"
//import MaskedInput from 'react-text-mask'
//import VerificationInput from 'react-verification-input'

class Component extends BasePromotion {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        subscribed: false,
        email_verify: 2,
        phone_verify: 2,
      },
      password: '',
      password1: '',
      password2: '',
      isVerifyEmail: false,
      isVerifyPhone: false,
      verifyPhoneCode: '',
    })

    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = 'Profile'
    this.initData();
    this.checkVerifyEmail()
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_DATAILS');
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      this.user = data
    })
  }

  userEditProfile = (notify) => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_EDIT_BASE');
    params.append('user', JSON.stringify(this.user));
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      // window.location.reload()
      if (notify) {
        emitter.emit("NOTIFY_SNACKBAR", 'Success!')
      }
    })
  }

  userEditPassword = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_EDIT_PASSWORD');
    params.append('password', this.password);
    params.append('password1', this.password1);
    params.append('password2', this.password2);
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success!')
      this.password = ''
      this.password1 = ''
      this.password2 = ''
    })
  }

  sendVerifyEmail = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_VERIFY_SEND_EMAIL');
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      this.isVerifyEmail = false
      emitter.emit("NOTIFY_SNACKBAR", 'Please check the email and complete the rest of the operation!')
    })
  }

  sendVerifyPhone = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_VERIFY_SEND_PHONE');
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      // this.isVerifyPhone = false
      emitter.emit("NOTIFY_SNACKBAR", 'Text message has been sent!')
    })
  }

  checkVerifyEmail = () => {
    let verification = this.props.match.params.verification
    if (!verification) return

    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_VERIFY_CHECK_EMAIL');
    params.append('verification', verification);
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      if (data === 'Email verify success') {
        emitter.emit("NOTIFY_SNACKBAR", 'Email verification succeeded')
        this.initData()
      }
      // this.props.history.push('/dts/user/profile')
    })
  }

  checkVerifyPhone = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_VERIFY_CHECK_PHONE');
    params.append('verification', this.verifyPhoneCode);
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      if (data === 'Phone verify success') {
        this.isVerifyPhone = false
        emitter.emit("NOTIFY_SNACKBAR", 'Phone verification succeeded')
        this.initData()
      } else {
        emitter.emit("NOTIFY_SNACKBAR", 'Phone verification failed')
      }
    })
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3'>
          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Profile"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="First Name"
                    margin="normal"
                    value={this.user.firstName}
                    onChange={e => { this.user.firstName = e.target.value }}
                  />
                </div>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="Last Name"
                    margin="normal"
                    value={this.user.lastName}
                    onChange={e => { this.user.lastName = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="Email Address"
                    margin="normal"
                    value={this.user.email}
                  />
                  <div className='text-button-right'>
                    {this.user.email_verify === 2 ? <Tooltip title="Verified" placement="left">
                      <i className="material-icons text-primary">check_circle</i>
                    </Tooltip> : <Tooltip title="Unverified" placement="left">
                        <i className="material-icons text-secondary cursor-pointer" onClick={() => { this.isVerifyEmail = true }}>info</i>
                      </Tooltip>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="Phone"
                    margin="normal"
                    value={this.user.phone}
                    onChange={e => {
                      this.user.phone = e.target.value
                      this.user.phone_verify = 1
                    }}
                  />
                  <div className='text-button-right'>
                    {this.user.phone_verify === 2 ? <Tooltip title="Verified" placement="left">
                      <i className="material-icons text-primary">check_circle</i>
                    </Tooltip> :
                      <Tooltip title="Unverified" placement="left">
                        <i className="material-icons text-secondary cursor-pointer" onClick={() => {
                          this.userEditProfile()
                          this.isVerifyPhone = true
                        }}>info</i>
                      </Tooltip>}
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col'>
                  <FormControlLabel
                    control={<Checkbox checked={this.user.subscribed} color="primary" onChange={e => {
                      this.user.subscribed = !this.user.subscribed
                    }} />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </div>
              </div>
            </CardContent>
            <CardActions style={{ maxWidth: '600px' }}>
              <Button fullWidth variant="contained" color="primary" onClick={() => { this.userEditProfile(true) }}> Save </Button>
            </CardActions>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Edit Password"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="Current Password"
                    margin="normal"
                    value={this.password}
                    onChange={e => { this.password = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="New Password"
                    margin="normal"
                    value={this.password1}
                    onChange={e => { this.password1 = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="Repeat New Password"
                    margin="normal"
                    value={this.password2}
                    onChange={e => { this.password2 = e.target.value }}
                  />
                </div>
              </div>
            </CardContent>
            <CardActions style={{ maxWidth: '600px' }}>
              <Button fullWidth variant="contained" color="secondary" onClick={this.userEditPassword}> Save </Button>
            </CardActions>
          </Card>
        </div>

        <Dialog fullWidth open={this.isVerifyEmail} onClose={() => { this.isVerifyEmail = false }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Verify my Email</DialogTitle>
          <DialogContent className='pt-2 pb-5'>
            <DialogContentText>
              {this.user.email}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.isVerifyEmail = false }} variant="contained" color="secondary">
              Not now
            </Button>
            <Button onClick={this.sendVerifyEmail} variant="contained" color="primary">
              Send a Email to me
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={this.isVerifyPhone} onClose={() => { this.isVerifyPhone = false }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Verify my Phone Number</DialogTitle>
          <DialogContent className='pt-2 pb-5'>
            <DialogContentText>
              {this.user.phone}
            </DialogContentText>

            <div className='text-center'>
              <TextField
                type="number"
                fullWidth
                placeholder="000000"
                margin="normal"
                value={this.verifyPhoneCode}
                onChange={e => {
                  if (e.target.value < 0 || e.target.value.length > 6) { return }
                  this.verifyPhoneCode = e.target.value

                  if (this.verifyPhoneCode.length === 6) {
                    this.checkVerifyPhone()
                  }
                }}
                className='text-verify-code'
              />
              {/* <FormControl >
                <InputLabel htmlFor="formatted-text-mask-input">Verify Code</InputLabel>
                <Input
                  value={this.verifyPhoneCode}
                  onChange={e => { this.verifyPhoneCode = e.target.value; console.log(this.verifyPhoneCode) }}
                  onFocus={e => { console.log(e.target.selectionStart); console.log(e.target.value); e.target.selectionStart = 0 }}
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                  className='text-verify-code'
                />
              </FormControl> */}
              {/* <VerificationInput /> */}
              <div>Please enter verification code</div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.isVerifyPhone = false }} variant="contained" color="secondary">
              Not now
            </Button>
            <Button onClick={this.sendVerifyPhone} variant="contained" color="primary">
              Send a Code to me
            </Button>
          </DialogActions>
        </Dialog>

      </div >
    )
  }

}

export default withRouter(observer(Component))
