import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import ActivityStorageOneLineVolume from "./farmer/ActivityStorageOneLineVolume";
import ActivityStorageOneLineTemp from "./farmer/ActivityStorageOneLineTemp";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Utils from "../../utility/Utils";
import DTS from "../../utility/DTS";
import { Spin } from "antd";
import { CardActions } from '@material-ui/core';

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        activity: {},
        detailData: [],
      }
    })
    this.initData()
    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = 'Storage'
  }

  @computed get getActivity() {
    let result = <Spin />
    if (this.data.activity.start) {
      result = 'Vat ' + this.data.device.vat_number//  + ' - '
      // result += moment(this.data.activity.start.published_at).format(global.constants.date_format_short)
      // result += ' - '
      // result += moment(this.data.activity.end.published_at).format(global.constants.date_format_short)
      // result = <Chip color="primary" label={result}/>
    }
    return result
  }

  @computed get getListStorage() {
    let detailData = []
    if (this.data.detailData.length) {
      detailData = this.data.detailData
    }
    return detailData
  }

  @computed get getAgitator() {
    let agitator = {}
    if (this.data.detailData.length) {
      agitator = DTS.getAgitatorStopStat(this.data.detailData)
    }
    return agitator
  }

  @computed get getAlert() {
    let result = null;
    if (this.data.alert) {
      result = this.data.alert
    }
    return result
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_STORAGE_ONE');
    params.append('activity_id', this.props.match.params.activity_id);
    params.append('alert_id', this.props.match.params.alert_id);
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      if (data) {
        if (data.device && data.device.farm_id) {
          const farm = JSON.parse(Utils.farmGet())
          // console.log(farm._id)
          if (farm._id != data.device.farm_id) {
            Utils.farmSet({ '_id': data.device.farm_id })
            window.location.reload()
            return
          }
        }
        this.data = data
      }
    })
  }

  render() {
    // console.log('render one time')
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>

            <Card className='mb-3 p-3'>
              {this.getActivity}
            </Card>

            <Card className='mb-3'>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    V
                  </Avatar>
                }
                action={
                  <div className='d-flex flex-row'>
                    <div style={{ width: 25, backgroundColor: global.constants.color.agitator_stop }}></div>
                    <div style={{
                      fontSize: '0.7rem',
                      padding: '2px 5px',
                      border: '1px solid ' + global.constants.color.agitator_stop
                    }}>Agitator Stopped
                    </div>
                  </div>
                }
                title="Milk storage data"
              // subheader="Data of the vat since the last empty"
              />
              <CardContent className='p-x-0'>
                <ActivityStorageOneLineVolume listStorage={this.getListStorage} agitator={this.getAgitator} alert={this.getAlert} />
                <ActivityStorageOneLineTemp listStorage={this.getListStorage} alert={this.getAlert} />
                {this.getAlert ? <div className="alert alert-danger" role="alert">{DTS.getNotificationByType(this.data.alert.type).description}</div> : null}
              </CardContent>
            </Card>



          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Component))
