import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import ActivityStorageOneLineTemp from "./farmer/ActivityStorageOneLineTemp";
import ActivityStorageOneLineVolume from "./farmer/ActivityStorageOneLineVolume";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import DTS from "../../utility/DTS";
import ActivityStorageListArea from "./farmer/ActivityStorageListArea";
import ActivityStorageQuality from "./farmer/ActivityStorageQuality";
import { Spin } from "antd";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import AgitatorStatus from './farmer/AgitatorStatus';
import VatVolumeLevel from './farmer/VatVolumeLevel'
import { Link, Button, Snackbar } from '@material-ui/core';
// import AddToHomescreen from 'react-add-to-homescreen';
import FarmerNavi from './farmer/FarmerNavi';
import Tools from '../../utility/Tools';

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: [],
      listStorageHistory: [],
      errorFlag: true,
    })
    this.initData()

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Vatman'
    store.showFarmList = true
  }

  // handleAddToHomescreenClick = () => {
  //   alert(`
  //     1. Open Share menu
  //     2. Tap on "Add to Home Screen" button`);
  // };

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_DASHBOARD_LAST');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      // data[0].detailData.lastStorage.detailData.forEach(item => {
      //   if (item.quality)
      //     console.log(item.quality.alert)
      // })
      this.data = data.sort((a, b) => {
        if (a.device.vat_number > b.device.vat_number) {
          return 1;
        } else {
          return -1;
        }
      })
      this.getStorageHistory()
      this.scrollToVat()
    })
  }

  scrollToVat = () => {
    const url = location.href
    const hash = url.substring(url.indexOf("#") + 1);
    const element = document.getElementById(hash);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }

  getAgitatorCurrent = (currentStatus) => {
    // console.log(item)
    let result
    if (currentStatus.agitator_status === 1) {
      result = 'Running'
    } else if (currentStatus.agitator_status === 0) {
      result = 'Stopped'
    } else {
      result = <Spin />
    }
    return result
  }

  getAgitatorStorage = (item) => {
    let agitator = {}
    if (item.lastStorage && item.lastStorage.detailData) {
      agitator = DTS.getAgitatorStopStat(item.lastStorage.detailData)
    }
    return agitator
  }

  getStorageHistory = () => {
    if (Tools.isMobileDevice()) {
      return
    }
    const params = new URLSearchParams();
    params.append('type', 'FARMER_STORAGE_HISTORY');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_activity, params, data => {
      // console.log(data)
      this.listStorageHistory = data
    })
  }

  getQuality = (current) => {
    let list = current.detailData.lastStorage.detailData
    let result = 3
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].quality) {
        if (list[i].quality.alert === 2 && list[i].quality.agitator) {
          result = 4.85
        } else if (list[i].quality.pickupEstCFU > 0) {
          result = list[i].quality.pickupEstCFU
        }
        break
      }
    }
    return result
  }


  render() {
    document.body.classList.add('milk-nav');
    return (
      <div className='container-fluid mt-3'>

        <div style={{ position: 'fixed', top: '96px', zIndex: 1, width: '100%' }}>
          {/* <div style={{ position: 'sticky', top: '0', zIndex: 2 }}> */}
          <FarmerNavi tabval={0} />
        </div>

        <div className='fix-subnva'></div>

        {this.data.length ? this.data.map((current, index) => {
          let item = current.detailData
          let currentStatus = current.device.last_updated
          let currentAgitator = this.getAgitatorStorage(item)
          let lastLength = item.lastStorage.detailData.length
          let isFonterra = DTS.isFonterraFarm(current.device.farm_id)
          let percentage = (currentStatus.milk_level * 100 / (current.device.vat_max_height)).toFixed()
          if (percentage > 100) percentage = 100

          // console.log(percentage)

          return <div key={index}>


            <div className='row'>


              {/*last status*/}

              <div className={`col-sm-12 col-md-12 dashboard-brief mb-3 ${isFonterra ? 'col-lg-6' : 'col-lg-8'} `}>

                <div className='d-flex bg-white text-muted' style={{ height: 38, borderBottom: '1px solid #ddd' }}>
                  <div id={`Vat-${current.device.vat_number}`} className='px-3 flex-grow-1 d-flex justify-content-start align-items-center text-primary'>
                    {/* <Chip label={`Vat ` + current.device.vat_number} variant_='outlined' color='primary' className='mr-3' style={{ width: 80 }} /> */}
                    <div className='bg-primary' style={{ backgroundColor: 'red', width: '10px', height: '1.2rem' }}></div>
                    <div className='bg-secondary mr-1' style={{ backgroundColor: 'red', width: '5px', height: '1.2rem', }}></div>
                    {`Vat ` + current.device.vat_number}
                  </div>
                  <div className='px-3 d-flex justify-content-end align-items-center'>
                    {moment.utc(currentStatus.published_at).local().format(global.constants.date_format_short)}
                  </div>
                </div>

                <div className='row no-gutters bg-white text-primary' style={{ height: 140 }}>
                  <div className='col d-flex flex-row justify-content-center align-items-center'>
                    <div className='flex-group-1 text-center'>
                      <div className='text-muted'>Volume</div>
                      {currentStatus.milk_volume !== undefined && currentStatus.milk_volume !== null ?
                        <div>
                          <div style={{ fontSize: '1.9rem' }}>
                            {DTS.getVolumeRound(currentStatus.milk_volume)}L
                          </div>
                          <small className='text-muted dashboard-volume-mobile' style={{ position: 'absolute', width: 80, marginLeft: -40, left: '50%', bottom: 20 }}>{percentage}%</small>
                        </div> :
                        <Spin />}
                    </div>
                    <div className='dashboard-volume-desk ml-3' style={{ width: '55px' }}>
                      <VatVolumeLevel width={45} height={100} value={currentStatus.milk_level} total={current.device.vat_max_height} />
                    </div>
                  </div>

                  <div className='col d-flex flex-column justify-content-center align-items-center border-left'>
                    <div className='text-muted'>Temperature</div>
                    {currentStatus.temp1 ? <span style={{ fontSize: '1.9rem' }}>{DTS.getTemperature(currentStatus.temp1)}°C</span> : <Spin />}
                  </div>
                  <div className='col d-flex flex-column justify-content-center align-items-center border-left'>
                    <div className='text-muted'>Agitator</div>
                    <span style={{ fontSize: '1.9rem' }}>{this.getAgitatorCurrent(currentStatus)}</span>
                  </div>
                </div>

              </div>

              {/* last activity time */}
              <div className={`col-sm-12 ${isFonterra ? 'col-md-6 col-lg-3' : 'col-md-12 col-lg-4'} `}>
                <div className='mb-3 dashboard-brief nowrap' style={{ fontSize: '1rem' }}>
                  <div className='brief-item d-flex'>
                    <div className='flex-grow-1 text-muted'>Last Collection:</div>
                    <div className='pr-3'>
                      {item.lastCollection && item.lastCollection.published_at ?
                        moment(item.lastCollection.published_at).local().fromNow() :
                        <Spin />}
                    </div>
                  </div>
                  <div className='brief-item d-flex'>
                    <div className='flex-grow-1 text-muted'>Last Milking Start:</div>
                    <div className='pr-3'>
                      {item.lastMilking && item.lastMilking.published_at ?
                        moment(item.lastMilking.published_at).local().fromNow() :
                        <Spin />}
                    </div>
                  </div>
                  <div className='brief-item d-flex'>
                    <div className='flex-grow-1 text-muted'>Last CIP:</div>
                    <div className='pr-3'>
                      {/* Yes */}
                      {item.lastCIP && item.lastCIP.activity && item.lastCIP.activity.start ?
                        moment(item.lastCIP.activity.start.published_at).local().fromNow() :
                        <Spin />}
                    </div>
                  </div>
                </div>
              </div>

              {/* milk quality */}
              {isFonterra ? <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='mb-3 dashboard-brief bg-white px-5s'>
                  <ActivityStorageQuality pickupEstCFU={this.getQuality(current)} />
                </div>
              </div> : null}

            </div>

            {/*vat data since last CIP or Empty*/}
            <Card className='mb-3'>
              <CardHeader
                avatar={<Avatar aria-label="recipe">{current.device.vat_number}</Avatar>}
                title="Milk storage data"
                subheader="Data of the vat since the last empty"
                action={
                  <Button color="primary" onClick={() => {
                    this.props.history.push("/dts/dashboard/data");
                  }}>
                    More
                  </Button>
                }
              />
              <CardContent className='p-x-0 position-relative'>

                <div className='storage-agitator'>
                  <AgitatorStatus agitator={currentAgitator} />
                </div>

                <ActivityStorageOneLineVolume
                  listStorage={item.lastStorage.detailData}
                  agitator={currentAgitator}
                  cip={item.lastCIP} />
                <ActivityStorageOneLineTemp
                  listStorage={item.lastStorage.detailData}
                  listCooler={item.lastStorage.coolerData}
                  cip={item.lastCIP}
                />
              </CardContent>
            </Card>
          </div>
        }) : null}

        {(Tools.isMobileDevice() && this.data.length && !DTS.isFonterraFarm(this.data[0].device.farm_id)) ?
          <div className='text-center mb-3'>
          <img alt='pickup' height='36' src='https://login.herdman.co.nz/img/other/pickup.jpg' />  
          <Button color="primary" variant="contained" onClick={() => {
            this.props.history.push("/dts/pickup");
          }}>
            Submit Pickup
          </Button></div> : null
        }

        {this.listStorageHistory.length
          ? <Card className='my-3'>
            {/*milking production comparision*/}
            <CardHeader
              avatar={<Avatar aria-label="recipe">M</Avatar>}
              action={<IconButton aria-label="settings"></IconButton>}
              title="Milk storage history (L)"
              subheader="Recent milk production"
              action={(this.data.length && !DTS.isFonterraFarm(this.data[0].device.farm_id)) ?
                <Button color="primary" onClick={() => {
                  this.props.history.push("/dts/pickup");
                }}>
                  Submit Pickup
                </Button> : null
              }
            />
            <CardContent className='p-x-0'>
              <ActivityStorageListArea storageList={this.listStorageHistory} typeYAxis='LEVEL' {...this.props} />
              <ActivityStorageListArea storageList={this.listStorageHistory} typeYAxis='TEMP' {...this.props} />
            </CardContent>
          </Card>
          : <div className='text-center py-5 d-none'><Spin /></div>}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={false}
          onClose={() => { }}
          message="We regret to inform you that the Vat-Man system experienced an unplanned outage between 01/04/2020 20:10 and 02/04/2020 08:40. We are happy to announce that everything is back up and running now. Unfortunately, all milk vat data during the outage time has been lost. The data loss may possibly trigger some false alerts, so please look closely at any alerts received over the next few hours to be certain. We apologise profusely for any inconvenience this outage has caused and are taking the necessary measures to ensure that this won’t happen again. "
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => { this.errorFlag = false }}>
                <span class="material-icons">close</span>
              </IconButton>
            </React.Fragment>
          }
        />

      </div>
    )
  }

}

export default withRouter(observer(Component))
