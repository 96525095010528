import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {
            supplyNumber: '',
            farmExists: true,

            checking: false,
            
            checkedOnline: false,
            checkedDiagnostics: false,
            checkedSensorBoards: false,

            online: false,
            onlineValue: '',
            networkStrength: false,
            networkStrengthValue: '',
            networkQuality: false,
            networkQualityValue: '',
            powerSource: false,
            powerSourceValue: '',
            batteryStatus: false,
            batteryStatusValue: '',
            sensorBoards: false,
            sensorBoardsValue: '',
        })
        this.state = {
            error: false
        }
        store.dashboard.menuActive = 'Check Install'
        store.dashboard.navbarLeft = 'Check Install'
        store.showFarmList = false
    }

    check = () => {
        if (this.supplyNumber){
            this.setState({error: false})

            this.checkedOnline = false
            this.checkedDiagnostics = false
            this.checkedSensorBoards = false
    
            this.online = false
            this.networkStrength = false
            this.powerSource = false
            this.batteryStatus = false
            this.sensorBoards = false
    
            this.onlineValue = ''
            this.networkStrengthValue = ''
            this.networkQualityValue = ''
            this.powerSourceValue = ''
            this.batteryStatusValue = ''
            this.sensorBoardsValue = ''
    
            this.checking = true
    
            this.checkOnline()
        }
        else this.setState({error: true})
    }

    checkOnline = () => {
        console.log('Checking Online')

        const params = new URLSearchParams()
        params.append('type', 'CHECK_ONLINE')
        params.append('supplyNumber', this.supplyNumber)

        Utils.httpPost(Utils.urlList.installer, params, data => {
            console.log(data)
            if (data != 'No such farm'){
                this.online = data['online'] // True or False
                this.checkedOnline = true
    
                if (this.online){
                    this.checkDiagnostics()
                    this.checkSensorBoards()
                }
                else this.checking = false
            }
            else{
                this.online = false
                this.checkedOnline = true
                this.farmExists = false
                this.checking = false
            }
        })
    }

    checkDiagnostics = () => {
        console.log('Checking Diagnostics')

        const params = new URLSearchParams()
        params.append('type', 'CHECK_DIAGNOSTICS')
        params.append('supplyNumber', this.supplyNumber)

        Utils.httpPost(Utils.urlList.installer, params, data => {
            console.log(data)
            if (data['data']){

                this.networkStrengthValue = data['data']['device']['network']['signal']['strength']
                if (this.networkStrengthValue > 20) this.networkStrength = true
                else this.networkStrength = false

                this.networkQualityValue = data['data']['device']['network']['signal']['quality']
                if (this.networkQualityValue > 55) this.networkQuality = true
                else this.networkQuality = false

                this.powerSourceValue = data['data']['device']['power']['source']
                if (this.powerSourceValue == 'VIN')this.powerSource = true
                else this.powerSource = false

                this.batteryStatusValue = data['data']['device']['power']['battery']['state'];
                if (this.batteryStatusValue == 'charging' || this.batteryStatusValue == 'charged') this.batteryStatus = true
                else this.batteryStatus = false

                this.checkedDiagnostics = true
            }
            if (this.checkedOnline && this.checkedDiagnostics && this.checkedSensorBoards) this.checking = false
        })
    }

    checkSensorBoards = () => {
        console.log('Checking Sensor Boards')

        const params = new URLSearchParams()
        params.append('type', 'CHECK_SENSORBOARDS')
        params.append('supplyNumber', this.supplyNumber)

        Utils.httpPost(Utils.urlList.installer, params, data => {
            console.log(data)

            if (data.startsWith('fault')) this.sensorBoards = false
            else this.sensorBoards = true
            this.sensorBoardsValue = data;

            this.checkedSensorBoards = true
            if (this.checkedOnline && this.checkedDiagnostics && this.checkedSensorBoards) this.checking = false
        })
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className='bg-white'>
                    <Container component="main" maxWidth="xs">
                        <div className='my-3'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled={this.checking}
                                name="supplyNumber"
                                label="Supply Number (e.g. FON12345)"
                                id="supplyNumber"
                                value={this.supplyNumber}
                                error={this.supplyNumber == '' && this.state.error}
                                helperText={this.supplyNumber == '' && this.state.error  && "Required Field"}
                                onChange={e => {
                                    this.supplyNumber = e.target.value.toUpperCase().replace(' ','')
                                }}
                                onKeyUp={e => {
                                  if (e.keyCode === 13) this.check()
                                }}
                            />
                            <Button
                                type="button"
                                fullWidth
                                disabled={this.checking}
                                variant="contained"
                                color="primary"
                                className="my-3"
                                onClick={this.check}
                            >
                                {!this.checking && "Check"}
                                {this.checking && "Checking..."}
                            </Button>

                            {(!this.checkedOnline && this.checking) && <Typography>Checking connection...</Typography>}
                            {(this.checkedOnline && this.farmExists) && <div>
                                {this.online && <Typography>Connection is up{this.onlineValue}</Typography>}
                                {!this.online && <Typography style={{color:'red'}}>Connection is down{this.onlineValue}</Typography>}
                            </div>}
                            {(this.checkedOnline && !this.farmExists) && <div>
                                <Typography style={{color:'red'}}>Farm not found, try again.</Typography>
                            </div>}
                            
                            {(!this.checkedDiagnostics && this.checking) && <div>
                                <Typography>Checking network strength...</Typography>
                                <Typography>Checking network quality...</Typography>
                                <Typography>Checking mains power...</Typography>
                                <Typography>Checking battery status...</Typography>
                            </div>}
                            {this.checkedDiagnostics && <div>
                                {this.networkStrength && <Typography>Network strength is okay ({this.networkStrengthValue}%)</Typography>}
                                {!this.networkStrength && <Typography style={{color:'red'}}>Network strength is poor ({this.networkStrengthValue}%)</Typography>}
                                {this.networkQuality && <Typography>Network quality is okay ({this.networkQualityValue}%)</Typography>}
                                {!this.networkQuality && <Typography style={{color:'red'}}>Network quality is poor ({this.networkQualityValue}%)</Typography>}
                                {this.powerSource && <Typography>Mains power is okay ({this.powerSourceValue})</Typography>}
                                {!this.powerSource && <Typography style={{color:'red'}}>Mains power is bad ({this.powerSourceValue})</Typography>}
                                {this.batteryStatus && <Typography>Battery status is okay ({this.batteryStatusValue})</Typography>}
                                {!this.batteryStatus && <Typography style={{color:'red'}}>Battery status is bad ({this.batteryStatusValue})</Typography>}
                            </div>}
                            
                            {(!this.checkedSensorBoards && this.checking) && <Typography>Checking sensor boards...</Typography>}
                            {this.checkedSensorBoards && <div>
                                {this.sensorBoards && <Typography>Sensor boards {this.sensorBoardsValue}</Typography>}
                                {!this.sensorBoards && <Typography style={{color:'red'}}>Sensor boards {this.sensorBoardsValue}</Typography>}
                            </div>}
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))