import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Button from '@material-ui/core/Button';
import store from "../../store/Store";
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import Utils from "../../utility/Utils";


const GreenRadio = withStyles({
  root: {
    color: '#03a800',
    '&$checked': {
      color: '#03a800',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const RedRadio = withStyles({
  root: {
    color: '#bf0006',
    '&$checked': {
      color: '#bf0006',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const OrangeRadio = withStyles({
  root: {
    color: '#a66b00',
    '&$checked': {
      color: '#a66b00',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      steps: [
        'Setting the pre-cooling system',
        'Setting the refrigerator system',
        'Finished',
      ],
      activeStep: 0,
      data: [],
      data_base: [
        {
          type: 1,
          list: [
            { name: 'Chilling', value: 1, },
            { name: 'Ice Bank', value: 2, },
            { name: 'None', value: 0, },
          ]
        },
        {
          type: 2,
          list: [
            { name: 'Vat', value: 1, },
            { name: 'Vat with sidewall', value: 2, },
          ]
        },
        {
          type: 3,
          list: [
            { name: 'Fridge1', value: 1, },
          ]
        },
        {
          type: 4,
          list: [
            { name: 'Fridge1', value: 1, },
            { name: 'Fridge2', value: 2, },
            { name: 'Fridge3', value: 3, },
          ]
        },
      ],
    })
    this.initData()
    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Builder'
  }

  initData = () => {
    const params = new URLSearchParams()
    params.append('type', 'EQUIPMENT_GET')
    params.append('farm', Utils.farmGet())
    Utils.httpPost(Utils.urlList.farmer_equipment, params, data => {
      // console.log(data)
      this.data = data
    })
  }

  handleFinished = () => {
    const params = new URLSearchParams()
    params.append('type', 'EQUIPMENT_UPDATE')
    params.append('farm', Utils.farmGet())
    params.append('equipment', JSON.stringify(this.data))
    Utils.httpPost(Utils.urlList.farmer_equipment, params, data => {
      // console.log(data)
      this.props.history.push('/dts/equipment')
    })
  }

  // get step 1 or 2 or some else
  getEquipmentList(type) {
    let list = [];
    for (let item of this.data) {
      if (item.type === type) {
        list.push(item)
      }
    }
    return list
  }

  // get specific info
  getEquipmentBase(type) {
    for (let item of this.data_base) {
      if (item.type === type) {
        return item.list
      }
    }
    return []
  }

  handleNext = () => {
    this.activeStep++
  }

  handleBack = () => {
    this.activeStep--
  }

  render() {
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>
            <Stepper activeStep={this.activeStep} orientation="vertical" className='px-3'>
              {this.steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {this.getEquipmentList(index + 1).map((item2, index2) => <div key={index2} className='my-3 p-3 bg-light'>

                      {index === 0 ? <div>
                        <h4 className='mb-3'>Pre-cooling System</h4>
                        <RadioGroup
                          row
                          aria-label="cooling"
                          name="cooling"
                          value={item2.value}
                          onChange={e => {
                            item2.value = parseInt(e.target.value)
                          }}
                        >
                          {/* pre cooling system */}
                          {this.getEquipmentBase(1).map((item3, index3) => <FormControlLabel key={index3} value={item3.value} control={<RedRadio />} label={item3.name} />)}
                        </RadioGroup>
                      </div> : null}

                      {index === 1 ? <div>
                        <h4 className='mb-3'>Vat {item2.id}</h4>
                        <RadioGroup
                          row
                          aria-label="cooling"
                          name="cooling"
                          value={item2.value}
                          onChange={e => {
                            item2.value = parseInt(e.target.value)
                            item2.sub_value = 1
                          }}
                        >
                          {/* vat */}
                          {this.getEquipmentBase(2).map((item3, index3) => <FormControlLabel key={index3} value={item3.value} control={<GreenRadio />} label={item3.name} />)}
                        </RadioGroup>

                        <RadioGroup
                          row
                          aria-label="cooling"
                          name="cooling"
                          value={item2.sub_value}
                          onChange={e => {
                            item2.sub_value = parseInt(e.target.value)
                          }}
                        >
                          {/* fridge 1 */}
                          {item2.value === 1 ? this.getEquipmentBase(3).map((item3, index3) => <FormControlLabel key={index3} value={item3.value} control={<OrangeRadio />} label={item3.name} />) : null}
                          {/* fridge 1, 2, 3 */}
                          {item2.value === 2 ? this.getEquipmentBase(4).map((item3, index3) => <FormControlLabel key={index3} value={item3.value} control={<OrangeRadio />} label={item3.name} />) : null}
                        </RadioGroup>
                      </div> : null}

                    </div>)}
                    <div className='my-3'>
                      <Button
                        disabled={this.activeStep === 0}
                        onClick={this.handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (this.activeStep === this.steps.length - 1) {
                            this.handleFinished()
                          } else {
                            this.handleNext()
                          }
                        }}
                      >
                        {this.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Component))
