import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Tools from "../../../utility/Tools";
import Highcharts from "highcharts";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

class Component extends React.Component {

  constructor(props) {
    super(props)
    Highcharts.setOptions({
      time: {
        useUTC: false
      },
    })
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {

    let listStorage = [], listAgitator = []
    if (this.props.listStorage && this.props.listStorage.length) {
      listStorage = this.props.listStorage
    }
    if (this.props.agitator && this.props.agitator.listStop && this.props.agitator.listStop.length) {
      listAgitator = this.props.agitator.listStop
    }

    let listData = []
    for (let i = 0; i < listStorage.length; i++) {
      let item = listStorage[i]

      if (item.state_machine === 'startup') {
        continue;
      }

      if (item.milk_volume !== undefined && item.milk_volume !== null) {
        let y = i === 0 ? 0 : item.milk_volume
        let x = moment.utc(item.published_at).local().toDate()
        // console.log(x)
        listData.push({
          x: x,
          y: parseFloat(y),
          marker: global.constants.highcharts.marker,
        })
      }
    }

    let listBandX = []
    let listPlotX = []

    if (this.props.alert) {
      listPlotX.push({
        color: '#FF0000',
        // dashStyle: 'longdashdot',
        width: 3,
        zIndex: 2,
        value: moment.utc(this.props.alert.data.published_at).local().toDate(),
      })
    }

    for (let item of listAgitator) {
      listBandX.push({
        from: moment.utc(item.start.published_at).local().toDate(),
        to: moment.utc(item.end.published_at).local().toDate(),
        color: global.constants.color.agitator_stop,
      })
    }
    let listSeries = []
    listSeries.push({
      name: 'Milk volume (L)',
      turboThreshold: 10000,
      data: listData,
    })

    // // CIP
    // if (this.props.cip && this.props.cip.activity) {
    //   listBandX.push({
    //     from: moment.utc(this.props.cip.activity.start.published_at).local().toDate(),
    //     to: moment.utc(this.props.cip.activity.end.published_at).local().toDate(),
    //     color: global.constants.color.volume,
    //   })
    //   let listCIP = []
    //   for (let item of this.props.cip.detailData) {
    //     listCIP.push({
    //       x: moment.utc(item.published_at).local().toDate(),
    //       y: item.temp5,
    //       marker: global.constants.highcharts.marker,
    //     })
    //   }
    //   listSeries.push({
    //     name: 'CIP',
    //     turboThreshold: 10000,
    //     data: listCIP,
    //     visible: store.farmer.chartSyncCIP,
    //   })
    // }

    // ------------------

    let plotOptions = {
      // column: {colorByPoint: true},
      series: {
        color: '#2594b7',
        // cursor: 'pointer',
        events: {
          legendItemClick: function () {
            // if (this.name === 'CIP') {
            //   store.farmer.chartSyncCIP = !store.farmer.chartSyncCIP
            //   return false
            // }
          },
        }
      },
    }

    let result = {
      credits: false,
      chart: {
        type: 'line',
        height: 300,
        zoomType: '',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        plotLines: listPlotX,
        plotBands: listBandX,
        title: { text: null },
      },
      yAxis: {
        title: { text: '' },
        gridLineWidth: 1,
        lineWidth: 1,
        min: 0,
        labels: {
          formatter: function () {
            return this.value / 1000 + 'k'
          }
        }
      },
      plotOptions: plotOptions,
      series: listSeries,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment.utc(this.x).local().format(global.constants.date_format_week)
          switch (this.key) {
            default:
              return `<div>${xx} / <strong>${DTS.getVolumeRound(this.y)} L</strong></div>`;
          }
        }
      }
    }
    return result
  }


  render() {
    return (
      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />
    )
  }


}

export default observer(Component)
