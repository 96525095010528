import React from 'react'
import {observer} from "mobx-react"
import {extendObservable} from "mobx"
import {withRouter} from "react-router-dom"

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
    })
    this.initData()
  }

  initData = () => {

  }


  render() {

    return (

      <div className='container-fluid'>
        <div className='my-3'>


        </div>
      </div>
    )
  }


}

export default withRouter(observer(Component))
