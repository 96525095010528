import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import BasePromotion from "../promotion/BasePromotion";
import store from "../../store/Store";
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MuiThemeProvider, Popover } from '@material-ui/core';
import theme from "../common/theme";
import Utils from "../../utility/Utils";
import DTSPopover from "../common/DTSPopover"
import emitter from "../../utility/EventEmitter"


class Component extends BasePromotion {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        power_outage: { email: true, text: true, },
        agitator_stopped: { email: true, text: true, },
        milk_warm: { email: true, text: true, },
        milk_warm_plate: { email: true, text: true, },
        milk_cold: { email: true, text: true, },
        milk_volume: { email: true, text: true, },
        milk_online_offline: { email: true, text: true, },

        pro_slow_to_cold_temp: { email: true, text: true, },
        pro_slow_to_cold_period: { email: true, text: true, },
        pro_pick_up: { email: true, text: true, },
        pro_milking_duration: { email: true, text: true, },
        pro_collection_duration: { email: true, text: true, },

        auto_unknown: { email: true, text: true, },
        auto_manual: { email: true, text: true, },
        auto_off: { email: true, text: true, },

        water: { email: true, text: true, },
        effluent: { email: true, text: true, },
        land: { email: true, text: true, },
        weather: { email: true, text: true, },
      },
      anchorEl_power: null,
      anchorEl_agitator: null,
      anchorEl_warm_vat: null,
      anchorEl_warm_plate: null,
      anchorEl_cold: null,
      anchorEl_level: null,
      anchorEl_online_offline: null,
      anchorEl_milking_duration: null,
      anchorEl_collection_duration: null,

      anchorEl_pro_slow_to_cold_temp: null,
      anchorEl_pro_slow_to_cold_period: null,
      anchorEl_pro_pick_up: null,

      anchorEl_auto_unknown: null,
      anchorEl_auto_manual: null,
      anchorEl_auto_off: null,

      anchorEl_water: null,
      anchorEl_effluent: null,
      anchorEl_land: null,
      anchorEl_weather: null,
    })
    this.initData()

    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = 'Notification'
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_SETTING_ALERT_GET_USER');
    params.append('farm', Utils.farmGet());
    params.append('alert_setting', JSON.stringify(this.data));
    Utils.httpPost(Utils.urlList.farmer_setting, params, data => {
      // console.log(data)
      if (data) {
        const target = this.data
        const source = data
        Object.assign(target, source);

        // if (!data.water) data.water = { email: true, text: true, }
        // if (!data.effluent) data.effluent = { email: true, text: true, }
        // if (!data.land) data.land = { email: true, text: true, }
        // if (!data.weather) data.weather = { email: true, text: true, }
        // this.data = data
      }
    })
  }

  userAlertSet = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_SETTING_ALERT_SET_USER');
    params.append('farm', Utils.farmGet());
    params.append('alert_setting', JSON.stringify(this.data));
    Utils.httpPost(Utils.urlList.farmer_setting, params, data => {
      // console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success!')
    })
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3'>
          <Card className='mb-3'>
            {/* <CardHeader subheader="Milk" /> */}
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Vat-Man Lite"
            />
            <CardContent>
              <div className="table-responsive">
                <table className='table table-td-x-0'>
                  <thead>
                    <tr><th></th><th className='text-center px-3'>EMAIL</th><th className='text-center'>TEXT</th></tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Power outage<i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                        this.anchorEl_power = e.currentTarget
                      }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_power}
                          actionHide={() => { this.anchorEl_power = null }}
                          text='The Power outage  alert will inform you if the Vat-Man system has detected a loss of mains power to our control unit for a continuous period of 5 minutes.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.power_outage.email} onChange={e => { this.data.power_outage.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.power_outage.text} onChange={e => { this.data.power_outage.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td>Agitator stopped<i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                        this.anchorEl_agitator = e.currentTarget
                      }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_agitator}
                          actionHide={() => { this.anchorEl_agitator = null }}
                          text='The Agitator off alert will inform you if the Vat-Man system has detected that the agitator stopped while there is milk in the vat.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.agitator_stopped.email} onChange={e => { this.data.agitator_stopped.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.agitator_stopped.text} onChange={e => { this.data.agitator_stopped.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td>Milk in vat too warm<i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                        this.anchorEl_warm_vat = e.currentTarget
                      }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_warm_vat}
                          actionHide={() => { this.anchorEl_warm_vat = null }}
                          text='The Milk in the vat too warm alert will let you know when the temperature of the milk in the vat does not meet the NZCP1-5.15 cooling standard.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.milk_warm.email} onChange={e => { this.data.milk_warm.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.milk_warm.text} onChange={e => { this.data.milk_warm.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td>
                        <div className="d-flex align-items-center nowrap">
                          Milk from plate too warm
                          <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                            this.anchorEl_warm_plate = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_warm_plate}
                            actionHide={() => { this.anchorEl_warm_plate = null }}
                            text='The milk out of the plate cooler too warm alert will let you know when the milk inlet temperature exceeds the temperature threshold that you can set.'
                          />
                        </div>
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.milk_warm_plate.email} onChange={e => { this.data.milk_warm_plate.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.milk_warm_plate.text} onChange={e => { this.data.milk_warm_plate.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td>
                        <div className="d-flex align-items-center nowrap">
                          Milk in vat too cold
                          <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                            this.anchorEl_cold = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_cold}
                            actionHide={() => { this.anchorEl_cold = null }}
                            text='The milk in the vat too cold alert will let you know when the milk temperature in the vat drops below a threshold that you can set.'
                          />
                        </div>
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.milk_cold.email} onChange={e => { this.data.milk_cold.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.milk_cold.text} onChange={e => { this.data.milk_cold.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Milk volume level
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_level = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_level}
                          actionHide={() => { this.anchorEl_level = null }}
                          text='The milk vat volume alert will let you know when the volume level of a vat exceeds the percentage threshold that you can set.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.milk_volume.email} onChange={e => { this.data.milk_volume.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.milk_volume.text} onChange={e => { this.data.milk_volume.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Vat-Man system Online/Offline 
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_online_offline = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_online_offline}
                          actionHide={() => { this.anchorEl_online_offline = null }}
                          text='System Online/Offline alert will let you know when Vat-Man system status changed to Online or Offline.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.milk_online_offline.email} onChange={e => { this.data.milk_online_offline.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.milk_online_offline.text} onChange={e => { this.data.milk_online_offline.text = e.target.checked }} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Vat-Man Pro"
            />
            <CardContent>
              <div className="table-responsive">
                <table className='table table-td-x-0'>
                  <thead>
                    <tr><th></th><th className='text-center px-3'>EMAIL</th><th className='text-center'>TEXT</th></tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Milk in vat slow to cool target temperature
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_pro_slow_to_cold_temp = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_pro_slow_to_cold_temp}
                          actionHide={() => { this.anchorEl_pro_slow_to_cold_temp = null }}
                          text='Milk in vat slow to cool target temperature'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.pro_slow_to_cold_temp.email} onChange={e => { this.data.pro_slow_to_cold_temp.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.pro_slow_to_cold_temp.text} onChange={e => { this.data.pro_slow_to_cold_temp.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Milk in vat slow to cool time period
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_pro_slow_to_cold_period = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_pro_slow_to_cold_period}
                          actionHide={() => { this.anchorEl_pro_slow_to_cold_period = null }}
                          text='Milk in vat slow to cool time period'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.pro_slow_to_cold_period.email} onChange={e => { this.data.pro_slow_to_cold_period.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.pro_slow_to_cold_period.text} onChange={e => { this.data.pro_slow_to_cold_period.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Milk pick-up alert
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_pro_pick_up = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_pro_pick_up}
                          actionHide={() => { this.anchorEl_pro_pick_up = null }}
                          text='Milk pick-up alert'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.pro_pick_up.email} onChange={e => { this.data.pro_pick_up.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.pro_pick_up.text} onChange={e => { this.data.pro_pick_up.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Milking duration alert
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_milking_duration = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_milking_duration}
                          actionHide={() => { this.anchorEl_milking_duration = null }}
                          text='The milking duration alert will inform you if there is a variance with your milking duration or start time greater than the time period that you set.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.pro_milking_duration.email} onChange={e => { this.data.pro_milking_duration.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.pro_milking_duration.text} onChange={e => { this.data.pro_milking_duration.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Collection duration alert
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_collection_duration = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_collection_duration}
                          actionHide={() => { this.anchorEl_collection_duration = null }}
                          text='The milk not collected alert will inform you after x minutes/hours that the milk in your vat/s hasn’t been collected.'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.pro_collection_duration.email} onChange={e => { this.data.pro_collection_duration.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.pro_collection_duration.text} onChange={e => { this.data.pro_collection_duration.text = e.target.checked }} /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Vat-Man Auto"
            />
            <CardContent>
              <div className="table-responsive">
                <table className='table table-td-x-0'>
                  <thead>
                    <tr><th></th><th className='text-center px-3'>EMAIL</th><th className='text-center'>TEXT</th></tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Vat-Man Auto Status Unknown
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_auto_unknown = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_auto_unknown}
                          actionHide={() => { this.anchorEl_auto_unknown = null }}
                          text='Vat-Man Auto Status Unknown'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.auto_unknown.email} onChange={e => { this.data.auto_unknown.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.auto_unknown.text} onChange={e => { this.data.auto_unknown.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Vat-Man Auto Manual Override
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_auto_manual = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_auto_manual}
                          actionHide={() => { this.anchorEl_auto_manual = null }}
                          text='Vat-Man Auto Manual Override'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.auto_manual.email} onChange={e => { this.data.auto_manual.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.auto_manual.text} onChange={e => { this.data.auto_manual.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Vat-Man Auto Off status
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_auto_off = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_auto_off}
                          actionHide={() => { this.anchorEl_auto_off = null }}
                          text='The vat control switch is in the off position'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.auto_off.email} onChange={e => { this.data.auto_off.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.auto_off.text} onChange={e => { this.data.auto_off.text = e.target.checked }} /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Other Monitoring"
            />
            <CardContent>
              <div className="table-responsive">
                <table className='table table-td-x-0'>
                  <thead>
                    <tr><th></th><th className='text-center px-3'>EMAIL</th><th className='text-center'>TEXT</th></tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Water
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_water = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_water}
                          actionHide={() => { this.anchorEl_water = null }}
                          text='Water'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.water.email} onChange={e => { this.data.water.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.water.text} onChange={e => { this.data.water.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Effluent
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_effluent = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_effluent}
                          actionHide={() => { this.anchorEl_effluent = null }}
                          text='Effluent'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.effluent.email} onChange={e => { this.data.effluent.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.effluent.text} onChange={e => { this.data.effluent.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Land
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_land = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_land}
                          actionHide={() => { this.anchorEl_land = null }}
                          text='Land'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.land.email} onChange={e => { this.data.land.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.land.text} onChange={e => { this.data.land.text = e.target.checked }} /></td>
                    </tr>

                    <tr>
                      <td className="d-flex1 align-items-center">
                        Weather
                        <i className="material-icons material-icons-align text-help cursor-pointer ml-1" onClick={e => {
                          this.anchorEl_weather = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_weather}
                          actionHide={() => { this.anchorEl_weather = null }}
                          text='Weather'
                        />
                      </td>
                      <td className='text-center'><Checkbox checked={this.data.weather.email} onChange={e => { this.data.weather.email = e.target.checked }} /></td>
                      <td className='text-center'><Checkbox checked={this.data.weather.text} onChange={e => { this.data.weather.text = e.target.checked }} /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardActions>
              <Button variant="contained" color="primary" onClick={this.userAlertSet} className="m-auto" style={{ width: 100 }}> Save </Button>
            </CardActions>
          </Card>
        </div>
      </div >
    )
  }

}

export default withRouter(observer(Component))
