import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {
            supplyNumber: '',
            type: 'All',
            installs: [],
            tab: '\t',
            page: 1,
            pageCount: 0,
            pageSize: 50,
        })
        store.dashboard.menuActive = 'Install Log'
        store.dashboard.navbarLeft = 'Install Log'
        store.showFarmList = false
    }

    search = () => {

        this.installs = []

        // Request list of past installs and config changes
        const params = new URLSearchParams()
        params.append('type', 'INSTALLS')
        params.append('supplyNumber', this.supplyNumber)
        params.append('logType', this.type)
        params.append('page', this.page-1)
        params.append('pageSize', this.pageSize)

        Utils.httpPost(Utils.urlList.installer, params, data => {
            this.pageCount = Math.ceil(data['count'] / this.pageSize);
            data['installs'].slice().map((install,i) => {
                
                install.created_at = moment.utc(install.created_at).local().format(global.constants.date_format_utc)
                install.updated_at = moment.utc(install.updated_at).local().format(global.constants.date_format_utc)

                install.stringify = JSON.stringify(install,null,2).replace(/"/g,'').replace('{','').replace('}','').replace(/,/g,'')

                this.installs.push(install)
            })
        })
    }

    render() {
        if (this.installs != null)  return (
            <div className='container-fluid'>
                <div className='bg-white'>
                    <Container component="main" maxWidth="xs">
                        <div className='my-3'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="supplyNumber"
                                label="Supply Number Or Blank For All Farms"
                                id="supplyNumber"
                                value={this.supplyNumber}
                                onChange={e => {
                                    this.supplyNumber = e.target.value.toUpperCase()
                                }}
                                onKeyUp={e => {
                                  if (e.keyCode === 13) this.search()
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                select
                                name="type"
                                label="Log Type"
                                id="type"
                                value={this.type}
                                onChange={e => {
                                    this.type = e.target.value
                                }}
                            >
                                <MenuItem key="1" value="All">All</MenuItem>
                                <MenuItem key="2" value="New Installs">New Installs</MenuItem>
                                <MenuItem key="3" value="Install Checks">Install Checks</MenuItem>
                                <MenuItem key="4" value="Device Diagnostics">Device Diagnostics</MenuItem>
                            </TextField>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="my-3"
                                onClick={() => {
                                    this.page = 0
                                    this.search()
                                }}
                            >
                                Search
                            </Button>
                        </div>
                    </Container>
                </div>

                <div className='my-3'>
                    {this.installs.map((install,i) => {
                        if (!install.type) return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<i className='material-icons d-none d-sm-block'>details</i>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {install.created_at} / {install.supply_number} / {install.installer_name} / {install.imei} / {install.result}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <pre>{install.supply_number}{this.tab}{install.numVats}{this.tab}{install.particle_id}{this.tab}{install.imei}</pre>
                                        <pre>{install.stringify}</pre>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                        else if (install.type == 'Check Online') return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<i className='material-icons d-none d-sm-block'>details</i>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {install.created_at} / {install.supply_number} / {install.installer_name} / Check Online
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <pre>{install.stringify}</pre>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                        else if (install.type == 'Check Diagnostics') return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<i className='material-icons d-none d-sm-block'>details</i>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {install.created_at} / {install.supply_number} / {install.installer_name} / {install.network} / Strength: {install.signalStrength} / Quality: {install.signalQuality} / Disconnects: {install.disconnects} / Power Source: {install.power} / Battery: {install.batteryCharge} / {install.batteryState}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <pre>{install.stringify}</pre>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                        else if (install.type == 'Check Sensor Boards') return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<i className='material-icons d-none d-sm-block'>details</i>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {install.created_at} / {install.supply_number} / {install.installer_name} / Sensor boards {install.checkSensorBoards}    
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <pre>{install.stringify}</pre>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
                <Pagination
                    count={this.pageCount}
                    page={this.page}
                    color="primary"
                    onChange={(event, value) => {
                        this.page = value
                        this.search()
                    }}
                />
            </div>
        )
        else return (null)
    }
}

export default withRouter(observer(Component))
