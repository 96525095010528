import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";

import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

class Component extends React.Component {

    constructor(props) {
        super(props)
        store.dashboard.menuActive = 'Help'
        store.dashboard.navbarLeft = 'Help'
        store.showFarmList = false
    }

    render() {
        return (
            <div className='container-fluid bg-white'>
                <div className='my-3'>
                    <Container component="main" maxWidth="xs">
                        <Typography>
                            <Link href="https://docs.google.com/forms/d/e/1FAIpQLSeTX3ROcAjEJYyPM3FffzpVHSK-WKFZkU8MvTOmZAHfLaBsDQ/viewform">
                                Link to Fonterra Form to fill out after install
                            </Link>
                        </Typography>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))
