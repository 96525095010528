import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";
import Utils from "../../utility/Utils";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode, obsidian, agate, arta } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseDashboardFarmer from "./BaseDashboardFarmer";

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: [],
    })
    this.initData()

    store.dashboard.menuActive = 'LogFonterra'
    store.dashboard.navbarLeft = 'Logs of Fonterra'
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_LOG_FONTERRA');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_log_fonterra, params, data => {
      // console.log(data)
      this.data = data
    })
  }

  render() {
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>
            {this.data.map((item, index) => <div key={index}>

              <h4 className='px-3 py-2' style={{ backgroundColor: 'rgb(209, 154, 102)' }}>Vat {item.vat.vat_number}: Milking event</h4>
              {item.activity.filter(item3 => item3.type === 'milking').map((item2, index2) => <div key={index2}>
                {/* {item2._id} */}
                <SyntaxHighlighter language="json" style={arta}>
                  {JSON.stringify(item2.fonterra, null, 4)}
                </SyntaxHighlighter>
              </div>)}

              <h4 className='px-3 py-2' style={{ backgroundColor: 'rgb(209, 154, 102)' }}>Vat {item.vat.vat_number}: Collection event</h4>
              {item.activity.filter(item3 => item3.type === 'collection').map((item2, index2) => <div key={index2}>
                {/* {item2._id} */}
                <SyntaxHighlighter language="json" style={arta}>
                  {JSON.stringify(item2.fonterra, null, 4)}
                </SyntaxHighlighter>
              </div>)}

              <h4 className='px-3 py-2' style={{ backgroundColor: 'rgb(209, 154, 102)' }}>Vat {item.vat.vat_number}: Exception event</h4>
              {item.alert.length
                ? item.alert.map((item2, index2) => <div key={index2}>
                  {/* {item2._id} */}
                  <SyntaxHighlighter language="json" style={arta}>
                    {JSON.stringify(item2.fonterra, null, 4)}
                  </SyntaxHighlighter>
                </div>)
                : <SyntaxHighlighter className="pb-5" language="json" style={arta}>
                  No alert report
                </SyntaxHighlighter>}

            </div>)}
          </div>
        </div>
      </div >
    )
  }
}

export default withRouter(observer(Component))
