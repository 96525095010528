import React, { Component } from "react"
import emitter from "../../utility/EventEmitter"
import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import Snackbar from "@material-ui/core/Snackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import store from "../../store/Store";

class Events extends Component {

  constructor(props) {
    super(props);

    extendObservable(this, {
      message: 'An error occurred',
      messageOpen: false,
      dialog: {
        open: false,
        title: '',
        body: '',
        callbackCancel: null,
        callbackOk: null,
        forceSelect: false,
      },
    })
  }

  componentDidMount() {
    // a event listnener
    this.eventSnackbar = emitter.addListener("NOTIFY_SNACKBAR", (data) => {
      if (Array.isArray(data)) {
        this.message = data.join('<br/>');
      } else if (typeof data === 'object') {
        this.message = JSON.stringify(data);
      } else {
        // string number boolean etc
        this.message = data;
      }
      this.messageOpen = true;
    });
    // a event listnener
    this.eventSnackbarHide = emitter.addListener("NOTIFY_SNACKBAR_HIDE", () => {
      this.messageOpen = false;
    });
    // a event listnener
    this.eventDialog = emitter.addListener("NOTIFY_DIALOG", (data) => {
      if (data.title) {
        this.dialog.title = data.title
      } else {
        this.dialog.title = 'Are you sure?'
      }
      if (data.body) {
        this.dialog.body = data.body
      } else {
        this.dialog.body = 'This operation requires your second confirmation.'
      }
      if (data.callbackCancel) {
        this.dialog.callbackCancel = data.callbackCancel
      } else {
        this.dialog.callbackCancel = null
      }
      if (data.callbackOk) {
        this.dialog.callbackOk = data.callbackOk
      } else {
        this.dialog.callbackOk = null
      }
      if (data.forceSelect) {
        this.dialog.forceSelect = true
      } else {
        this.dialog.forceSelect = false
      }
      this.dialog.open = true;
    });
    // a event listnener
    this.eventUnauthorized = emitter.addListener("NOTIFY_UNAUTHORIZED", () => {
      // got to login page
      if (store.current.user && store.current.user._id) {
        if (store.current.user.role == "Admin" && store.current.user.token_id){
          window.location.href = "/dts/users"
        }
        else if (store.current.user.is_herd_enabled && store.current.user.token_id && location.href == 'https://login.herdman.co.nz/dashboard') {
          let url = "https://herdman.co.nz/login.aspx?t_id=" + store.current.user.token_id
          window.location.href = url
        }
        else if (store.current.user.is_herd_enabled && store.current.user.token_id && ['/dashboard', '/dts/survey', '/dts/user/profile', '/dts/user/setting'].includes(window.location.pathname)) {
          // do nothing
        }
        else if (store.current.user.is_herd_enabled && store.current.user.token_id && window.location.pathname == '/dts/survey') {
          // do nothing
        }
        else if (store.current.user.role === 'Milk Quality') {
          window.location.href = "/dts/fonterra/milkquality"
        }
        else {
          window.location.href = 'https://' + constants.doamin_dtslive + "/dts/permission"
        }
      } else {
        // console.log('Normally doesn\'t run here')
        window.location.href = "/dts/login"
      }
    });
  }

  // componentWillUnmount() {
  //   emitter.removeListener(this.eventSnackbar);
  //   emitter.removeListener(this.eventSnackbarHide);
  //   emitter.removeListener(this.eventDialog);
  //   emitter.removeListener(this.eventUnauthorized);
  // }

  dialogCancel = () => {
    this.dialog.open = false
    if (typeof this.dialog.callbackCancel === "function") {
      this.dialog.callbackCancel()
    }
  }

  dialogOK = () => {
    this.dialog.open = false
    if (typeof this.dialog.callbackOk === "function") {
      this.dialog.callbackOk()
    }
  }

  render() {
    return (<div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.messageOpen}
        autoHideDuration={6000}
        onClose={() => {
          this.messageOpen = false
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<div dangerouslySetInnerHTML={{ __html: this.message }}></div>}
        action={[]}
      />
      <Dialog
        disableBackdropClick={this.dialog.forceSelect}
        disableEscapeKeyDown={this.dialog.forceSelect}
        open={this.dialog.open}
        onClose={this.dialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> {this.dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.dialog.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.dialog.callbackCancel ? <Button onClick={this.dialogCancel} color="primary">
            Cancel
          </Button> : null}
          {this.dialog.callbackOk ? <Button onClick={this.dialogOK} color="primary">
            OK
          </Button> : null}
        </DialogActions>
      </Dialog>
    </div>)
  }


}

export default observer(Events);


