import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BasePromotion from "../promotion/BasePromotion";
import store from "../../store/Store";
import TextField from '@material-ui/core/TextField';
import Utils from "../../utility/Utils";
import emitter from "../../utility/EventEmitter"

class Component extends BasePromotion {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        subscribed: false,
        email_verify: 2,
        phone_verify: 2,
      },
      password: '',
      password1: '',
      password2: '',
      isVerifyEmail: false,
      isVerifyPhone: false,
      verifyPhoneCode: '',
    })

    store.dashboard.navbarLeft = 'Profile'
    this.initData();
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_DATAILS');
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      this.user = data
    })
  }

  userEditProfile = (notify) => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_EDIT_BASE');
    params.append('user', JSON.stringify(this.user));
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      // window.location.reload()
      if (notify) {
        emitter.emit("NOTIFY_SNACKBAR", 'Success!')
      }
    })
  }

  userEditPassword = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_EDIT_PASSWORD');
    params.append('password', this.password);
    params.append('password1', this.password1);
    params.append('password2', this.password2);
    Utils.httpPost(Utils.urlList.setting_profile, params, data => {
      // console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success!')
      this.password = ''
      this.password1 = ''
      this.password2 = ''
    })
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3'>
          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Profile"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="First Name"
                    margin="normal"
                    value={this.user.firstName}
                    onChange={e => { this.user.firstName = e.target.value }}
                  />
                </div>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="Last Name"
                    margin="normal"
                    value={this.user.lastName}
                    onChange={e => { this.user.lastName = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="Email Address"
                    margin="normal"
                    value={this.user.email}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    label="Phone"
                    margin="normal"
                    value={this.user.phone}
                    onChange={e => {
                      this.user.phone = e.target.value
                    }}
                  />
                </div>
              </div>
            </CardContent>
            <CardActions style={{ maxWidth: '600px' }}>
              <Button fullWidth variant="contained" color="primary" onClick={() => { this.userEditProfile(true) }}> Save </Button>
            </CardActions>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Edit Password"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="Current Password"
                    margin="normal"
                    value={this.password}
                    onChange={e => { this.password = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="New Password"
                    margin="normal"
                    value={this.password1}
                    onChange={e => { this.password1 = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    type="password"
                    label="Repeat New Password"
                    margin="normal"
                    value={this.password2}
                    onChange={e => { this.password2 = e.target.value }}
                  />
                </div>
              </div>
            </CardContent>
            <CardActions style={{ maxWidth: '600px' }}>
              <Button fullWidth variant="contained" color="secondary" onClick={this.userEditPassword}> Save </Button>
            </CardActions>
          </Card>
        </div>
      </div >
    )
  }

}

export default withRouter(observer(Component))
