import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MuiThemeProvider, Popover } from '@material-ui/core';
import theme from "../common/theme";
import Utils from "../../utility/Utils";
import DTSPopover from "../common/DTSPopover"
import emitter from "../../utility/EventEmitter"
import FarmerNavi from './farmer/FarmerNavi';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import DTS from "../../utility/DTS";

const pro_status = ' d-none1'

const pro_settings = {
  "tile1": {
    "title": "Automatic refrigeration and agitation turn on settings",
    "items": [
      {
        "subtitle": "Base pad refrigeration and agitator ON milk height",
        "info": "The set height level will determine when to turn the base pad refrigeration unit and the agitator based on how much milk is in the milk vat",
        "textbox_unit": "mm",
        "textbox_before": "Height",
        "textbox_after": "50-5500mm"
      },
      {
        "subtitle": "Sidewall refrigeration ON milk height",
        "info": "The set height level will determine when to turn the sidewall refrigeration unit based on how much milk is in the milk vat. If no sidewall present, set value at max height",
        "textbox_unit": "mm",
        "textbox_before": "Height",
        "textbox_after": "100-5500mm"
      }
    ]
  },
  "tile2": {
    "title": "Refrigeration unit/s temperature to cycle between ON and OFF",
    "items": [
      {
        "subtitle": "Fridge ON Temperature",
        "info": "The set temperature is when the refrigeration unit/s will turn on",
        "textbox_unit": "°C",
        "textbox_before": "Temperature",
        "textbox_after": "1.0 - 10.0°C"
      },
      {
        "subtitle": "Fridge OFF temperature",
        "info": "The set temperature is when the refrigeration unit/s will turn off",
        "textbox_unit": "°C",
        "textbox_before": "Temperature",
        "textbox_after": "1.0 - 10.0°C"
      }
    ]
  },
  "tile3": {
    "title": "Manual override delay time",
    "items": [
      {
        "subtitle": "Refrigeration and agitator ON delay",
        "info": "This is the delay time in minutes before the refrigeration and agitator are turned on when using the manual override switch on the Vat-Man Auto control box",
        "textbox_unit": "mins",
        "textbox_before": "Delay",
        "textbox_after": "0.0 - 30.00 mins"
      }
    ]
  },
  "tile4": {
    "title": "Vat temperature probe failure fallback time",
    "items": [
      {
        "subtitle": "Fallback fridge ON duration",
        "info": "This is the time duration in minutes that the refrigeration unit will cycle on for in case of a temperature probe full failure",
        "textbox_unit": "mins",
        "textbox_before": "Duration",
        "textbox_after": "0.0 - 59.0 mins"
      },
      {
        "subtitle": "Fallback fridge OFF duration",
        "info": "This is the time duration in minutes that the refrigeration unit will cycle off for in case of a temperature probe full failure",
        "textbox_unit": "mins",
        "textbox_before": "Duration",
        "textbox_after": "0.0 - 59.0 mins"
      }
    ]
  },
  "tile5": {
    "title": "CIP pump cycle and delay duration",
    "items": [
      {
        "subtitle": "Rinse On Duration",
        "info": "This is the time duration for running the CIP pump after the CIP rinse button has been pressed",
        "textbox_unit": "mins",
        "textbox_before": "Duration",
        "textbox_after": "0.0 - 10.0 mins"
      },
      {
        "subtitle": "CIP On Duration",
        "info": "This is the time duration for running the CIP pump after the CIP button has been pressed",
        "textbox_unit": "mins",
        "textbox_before": "Duration",
        "textbox_after": "0.0 - 30.0 mins"
      }
    ]
  }
}

const data_auto_default = {
  pro_refrige_level_on_basepad: 100,
  pro_refrige_level_on_sidewall: 5500,
  pro_refrige_temp_on: 5,
  pro_refrige_temp_off: 4,
  pro_refrige_agitator_on_delay: 10,
  pro_refrige_duration_on: 30,
  pro_refrige_duration_off: 30,
  pro_cip_cycle: 3,
  pro_cip_delay: 10,
}

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      data: {
        milk_warm_plate: 18,
        milk_cold: 2,
        milk_volume: 80,
        milk_slow_temp: 8,
        milk_slow_peroid: 45,
        milk_duration: 45,
        collection_duration: 1,
        pick_up_alert: 0,
        // pro_refrige_level_on_basepad: 500,
        // pro_refrige_level_on_sidewall: 35,
        // pro_refrige_temp_on: 5,
        // pro_refrige_temp_off: 4,
        // pro_refrige_agitator_on_delay: 10,
        // pro_refrige_duration_on: 30,
        // pro_refrige_duration_off: 30,
        // pro_cip_cycle: 3,
        // pro_cip_delay: 0,
      },
      data_auto: [],
      data_auto_idx: 0,
      anchorEl_power: null,
      anchorEl_agitator: null,
      anchorEl_warm_vat: null,
      anchorEl_warm_plate: null,
      anchorEl_cold: null,
      anchorEl_level: null,
      anchorEl_slowtocool1: null,
      anchorEl_slowtocool2: null,
      anchorEl_Milking_duration: null,
      anchorEl_Collection_duration: null,
      anchorEl_pickup: null,
      anchorEl_pro_refrige_level_on_basepad: null,
      anchorEl_pro_refrige_level_on_sidewall: null,
      anchorEl_pro_refrige_temp_on: null,
      anchorEl_pro_refrige_temp_off: null,
      anchorEl_pro_refrige_agitator_on_delay: null,
      anchorEl_pro_refrige_duration_on: null,
      anchorEl_pro_refrige_duration_off: null,
      anchorEl_pro_cip_cycle: null,
      anchorEl_pro_cip_delay: null,
      package: null,
      pro_settings: Object.values(pro_settings)
    })
    this.initData()

    store.dashboard.menuActive = 'Milk'
    store.dashboard.navbarLeft = 'Settings'
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_SETTING_ALERT_GET');
    params.append('farm', Utils.farmGet());
    params.append('alert_setting', JSON.stringify(this.data));
    Utils.httpPost(Utils.urlList.farmer_setting, params, data => {
      console.log(data)
      // const target = { a: 1, b: 2 };
      // const source = { b: 4, c: 5 };
      // const returnedTarget = Object.assign(target, source);
      // console.log(target);
      // // expected output: Object { a: 1, b: 4, c: 5 }
      // console.log(returnedTarget);
      // // expected output: Object { a: 1, b: 4, c: 5 }
      if (data.farm.alert_setting) {
        const target = this.data
        const source = data.farm.alert_setting
        Object.assign(target, source);
        // this.data = returnedTarget
        // if (!data.alert_setting.milk_slow_temp || !data.alert_setting.milk_slow_peroid) {
        //   data.alert_setting.milk_slow_temp = 8
        //   data.alert_setting.milk_slow_peroid = 45
        // }
        // if (!data.alert_setting.pick_up_alert) {
        //   data.alert_setting.pick_up_alert = 0
        // }
        // this.data = data.alert_setting
      }
      if (data.vats) {
        for (let i = 0; i < data.vats.length; i++) {
          const item = data.vats[i]
          if (item.auto_settings) {
            const target = JSON.parse(JSON.stringify(data_auto_default)) // item.auto_settings
            const source = item.auto_settings
            Object.assign(target, source);
            item.auto_settings = target
            // console.log(target)
          } else {
            item.auto_settings = data_auto_default
          }
        }
        this.data_auto = data.vats
      }
      if (data.farm.package) {
        this.package = data.farm.package
      }
      return
      if (!data) { data = {} }
      if (!data.milk_slow_temp) {
        data.milk_slow_temp = 8
      }
      if (!data.milk_slow_peroid) {
        data.milk_slow_peroid = 45
      }
      if (data) {
        this.data = data
      }
    })
  }

  @computed get getDataAutoCurrent() {
    if (this.data_auto && this.data_auto.length) {
      const item = this.data_auto[this.data_auto_idx]
      return item
      if (item.auto_settings) {
        const target = item.auto_settings
        const source = data_auto_default
        Object.assign(target, source);
      } else {
        item.auto_settings = data_auto_default
      }
      return item
    } else {
      return null
    }
  }

  validation_warm = () => {
    let reg = /^([0-9]|[1-9][0-9]*)$/;
    if (!reg.test(this.data.milk_warm_plate)) {
      emitter.emit("NOTIFY_SNACKBAR", 'Too warm input verification failed!')
      return false
    }
    return true
  }

  validation_level = () => {
    let reg = /^([0-9]|[1-9][0-9]*)$/;
    if (!reg.test(this.data.milk_volume)) {
      emitter.emit("NOTIFY_SNACKBAR", 'Volume level input verification failed!')
      return false
    }
    return true
  }

  validation_cold = () => {
    let reg = /^(?:0|[1-9]\d{0,2}(?:,?\d{0,1}))(?:\.\d{1})?$/;
    if (!reg.test(this.data.milk_cold)) {
      emitter.emit("NOTIFY_SNACKBAR", 'Too cold input verification failed!')
      return false
    }
    this.data.milk_cold = parseFloat(this.data.milk_cold).toFixed(1);
    return true
  }

  userAlertSet = () => {
    if (!this.validation_level() || !this.validation_cold() || !this.validation_warm()) {
      return
    }
    const params = new URLSearchParams();
    params.append('type', 'USER_SETTING_ALERT_SET');
    params.append('farm', Utils.farmGet());
    params.append('alert_setting', JSON.stringify(this.data));
    Utils.httpPost(Utils.urlList.farmer_setting, params, data => {
      // console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success!')
    })
  }

  userAlertSetController = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_SETTING_ALERT_SET_AUTO');
    params.append('farm', Utils.farmGet());
    params.append('vat', this.getParamsController());
    // params.append('alert_setting', JSON.stringify(this.data));
    Utils.httpPost(Utils.urlList.farmer_setting, params, data => {
      console.log(data)
      emitter.emit("NOTIFY_SNACKBAR", 'Success: ' + data['return_value'])
    })
  }

  getParamsController = () => {
    let res = this.getDataAutoCurrent
    res = JSON.stringify(this.getDataAutoCurrent)
    return res
  }

  getFarmId = () => {
    const farm = JSON.parse(Utils.farmGet())
    return farm._id
  }

  render() {
    document.body.classList.add('milk-nav');
    return (
      <div className='container-fluid my-3'>

        <div style={{ position: 'fixed', top: '96px', zIndex: 1, width: '100%' }}>
          <FarmerNavi tabval={4} />
        </div>

        <div className='mb-3 fix-subnva'>
          {DTS.isFonterraFarm(this.getFarmId()) ? null : <Card className='mb-3'>
            <CardActions>
              <Button variant="contained" color="primary" onClick={()=>{
                this.props.history.push("/dts/pickup");
              }} style={{ marginLeft: 'auto' }}> Submit Pickup </Button>
            </CardActions>
          </Card>}           

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Vat-Man Lite"
            />
            <CardContent>
              {/* <CardHeader subheader="Milk" /> */}
              <div className="table-responsive">
                <table className='table table-td-x-0'>
                  <tbody>
                    {/* Milk from plate too warm */}
                    <tr>
                      <td className="nowrap">
                        Milk from plate too warm
                        <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                          this.anchorEl_warm_plate = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_warm_plate}
                          actionHide={() => { this.anchorEl_warm_plate = null }}
                          text='The milk out of the plate cooler too warm alert will let you know when the milk inlet temperature exceeds the temperature threshold that you can set.'
                        />
                      </td>
                      <td className="d-flex align-items-center nowrap">
                        <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                        <MuiThemeProvider theme={theme}>
                          <TextField
                            className="mx-3"
                            id="tf-milk_warm_plate"
                            variant="outlined"
                            style={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            value={this.data.milk_warm_plate}
                            InputProps={{ endAdornment: <InputAdornment position="end">℃</InputAdornment>, }}
                            onChange={e => {
                              // let reg = /^\d+$/;
                              // if (!reg.test(e.target.value)) {
                              //   return
                              // }
                              let value = e.target.value
                              this.data.milk_warm_plate = value
                            }}
                            onBlur={e => {
                              let value = e.target.value
                              value = parseInt(value)
                              if (value > 30) {
                                value = 30
                              } else if (value < 15) {
                                value = 15
                              }
                              this.data.milk_warm_plate = value
                            }}
                          /> 15 - 30
                        </MuiThemeProvider>
                      </td>
                    </tr>
                    {/* Milk in vat too cold */}
                    <tr>
                      <td className="nowrap">
                        Milk in vat too cold
                        <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                          this.anchorEl_cold = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_cold}
                          actionHide={() => { this.anchorEl_cold = null }}
                          text='The milk in the vat too cold alert will let you know when the milk temperature in the vat drops below a threshold that you can set.'
                        />
                      </td>
                      <td className="d-flex align-items-center nowrap">
                        <div style={{ width: 100, textAlign: 'right' }}>Lower than</div>
                        <MuiThemeProvider theme={theme}>
                          <TextField
                            className="mx-3"
                            id="tf-milk_cold"
                            variant="outlined"
                            style={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            value={this.data.milk_cold}
                            InputProps={{ endAdornment: <InputAdornment position="end">℃</InputAdornment>, }}
                            onChange={e => {
                              // let reg = /^\d+$/;
                              // if (!reg.test(e.target.value)) {
                              //   return
                              // }
                              let value = e.target.value
                              this.data.milk_cold = value
                            }}
                            onBlur={e => {
                              let value = e.target.value
                              value = Math.round(value * 2) / 2
                              if (value > 6) {
                                value = 6
                              } else if (value < 0) {
                                value = 0
                              }
                              this.data.milk_cold = value
                            }}
                          /> 0.0 - 6.0
                        </MuiThemeProvider>
                      </td>
                    </tr>
                    {/* Milk volume level */}
                    <tr>
                      <td className="nowrap">
                        Milk volume level
                        <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                          this.anchorEl_level = e.currentTarget
                        }}>info</i>
                        <DTSPopover
                          anchorEl={this.anchorEl_level}
                          actionHide={() => { this.anchorEl_level = null }}
                          text='The milk vat volume alert will let you know when the volume level of a vat exceeds the percentage threshold that you can set.'
                        />
                      </td>
                      <td className="d-flex align-items-center nowrap">
                        <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                        <MuiThemeProvider theme={theme}>
                          <TextField
                            className="mx-3"
                            id="tf-milk_volume"
                            variant="outlined"
                            style={{ width: '112px' }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            value={this.data.milk_volume}
                            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, }}
                            onChange={e => {
                              // let reg = /^\d+$/;
                              // if (!reg.test(e.target.value)) {
                              //   return
                              // }
                              let value = e.target.value
                              this.data.milk_volume = value
                            }}
                            onBlur={e => {
                              let value = e.target.value
                              value = parseInt(value)
                              if (value > 100) {
                                value = 100
                              } else if (value < 50) {
                                value = 50
                              }
                              this.data.milk_volume = value
                            }}
                          /> 50 - 100
                        </MuiThemeProvider>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          {['Vat-Man Pro', 'Vat-Man Auto'].includes(this.package) ? <>

            <Card className='mb-3'>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                subheader="Vat-Man Pro"
              />
              <CardContent>
                {/* <CardHeader subheader="Milk" /> */}
                <div className="table-responsive">
                  <table className='table table-td-x-0'>
                    <tbody>

                      {/* Milk in vat too cold peroid */}
                      <tr>
                        <td className="nowrap">
                          Milk in vat slow to cool target temperature
                          <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                            this.anchorEl_slowtocool1 = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_slowtocool1}
                            actionHide={() => { this.anchorEl_slowtocool1 = null }}
                            text='The slow to cool alert will let you know if the milk vat temperature that the chiller is set to is not reached within a specified time frame.'
                          />
                        </td>
                        <td className="d-flex align-items-center nowrap">
                          <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              className="mx-3"
                              variant="outlined"
                              style={{ width: '112px' }}
                              inputProps={{ style: { textAlign: 'center' } }}
                              value={this.data.milk_slow_temp}
                              InputProps={{ endAdornment: <InputAdornment position="end">℃</InputAdornment>, }}
                              onChange={e => {
                                // let reg = /^\d+$/;
                                // if (!reg.test(e.target.value)) {
                                //   return
                                // }
                                let value = e.target.value
                                this.data.milk_slow_temp = value
                              }}
                              onBlur={e => {
                                let value = e.target.value
                                value = parseInt(value)
                                if (value > 10) {
                                  value = 10
                                } else if (value < 3) {
                                  value = 3
                                }
                                this.data.milk_slow_temp = value
                              }}
                            /> 3 - 10 ℃
                          </MuiThemeProvider>
                        </td>
                      </tr>
                      <tr>
                        <td className="nowrap">
                          Milk in vat slow to cool time period
                          <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                            this.anchorEl_slowtocool2 = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_slowtocool2}
                            actionHide={() => { this.anchorEl_slowtocool2 = null }}
                            text='The slow to cool alert will let you know if the milk vat temperature that the chiller is set to is not reached within a specified time frame.'
                          />
                        </td>
                        <td className="d-flex align-items-center nowrap">
                          <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              className="mx-3"
                              variant="outlined"
                              style={{ width: '112px' }}
                              inputProps={{ style: { textAlign: 'center' } }}
                              value={this.data.milk_slow_peroid}
                              InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment>, }}
                              onChange={e => {
                                // let reg = /^\d+$/;
                                // if (!reg.test(e.target.value)) {
                                //   return
                                // }
                                let value = e.target.value
                                this.data.milk_slow_peroid = value
                              }}
                              onBlur={e => {
                                let value = e.target.value
                                value = parseInt(value)
                                if (value > 480) {
                                  value = 480
                                } else if (value < 20) {
                                  value = 20
                                }
                                this.data.milk_slow_peroid = value
                              }}
                            /> 20 - 480 mins
                          </MuiThemeProvider>
                        </td>
                      </tr>
                      <tr>
                        <td className="nowrap">
                          Milking duration alert
                          <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                            this.anchorEl_Milking_duration = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_Milking_duration}
                            actionHide={() => { this.anchorEl_Milking_duration = null }}
                            text='The milking duration alert will inform you if there is a variance with your milking duration or start time greater than the time period that you set.'
                          />
                        </td>
                        <td className="d-flex align-items-center nowrap">
                          <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              className="mx-3"
                              variant="outlined"
                              style={{ width: '112px' }}
                              inputProps={{ style: { textAlign: 'center' } }}
                              value={this.data.milk_duration}
                              InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment>, }}
                              onChange={e => {
                                // let reg = /^\d+$/;
                                // if (!reg.test(e.target.value)) {
                                //   return
                                // }
                                let value = e.target.value
                                this.data.milk_duration = value
                              }}
                              onBlur={e => {
                                let value = e.target.value
                                value = parseInt(value)
                                if (value > 60) {
                                  value = 60
                                } else if (value < 30) {
                                  value = 30
                                }
                                this.data.milk_duration = value
                              }}
                            /> 30 - 60 mins
                          </MuiThemeProvider>
                        </td>
                      </tr>
                      <tr>
                        <td className="nowrap">
                          Collection duration alert
                          <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                            this.anchorEl_Collection_duration = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_Collection_duration}
                            actionHide={() => { this.anchorEl_Collection_duration = null }}
                            text='The milk not collected alert will inform you after x minutes/hours that the milk in your vat/s hasn’t been collected.'
                          />
                        </td>
                        <td className="d-flex align-items-center nowrap">
                          <div style={{ width: 100, textAlign: 'right' }}>More than</div>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              className="mx-3"
                              variant="outlined"
                              style={{ width: '112px' }}
                              inputProps={{ style: { textAlign: 'center' } }}
                              value={this.data.collection_duration}
                              InputProps={{ endAdornment: <InputAdornment position="end">hrs</InputAdornment>, }}
                              onChange={e => {
                                // let reg = /^\d+$/;
                                // if (!reg.test(e.target.value)) {
                                //   return
                                // }
                                let value = e.target.value
                                this.data.collection_duration = value
                              }}
                              onBlur={e => {
                                let value = e.target.value
                                value = parseInt(value)
                                if (value > 3) {
                                  value = 3
                                } else if (value < 1) {
                                  value = 1
                                }
                                this.data.collection_duration = value
                              }}
                            /> 1 - 3 hrs
                          </MuiThemeProvider>
                        </td>
                      </tr>
                      <tr className='d-none'>
                        <td className="nowrap">
                          Milk pick-up alert
                          <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                            this.anchorEl_pickup = e.currentTarget
                          }}>info</i>
                          <DTSPopover
                            anchorEl={this.anchorEl_pickup}
                            actionHide={() => { this.anchorEl_pickup = null }}
                            text='The milk pick-up alert will inform you when the milk in a vat/s has been collected by the milk tanker.'
                          />
                        </td>
                        <td className="d-flex align-items-center nowrap">
                          <div style={{ width: 100, textAlign: 'right' }}>After</div>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              className="mx-3"
                              variant="outlined"
                              style={{ width: '112px' }}
                              inputProps={{ style: { textAlign: 'center' } }}
                              value={this.data.pick_up_alert}
                              InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment>, }}
                              onChange={e => {
                                // let reg = /^\d+$/;
                                // if (!reg.test(e.target.value)) {
                                //   return
                                // }
                                let value = e.target.value
                                this.data.pick_up_alert = value
                              }}
                              onBlur={e => {
                                let value = e.target.value
                                value = parseInt(value)
                                if (value > 30) {
                                  value = 30
                                } else if (value < 0) {
                                  value = 0
                                }
                                this.data.pick_up_alert = value
                              }}
                            /> 0 - 30 mins
                          </MuiThemeProvider>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>

          </> : null}

          <Card className='mb-3 d-none1'>
            <CardActions>
              <Button variant="contained" color="primary" onClick={this.userAlertSet} className="m-auto" style={{ width: 100 }}> Save </Button>
            </CardActions>
          </Card>

          {['Vat-Man Auto'].includes(this.package) ? <>

            <Card className='mb-3'>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                subheader="Vat-Man Auto"
              />
              <CardContent>
                {this.getDataAutoCurrent && this.getDataAutoCurrent._id
                  ? <Paper>
                    <Tabs
                      value={this.data_auto_idx}
                      onChange={(evt, val) => {
                        // console.log(val)
                        this.data_auto_idx = val
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      {this.data_auto.map((x, i) => <Tab key={i} label={"Vat " + x.vat_number} />)}
                      {/* <Tab label="Vat 1" />
                    <Tab label="Vat 2" />
                    <Tab label="Vat 3" /> */}
                    </Tabs>
                  </Paper>
                  : null}
                {/* <Tabs
                  value={1}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon tabs example"
                  style={{ width: '200px', margin: 'auto' }}
                >
                  <Tab icon={'Vat 1'} aria-label="phone" />
                  <Tab icon={'Vat 2'} aria-label="phone1" />
                </Tabs> */}
                {/* {this.data_auto.map((x, i) => {
                  const item = {}
                  return <div key={i}>
                  </div>
                })} */}
                {this.getDataAutoCurrent && this.getDataAutoCurrent._id
                  ? <div className="table-responsive">
                    <table className='table table-td-x-0'>
                      <tbody>

                        {/* 1 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[0].items[0].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_level_on_basepad = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_level_on_basepad}
                              actionHide={() => { this.anchorEl_pro_refrige_level_on_basepad = null }}
                              text={this.pro_settings[0].items[0].info}
                            />
                          </td>
                          <td className="d-flex align-items-center justify-content-end1 nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[0].items[0].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_basepad}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[0].items[0].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_basepad = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  if (value > 5500) {
                                    value = 5500
                                  } else if (value < 50) {
                                    value = 50
                                  }
                                  value = parseInt(value)
                                  if (value > this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_sidewall){
                                    value = this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_sidewall
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_basepad = value
                                }}
                              /> {this.pro_settings[0].items[0].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* 2 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[0].items[1].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_level_on_sidewall = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_level_on_sidewall}
                              actionHide={() => { this.anchorEl_pro_refrige_level_on_sidewall = null }}
                              text={this.pro_settings[0].items[1].info}
                            />
                          </td>
                          <td className="d-flex align-items-center justify-content-end1 nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[0].items[1].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_sidewall}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[0].items[1].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_sidewall = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = parseInt(value)
                                  if (value > 5500) {
                                    value = 5500
                                  } else if (value < 100) {
                                    value = 100
                                  }
                                  value = parseInt(value)
                                  if (value < this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_basepad){
                                    value = this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_basepad
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_level_on_sidewall = value
                                }}
                              /> {this.pro_settings[0].items[1].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>


                        {/* 3 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[1].items[0].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_temp_on = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_temp_on}
                              actionHide={() => { this.anchorEl_pro_refrige_temp_on = null }}
                              text={this.pro_settings[1].items[0].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[1].items[0].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_temp_on}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[1].items[0].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_temp_on = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 10) {
                                    value = 10
                                  } else if (value < 1) {
                                    value = 1
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_temp_on = value
                                }}
                              /> {this.pro_settings[1].items[0].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* 4 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[1].items[1].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_temp_off = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_temp_off}
                              actionHide={() => { this.anchorEl_pro_refrige_temp_off = null }}
                              text={this.pro_settings[1].items[1].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[1].items[1].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_temp_off}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[1].items[1].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_temp_off = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 10) {
                                    value = 10
                                  } else if (value < 1) {
                                    value = 1
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_temp_off = value
                                }}
                              /> {this.pro_settings[1].items[1].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* 8 Volume percentage level */}
                        {/* 3 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[4].items[0].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_cip_cycle = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_cip_cycle}
                              actionHide={() => { this.anchorEl_pro_cip_cycle = null }}
                              text={this.pro_settings[4].items[0].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[4].items[0].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                value={this.getDataAutoCurrent.auto_settings.pro_cip_cycle}
                                inputProps={{ style: { textAlign: 'center' } }}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[4].items[0].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_cip_cycle = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 10) {
                                    value = 10
                                  } else if (value < 0) {
                                    value = 0
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_cip_cycle = value
                                }}
                              /> {this.pro_settings[4].items[0].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* 4 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[4].items[1].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_cip_delay = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_cip_delay}
                              actionHide={() => { this.anchorEl_pro_cip_delay = null }}
                              text={this.pro_settings[4].items[1].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[4].items[1].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                value={this.getDataAutoCurrent.auto_settings.pro_cip_delay}
                                inputProps={{ style: { textAlign: 'center' } }}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[4].items[1].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_cip_delay = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 30) {
                                    value = 30
                                  } else if (value < 0) {
                                    value = 0
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_cip_delay = value
                                }}
                              /> {this.pro_settings[4].items[1].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* Manual override */}
                        <tr className='d-none1 text-muted'><td>Manual override</td><td></td></tr>

                        {/* 7 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[2].items[0].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_agitator_on_delay = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_agitator_on_delay}
                              actionHide={() => { this.anchorEl_pro_refrige_agitator_on_delay = null }}
                              text={this.pro_settings[2].items[0].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[2].items[0].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_agitator_on_delay}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[2].items[0].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_agitator_on_delay = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 30) {
                                    value = 30
                                  } else if (value < 0) {
                                    value = 0
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_agitator_on_delay = value
                                }}
                              /> {this.pro_settings[2].items[0].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>



                        {/* 8 Volume percentage level */}
                        {/* 9 Volume percentage level */}
                        {/* 3 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[3].items[0].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_duration_on = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_duration_on}
                              actionHide={() => { this.anchorEl_pro_refrige_duration_on = null }}
                              text={this.pro_settings[3].items[0].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[3].items[0].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_duration_on}
                                inputProps={{ style: { textAlign: 'center' } }}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[3].items[0].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_duration_on = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 59) {
                                    value = 59
                                  } else if (value < 0) {
                                    value = 0
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_duration_on = value
                                }}
                              /> {this.pro_settings[3].items[0].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                        {/* 4 Volume percentage level */}
                        <tr className='d-none1'>
                          <td className="nowrap">
                            {this.pro_settings[3].items[1].subtitle}
                            <i className="material-icons material-icons-align text-help cursor-pointer mx-2" onClick={e => {
                              this.anchorEl_pro_refrige_duration_off = e.currentTarget
                            }}>info</i>
                            <DTSPopover
                              anchorEl={this.anchorEl_pro_refrige_duration_off}
                              actionHide={() => { this.anchorEl_pro_refrige_duration_off = null }}
                              text={this.pro_settings[3].items[1].info}
                            />
                          </td>
                          <td className="d-flex align-items-center nowrap">
                            <div style={{ width: 100, textAlign: 'right' }}>{this.pro_settings[3].items[1].textbox_before}</div>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                className="mx-3"
                                variant="outlined"
                                style={{ width: '112px' }}
                                value={this.getDataAutoCurrent.auto_settings.pro_refrige_duration_off}
                                inputProps={{ style: { textAlign: 'center' } }}
                                InputProps={{ endAdornment: <InputAdornment position="end">{this.pro_settings[3].items[1].textbox_unit}</InputAdornment>, }}
                                onChange={e => {
                                  // let reg = /^\d+$/;
                                  // if (!reg.test(e.target.value)) {
                                  //   return
                                  // }
                                  let value = e.target.value
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_duration_off = value
                                }}
                                onBlur={e => {
                                  let value = e.target.value
                                  value = Math.round(value * 2) / 2
                                  if (value > 59) {
                                    value = 59
                                  } else if (value < 0) {
                                    value = 0
                                  }
                                  this.getDataAutoCurrent.auto_settings.pro_refrige_duration_off = value
                                }}
                              /> {this.pro_settings[3].items[1].textbox_after}
                            </MuiThemeProvider>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  : null}

              </CardContent>
            </Card>

            <Card className='mb-3'>
              <CardActions>
                <Button variant="contained" color="primary" onClick={this.userAlertSetController} className="m-auto" style={{ width: 100 }}> Save </Button>
              </CardActions>
            </Card>

          </> : null}

          {/* Water Effluent Land Weather */}
          {/* <Card className='mb-3'>
            <CardHeader subheader="Water" />
          </Card>
          <Card className='mb-3'>
            <CardHeader subheader="Effluent" />
          </Card> */}

        </div>
      </div >
    )
  }

}

export default withRouter(observer(Component))
