import * as moment from "moment";
import 'moment-timezone';
import store from "../store/Store";

class DTS {

  static getAgitatorStopStat = (list) => {
    let listStop = DTS.getAgitatorStopList(list)
    let periodTotal = 0
    let periodStop = 0

    if (list.length > 1) {
      let t = moment.utc(list[list.length - 1].published_at).diff(moment.utc(list[0].published_at)) / 1000
      periodTotal = t
    }

    for (let item of listStop) {
      let t = moment.utc(item.end.published_at).diff(moment.utc(item.start.published_at)) / 1000
      periodStop += t
    }

    const percent = Math.round(periodStop / periodTotal * 100)
    periodStop = periodStop === 0 ? "None" : moment.duration(periodStop, 'seconds').humanize()

    // console.log(periodStop, periodTotal, percent)
    return {
      listStop: listStop,
      percent: percent,
      periodStop: periodStop
    }
  }

  // return agitator list stop
  static getAgitatorStopList = (list) => {
    let length = list.length
    let listStop = []
    let itemStop = {}
    itemStop.flag = false

    let previous
    let item
    for (let i = 0; i < length; i++) {
      item = list[i]
      if (i > 0) previous = list[i - 1]

      // if (item.shaft_rpm < motor_rumble_threshold) {
      if (item.agitator_status === 0) {
        // stop
        itemStop.flag = true
        if (!itemStop.start) {
          itemStop.start = item
        }
      } else if (item.agitator_status === 1) {
        // running
        if (itemStop.flag) {
          // record one stop event
          itemStop.end = item
          listStop.push(itemStop)
          itemStop = {}
          itemStop.flag = false
          // } else {
          //   itemStop.start = item
        }
      }
    }
    // console.log(previous)
    if (itemStop.flag && previous && itemStop.start._ID !== previous._id) {
      itemStop.end = previous
      listStop.push(itemStop)
    }
    // console.log(listStop)
    return listStop
  }

  // get fonterra id by farm id
  static getFonterraId = (farm_id) => {
    for (let item of store.current.listFarm) {
      if (item._id === farm_id) {
        return item.fonterra_id
      }
    }
    return 'N/A'
  }

  // get fonterra id by farm id
  static isFonterraFarm = (farm_id) => {
    for (let item of store.current.listFarm) {
      if (item._id === farm_id) {
        let regex = /^\d{5}$/;
        let found = item.fonterra_id.match(regex);
        if (!found) {
          regex = /^FON/;
          found = item.fonterra_id.match(regex);
        }
        //console.log(found)
        if (found) return true
        break
      }
    }
    return false
  }

  // get volume round
  static getVolumeRound(volume) {
    let result = this.getVolumeRoundNumber(volume)
    return result.toLocaleString()
  }

  // get volume round
  static getVolumeRoundNumber(volume) {
    let result
    if (!volume) {
      result = 0
    }
    result = Math.round(volume / 10) * 10
    return result
  }

  // get volume round
  static getTemperature(temp) {
    if (!temp) return
    let result = Math.round(temp * 10) / 10
    // let result = temp.toFixed(1)
    return result
  }

  // datetime is a utc datetime string or maybe moment object
  static getSpecificTime(datetime) {
    // let result = moment.utc(datetime).local()
    let tz = moment.tz.guess()
    // console.log(tz)
    let result = moment.utc(datetime).tz(tz) // .format()
    console.log(result)
    return result
  }

  static getNotificationByType = (type) => {
    let milk_volume = '80%'
    let result = { 'label': '', 'description': '' }
    switch (type) {
      case 101:
        result = { 'label': 'Temperature', 'description': 'The temperature of the vat has exceeded 10°C' }
        break;
      case 102:
        result = { 'label': 'Temperature', 'description': 'The temperature of the vat has exceeded 6°C' }
        break;
      case 103:
        result = { 'label': 'PlateWarm', 'description': 'The milk inlet temperature is too warm' }
        break;
      case 104:
        result = { 'label': 'TooCold', 'description': 'The vat temperature is too cold' }
        break;
      case 201:
        result = { 'label': 'Volume', 'description': 'Vat level is over ' + milk_volume }
        break;
      case 301:
        result = { 'label': 'Power', 'description': 'Power outage detected' }
        break;
      case 401:
        result = { 'label': 'Agitator', 'description': 'Agitator stopped' }
        break;
      case 110:
        result = { 'label': 'SlowCool', 'description': 'Slow to Cool' }
        break;
      case 111:
        result = { 'label': 'PickUp', 'description': 'Milk has been picked up' }
        break;
      case 112:
        result = { 'label': 'VatControl', 'description': 'Vat-Man Auto status unknown. Please check the Vat Control box screen for more information' }
        break;
      case 113:
        result = { 'label': 'ManualOverride', 'description': 'Vat-Man Auto working on manual override' }
        break;
      case 114:
        result = { 'label': 'OffPosition', 'description': 'Vat-Man Auto working on off position' }
        break;
      case 1001:
        result = { 'label': 'APCLow', 'description': 'NOTIFY_FONTERRA_APC_LOW.' }
        break;
      case 1002:
        result = { 'label': 'APC_MODERATE', 'description': 'NOTIFY_FONTERRA_APC_MODERATE.' }
        break;
      case 1003:
        result = { 'label': 'APC_HIGH', 'description': 'NOTIFY_FONTERRA_APC_HIGH.' }
        break;
      case 1004:
        result = { 'label': 'APC_ACCEPTANCERISKEXCEEDED', 'description': 'NOTIFY_FONTERRA_APC_ACCEPTANCERISKEXCEEDED.' }
        break;
      case 1102:
        result = { 'label': 'AGITATOR', 'description': 'NOTIFY_FONTERRA_AGITATOR.' }
        break;
      case 1201:
        result = { 'label': 'LATEMILKING', 'description': 'NOTIFY_FONTERRA_LATEMILKING.' }
        break;
      case 501:
        result = { 'label': 'ONLINE', 'description': 'System Alert Vat-Man is back online.' }
        break;
      case 502:
        result = { 'label': 'OFFLINE', 'description': 'System Alert Vat-Man has gone offline.' }
        break;
      case 601:
        result = { 'label': 'Milking', 'description': 'Farm Alert milking duration variance.' }
        break;
      case 701:
        result = { 'label': 'Collection', 'description': 'Farm Alert milk not collected within estimated pickup time.' }
        break;
    }
    return result
  }

  static getDemoFarmName = (user) => {
    let result = null
    if (!user) {
      result = 'NOLOGIN'
    }
    switch (user._id) {
      case '5dc8dc0021ca930cc14408f5': // 5e1fb43321ca93176e2ae8d7
        result = 'FarmDemo'
        break
      case '5dce19a8fdce270bc27a2c15': // 5e1fb43321ca93176e2ae8d7
        result = 'FarmSource'
        break
      case '5f71197321ca93789f2e76de': // 5e1fb43321ca93176e2ae8d7
        result = 'FarmNF'
        break
    }
    return result
  }

  static domainRedirect(flag) {
    const url = window.location.href
    const arr = url.split("/");
    const dom = arr[2]
    const dom1 = url.replace(dom, global.constants.doamin_dtslive)
    const dom2 = url.replace(dom, global.constants.doamin_vatman)
    // console.log(url, arr, dom, dom1, dom2)
    if (dom.startsWith('localhost')) return
    switch (flag) {
      case 1:
        if (dom.startsWith(global.constants.doamin_dtslive)) return
        location.href = dom1
        break
      case 2:
        if (dom.startsWith(global.constants.doamin_vatman)) return
        location.href = dom2
        break
      default:
        if (dom.startsWith(global.constants.doamin_dtslive)) return
        location.href = dom1
        break
    }
  }

  static getDomain(domain) {
    const status = process.env.NODE_ENV && process.env.NODE_ENV !== 'development'
    if (status) return 'https://' + domain
    else return ''
  }

}

export default DTS