import axios from "axios"
import emitter from "./EventEmitter"
import store from "../store/Store"
import Cookies from 'universal-cookie';


class Utils {

  /* ----------------------------------------------------
  ---------------------------------------------------- */
  static urlList = {
    'admin_device': '/api/admin/device',
    'admin_user': '/api/admin/user',
    'admin_farm': '/api/admin/farm',
    'admin_organization': '/api/admin/organization',
    'user': '/api/user',
    'farmer_dashboard': '/api/farmer/dashboard',
    'farmer_activity': '/api/farmer/activity',
    'farmer_notification': '/api/farmer/notification',
    'farmer_organization': '/api/farmer/organization',
    'farmer_equipment': '/api/farmer/equipment',
    'farmer_log_fonterra': '/api/farmer/log/fonterra',
    'farmer_setting': '/api/farmer/setting',
    'farmer_survey': '/api/farmer/survey',
    'setting_profile': '/api/setting/profile',
    'calibration': '/api/calibration',
    'cases': '/api/cases',
    'installer': '/api/installer',
    'milkquality': '/api/milkquality',
    'watchman': '/api/watchman',
  }

  /* ----------------------------------------------------
  ---------------------------------------------------- */
  static getUrlBase = () => {
    // console.log(global.constants)
    if (process.env.NODE_ENV === 'development') {
      return global.env.api_server_dev
    } else {
      return global.constants.api_server_prod
    }
  }

  /* ----------------------------------------------------
  ---------------------------------------------------- */
  static httpPost = (url, params, callback, callback_error) => {
    // console.log(global.constants)
    url = Utils.getUrlBase() + url
    store.progressNumber++
    store.progressOpen = true

    let cancelTokenSource = axios.CancelToken.source()

    let config = {
      headers: {
        Authorization: Utils.authGet(),
      },
      cancelToken: cancelTokenSource.token,
      // withCredentials: true,
    }
    axios
      .post(url, params, config)
      .then((response) => {
        // console.log(url, response)
        store.progressNumber--
        if (store.progressNumber === 0) {
          store.progressOpen = false
        }
        if (callback) {
          callback(response.data)
        }
      })
      .catch(error => {
        store.progressNumber--
        if (store.progressNumber === 0) {
          store.progressOpen = false
        }
        console.log("Jack: ", url, params.toString(), error)
        this.httpError(error, callback_error)
      })
    return cancelTokenSource
  }

  /* ----------------------------------------------------
  ---------------------------------------------------- */
  static httpError = (error, callback_error) => {
    if (axios.isCancel(error)) {
      // nothing
    } else if (callback_error) {
      callback_error(error.response)
    } else if (error.response) {
      switch (error.response.status) {
        case 401:
          emitter.emit("NOTIFY_UNAUTHORIZED")
          break
        case 422:
        case 429:
        case 500:
        case 510:
          let error_msg = error.response.data.message
          switch (error_msg) {
            case 'timeout-or-duplicate':
              // error_msg = 'Page has timed-out. Please reload the page to login.'
              error_msg = 'An error occurred, please try again!'
              break
            case 'invalid-input-response':
              // error_msg = 'Invalid input response. Please reload the page to retry.'
              error_msg = 'An error occurred, please try again!'
              window.location.reload()
              break
          }
          emitter.emit("NOTIFY_SNACKBAR", error_msg)
          break
        default:
          emitter.emit("NOTIFY_SNACKBAR", 'Server Error!')
          break
      }
    } else {
      // DOMException: Failed to execute 'setRequestHeader' on 'XMLHttpRequest'
      // console.log(error.response)
      emitter.emit("NOTIFY_SNACKBAR", 'Failed to execute')
    }
  }

  // ----------------------------- user auth -----------------------------
  static authName = 'J5y'
  // get user auth
  static authGet = () => {
    // let string = window.localStorage.getItem(this.authName)
    const cookies = new Cookies();
    let data = cookies.get(this.authName)
    // sometimes the sever error will return a wrong token, encodeURIComponent just protect the request can execute
    return 'Bearer ' + encodeURIComponent(data)
  }

  // set user auth
  static authSet = (data) => {
    this.authRemove()
    // window.localStorage.setItem(this.authName, JSON.stringify(data))
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    if (process.env.NODE_ENV === 'development') {
      cookies.set(this.authName, data, { path: '/', expires: nextYear });
    } else {
      let url = 'herdman.co.nz'
      //let arr = url.split("/");
      cookies.set(this.authName, data, { path: '/', domain: '.' + url, expires: nextYear });
    }
  }

  // remove user auth
  static authRemove = () => {
    const cookies = new Cookies();
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
      cookies.remove(this.authName, { path: '/', domain: '.' + constants.doamin_dtslive });
    }
    // let string = window.localStorage.removeItem(this.authName)
    cookies.remove(this.authName, { path: '/' });

    let url = 'herdman.co.nz'
    //let arr = url.split("/");
    cookies.remove(this.authName, { path: '/', domain: '.' + url });
  }

  // ----------------------------- Halo Dialog ------------------------------

  static getHaloDialog = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    if (cookies.get("haloSeen") === "true"){
      return "true"
    }
    else{
      cookies.set("haloSeen", "false", {path: '/', expires: nextYear});
    }
  }


  static setHaloDialog = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    cookies.set("haloSeen", "true", {path: '/', expires: nextYear});
  }

  static getMTDialog = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    if (cookies.get("milktechSeen") === "true"){
      return "true"
    }
    else{
      cookies.set("milktechSeen", "false", {path: '/', expires: nextYear});
    }
  }


  static setMTDialog = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    cookies.set("milktechSeen", "true", {path: '/', expires: nextYear});
  }

  // ----------------------------- current farm -----------------------------
  static farmName = 'J3h'
  // get user auth
  static farmGet = () => {
    // let string = window.localStorage.getItem(this.farmName)
    const cookies = new Cookies();
    let data = cookies.get(this.farmName)
    // sometimes the sever error will return a wrong token, encodeURIComponent just protect the request can execute
    // if (data) data = decodeURIComponent(data)
    if (!data) {
      data = {}
    }
    return JSON.stringify(data)
    if (data) {
      data = JSON.stringify(data)
      return data
    }
    else {
      return "{}"
    }

  }

  // set user auth
  static farmSet = (data) => {
    // window.localStorage.setItem(this.farmName, JSON.stringify(data))
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 100);
    const cookies = new Cookies();
    if (process.env.NODE_ENV === 'development') {
      cookies.set(this.farmName, JSON.stringify(data), { path: '/', expires: nextYear });
    } else {
      let url = window.location.href
      let arr = url.split("/");
      cookies.set(this.farmName, JSON.stringify(data), { path: '/', domain: '.herdman.co.nz', expires: nextYear });
    }
  }
  // get user a farm
  static farmGet1 = () => {
    let string = window.localStorage.getItem(this.farmName)
    return string
  }

  // set user a farm
  static farmSet1 = (data) => {
    if (data && data._id) {
      // let obj = {_id: data._id}
      window.localStorage.setItem(this.farmName, JSON.stringify(data))
    }
  }

  // remove user a farm
  static farmRemove = () => {
    window.localStorage.removeItem(this.farmName)
  }

  static isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}


export default Utils
