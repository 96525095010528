import React from 'react'
import { observer } from "mobx-react";
import { extendObservable, computed } from "mobx";

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
    })
  }

  @computed get getValueHeight() {
    let result = this.props.height - this.props.height * this.props.value / this.props.total
    if (result >= this.props.height * 0.95) {
      result = this.props.height * 0.95
    }
    return result
  }

  @computed get getValuePercentage() {
    let result = (this.props.value * 100 / this.props.total).toFixed()
    if (result > 100) result = 100
    if (isNaN(result)) result = ''
    return result
  }

  render() {
    return (<div className=' position-relative' style={{
      width: this.props.width,
      height: this.props.height,
      backgroundColor: 'white',
      border: '2px solid #bbb',
      borderRadius: '8px 8px 0 0',
    }}>
      <div style={{ height: this.getValueHeight ? this.getValueHeight : 0, borderBottom: '1px solid #ccc', backgroundColor: 'rgba(118, 218, 255, 0.4)' }}></div>
      <div className='text-center small position-absolute' style={{ width: '100%', top: '50%', color: 'black' }}>{this.getValuePercentage}%</div>
    </div>)
  }


}

export default observer(Component)
