import React from 'react'
import {observer} from "mobx-react"
import {extendObservable} from "mobx"
import {withRouter} from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";

class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {})
    this.initData()

    store.dashboard.menuActive = 'Settings'
    store.dashboard.navbarLeft = 'Settings'
  }

  initData = () => {

  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3'>
          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title="Herds"
            />
            <CardContent>
              <table className='table'>
                <tbody>
                <tr>
                  <th>Herd name</th>
                  <th>Manager name</th>
                  <th>Cows number</th>
                  <th>Milking window time</th>
                  <th className='text-center'>Edit</th>
                </tr>
                <tr>
                  <td>Some awesome name</td>
                  <td>Someone</td>
                  <td>257</td>
                  <td>Once per day</td>
                  <td className='text-center'>
                    <IconButton>
                      <i className="material-icons font-size-base">
                        border_color
                      </i>
                    </IconButton>
                  </td>
                </tr>
                <tr>
                  <td>Some awesome name</td>
                  <td>Someone</td>
                  <td>123</td>
                  <td>Once per day</td>
                  <td className='text-center'>
                    <IconButton>
                      <i className="material-icons font-size-base">
                        border_color
                      </i>
                    </IconButton>
                  </td>
                </tr>
                </tbody>
              </table>

            </CardContent>
            <CardActions>
              <Button size="small">+Add a Herd</Button>
            </CardActions>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title="Staff"
            />
            <CardContent>
              <table className='table'>
                <tbody>
                <tr>
                  <th>Staff name</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Edit</th>
                </tr>
                <tr>
                  <td>Some awesome name</td>
                  <td>Male</td>
                  <td>0000000@00.00</td>
                  <td>02100000000</td>
                  <td className='text-center'><i className="material-icons font-size-base">check_circle</i></td>
                  <td className='text-center'>
                    <IconButton>
                      <i className="material-icons font-size-base">
                        border_color
                      </i>
                    </IconButton>
                  </td>
                </tr>
                <tr>
                  <td>Some awesome name</td>
                  <td>Male</td>
                  <td>0000000@00.00</td>
                  <td>02100000000</td>
                  <td className='text-center'><i className="material-icons font-size-base">check_circle</i></td>
                  <td className='text-center'>
                    <IconButton>
                      <i className="material-icons font-size-base">
                        border_color
                      </i>
                    </IconButton>
                  </td>
                </tr>
                </tbody>
              </table>

            </CardContent>
            <CardActions>
              <Button size="small">+Add a Staff</Button>
            </CardActions>
          </Card>

        </div>
      </div>
    )
  }

}

export default withRouter(observer(Component))
