import React from 'react'
import store from "../../store/Store";
import DTS from "../../utility/DTS"

// 继承
class Component extends React.Component {
  constructor(props) {
    super(props)
    DTS.domainRedirect(1)
  }
}

export default Component
