import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import emitter from "../../utility/EventEmitter";
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import BaseDashboard from "./BasePromotion"


class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        password: {value: null, errorText: ''},
      },
      gRecaptchaResponse: null,
    })
  }

  handleChange = (successCallback) => {
    this.user.password.errorText = ''
    Validator.reset.validate({
      password: this.user.password.value,
    }, (errors) => {
      // console.log(errors)
      if (errors != null) {
        for (let o of errors) {
          switch (o.field) {
            case "password":
              this.user.password.errorText = o.message
              break;
            default:
              break
          }
          break;
        }
      } else if (successCallback) {
        successCallback()
      }
    })
  }

  reset = e => {
    this.handleChange(() => {
      const params = new URLSearchParams();
      params.append('type', 'USER_RESET_PASSWORD');
      params.append('gRecaptchaResponse', this.gRecaptchaResponse);
      params.append('user', JSON.stringify(this.user));
      params.append('token', this.props.match.params.token);
      Utils.httpPost(Utils.urlList.user, params, data => {
        // console.log(data)
        emitter.emit("NOTIFY_DIALOG", {
          title: 'Password has been reset',
          body: 'Enjoy it.',
          callbackOk: () => {
            this.props.history.push("/dts/login");
          },
          forceSelect: true,
        })
      })
    })
  }


  render() {


    return (

      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">

          <CssBaseline/>
          <div>
            <Typography component="h1" variant="h5">
              Reset my password
            </Typography>

            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                helperText={this.user.password.errorText}
                error={this.user.password.errorText !== ''}
                onChange={e => {
                  this.user.password.value = e.target.value
                  this.handleChange()
                }}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    this.reset()
                  }
                }}
              />
              <ReCAPTCHA verifyCallback={v => {
                this.gRecaptchaResponse = v
              }}/>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className="my-3"
                onClick={this.reset}
              >
                Reset my password
              </Button>
              <Grid container>
                <Grid item>

                </Grid>
              </Grid>
            </div>
          </div>

        </Container>
      </div>

    )
  }


}

export default observer(Login)
