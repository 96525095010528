import * as d3 from "d3";

class D3Utils {

  // draw a rect with raidus
  static  rightRoundedRect = (x, y, w, h, r, tl, tr, bl, br) => {
    let path;
    path = "M" + (x + r) + "," + y;
    path += "h" + (w - 2 * r);
    if (tr) {
      path += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
    } else {
      path += "h" + r;
      path += "v" + r;
    }
    path += "v" + (h - 2 * r);
    if (br) {
      path += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
    } else {
      path += "v" + r;
      path += "h" + -r;
    }
    path += "h" + (2 * r - w);
    if (bl) {
      path += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
    } else {
      path += "h" + -r;
      path += "v" + -r;
    }
    path += "v" + (2 * r - h);
    if (tl) {
      path += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
    } else {
      path += "v" + -r;
      path += "h" + r;
    }
    path += "z";
    return path;
  }

  // bg linear color
  static resourceGradient = (svg) => {

    let a = d3.color("#3c3c3c")
    let b = d3.color("#787878")
    let linearGradient = svg.append("linearGradient")
      .attr("id", "resourceGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%")

    linearGradient.append("stop")
      .attr("offset", "0%")
      .style("stop-color", a.toString())

    linearGradient.append("stop")
      .attr("offset", "50%")
      .style("stop-color", b.toString())

    linearGradient.append("stop")
      .attr("offset", "100%")
      .style("stop-color", a.toString())

  }

  //  bg linear color resourceGradientRotate
  static resourceGradientRotate = (svg) => {

    let a = d3.color("#535353")
    let b = d3.color("#787878")
    let linearGradient = svg.append("linearGradient")
      .attr("id", "resourceGradientRotate")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%")
      .attr("gradientTransform", "rotate(45)")

    linearGradient.append("stop").attr("offset", "0%").style("stop-color", a.toString())

    linearGradient.append("stop").attr("offset", "25%").style("stop-color", b.toString())

    linearGradient.append("stop").attr("offset", "50%").style("stop-color", a.toString())

    linearGradient.append("stop").attr("offset", "75%").style("stop-color", b.toString())

    linearGradient.append("stop").attr("offset", "100%").style("stop-color", a.toString())
  }

  // get a arrow
  static resourceArrow = (svg) => {
    return svg.append("svg:defs").append("svg:marker")
      .attr("id", "resourceArrow")
      .attr("viewBox", "0 -5 10 10")
      .attr('refX', 0) //so that it comes towards the center.
      .attr("markerWidth", 5)
      .attr("markerHeight", 5)
      .attr("orient", "auto")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5")
      .style('fill', 'orange')
  }

}


export default D3Utils;
