import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import Utils from "../../utility/Utils";

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {})
    this.initData()
  }

  initData = () => {
    Utils.authRemove()
    window.location.href = "/dts/login"
  }

  render() {
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Component))
