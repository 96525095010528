class D3Tube {

  // draw a list tube
  static getTubePath = (list, tube) => {

    if (!list.length || list.length < 2) return

    const getDirectionSub = (before, after) => {
      let result = null
      if (Math.abs(before.x - after.x) > Math.abs(before.y - after.y)) { // horizontal
        if (before.x > after.x) {
          result = 'left'
        } else {
          result = 'right'
        }
      } else { // vertical
        if (before.y > after.y) {
          result = 'up'
        } else {
          result = 'down'
        }
      }
      return result
    }

    const getDirection = (i) => {
      let result = null
      let current = list[i]
      if (i === 0) { //  start
        let next = list[i + 1]
        result = getDirectionSub(current, next)
      } else if (i === list.length - 1) { //  end
        let before = list[i - 1]
        result = getDirectionSub(before, current)
      } else {
        let before = list[i - 1]
        let next = list[i + 1]
        result = getDirectionSub(before, current) + '-' + getDirectionSub(current, next)
      }
      return result
    }


    let path = ""
    for (let i = 0; i < list.length; i++) {
      let point = list[i]
      let pre = i === 0 ? 'M' : 'L'
      const direction = getDirection(i)
      switch (direction) {
        // ------------------------- 去程 端点 -------------------------
        case "right": // horizontal to left
          path += `${pre}${point.x} ${point.y - tube} `
          break
        case "down":
          path += `${pre}${point.x + tube} ${point.y} `
          break
        case "left":
          path += `${pre}${point.x} ${point.y + tube} `
          break
        case "up":
          path += `${pre}${point.x - tube} ${point.y} `
          break
        // ------------------------- 去程 顺时针 -------------------------
        case "right-down":
          path += `L${point.x - tube} ${point.y - tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${tube * 2},${tube * 2} `
          break
        case "down-left":
          path += `L${point.x + tube} ${point.y - tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${-tube * 2},${tube * 2} `
          break
        case "left-up":
          path += `L${point.x + tube} ${point.y + tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${-tube * 2},${-tube * 2} `
          break
        case "up-right":
          path += `L${point.x - tube} ${point.y + tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${tube * 2},${-tube * 2} `
          break
        // ------------------------- 去程 逆时针 -------------------------
        case "down-right":
          path += `L${point.x + tube} ${point.y - tube * 2} `
          path += `a${tube},${tube} 0 0,0 ${tube},${tube} `
          break
        case "right-up":
          path += `L${point.x - tube * 2} ${point.y - tube} `
          path += `a${tube},${tube} 0 0,0 ${tube},${-tube} `
          break
        case "up-left":
          path += `L${point.x - tube} ${point.y + tube * 2} `
          path += `a${tube},${tube} 0 0,0 ${-tube},${-tube} `
          break
        case "left-down":
          path += `L${point.x + tube * 2} ${point.y + tube} `
          path += `a${tube},${tube} 0 0,0 ${-tube},${tube} `
          break
        default:
          break
      }
    }
    for (let i = list.length - 1; i >= 0; i--) {
      let point = list[i]
      const direction = getDirection(i)
      switch (direction) {
        // ------------------------- 回程 端点 -------------------------
        case "right":
          path += `L${point.x} ${point.y + tube} `
          break
        case "down":
          path += `L${point.x - tube} ${point.y} `
          break
        case "left":
          path += `L${point.x} ${point.y - tube} `
          break
        case "up":
          path += `L${point.x + tube} ${point.y} `
          break
        // ------------------------- 回程 顺时针 -------------------------
        case "right-down":
          path += `L${point.x - tube} ${point.y + tube * 2} `
          path += `a${tube},${tube} 0 0,0 ${-tube},${-tube} `
          break
        case "down-left":
          path += `L${point.x - tube * 2} ${point.y - tube} `
          path += `a${tube},${tube} 0 0,0 ${tube},${-tube} `
          break
        case "left-up":
          path += `L${point.x + tube} ${point.y - tube * 2} `
          path += `a${tube},${tube} 0 0,0 ${tube},${tube} `
          break
        case "up-right":
          path += `L${point.x + tube * 2} ${point.y + tube} `
          path += `a${tube},${tube} 0 0,0 ${-tube},${tube} `
          break
        // ------------------------- 回程 逆时针 -------------------------
        case "down-right":
          path += `L${point.x + tube} ${point.y + tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${-tube * 2},${-tube * 2} `
          break
        case "right-up":
          path += `L${point.x + tube} ${point.y - tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${-tube * 2},${tube * 2} `
          break
        case "up-left":
          path += `L${point.x - tube} ${point.y - tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${tube * 2},${tube * 2} `
          break
        case "left-down":
          path += `L${point.x - tube} ${point.y + tube} `
          path += `a${tube * 2},${tube * 2} 0 0,1 ${tube * 2},${-tube * 2} `
          break
        default:
          break
      }
    }

    return path

  }

  static tubeDraw = (g, list, tube, fill) => {
    let path = D3Tube.getTubePath(list, tube)
    if (!fill) fill = '#92C1FF'
    let stroke = '#6b676b'
    let strokeWidth = 0.4
    g.append('path')
      .attr('d', path)
      .style("fill", fill)
      .style('stroke', stroke)
      .style('stroke-width', strokeWidth)
  }

  // water line 自来水
  static tubeWater = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 10, y: 10},
      {x: 90, y: 10},
      {x: 90, y: 20},
      {x: 10, y: 20},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#c9bcff')

    g.append("line")
      .attr("x1", currentX - 15)
      .attr("y1", currentY + 10)
      .attr("x2", currentX)
      .attr("y2", currentY + 10)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX - 15)
      .attr("y", currentY + 5)
      .text('Cool water')

    g.append("line")
      .attr("x1", currentX + 5)
      .attr("y1", currentY + 20)
      .attr("x2", currentX - 10)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX - 15)
      .attr("y", currentY + 30)
      .text('Warm water')
  }

  // water line
  static tubeIce = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 30, y: 10},
      {x: 90, y: 10},
      {x: 90, y: 110},
      {x: 30, y: 110},
      {x: 30, y: 100},
      {x: 80, y: 100},
      {x: 80, y: 20},
      {x: 30, y: 20},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#92C1FF')

    g.append("line")
      .attr("x1", currentX + 71)
      .attr("y1", currentY + 45)
      .attr("x2", currentX + 71)
      .attr("y2", currentY + 60)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 62)
      .attr("y", currentY + 40)
      .text('Cool water')
      .attr("transform", "rotate(90, " + (currentX + 62) + ", " + (currentY + 40) + " )")


    g.append("line")
      .attr("x1", currentX + 98)
      .attr("y1", currentY + 65)
      .attr("x2", currentX + 98)
      .attr("y2", currentY + 50)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 105)
      .attr("y", currentY + 40)
      .text('Warm water')
      .attr("transform", "rotate(90, " + (currentX + 105) + ", " + (currentY + 40) + " )")
  }

  // water line
  static tubeIceTwoVat = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 30, y: 10},
      {x: 90, y: 10},
      {x: 90, y: 130},
      {x: 30, y: 130},
      {x: 30, y: 120},
      {x: 80, y: 120},
      {x: 80, y: 20},
      {x: 30, y: 20},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#92C1FF')

    g.append("line")
      .attr("x1", currentX + 71)
      .attr("y1", currentY + 45)
      .attr("x2", currentX + 71)
      .attr("y2", currentY + 60)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 62)
      .attr("y", currentY + 40)
      .text('Cool water')
      .attr("transform", "rotate(90, " + (currentX + 62) + ", " + (currentY + 40) + " )")


    g.append("line")
      .attr("x1", currentX + 98)
      .attr("y1", currentY + 65)
      .attr("x2", currentX + 98)
      .attr("y2", currentY + 50)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 105)
      .attr("y", currentY + 40)
      .text('Warm water')
      .attr("transform", "rotate(90, " + (currentX + 105) + ", " + (currentY + 40) + " )")
  }

  // water milk
  static tubeMilk = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 10, y: 10},
      {x: 300, y: 10},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#a5ff88')

    g.append("line")
      .attr("x1", currentX + 50)
      .attr("y1", currentY + 20)
      .attr("x2", currentX + 65)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 45)
      .attr("y", currentY + 30)
      .text('Warm milk')

    g.append("line")
      .attr("x1", currentX + 200)
      .attr("y1", currentY + 20)
      .attr("x2", currentX + 215)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 195)
      .attr("y", currentY + 30)
      .text('Cool milk')
  }

  // water milk
  static tubeMilkTwoVat = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 80, y: 85},
      {x: 120, y: 85},
      {x: 120, y: 10},
      {x: 275, y: 10},
      {x: 275, y: 100},
      {x: 276, y: 100},
      {x: 275, y: 100},
      {x: 275, y: 10},
      {x: 580, y: 10},
      {x: 580, y: 100},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#a5ff88')

    g.append("line")
      .attr("x1", currentX + 50)
      .attr("y1", currentY + 80)
      .attr("x2", currentX + 65)
      .attr("y2", currentY + 80)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 45)
      .attr("y", currentY + 90)
      .text('Warm milk')

    g.append("line")
      .attr("x1", currentX + 200)
      .attr("y1", currentY + 20)
      .attr("x2", currentX + 215)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 195)
      .attr("y", currentY + 30)
      .text('Cool milk')
  }

  // water refrigeration
  static tubeRefrigerationBase = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 10, y: 10},
      {x: 120, y: 10},
      {x: 120, y: 15},
      {x: 10, y: 15},
      {x: 10, y: 10},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 1, '#92C1FF')

    g.append("line")
      .attr("x1", currentX + 70)
      .attr("y1", currentY + 5)
      .attr("x2", currentX + 55)
      .attr("y2", currentY + 5)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("line")
      .attr("x1", currentX + 55)
      .attr("y1", currentY + 20)
      .attr("x2", currentX + 70)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 50)
      .attr("y", currentY + 27)
      .text('Base unit')
  }

  // water refrigeration
  static tubeRefrigerationSidewall = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 10, y: 10},
      {x: 120, y: 10},
      {x: 120, y: 15},
      {x: 10, y: 15},
      {x: 10, y: 10},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 1, '#92C1FF')

    g.append("line")
      .attr("x1", currentX + 70)
      .attr("y1", currentY + 5)
      .attr("x2", currentX + 55)
      .attr("y2", currentY + 5)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 50)
      .attr("y", currentY + 1)
      .text('Sidewall unit')

    g.append("line")
      .attr("x1", currentX + 55)
      .attr("y1", currentY + 20)
      .attr("x2", currentX + 70)
      .attr("y2", currentY + 20)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)
  }

  // water refrigeration
  static tubeRefrigeration2 = (g, currentX, currentY) => {
    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    let list = [
      {x: 10, y: 10},
      {x: 10, y: 42},
      {x: 120, y: 42},
      {x: 120, y: 10},
    ]
    D3Tube.tubeDraw(gg, list, 1, '#92C1FF')

    list = [
      {x: 0, y: 10},
      {x: 0, y: 52},
      {x: 130, y: 52},
      {x: 130, y: 10},
    ]
    D3Tube.tubeDraw(gg, list, 1, '#92C1FF')

    g.append("line")
      .attr("x1", currentX + 70)
      .attr("y1", currentY + 35)
      .attr("x2", currentX + 55)
      .attr("y2", currentY + 35)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 50)
      .attr("y", currentY + 30)
      .text('Cool water')

    g.append("line")
      .attr("x1", currentX + 55)
      .attr("y1", currentY + 58)
      .attr("x2", currentX + 70)
      .attr("y2", currentY + 58)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 50)
      .attr("y", currentY + 67)
      .text('Warm water')


    // draw a flash light: refrigeration input
    g.append("circle")
      .attr("cx", currentX + 35)
      .attr("cy", currentY + 42)
      .attr("r", 4)
      .attr('class', 'stroke-white e-sensor-green')
    // draw a flash light: refrigeration outout
    g.append("circle")
      .attr("cx", currentX + 35)
      .attr("cy", currentY + 52)
      .attr("r", 4)
      .attr('class', 'stroke-white e-sensor-green')
  }

  // water hot
  static tubeHotwater = (g, currentX, currentY) => {
    // water line
    let list = [
      {x: 10, y: 10},
      {x: 40, y: 10},
      {x: 40, y: 30},
    ]

    let gg = g.append('g').attr('transform', `matrix(1, 0, 0, 1, ${currentX}, ${currentY})`)

    D3Tube.tubeDraw(gg, list, 2, '#ff8784')

    g.append("line")
      .attr("x1", currentX + 10)
      .attr("y1", currentY)
      .attr("x2", currentX + 25)
      .attr("y2", currentY)
      .attr('class', 'stroke-light')
      .attr('marker-end', (d) => "url(#resourceArrow)")
      .style('stroke', 'orange')
      .style('stroke-width', 1)

    g.append("text")
      .attr("x", currentX + 10)
      .attr("y", currentY - 5)
      .text('Hot water(CIP)')
  }
}


export default D3Tube;
