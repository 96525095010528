import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import emitter from "../../utility/EventEmitter";
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import BaseDashboard from "./BasePromotion"
import { Divider } from '@material-ui/core';


class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {})
  }

  


  render() {
    const routeChange = () => {
      let path = 'https://www.halosystems.co.nz/welcome-dts'; 
      window.location.href = path;
    };

    return (

      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">

          <CssBaseline/>
          <div>
            <Typography component="h1" variant="h5">
              No Permission
            </Typography>

            <div className='py-3' style={{lineHeight: '2'}}>
              Looking for Milk Vat Monitoring? Click the Halo button or call HALO on 0800 425 679!
            </div>

            <div className='py-3'>
            <Button style={{margin: 'auto'}} height={'200'} width={500} startIcon={<img src={'https://static.herdman.co.nz/common/images/logo-halo.svg'}  style={{ maxWidth: '100%', height: 'auto', width: '200' }} />} color="primary" onClick={routeChange}></Button>
            </div>
            <Divider></Divider>
            <div className='py-3' style={{lineHeight: '2'}}>
              Looking for DTS Herd-Man? Thank you for signing up, you now need to be linked to a Herd-Man farm, please call DTS at 0800 500 387.
            </div>

            <Grid container>

              <Grid item>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="my-3"
                  onClick={() => {
                    Utils.authRemove()
                    window.location.href = "/dts/login"
                  }}
                >
                  Logout
                </Button>
              </Grid>
              <Grid item className='flex-grow-1'>
              </Grid>

            </Grid>

          </div>
        </Container>
      </div>

    )
  }


}

export default observer(Login)
