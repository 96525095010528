import React from 'react'
import { observer } from "mobx-react"
import { computed, extendObservable } from "mobx"
import { withRouter } from "react-router-dom"
import store from "../../store/Store";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { CardContent } from '@material-ui/core';
import Utils from "../../utility/Utils";
import DTS from "../../utility/DTS";
import VatVolumeLevel from '../dashboard/farmer/VatVolumeLevel'
import BaseDashboard from "../promotion/BasePromotion"
import { Link, Button, Snackbar } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Spin } from "antd";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import Switch from '@material-ui/core/Switch';

const tile = ' d-none'

const data_vats = {
  "Vat 1": {
    "Fridge 1": "ON",
    "Fridge 2": "OFF",
    "Agitator": "ON",
    "CIP": "OFF",
  },
  "Vat 2 ( Demo )": {
    "Fridge 1": "Unknown",
    "Fridge 2": "Unknown",
    "Agitator": "Manual Override",
    "CIP": "Manual Override",
  },
}

const state_on = (key) => {
  const dct = {
    'ON': <span style={{ color: '#f3bf38' }}>{key}</span>,
    'OFF': <span style={{ color: 'red' }}>{key}</span>,
    'Unknown': <span style={{ color: 'red' }}>{key}</span>,
    'Manual Override': <span style={{ color: '#ccc' }}>{key}</span>,
  }
  return dct[key]
}

const state_result = (data, key) => {
  const status = data[key + 1]
  switch (status) {
    case 1:
      return state_on('ON')
    case 0:
      return state_on('OFF')
  }
  return state_on('Unknown')
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

// const {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} = recharts;
const data = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 }
];

class Component extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      farm: {},
      knode: {},
      vats: [],
      tile: tile,
    })
    this.initData()
    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = ''
    store.showFarmList = true
    this.fieldays()
  }

  fieldays = () => {
    // if (store.current.user._id == '5dc8dc0021ca930cc14408f5') {
    //   this.tile = ' d-none1'
    // }
  }

  componentDidMount() {
    // console.log('Test a new Version')
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_DASHBOARD_GET');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_dashboard, params, data => {
      console.log(data)
      this.farm = data.farm;
      this.vats = data.vats;
      this.setDashboard()
      //this.initDataKnode()
      if (data.farm && data.farm.package === 'Vat-Man Auto') {
        this.tile = ' d-none1'
      }
    })
  }

  @computed get autoVats() {
    return this.vats.filter(x => x['vat_auto'] === true)
  }

  initDataKnode = () => {
    const params = new URLSearchParams();
    params.append('type', 'FARMER_DASHBOARD_GET_KNODE');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.farmer_dashboard, params, data => {
      // console.log(data)
      this.farm = data.farm;
      this.vats = data.vats;
      if (data.knode) this.knode = data.knode;
      this.setDashboard()
    })
  }

  setDashboard = () => {
    if (!this.farm) {
      return
    }
    store.dtslive_dashboard.milk = this.farm.is_milk_enabled !== 0
    if (!this.farm.knode) {
      return
    }
    store.dtslive_dashboard.water = this.farm.knode.is_water_enabled
    store.dtslive_dashboard.effluent = this.farm.knode.is_effluent_enabled
    store.dtslive_dashboard.land = this.farm.knode.is_land_enabled
    store.dtslive_dashboard.weather = this.farm.knode.is_weather_enabled
  }

  @computed get getDemoFarmName() {
    return DTS.getDemoFarmName(store.current.user)
  }

  @computed get getCombinedVolume() {
    if (!this.vats) return null
    let result = 0
    this.vats.forEach(item => {
      result += item.last_updated ? item.last_updated.milk_volume : 0
    })
    result = DTS.getVolumeRound(result)
    return result
  }

  @computed get getCombinedPower() {
    return this.farm && this.farm.power
  }

  @computed get getAlarmWater() {
    if (this.knode.Water && this.knode.Water != null && this.knode.Water != undefined && this.knode.Water.length > 0 && Symbol.iterator in Object(this.knode.Water)) {
      for (let item of this.knode.Water) {
        if (item.alarm_active) {
          return true
        }
      }
    }
    return false
  }

  getEffluentBody = (item) => {
    let res = ''
    if (item.metric_value_1 !== false && item.unit_1 !== null) {
      res = item.metric_value_1 + ' ' + item.unit_1
    } else if (item.type == 'TravellingIrrigator') {
      res = 'Stationary'
    } else if (item.type == 'PumpEffluentSurface') {
      res = 'Off'
    }
    return res
  }

  render() {
    return (
      <div className='container-fluid'>

        {/* Welcome */}
        <div className='row'>
          <div className='col'>
            <Card className='mt-3'>
              {/* <CardHeader subheader="Vat 1" className='border-bottom' /> */}
              {this.farm.fonterra_id ? <div className='p-3'>Hello <span className="text-primary">{store.current.user.firstName}</span>, welcome to the MilkHub Dashboard.</div> : null}
            </Card>
          </div>
        </div>

        {/* Milk */}
        <div className='row'>
          <div className='col'>
          <Card style={{ cursor: 'pointer' }} className='mt-3' onClick={() => {
                      location.href = 'https://herdman.co.nz/login.aspx?t_id=' + store.current.user.token_id
                    }}>
                      <CardHeader
                        avatar={<span className="material-icons">opacity</span>}
                        subheader={`MilkHub`}
                        className='border-bottom'
                      />
                      <CardContent>
                        <img height='128' alt="MilkHub" src={global.constants.api_server_resource + `/common/images/MilkHub-Logo.png`} />
                      </CardContent>
                    </Card>
          </div>
        </div>

        {/* Water */}
        {store.dtslive_dashboard.water && this.knode.Water ? <div className='row'>
          <div className='col'>
            <Card className='mt-3'>
              <CardHeader
                avatar={<span className="material-icons">water</span>}
                subheader={`Water`}
                className='border-bottom'
                action={
                  this.getAlarmWater ? <IconButton style={{ marginRight: '5px' }} size="small" className="text-secondary" aria-label="warning" onClick={() => {
                    location.href = 'https://app.dtslive.co.nz/water'
                  }}><span className="material-icons">warning</span></IconButton> : null
                } />
              <CardContent>
                <div className='row col-vertical-line'>
                  {store.dtslive_dashboard.water && this.knode.Water ? this.knode.Water.map((item, index) => {
                    return item.metric_value_1 ? <div key={index} className='col' style={{ cursor: 'pointer' }} onClick={() => {
                      location.href = item.url
                    }}>
                      {item.type != 'Tank' ? <div>
                        Asset: {item.asset_name} <br />
                        {/* Type: {item.type}<br /> */}
                        Value: {item.metric_value_1.toFixed(1)} {item.unit_1}
                      </div> : <div className='row' style={{ cursor: 'pointer' }} onClick={() => {
                        location.href = item.url
                      }}>
                        <div className='col'>
                          Asset: {item.asset_name} <br />
                          {/* Type: {item.type}<br /> */}
                          Value: {item.metric_value_1.toFixed(1)} {item.unit_1}
                        </div>
                        <div className='col'>
                          <BarChart width={30} height={80} data={[{ name: 'Water', uv: item.metric_value_1, pv: item.metric_value_1 > 100 ? 1 : 100 - item.metric_value_1 }]}>
                            <Bar dataKey='uv' stackId="a" fill='#f3bf38' />
                            <Bar dataKey="pv" stackId="a" fill="#bbbbbb" />
                          </BarChart>
                        </div>
                      </div>}
                    </div> : null
                  }) : null}
                </div>
              </CardContent>
            </Card>
          </div >
        </div > : null}

        {/* Effluent */}
        {store.dtslive_dashboard.effluent && this.knode.Effluent ? <div className='row'>
          <div className='col'>
            <Card className='mt-3'>
              <CardHeader
                avatar={<span className="material-icons">delete</span>}
                subheader={`Effluent`}
                className='border-bottom'
                action={
                  this.getAlarmWater ? <IconButton style={{ marginRight: '5px' }} size="small" className="text-secondary" aria-label="warning"><span className="material-icons">warning</span></IconButton> : null
                } />
              <CardContent>
                <div className='row col-vertical-line'>
                  {store.dtslive_dashboard.effluent && this.knode.Effluent ? this.knode.Effluent.map((item, index) => {
                    return <div style={{ cursor: 'pointer' }} key={index} className='col' onClick={() => {
                      location.href = item.url
                    }}>
                      Asset: {item.asset_name} <br />
                      {/* Type: {item.type}<br /> */}
                      <strong>{this.getEffluentBody(item)}</strong>
                    </div>
                  })
                    : null}
                </div>
              </CardContent>
            </Card>
          </div >
        </div > : null}

        {/* Land */}
        {store.dtslive_dashboard.land && this.knode.Land ? <div className='row'>
          <div className='col'>
            <Card className='mt-3'>
              <CardHeader
                avatar={<span className="material-icons">grass</span>}
                // title='Land'
                subheader={`${this.knode.Land[0].asset_name}`}
                className='border-bottom'
                action={
                  this.getAlarmWater ? <IconButton style={{ marginRight: '5px' }} size="small" className="text-secondary" aria-label="warning"><span className="material-icons">warning</span></IconButton> : null
                } />
              <CardContent>
                {store.dtslive_dashboard.land && this.knode.Land ? this.knode.Land.map((item, index) => {
                  return <div className='row col-vertical-line' key={index} key={index} style={{ cursor: 'pointer' }} onClick={() => {
                    location.href = item.url
                  }}>
                    <div className='col'>
                      Soil Temp @ 10cm: <br />
                      <strong>{item.metric_value_1 + ' ' + (item.unit_1 == '°' ? '°C' : item.unit_1)}</strong>
                    </div>
                    <div className='col'>
                      Soil Moisture Group @10cm<br />
                      <strong>{item.metric_value_2}</strong>
                    </div>
                  </div>
                })
                  : null}
              </CardContent>
            </Card>
          </div >
        </div > : null}

        {/* Weather */}
        {store.dtslive_dashboard.weather && this.knode.Weather && this.knode.Weather.length ? <div className='row'>
          <div className='col'>
            <Card className='mt-3'>
              <CardHeader
                avatar={<span className="material-icons">ac_unit</span>}
                // title={`Weather`}
                subheader={`${this.knode.Weather[0].asset_name}`}
                className='border-bottom'
                action={
                  this.getAlarmWater ? <IconButton style={{ marginRight: '5px' }} size="small" className="text-secondary" aria-label="warning"><span className="material-icons">warning</span></IconButton> : null
                } />
              <CardContent>
                {store.dtslive_dashboard.weather && this.knode.Weather ? this.knode.Weather.map((item, index) => {
                  return <div className='row col-vertical-line' key={index} style={{ cursor: 'pointer' }} onClick={() => {
                    location.href = item.url
                  }}>
                    <div className='col'>
                      Todays Rainfall: <br />
                      <strong>{item.metric_value_1.toFixed(1) + ' ' + item.unit_1}</strong>
                    </div>
                    <div className='col'>
                      Current Temp: <br />
                      <strong>{item.metric_value_2 ? item.metric_value_2.toFixed(1) : null}</strong>
                    </div>
                    <div className='col'>
                      Current Humidity: <br />
                      <strong>{item.metric_value_3 ? item.metric_value_3.toFixed(0) : null}</strong>
                    </div>
                    <div className='col'>
                      Wind Speed: <br />
                      <strong>{item.metric_value_4 ? item.metric_value_4.toFixed(1) : null}</strong>
                    </div>
                    <div className='col'>
                      Wind Direction: <br />
                      {/* http://snowfence.umn.edu/Components/winddirectionanddegrees.htm */}
                      <strong>{global.constants.wind_direction[item.metric_value_5 ? item.metric_value_5.toFixed(0) : null]}</strong>
                    </div>
                    <div className='col'>
                      Solar radiation: <br />
                      <strong>{item.metric_value_6}</strong>
                    </div>
                  </div>
                })
                  : null}
              </CardContent>
            </Card>
          </div >
        </div > : null}

        <div className='row mt-3'>
          <div className='col'>

          </div>
        </div>

      </div >
    )
  }
}

export default withRouter(observer(Component))
