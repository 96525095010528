import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
    })
  }


  componentDidMount() {
    
  }

  render() {
    return (
      <div className='text-center'>DTSLive</div>
    )
  }
}

export default withRouter(observer(Component))
