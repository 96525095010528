import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Checkbox, Typography } from '@material-ui/core';

class Component extends React.Component {

    constructor(props) {
        super(props)
        extendObservable(this, {
            supplyNumber: '',
            vatNumber: '',
            collections: [],

            vatSize: '',
            height: '',
            diameter: '',
            wedgeHeight: '',
            volumeOffset: '',

            vatSettingsJSON:'',
            resultValue:''
        })

        this.state = {
            errorFarm: false,
            loadedCollections: false,
            errorConfig: false,
            errorHeight: false,
            loadedConfig: false,
            confirmDialog: false,
            resultDialog: false,
            omit: false,
            adjust: false,
        }

        store.dashboard.menuActive = 'Calibration'
        store.dashboard.navbarLeft = 'Calibration'
        store.showFarmList = false
    }

    getRecentCollections = () => {
        if (this.supplyNumber && this.vatNumber){
            this.setState({errorFarm: false})

            // Reset Vat Settings
            this.vatSize = ''
            this.height = ''
            this.diameter = ''
            this.wedgeHeight = ''
            this.volumeOffset = ''
            this.vatSettingsJSON = ''
            this.setState({loadedConfig: false})

            // Request list of past collections compared to fonterra data
            this.collections = []
    
            const params = new URLSearchParams()
            params.append('type', 'COLLECTIONS')
            params.append('amount', 'RECENT')
            params.append('supplyNumber', this.supplyNumber)
            params.append('vatNumber', this.vatNumber)
    
            Utils.httpPost(Utils.urlList.calibration, params, data => {
                //console.log(data)
                if (data){
                    this.collections = data.slice().reverse()
                    this.setState({loadedCollections: true})
                    this.getVatConfig()
                }
            })
        }
        else this.setState({errorFarm: true})
    }

    getAllCollections = () => {
        if (this.supplyNumber && this.vatNumber){
            this.setState({errorFarm: false})

            // Reset Vat Settings
            this.vatSize = ''
            this.height = ''
            this.diameter = ''
            this.wedgeHeight = ''
            this.volumeOffset = ''
            this.vatSettingsJSON = ''
            this.setState({loadedConfig: false})
        
            // Request list of past collections compared to fonterra data
            this.collections = []
    
            const params = new URLSearchParams()
            params.append('type', 'COLLECTIONS')
            params.append('amount', 'ALL')
            params.append('supplyNumber', this.supplyNumber)
            params.append('vatNumber', this.vatNumber)
    
            Utils.httpPost(Utils.urlList.calibration, params, data => {
                //console.log(data)
                if (data){
                    this.collections = data.slice().reverse()
                    this.setState({loadedCollections: true})
                    this.getVatConfig()
                }
            })
        }
        else this.setState({errorFarm: true})
    }

    getVatConfig = () => {
        if (this.supplyNumber&& this.vatNumber){
            this.setState({errorFarm: false})

            this.vatSize = ''
            this.height = ''
            this.diameter = ''
            this.wedgeHeight = ''
            this.volumeOffset = ''
    
            // Request Vat Config from database
            const params = new URLSearchParams()
            params.append('type', 'VAT_CONFIG')
            params.append('supplyNumber', this.supplyNumber)
            params.append('vatNumber', this.vatNumber)
    
            Utils.httpPost(Utils.urlList.calibration, params, data => {
                if (data['vat_size']) this.vatSize = data['vat_size']
                if (data['vat_max_height']) this.height = data['vat_max_height']
                if (data['vat_diameter']) this.diameter = data['vat_diameter']
                if (data['wedgeHeight']) this.wedgeHeight = data['wedgeHeight']
                if (data['vat_volume_offset']) this.volumeOffset = data['vat_volume_offset']
                this.setState({loadedConfig: true})
            })
        }
        else this.setState({errorFarm: true})
    }

    getRadarHeight = () => {

        var radar_max_height = 0;
        
        this.collections.map((collection,i) => {
            if (!collection.omit && collection.end_adjust && collection.end_adjust.radar_distance > radar_max_height){
                radar_max_height = collection.end_adjust.radar_distance
            }
        })

        if (radar_max_height != 0) this.height = radar_max_height
    }

    setHeightToRadar = () => {        
        if (this.height){
            this.setState({errorHeight: false})

            this.collections.map((collection,i) => {
                if (!collection.omit && collection.end_adjust){
                    collection.end_adjust.radar_distance = this.height
                }
            })
        }
        else this.setState({errorHeight: true})
    }

    calculate = () => {

        if (this.height && this.diameter && this.wedgeHeight && this.volumeOffset){

            this.collections.map((collection,i) => {
                if (collection.start_adjust && collection.omit == false){
                    this.setState({errorConfig: false})

                    // Calculate volumes
                    collection.start_adjust.milk_level = Math.round(this.height - collection.start_adjust.radar_distance)

                    collection.start_adjust.milk_volume = Math.round(((collection.start_adjust.milk_level - parseInt(this.wedgeHeight)) * Math.PI * parseInt(this.diameter) * parseInt(this.diameter) / 4000000) + parseInt(this.volumeOffset))

                    collection.end_adjust.milk_level = Math.round(this.height - collection.end_adjust.radar_distance)

                    if (collection.end_adjust.milk_level < parseInt(this.wedgeHeight)) collection.end_adjust.milk_level = 0

                    if (collection.end_adjust.milk_level > 0){

                        collection.end_adjust.milk_volume = Math.round((((collection.end_adjust.milk_level - parseInt(this.wedgeHeight)) * Math.PI * parseInt(this.diameter) * parseInt(this.diameter)) / 4000000) + parseInt(this.volumeOffset))

                        if (collection.end_adjust.milk_volume < 0) collection.end_adjust.milk_volume = 0
                    }
                    else{
                        collection.end_adjust.milk_level = 0
                        collection.end_adjust.milk_volume = 0
                    }

                    // Calculate diameter / slope
                    collection.diameter = Math.round((2000 * Math.sqrt(collection.fonterra_volume-this.volumeOffset)) / (Math.sqrt(Math.PI) * Math.sqrt(collection.start_adjust.milk_level-this.wedgeHeight)))
                }
            })
        }
        else this.setState({errorConfig: true})
    }

    getAverageDiameter = () => {

        var count = 0
        var diameter = 0

        this.collections.map((collection,i) => {
            if (collection.start_adjust && collection.omit == false){
                diameter += collection.diameter
                count++
            }
        })

        this.diameter = Math.round(diameter / count)
    }

    applyVatSettings = () => {
        this.toggleDialog()
        this.vatSettingsJSON = '{"type":"vatSettings","id":'+this.vatNumber+',"volume":'+this.vatSize+',"height":'+this.height+',"diameter":'+this.diameter+',"wedgeHeight":'+this.wedgeHeight+',"volumeOffset":'+this.volumeOffset+'}'

        if (this.supplyNumber && this.vatNumber && this.vatSize && this.height && this.diameter && this.wedgeHeight && this.volumeOffset){

            // Submit Vat Settings to Particle through backend
            const params = new URLSearchParams()
            params.append('type', 'VAT_SETTINGS')
            params.append('supplyNumber', this.supplyNumber)
            params.append('vatNumber', this.vatNumber)
            params.append('vatSize', this.vatSize)
            params.append('height', this.height)
            params.append('diameter', this.diameter)
            params.append('wedgeHeight', this.wedgeHeight)
            params.append('volumeOffset', this.volumeOffset)

            Utils.httpPost(Utils.urlList.installer, params, data => {
                console.log(data)

                // Success
                if (data['return_value'] == 1){
                    this.resultValue = 'Success'
                }
                // Fail -- doesn't get actioned due to abort in backend from Particle error response.
                else{
                    if (data['connected']) this.resultValue = 'Fail, particle is connected.'
                    else this.resultValue = 'Fail, particle is not connected.'
                }
                this.toggleResultDialog()
            })
        }
    }

    toggleDialog = () => {
        if (this.supplyNumber && this.vatNumber && this.vatSize && this.height && this.diameter && this.wedgeHeight && this.volumeOffset){
            this.setState({confirmDialog: !this.state.confirmDialog, errorFarm: false, errorConfig: false})
        }
        else this.setState({errorFarm: true, errorConfig: true})
    }

    toggleResultDialog = () => {
        this.setState({resultDialog: !this.state.resultDialog})
    }

    render() {
        return (
            <div>
                <div className='bg-white'>
                    <Dialog
                        open={this.state.confirmDialog}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Are you sure you want to submit this calibration</DialogContentText>
                        </DialogContent>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.applyVatSettings}>
                            Submit
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.toggleDialog}>
                            Cancel
                        </Button>
                    </Dialog>
                    <Dialog
                        open={this.state.resultDialog}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{this.resultValue}</DialogContentText>
                        </DialogContent>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.toggleResultDialog}>
                            Ok
                        </Button>
                    </Dialog>
                    <Container component="main">
                        <div className="row">
                            <div className='container-fluid col-md-3'>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="supplyNumber"
                                    label="Supply Number (e.g. FON12345)"
                                    id="supplyNumber"
                                    value={this.supplyNumber}
                                    error={this.supplyNumber == '' && this.state.errorFarm}
                                    onChange={e => {
                                        this.supplyNumber = e.target.value.toUpperCase().trim()
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="vatNumber"
                                    label="Vat Number"
                                    id="vatNumber"
                                    value={this.vatNumber}
                                    error={this.vatNumber == '' && this.state.errorFarm}
                                    onChange={e => {
                                        this.vatNumber = e.target.value.toUpperCase().trim()
                                    }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    onClick={this.getRecentCollections}
                                >
                                    Load Recent Collections
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    onClick={this.getAllCollections}
                                >
                                    Load All Collections
                                </Button>
                            </div>
                            <div className='container-fluid col-md-3'>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="vatSize"
                                    label="Vat Size"
                                    id="vatSize"
                                    type="number"
                                    value={this.vatSize}
                                    error={this.vatSize == '' && this.state.errorConfig}
                                    onChange={e => {
                                        this.vatSize = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="blank"
                                    label=""
                                    id="blank"
                                    type="number"
                                    disabled
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    onClick={this.getVatConfig}
                                >
                                    Load Vat Config From Database
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    disabled={!this.state.loadedCollections}
                                    onClick={this.getRadarHeight}
                                >
                                    Get Max Height From Radar
                                </Button>
                            </div>
                            <div className='container-fluid col-md-3'>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="height"
                                    label="Height"
                                    id="height"
                                    type="number"
                                    value={this.height}
                                    error={this.height == '' && (this.state.errorConfig || this.state.errorHeight)}
                                    onChange={e => {
                                        this.height = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="diameter"
                                    label="Diameter"
                                    id="diameter"
                                    type="number"
                                    value={this.diameter}
                                    error={this.diameter == '' && this.state.errorConfig}
                                    onChange={e => {
                                        this.diameter = e.target.value
                                    }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    disabled={!this.state.loadedCollections}
                                    onClick={this.setHeightToRadar}
                                >
                                    Set All Radar After To Height
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    disabled={!this.state.loadedCollections}
                                    onClick={this.getAverageDiameter}
                                >
                                    Set Diameter To Average
                                </Button>
                            </div>
                            <div className='container-fluid col-md-3'>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="wedgeHeight"
                                    label="Wedge Height"
                                    id="wedgeHeight"
                                    type="number"
                                    value={this.wedgeHeight}
                                    error={this.wedgeHeight == '' && this.state.errorConfig}
                                    onChange={e => {
                                        this.wedgeHeight = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="my-2"
                                    name="volumeOffset"
                                    label="Volume Offset"
                                    id="volumeOffset"
                                    type="number"
                                    value={this.volumeOffset}
                                    error={this.volumeOffset == '' && this.state.errorConfig}
                                    onChange={e => {
                                        this.volumeOffset = e.target.value
                                    }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    disabled={!this.state.loadedCollections}
                                    onClick={this.calculate}
                                >
                                    Recalculate Volumes
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-1"
                                    onClick={this.toggleDialog}
                                >
                                    Apply Settings to Vat
                                </Button>
                            </div>
                        </div>
                        <div>
                            {this.vatSettingsJSON}
                        </div>
                    </Container>
                </div>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.adjust}
                                                onChange={e => {
                                                    this.setState({adjust : !this.state.adjust})
                                                }}
                                            />
                                        }
                                        label="Collection Start"
                                    />
                                </TableCell>
                                <TableCell>Radar Before</TableCell>
                                <TableCell>Milk Before</TableCell>
                                <TableCell>Volume Before</TableCell>
                                <TableCell>Radar After</TableCell>
                                <TableCell>Milk After</TableCell>
                                <TableCell>Volume After</TableCell>
                                <TableCell>Collection Volume</TableCell>
                                <TableCell>Fonterra Volume</TableCell>
                                <TableCell>Difference</TableCell>
                                <TableCell>Diameter</TableCell>
                                <TableCell>Collection Temp</TableCell>
                                <TableCell>Fonterra Temp</TableCell>
                                <TableCell>Difference</TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.omit}
                                                onChange={e => {
                                                    this.setState({omit : !this.state.omit})
                                                }}
                                            />
                                        }
                                        label="Omit"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.collections.map((collection,i) => {

                                var volumeOutOfBounds = false
                                var tempOutOfBounds = false
                                if (collection.start_adjust && collection.end_adjust && collection.fonterra_volume && collection.fonterra_temperature){

                                    var volumeLimit = (collection.start_adjust.milk_volume-collection.end_adjust.milk_volume)/100
                                    if (volumeLimit < 100) volumeLimit = 100
                                    if (Math.abs(collection.start_adjust.milk_volume-collection.end_adjust.milk_volume-collection.fonterra_volume) >= volumeLimit) volumeOutOfBounds = true

                                    var tempLimit = 0.3
                                    if (Math.abs(collection.start.temp1-collection.fonterra_temperature) >= tempLimit) tempOutOfBounds = true;
                                    var tempDiff = Math.round((collection.start.temp1-collection.fonterra_temperature + Number.EPSILON) * 100) / 100
                                }

                                if (collection.start_adjust && collection.end_adjust && (!this.state.omit || (this.state.omit && !collection.omit))) return (
                                    <TableRow key={i}>
                                        <TableCell>{moment.utc(collection.start_adjust.created_at).local().format(global.constants.date_format_utc)}</TableCell>
                                        <TableCell>{collection.start_adjust.radar_distance}</TableCell>
                                        <TableCell>{collection.start_adjust.milk_level}</TableCell>
                                        <TableCell>{collection.start_adjust.milk_volume}</TableCell>
                                        <TableCell>{collection.end_adjust.radar_distance}</TableCell>
                                        <TableCell>{collection.end_adjust.milk_level}</TableCell>
                                        <TableCell>{collection.end_adjust.milk_volume}</TableCell>
                                        <TableCell>{collection.start_adjust.milk_volume-collection.end_adjust.milk_volume}</TableCell>
                                        <TableCell>{collection.fonterra_volume}</TableCell>

                                        <TableCell
                                            style={{color:volumeOutOfBounds?'red':'black'}}
                                        >
                                            {collection.fonterra_volume && collection.start_adjust.milk_volume-collection.end_adjust.milk_volume-collection.fonterra_volume}
                                        </TableCell>

                                        <TableCell>{collection.diameter}</TableCell>

                                        <TableCell>{collection.start.temp1}</TableCell>
                                        <TableCell>{collection.fonterra_temperature}</TableCell>
                                        <TableCell
                                            style={{color:tempOutOfBounds?'red':'black'}}
                                        >
                                            {collection.fonterra_temperature && tempDiff}
                                        </TableCell>

                                        <TableCell>
                                            <Checkbox
                                                checked={collection.omit}
                                                onChange={e => {
                                                    collection.omit = e.target.checked
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                                else if (!collection.start_adjust && this.state.adjust) return(
                                    
                                    <TableRow key={i}>
                                        <TableCell>{moment.utc(collection.start.published_at).local().format(global.constants.date_format_utc)}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{collection.start.milk_volume}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{collection.end.milk_volume}</TableCell>
                                        <TableCell>{collection.start.milk_volume-collection.end.milk_volume}</TableCell>
                                        <TableCell>{collection.fonterra_volume}</TableCell>

                                        <TableCell
                                            style={{color:volumeOutOfBounds?'red':'black'}}
                                        >
                                            {collection.fonterra_volume && collection.start.milk_volume-collection.end.milk_volume-collection.fonterra_volume}
                                        </TableCell>

                                        <TableCell>{collection.diameter}</TableCell>

                                        <TableCell>{collection.start.temp1}</TableCell>
                                        <TableCell>{collection.fonterra_temperature}</TableCell>
                                        <TableCell
                                            style={{color:tempOutOfBounds?'red':'black'}}
                                        >
                                            {collection.fonterra_temperature && tempDiff}
                                        </TableCell>

                                        <TableCell>
                                            <Checkbox
                                                checked
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

export default withRouter(observer(Component))
