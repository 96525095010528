import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store";
import Utils from "../../utility/Utils";

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";

class Component extends React.Component {

    constructor(props) {
        super(props)

        extendObservable(this, {
            dialogTitle: '',
            dialogMessage: '',
            redirect: '',

            key: '',
            particle: null,
            farm: null,
            devices: null,

            imei: '',
            supplyNumber: '',
            productVariant: '',

            vat1Height: '', vat1OClock: '', vat1RadarSerial: '', vat1Manufacturer: '', vat1Serial: '', vat1DeviceID: '', vat1WedgeHeight: '',
            vat2Height: '', vat2OClock: '', vat2RadarSerial: '', vat2Manufacturer: '', vat2Serial: '', vat2DeviceID: '', vat2WedgeHeight: '',
            vat3Height: '', vat3OClock: '', vat3RadarSerial: '', vat3Manufacturer: '', vat3Serial: '', vat3DeviceID: '', vat3WedgeHeight: '',
            vat4Height: '', vat4OClock: '', vat4RadarSerial: '', vat4Manufacturer: '', vat4Serial: '', vat4DeviceID: '', vat4WedgeHeight: '',
        })

        this.state = {
            tab: 0,
            found: false,
            openDialog: false,
            lowConnectivity: false,
            error: false,

            vat1Diameter: '', vat1Volume: '', vat1ManualVatInput: true, vat1ManualRadarInput: true, vat1Auto: false, vat1Error: false, vat1Exists: false, vat1Adding: false,
            vat2Diameter: '', vat2Volume: '', vat2ManualVatInput: true, vat2ManualRadarInput: true, vat2Auto: false, vat2Error: false, vat2Exists: false, vat2Adding: false,
            vat3Diameter: '', vat3Volume: '', vat3ManualVatInput: true, vat3ManualRadarInput: true, vat3Auto: false, vat3Error: false, vat3Exists: false, vat3Adding: false,
            vat4Diameter: '', vat4Volume: '', vat4ManualVatInput: true, vat4ManualRadarInput: true, vat4Auto: false, vat4Error: false, vat4Exists: false, vat4Adding: false,
        }

        store.dashboard.menuActive = 'Vat Settings'
        store.dashboard.navbarLeft = 'Vat Settings'
        store.showFarmList = false
    }

    search = () => {

        if (this.imei != '' || this.supplyNumber != ''){
            this.setState({error: false})

            // API 
            const params = new URLSearchParams()
            params.append('type', 'DEVICE_SEARCH')
    
            params.append('imei',this.imei)
            params.append('supplyNumber',this.supplyNumber)
    
            Utils.httpPost(Utils.urlList.installer, params, data => {
                console.log(data)
    
                this.particle = data['particle']
                this.farm = data['farm']
                this.devices = data['devices']

                if (this.particle == null){

                    this.setState({openDialog:true})
                    this.dialogTitle = "Farm/Particle Not Found"
                    this.dialogMessage = "Please try again"
                    this.redirect = ''
                }
                else if (this.devices == null){

                    this.setState({openDialog:true})
                    this.dialogTitle = "Vats Not Setup For This Particle"
                    this.dialogMessage = "Please enter a new install - Redirecting"
                    this.redirect = 'newInstall'
                }
                else{

                    // Load Device
                    this.key = data['key']
                    if (this.key == 'farm') this.imei = this.particle.body.imei
                    else if (this.key == 'imei') this.supplyNumber = this.farm.fonterra_id
                    this.productVariant = this.farm.package
                    this.setState({lowConnectivity: this.farm.lowConnectivity})

                    // Unload Vats
                    this.setState({vat1Exists: false})
                    this.setState({vat2Exists: false})
                    this.setState({vat3Exists: false})
                    this.setState({vat4Exists: false})
    
                    // Load Vats
                    this.devices.map((device,i) => {
                        if (device.vat_number == 1){
                            this.setState({vat1Exists: true})

                            this.vat1DeviceID = device._id
                            this.vat1Height = (device.vat_max_height) ? device.vat_max_height : ''
                            this.vat1OClock = (device.oclock) ? device.oclock : ''
                            this.vat1RadarSerial = (device.radar_serial) ? device.radar_serial : ''
                            this.vat1Manufacturer = (device.vat_manufacturer) ? device.vat_manufacturer : ''
                            this.vat1Serial = (device.vat_serial) ? device.vat_serial : ''
                            this.vat1WedgeHeight = (device.wedgeHeight) ? device.wedgeHeight : ''

                            if (device.vat_diameter) this.setState({vat1Diameter: device.vat_diameter})
                            else this.setState({vat1Diameter: ''})
                            if (device.vat_size) this.setState({vat1Volume: device.vat_size})
                            else this.setState({vat1Volume: ''})
                            if (device.vat_auto) this.setState({vat1Auto: device.vat_auto})
                        }
                        else if (device.vat_number == 2){
                            this.setState({vat2Exists: true})

                            this.vat2DeviceID = device._id
                            this.vat2Height = (device.vat_max_height) ? device.vat_max_height : ''
                            this.vat2OClock = (device.oclock) ? device.oclock : ''
                            this.vat2RadarSerial = (device.radar_serial) ? device.radar_serial : ''
                            this.vat2Manufacturer = (device.vat_manufacturer) ? device.vat_manufacturer : ''
                            this.vat2Serial = (device.vat_serial) ? device.vat_serial : ''
                            this.vat2WedgeHeight = (device.wedgeHeight) ? device.wedgeHeight : ''

                            if (device.vat_diameter) this.setState({vat2Diameter: device.vat_diameter})
                            else this.setState({vat2Diameter: ''})
                            if (device.vat_size) this.setState({vat2Volume: device.vat_size})
                            else this.setState({vat2Volume: ''})
                            if (device.vat_auto) this.setState({vat2Auto: device.vat_auto})
                        }
                        else if (device.vat_number == 3){
                            this.setState({vat3Exists: true})

                            this.vat3DeviceID = device._id
                            this.vat3Height = (device.vat_max_height) ? device.vat_max_height : ''
                            this.vat3OClock = (device.oclock) ? device.oclock : ''
                            this.vat3RadarSerial = (device.radar_serial) ? device.radar_serial : ''
                            this.vat3Manufacturer = (device.vat_manufacturer) ? device.vat_manufacturer : ''
                            this.vat3Serial = (device.vat_serial) ? device.vat_serial : ''
                            this.vat3WedgeHeight = (device.wedgeHeight) ? device.wedgeHeight : ''

                            if (device.vat_diameter) this.setState({vat3Diameter: device.vat_diameter})
                            else this.setState({vat3Diameter: ''})
                            if (device.vat_size) this.setState({vat3Volume: device.vat_size})
                            else this.setState({vat3Volume: ''})
                            if (device.vat_auto) this.setState({vat3Auto: device.vat_auto})
                        }
                        else if (device.vat_number == 4){
                            this.setState({vat4Exists: true})

                            this.vat4DeviceID = device._id
                            this.vat4Height = (device.vat_max_height) ? device.vat_max_height : ''
                            this.vat4OClock = (device.oclock) ? device.oclock : ''
                            this.vat4RadarSerial = (device.radar_serial) ? device.radar_serial : ''
                            this.vat4Manufacturer = (device.vat_manufacturer) ? device.vat_manufacturer : ''
                            this.vat4Serial = (device.vat_serial) ? device.vat_serial : ''
                            this.vat4WedgeHeight = (device.wedgeHeight) ? device.wedgeHeight : ''

                            if (device.vat_diameter) this.setState({vat4Diameter: device.vat_diameter})
                            else this.setState({vat4Diameter: ''})
                            if (device.vat_size) this.setState({vat4Volume: device.vat_size})
                            else this.setState({vat4Volume: ''})
                            if (device.vat_auto) this.setState({vat4Auto: device.vat_auto})
                        }
                    })
                    this.setState({found:true})
                }
            })
        }
        else this.setState({error: true})
    }

    handleLowConnectivity = () => {
        this.setState({ lowConnectivity: !this.state.lowConnectivity })
    }

    addVat = () => {
        if (this.state.tab == 1) this.setState({vat1Adding: true})
        else if (this.state.tab == 2) this.setState({vat2Adding: true})
        else if (this.state.tab == 3) this.setState({vat3Adding: true})
        else if (this.state.tab == 4) this.setState({vat4Adding: true})
    }
    removeVat = () => {
        if (this.state.tab == 1) this.setState({vat1Adding: false})
        else if (this.state.tab == 2) this.setState({vat2Adding: false})
        else if (this.state.tab == 3) this.setState({vat3Adding: false})
        else if (this.state.tab == 4) this.setState({vat4Adding: false})
    }

    refresh = () => {
        this.setState({vat1ManualVatInput: true, vat1ManualRadarInput: true})
        if (this.key == 'imei') this.supplyNumber = ''
        else this.imei = ''
        this.search()
    }

    submit = () => {

        if (this.state.tab == 0){

            const params = new URLSearchParams()
            params.append('type', 'PARTICLE_CONFIG')

            params.append('key',this.key)

            params.append('particleID',this.particle._id)
            params.append('imei',this.imei)

            params.append('farmID',this.farm._id)
            params.append('supplyNumber',this.supplyNumber)

            params.append('productVariant',this.productVariant)
            params.append('lowConnectivity',this.state.lowConnectivity)

            Utils.httpPost(Utils.urlList.installer, params, data => {
                this.setState({openDialog:true})
                this.dialogTitle = 'Submit Particle Changes'
                this.dialogMessage = data
                this.redirect = 'refresh'
            })
        }
        else if (this.state.tab == 1){

            if (this.submitVat(this.vat1DeviceID, 1, this.state.vat1Diameter, this.state.vat1Volume, this.vat1Height, this.vat1Manufacturer, this.vat1Serial, this.vat1RadarSerial, this.vat1OClock, this.state.vat1ManualVatInput, this.vat1WedgeHeight, this.state.vat1ManualRadarInput, this.state.vat1Auto)){
                this.setState({vat1Exists: true, vat1Adding: false})
            }
            else this.setState({vat1Error: true})
        }
        else if (this.state.tab == 2){

            if (this.submitVat(this.vat2DeviceID, 2, this.state.vat2Diameter, this.state.vat2Volume, this.vat2Height, this.vat2Manufacturer, this.vat2Serial, this.vat2RadarSerial, this.vat2OClock, this.state.vat2ManualVatInput, this.vat2WedgeHeight, this.state.vat2ManualRadarInput, this.state.vat2Auto)){
                this.setState({vat1Exists: true, vat1Adding: false})
            }
            else this.setState({vat2Error: true})
        }
        else if (this.state.tab == 3){

            if (this.submitVat(this.vat3DeviceID, 3, this.state.vat3Diameter, this.state.vat3Volume, this.vat3Height, this.vat3Manufacturer, this.vat3Serial, this.vat3RadarSerial, this.vat3OClock, this.state.vat3ManualVatInput, this.vat3WedgeHeight, this.state.vat3ManualRadarInput, this.state.vat3Auto)){
                this.setState({vat1Exists: true, vat1Adding: false})
            }
            else this.setState({vat3Error: true})
        }
        else if (this.state.tab == 4){

            if (this.submitVat(this.vat4DeviceID, 4, this.state.vat4Diameter, this.state.vat4Volume, this.vat4Height, this.vat4Manufacturer, this.vat4Serial, this.vat4RadarSerial, this.vat4OClock, this.state.vat4ManualVatInput, this.vat4WedgeHeight, this.state.vat4ManualRadarInput, this.state.vat4Auto)){
                this.setState({vat1Exists: true, vat1Adding: false})
            }
            else this.setState({vat4Error: true})
        }

    }

    submitVat(deviceID,vatNumber,diameter,volume,height,manufacturer,serial,radarSerial,oclock,manualVatInput,wedgeHeight,manualRadarInput,auto){

        // Set Height
        if (!manualVatInput && diameter != '' && volume != ''){
            height = this.getKnownVatHeight(diameter,volume)
        }

        // Validation
        if (diameter == '' || volume == '' || height == '' || radarSerial == '') return false

        // Submit
        const params = new URLSearchParams()
        params.append('type', 'VAT_CONFIG')
        
        params.append('particleID',this.particle._id)
        params.append('farmID',this.farm._id)
        params.append('deviceID',deviceID)
        params.append('supplyNumber',this.supplyNumber)
        params.append('vatNumber',vatNumber)
        params.append('diameter',diameter)
        params.append('volume',volume.toString().replace(' Sidewall',''))
        params.append('height',height)
        params.append('manufacturer',manufacturer)
        params.append('serial',serial)
        params.append('radarSerial',radarSerial)
        params.append('auto',auto)

        if (manualRadarInput){
            params.append('wedgeHeight',wedgeHeight)
        }
        else{
            params.append('oclock',oclock)
        }

        Utils.httpPost(Utils.urlList.installer, params, data => {
            console.log(data)

            this.setState({openDialog:true})
            if (data['success']) this.dialogTitle = "Vat Update Success"
            else this.dialogTitle = "Vat Update Error"
            this.dialogMessage = data['result']
            this.redirect = ''
        })

        return true
    }

    getKnownVatHeight(diameter,volume) {
        if (diameter == '2337'){
            if (volume == '4500') return '1219'
            else if (volume == '5700') return '1500'
            else if (volume == '7800') return '2000'
            else if (volume == '9100') return '2438'
            else if (volume == '11000') return '2719'
            else if (volume == '12000') return '3000'
            else if (volume == '14000 Sidewall') return '3500'
            else if (volume == '16000 Sidewall') return '4000'
            else if (volume == '18000') return '4438'
        }
        else if (diameter == '2875'){
            if (volume == '11500') return '2000'
            else if (volume == '14000') return '2438'
            else if (volume == '16000') return '2719'
            else if (volume == '18000') return '3000'
            else if (volume == '18000 Sidewall') return '2750'
            else if (volume == '21500 Sidewall') return '3500'
            else if (volume == '24000 Sidewall') return '4000'
            else if (volume == '26000 Sidewall') return '4438'
            else if (volume == '30000 Sidewall') return '4719'
        }
        else return ''
    }

    handleDialogClose = () => {
        this.setState({openDialog:false})

        if (this.redirect == 'newInstall') window.location.href = '/dts/installer/new'
        else if (this.redirect =='refresh') window.location.href = '/dts/installer/config'
    }

    render() {
        return (
            <div className='container-fluid bg-white'>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        {this.dialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>{this.dialogMessage}</DialogContentText>
                    </DialogContent>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogClose}>
                        Ok
                    </Button>
                </Dialog>
                <Tabs
                    value={this.state.tab}
                    onChange={(e, newValue) => {
                        this.setState({ tab: newValue })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Device" />
                    {this.state.found && <Tab label="Vat 1" />}
                    {this.state.found && <Tab label="Vat 2" />}
                    {this.state.found && <Tab label="Vat 3" />}
                    {this.state.found && <Tab label="Vat 4" />}
                </Tabs>
                <Container component="main" maxWidth="xs">

                    {this.state.tab == 0 &&
                        <div className='container-fluid my-3'>
                            {!this.state.found && <div>Enter a Particle IMEI Number or Farm Supply Number to edit the Vat Settings or add a New Vat.</div>}
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled={this.key == 'imei'}
                                id="imei"
                                name="imei"
                                label="IMEI"
                                type="number"
                                value={this.imei}
                                error={this.imei == '' && this.supplyNumber == '' && this.state.error}
                                helperText={this.imei == '' && this.supplyNumber == '' && this.state.error  && "Please fill ONE of these fields"}
                                onChange={e => {
                                    this.imei = e.target.value
                                }}
                                onKeyUp={e => {
                                  if (e.keyCode === 13) this.search()
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled={this.key == 'farm'}
                                id="supplyNumber"
                                name="supplyNumber"
                                label="Supply Number (e.g. FON12345)"
                                value={this.supplyNumber}
                                error={this.imei == '' && this.supplyNumber == '' && this.state.error}
                                helperText={this.imei == '' && this.supplyNumber == '' && this.state.error  && "Please fill ONE of these fields"}
                                onChange={e => {
                                    this.supplyNumber = e.target.value.toUpperCase()
                                }}
                                onKeyUp={e => {
                                  if (e.keyCode === 13) this.search()
                                }}
                            />
                            
                            {this.state.found &&
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="productVariant"
                                    name="productVariant"
                                    label="Product Variant"
                                    value={this.productVariant}
                                    onChange={e => {
                                        this.productVariant = e.target.value
                                    }}
                                >
                                    <MenuItem key="Vat-Man Lite" value="Vat-Man Lite">Vat-Man Lite</MenuItem>
                                    <MenuItem key="Vat-Man Pro" value="Vat-Man Pro">Vat-Man Pro</MenuItem>
                                    <MenuItem key="Vat-Man Auto" value="Vat-Man Auto">Vat-Man Auto</MenuItem>
                                </TextField>
                            }
                        
                            {this.state.found &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.lowConnectivity}
                                            onChange={this.handleLowConnectivity}
                                            name="checkedLowConnectivity"
                                            color="primary"
                                        />
                                    }
                                    label="Select If Low Connectivity Install"
                                />
                            }
                        
                            {!this.state.found &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.search}>
                                    Search
                                </Button>
                            }
                            {!this.state.found && <div>- Using an IMEI Number will allow you to move a Vat-Man to a different farm.</div>}
                            {!this.state.found && <div>- Using a Supply Number will allow you to replace a Vat-Man on that farm.</div>}

                            {this.state.found &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.submit}>
                                    Submit Device Changes
                                </Button>
                            }
                            {this.state.found && this.key == 'farm' && <div>- Changing the IMEI Number will replace the Vat-Man on this farm. You must now submit the vat settings for each vat down to the Vat-Man.</div>}
                            {this.state.found && this.key == 'imei' && <div>- Changing the Supply Number will move this Vat-Man to the new farm.</div>}
                        </div>
                    }

                    {this.state.tab == 1 &&
                        <div className='container-fluid my-3'>
                            {(this.state.vat1Exists || this.state.vat1Adding) && <div>
                                {(!this.state.vat1Exists && this.state.vat1Adding) &&
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.removeVat}>
                                        Remove Vat 1
                                    </Button>
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.refresh}>
                                    Reset Changes
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat1ManualVatInput}
                                            onChange={e => {
                                                this.setState({ vat1ManualVatInput: e.target.checked, vat1Volume: '',})
                                            }}
                                        />}
                                    label="Toggle Manual Vat Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat1ManualVatInput}
                                    name="vat1Diameter"
                                    label="Vat Diameter (mm)"
                                    type="number"
                                    id="vat1Diameter"
                                    value={this.state.vat1Diameter}
                                    helperText={this.state.vat1Diameter == '' && this.state.vat1Error  && "Required Field"}
                                    error={this.state.vat1Diameter == '' && this.state.vat1Error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.setState({ vat1Diameter: e.target.value })
                                    }}
                                >
                                    <MenuItem key="2337" value="2337">2337</MenuItem>
                                    <MenuItem key="2875" value="2875">2875</MenuItem>
                                </TextField>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat1ManualVatInput}
                                    name="vat1Volume"
                                    label="Vat Capacity (L)"
                                    type="number"
                                    id="vat1Volume"
                                    value={this.state.vat1Volume}
                                    helperText={this.state.vat1Volume == '' && this.state.vat1Error  && "Required Field"}
                                    error={this.state.vat1Volume == '' && this.state.vat1Error}
                                    onChange={e => {
                                        this.setState({ vat1Volume: e.target.value })
                                    }}
                                >
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                    { this.state.vat1Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                                </TextField>
                                {this.state.vat1ManualVatInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="vat1Height"
                                        label="Height (mm)"
                                        type="number"
                                        id="vat1Height"
                                        value={this.vat1Height}
                                        helperText={this.vat1Height == '' && this.state.vat1Error && "Required Field"}
                                        error={this.vat1Height == '' && this.state.vat1Error}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat1Height = e.target.value
                                        }}
                                    />
                                }
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat1Manufacturer"
                                    label="Vat Manufacturer"
                                    id="vat1Manufacturer"
                                    value={this.vat1Manufacturer}
                                    onChange={e => {
                                        this.vat1Manufacturer = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat1Serial"
                                    label="Vat Serial Number"
                                    id="vat1Serial"
                                    value={this.vat1Serial}
                                    onChange={e => {
                                        this.vat1Serial = e.target.value
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.vat1Auto}
                                            onChange={() => {this.setState({ vat1Auto: !this.state.vat1Auto })} }
                                            name="checkedVat1Auto"
                                            color="primary"
                                        />
                                    }
                                    label="Select if this Vat has Vat-Man Auto"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat1ManualRadarInput}
                                            onChange={e => {
                                                this.setState({ vat1ManualRadarInput: e.target.checked })
                                            }}
                                        />}
                                    label="Toggle Manual Radar Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat1RadarSerial"
                                    label="Radar Serial Number"
                                    id="vat1RadarSerial"
                                    value={this.vat1RadarSerial}
                                    helperText={this.vat1RadarSerial == '' && this.state.vat1Error && "Required Field"}
                                    error={this.vat1RadarSerial == '' && this.state.vat1Error}
                                    onChange={e => {
                                        this.vat1RadarSerial = e.target.value
                                    }}
                                />
                                {this.state.vat1ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat1WedgeHeight"
                                        label="Wedgeheight"
                                        type="number"
                                        id="vat1WedgeHeight"
                                        value={this.vat1WedgeHeight}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat1WedgeHeight = e.target.value
                                        }}
                                    />
                                }
                                {!this.state.vat1ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat1OClock"
                                        label="Radar O'Clock Position"
                                        type="number"
                                        id="vat1OClock"
                                        value={this.vat1OClock}
                                        onChange={e => {
                                            if (e.target.value >= 0 && e.target.value <= 12) this.vat1OClock = e.target.value
                                        }}
                                    />
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.submit}>
                                    Submit Vat 1 Changes
                                </Button>
                                <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            </div>}
                            {(!this.state.vat1Exists && !this.state.vat1Adding) &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.addVat}>
                                    Add Vat 1
                                </Button>
                            }
                        </div>
                    }

                    {this.state.tab == 2 &&
                        <div className='container-fluid my-3'>
                            {(this.state.vat2Exists || this.state.vat2Adding) && <div>
                                {(!this.state.vat2Exists && this.state.vat2Adding) &&
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.removeVat}>
                                        Remove Vat 2
                                    </Button>
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.refresh}>
                                    Reset Changes
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat2ManualVatInput}
                                            onChange={e => {
                                                this.setState({ vat2ManualVatInput: e.target.checked, vat2Volume: '',})
                                            }}
                                        />}
                                    label="Toggle Manual Vat Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat2ManualVatInput}
                                    name="vat2Diameter"
                                    label="Vat Diameter (mm)"
                                    type="number"
                                    id="vat2Diameter"
                                    value={this.state.vat2Diameter}
                                    helperText={this.state.vat2Diameter == '' && this.state.vat2Error  && "Required Field"}
                                    error={this.state.vat2Diameter == '' && this.state.vat2Error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.setState({ vat2Diameter: e.target.value })
                                    }}
                                >
                                    <MenuItem key="2337" value="2337">2337</MenuItem>
                                    <MenuItem key="2875" value="2875">2875</MenuItem>
                                </TextField>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat2ManualVatInput}
                                    name="vat2Volume"
                                    label="Vat Capacity (L)"
                                    type="number"
                                    id="vat2Volume"
                                    value={this.state.vat2Volume}
                                    helperText={this.state.vat2Volume == '' && this.state.vat2Error  && "Required Field"}
                                    error={this.state.vat2Volume == '' && this.state.vat2Error}
                                    onChange={e => {
                                        this.setState({ vat2Volume: e.target.value })
                                    }}
                                >
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                    { this.state.vat2Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                                </TextField>
                                {this.state.vat2ManualVatInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="vat2Height"
                                        label="Height (mm)"
                                        type="number"
                                        id="vat2Height"
                                        value={this.vat2Height}
                                        helperText={this.vat2Height == '' && this.state.vat2Error && "Required Field"}
                                        error={this.vat2Height == '' && this.state.vat2Error}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat2Height = e.target.value
                                        }}
                                    />
                                }
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat2Manufacturer"
                                    label="Vat Manufacturer"
                                    id="vat2Manufacturer"
                                    value={this.vat2Manufacturer}
                                    onChange={e => {
                                        this.vat2Manufacturer = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat2Serial"
                                    label="Vat Serial Number"
                                    id="vat2Serial"
                                    value={this.vat2Serial}
                                    onChange={e => {
                                        this.vat2Serial = e.target.value
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.vat2Auto}
                                            onChange={() => {this.setState({ vat2Auto: !this.state.vat2Auto })} }
                                            name="checkedVat2Auto"
                                            color="primary"
                                        />
                                    }
                                    label="Select if this Vat has Vat-Man Auto"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat2ManualRadarInput}
                                            onChange={e => {
                                                this.setState({ vat2ManualRadarInput: e.target.checked })
                                            }}
                                        />}
                                    label="Toggle Manual Radar Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat2RadarSerial"
                                    label="Radar Serial Number"
                                    id="vat2RadarSerial"
                                    value={this.vat2RadarSerial}
                                    helperText={this.vat2RadarSerial == '' && this.state.vat2Error && "Required Field"}
                                    error={this.vat2RadarSerial == '' && this.state.vat2Error}
                                    onChange={e => {
                                        this.vat2RadarSerial = e.target.value
                                    }}
                                />
                                {this.state.vat2ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat2WedgeHeight"
                                        label="Wedgeheight"
                                        type="number"
                                        id="vat2WedgeHeight"
                                        value={this.vat2WedgeHeight}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat2WedgeHeight = e.target.value
                                        }}
                                    />
                                }
                                {!this.state.vat2ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat2OClock"
                                        label="Radar O'Clock Position"
                                        type="number"
                                        id="vat2OClock"
                                        value={this.vat2OClock}
                                        onChange={e => {
                                            if (e.target.value >= 0 && e.target.value <= 12) this.vat2OClock = e.target.value
                                        }}
                                    />
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.submit}>
                                    Submit Vat 2 Changes
                                </Button>
                                <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            </div>}
                            {(!this.state.vat2Exists && !this.state.vat2Adding) &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.addVat}>
                                    Add Vat 2
                                </Button>
                            }
                        </div>
                    }

                    {this.state.tab == 3 &&
                        <div className='container-fluid my-3'>
                            {(this.state.vat3Exists || this.state.vat3Adding) && <div>
                                {(!this.state.vat3Exists && this.state.vat3Adding) &&
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.removeVat}>
                                        Remove Vat 3
                                    </Button>
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.refresh}>
                                    Reset Changes
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat3ManualVatInput}
                                            onChange={e => {
                                                this.setState({ vat3ManualVatInput: e.target.checked, vat3Volume: '',})
                                            }}
                                        />}
                                    label="Toggle Manual Vat Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat3ManualVatInput}
                                    name="vat3Diameter"
                                    label="Vat Diameter (mm)"
                                    type="number"
                                    id="vat3Diameter"
                                    value={this.state.vat3Diameter}
                                    helperText={this.state.vat3Diameter == '' && this.state.vat3Error  && "Required Field"}
                                    error={this.state.vat3Diameter == '' && this.state.vat3Error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.setState({ vat3Diameter: e.target.value })
                                    }}
                                >
                                    <MenuItem key="2337" value="2337">2337</MenuItem>
                                    <MenuItem key="2875" value="2875">2875</MenuItem>
                                </TextField>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat3ManualVatInput}
                                    name="vat3Volume"
                                    label="Vat Capacity (L)"
                                    type="number"
                                    id="vat3Volume"
                                    value={this.state.vat3Volume}
                                    helperText={this.state.vat3Volume == '' && this.state.vat3Error  && "Required Field"}
                                    error={this.state.vat3Volume == '' && this.state.vat3Error}
                                    onChange={e => {
                                        this.setState({ vat3Volume: e.target.value })
                                    }}
                                >
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                    { this.state.vat3Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                                </TextField>
                                {this.state.vat3ManualVatInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="vat3Height"
                                        label="Height (mm)"
                                        type="number"
                                        id="vat3Height"
                                        value={this.vat3Height}
                                        helperText={this.vat3Height == '' && this.state.vat3Error && "Required Field"}
                                        error={this.vat3Height == '' && this.state.vat3Error}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat3Height = e.target.value
                                        }}
                                    />
                                }
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat3Manufacturer"
                                    label="Vat Manufacturer"
                                    id="vat3Manufacturer"
                                    value={this.vat3Manufacturer}
                                    onChange={e => {
                                        this.vat3Manufacturer = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat3Serial"
                                    label="Vat Serial Number"
                                    id="vat3Serial"
                                    value={this.vat3Serial}
                                    onChange={e => {
                                        this.vat3Serial = e.target.value
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.vat3Auto}
                                            onChange={() => {this.setState({ vat3Auto: !this.state.vat3Auto })} }
                                            name="checkedVat3Auto"
                                            color="primary"
                                        />
                                    }
                                    label="Select if this Vat has Vat-Man Auto"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat3ManualRadarInput}
                                            onChange={e => {
                                                this.setState({ vat3ManualRadarInput: e.target.checked })
                                            }}
                                        />}
                                    label="Toggle Manual Radar Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat3RadarSerial"
                                    label="Radar Serial Number"
                                    id="vat3RadarSerial"
                                    value={this.vat3RadarSerial}
                                    helperText={this.vat3RadarSerial == '' && this.state.vat3Error && "Required Field"}
                                    error={this.vat3RadarSerial == '' && this.state.vat3Error}
                                    onChange={e => {
                                        this.vat3RadarSerial = e.target.value
                                    }}
                            />
                                {this.state.vat3ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat3WedgeHeight"
                                        label="Wedgeheight"
                                        type="number"
                                        id="vat3WedgeHeight"
                                        value={this.vat3WedgeHeight}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat3WedgeHeight = e.target.value
                                        }}
                                    />
                                }
                                {!this.state.vat3ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat3OClock"
                                        label="Radar O'Clock Position"
                                        type="number"
                                        id="vat3OClock"
                                        value={this.vat3OClock}
                                        onChange={e => {
                                            if (e.target.value >= 0 && e.target.value <= 12) this.vat3OClock = e.target.value
                                        }}
                                    />
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.submit}>
                                    Submit Vat 3 Changes
                                </Button>
                                <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            </div>}
                            {(!this.state.vat3Exists && !this.state.vat3Adding) &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.addVat}>
                                    Add Vat 3
                                </Button>
                            }
                        </div>
                    }

                    {this.state.tab == 4 &&
                        <div className='container-fluid my-3'>
                            {(this.state.vat4Exists || this.state.vat4Adding) && <div>
                                {(!this.state.vat4Exists && this.state.vat4Adding) &&
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="my-3"
                                        onClick={this.removeVat}>
                                        Remove Vat 4
                                    </Button>
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.refresh}>
                                    Reset Changes
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat4ManualVatInput}
                                            onChange={e => {
                                                this.setState({ vat4ManualVatInput: e.target.checked, vat4Volume: '',})
                                            }}
                                        />}
                                    label="Toggle Manual Vat Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat4ManualVatInput}
                                    name="vat4Diameter"
                                    label="Vat Diameter (mm)"
                                    type="number"
                                    id="vat4Diameter"
                                    value={this.state.vat4Diameter}
                                    helperText={this.state.vat4Diameter == '' && this.state.vat4Error  && "Required Field"}
                                    error={this.state.vat4Diameter == '' && this.state.vat4Error}
                                    onChange={e => {
                                        if (e.target.value >= 0) this.setState({ vat4Diameter: e.target.value })
                                    }}
                                >
                                    <MenuItem key="2337" value="2337">2337</MenuItem>
                                    <MenuItem key="2875" value="2875">2875</MenuItem>
                                </TextField>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    select={!this.state.vat4ManualVatInput}
                                    name="vat4Volume"
                                    label="Vat Capacity (L)"
                                    type="number"
                                    id="vat4Volume"
                                    value={this.state.vat4Volume}
                                    helperText={this.state.vat4Volume == '' && this.state.vat4Error  && "Required Field"}
                                    error={this.state.vat4Volume == '' && this.state.vat4Error}
                                    onChange={e => {
                                        this.setState({ vat4Volume: e.target.value })
                                    }}
                                >
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="4500" value="4500">4500</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="5700" value="5700">5700</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="7800" value="7800">7800</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="9100" value="9100">9100</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="11000" value="11000">11000</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="12000" value="12000">12000</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="14000 Sidewall" value="14000 Sidewall">14000 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="16000 Sidewall" value="16000 Sidewall">16000 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2337" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="11500" value="11500">11500</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="14000" value="14000">14000</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="16000" value="16000">16000</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="18000" value="18000">18000</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="18000 Sidewall" value="18000 Sidewall">18000 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="21500 Sidewall" value="21500 Sidewall">21500 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="24000 Sidewall" value="24000 Sidewall">24000 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="26000 Sidewall" value="26000 Sidewall">26000 Sidewall</MenuItem> }
                                    { this.state.vat4Diameter == "2875" && <MenuItem key="30000 Sidewall" value="30000 Sidewall">30000 Sidewall</MenuItem> }
                                </TextField>
                                {this.state.vat4ManualVatInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="vat4Height"
                                        label="Height (mm)"
                                        type="number"
                                        id="vat4Height"
                                        value={this.vat4Height}
                                        helperText={this.vat4Height == '' && this.state.vat4Error && "Required Field"}
                                        error={this.vat4Height == '' && this.state.vat4Error}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat4Height = e.target.value
                                        }}
                                    />
                                }
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat4Manufacturer"
                                    label="Vat Manufacturer"
                                    id="vat4Manufacturer"
                                    value={this.vat4Manufacturer}
                                    onChange={e => {
                                        this.vat4Manufacturer = e.target.value
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="vat4Serial"
                                    label="Vat Serial Number"
                                    id="vat4Serial"
                                    value={this.vat4Serial}
                                    onChange={e => {
                                        this.vat4Serial = e.target.value
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.vat4Auto}
                                            onChange={() => {this.setState({ vat4Auto: !this.state.vat4Auto })} }
                                            name="checkedVat4Auto"
                                            color="primary"
                                        />
                                    }
                                    label="Select if this Vat has Vat-Man Auto"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.vat4ManualRadarInput}
                                            onChange={e => {
                                                this.setState({ vat4ManualRadarInput: e.target.checked })
                                            }}
                                        />}
                                    label="Toggle Manual Radar Input"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="vat4RadarSerial"
                                    label="Radar Serial Number"
                                    id="vat4RadarSerial"
                                    value={this.vat4RadarSerial}
                                    helperText={this.vat4RadarSerial == '' && this.state.vat4Error && "Required Field"}
                                    error={this.vat4RadarSerial == '' && this.state.vat4Error}
                                    onChange={e => {
                                        this.vat4RadarSerial = e.target.value
                                    }}
                            />
                                {this.state.vat4ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat4WedgeHeight"
                                        label="Wedgeheight"
                                        type="number"
                                        id="vat4WedgeHeight"
                                        value={this.vat4WedgeHeight}
                                        onChange={e => {
                                            if (e.target.value >= 0) this.vat4WedgeHeight = e.target.value
                                        }}
                                    />
                                }
                                {!this.state.vat4ManualRadarInput &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="vat4OClock"
                                        label="Radar O'Clock Position"
                                        type="number"
                                        id="vat4OClock"
                                        value={this.vat4OClock}
                                        onChange={e => {
                                            if (e.target.value >= 0 && e.target.value <= 12) this.vat4OClock = e.target.value
                                        }}
                                    />
                                }
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.submit}>
                                    Submit Vat 4 Changes
                                </Button>
                                <img src="https://static.herdman.co.nz/common/images/radar_clock.png" style={{width:'100%'}}/>
                            </div>}
                            {(!this.state.vat4Exists && !this.state.vat4Adding) &&
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-3"
                                    onClick={this.addVat}>
                                    Add Vat 4
                                </Button>
                            }
                        </div>
                    }

                </Container>
            </div>
        )
    }
}

export default withRouter(observer(Component))
