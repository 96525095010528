import React, { Suspense } from 'react'
import { BrowserRouter, Route, Link, Switch, withRouter } from "react-router-dom";

import store from "../../store/Store";
import LinearProgress from "@material-ui/core/LinearProgress";
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Utils from "../../utility/Utils";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { Icon } from 'antd';
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import emitter from "../../utility/EventEmitter";
import Scrollbar from "react-scrollbars-custom";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../common/theme";
import { Select } from 'antd';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import MilkQualityData from "./MilkQualityData";
import FonterraProfile from "./FonterraProfile";

const SvgIcon = React.lazy(() => import('@material-ui/core/SvgIcon'));
const Dashboard = React.lazy(() => import('../dtslive/Dashboard'));

const { Option } = Select;

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      farm: {},
      isMenuAnchorEl: null,
      isMenuOpen: false,
      isRetired: false,
    })
    this.listMenuSystem = []
    this.initCurrentUser()
  }

  // init current user infor
  initCurrentUser = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_ONE_GET_BY_TOKEN');
    params.append('farm', Utils.farmGet());
    Utils.httpPost(Utils.urlList.user, params, data => {
      // console.log(data)
      if (!data || !data.user) {
        // console.log('Normally run here')
        let reurl = window.location.href
        reurl = reurl.substr(reurl.indexOf('/', 8))
        reurl = encodeURIComponent(reurl)
        // console.log(curUrl)
        window.location.href = '/dts/login/' + reurl
        return
      }
      this.initData(data)
    })
  }

  initData = (data) => {

    let list = [];
    if (data.user.role === 'Milk Quality' || data.user.role === 'Admin') {
      list.push({ name: 'Milk Quality', icon: 'cloud_download', url: '/dts/fonterra/milkquality', })
      list.push({ name: 'Divider', })
    }

    this.listMenuSystem = list
    store.current = data
  }

  getNavbarRightCompenent = () => {
    let result = null
    return result
  }

  userMenuOpen = (e) => {
    if (!this.isMenuAnchorEl) this.isMenuAnchorEl = e.currentTarget
    this.isMenuOpen = !this.isMenuOpen
  }

  profile = () => {
    this.userMenuOpen()
  }

  settings = () => {
    this.userMenuOpen()
  }

  logoutDialogOpen = () => {
    this.isMenuOpen = false
    emitter.emit("NOTIFY_DIALOG", {
      title: 'Are you sure you want to logout?',
      body: 'Once you quit, you will need to sign in again to use the DTS service.',
      callbackCancel: true,
      callbackOk: () => {
        Utils.authRemove()
        if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
          window.location.href = 'https://' + constants.doamin_dtslive + "/dts/login"
        } else {
          window.location.href = "/dts/login"
        }
      },
      forceSelect: false,
    })
  }

  render() {
    // console.log('Dashboard Render', store.current.user)
    return (<>
      {store.current.user && store.current.user._id ?
        <BrowserRouter>
          {store.progressOpen ? <LinearProgress className='progress' /> : null}
          <div className={`${store.dashboard.isExtend ? 'dashboard extend' : 'dashboard'}`}>

            <AppBar>
              <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={e => {
                  store.dashboard.isExtend = !store.dashboard.isExtend
                }}>
                  <i className="material-icons">menu</i>
                </IconButton>
                <Typography className='flex-grow-1'>
                  {global.constants.appname}
                </Typography>

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={this.userMenuOpen}
                  color="inherit"
                >
                  <i className="material-icons">person</i>
                </IconButton>
              </Toolbar>
            </AppBar>

            <Toolbar variant="dense" className='navbar'>
              <div className='navbar-logo'>
                <img width='32' height='32' alt="DTS" src={global.constants.api_server_resource + `/common/images/logo.png`} />
              </div>
              <div className='navbar-content px-3'>
                <div className='flex-grow-1' style={{ lineHeight: '36px' }}>
                  {store.dashboard.navbarLeft}
                </div>

                {store.dashboard.navbarRight}
                {this.getNavbarRightCompenent()}
              </div>
            </Toolbar>

            <div className='content'>

              <div className='siderbar'>
                <List component="nav" aria-label="main mailbox folders" className='menu'>
                  {this.listMenuSystem.map((item, index) => {
                    switch (item.name) {
                      case 'Divider':
                        return <div
                          key={index}
                          style={{ height: 0, overflow: 'hidden', borderBottom: '1px solid #ccc' }}>
                        </div>
                        break
                      case 'System':
                        return <div
                          key={index}
                          className='text-center'
                          style={{ width: '57px', color: '#999', paddingTop: '5px' }}>
                          {item.name}
                        </div>
                        break
                      case 'Farmer':
                        return <div
                          key={index}
                          className='text-center'
                          style={{ width: '57px', color: '#999', paddingTop: '10px' }}>
                          {item.name}
                        </div>
                        break
                      default:
                        return <ListItem key={index} component={Link} to={item.url}>
                          <ListItemIcon className={item.name === store.dashboard.menuActive ? 'active' : null}>
                            <Badge badgeContent={0} color="secondary">
                              <i className="material-icons">{item.icon}</i>
                            </Badge>
                          </ListItemIcon>
                          <ListItemText primary={item.name} className='nowrap' />
                        </ListItem>
                        break
                    }

                  })}
                  
                </List>
                <div className='bottom help-launcher'>
                  <Icon type="question-circle" className='d-none-1' />
                </div>

              </div>

              <Scrollbar mobileNative={true} className='body'>

                {!store.current.user ? null : <Suspense fallback={<div>Loading...</div>}><Switch>
                  
                  {/*milk quality data router*/}
                  <Route path="/dts/fonterra/milkquality" render={() => (<MilkQualityData {...this.props} />)} exact />
                  <Route path="/dts/fonterra/profile/:verification?" render={() => (<FonterraProfile {...this.props} />)} />

                </Switch></Suspense>}

              </Scrollbar>

            </div>

          </div>
          <MuiThemeProvider theme={theme}>
            <Menu
              anchorEl={this.isMenuAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.isMenuOpen}
              onClose={this.userMenuOpen}
            >
              {process.env.NODE_ENV && process.env.NODE_ENV !== 'development' ? <MenuItem onClick={this.profile} component="a" href={'https://' + constants.doamin_dtslive + '/dts/fonterra/profile'} className="py-2">
                <ListItemIcon>
                  <i className="material-icons">person</i>
                </ListItemIcon>
                {store.current.user.firstName} Profile
              </MenuItem> : <MenuItem onClick={this.profile} component={Link} to={'/dts/fonterra/profile'} className="py-2">
                  <ListItemIcon>
                    <i className="material-icons">person</i>
                  </ListItemIcon>
                  {store.current.user.firstName} Profile
              </MenuItem>}
              <Divider />
              <MenuItem onClick={this.logoutDialogOpen} className="py-2">
                <ListItemIcon>
                  <i className="material-icons">block</i>
                </ListItemIcon>
                {/*{store.current.user && store.current.user.firstName ? store.current.user.firstName : ''} Logout*/}
                Logout
              </MenuItem>
            </Menu>
          </MuiThemeProvider>
        </BrowserRouter>
        : null}
    </>)
  }

}

export default withRouter(observer(Component))
