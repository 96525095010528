import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Tools from "../../../utility/Tools";
import Highcharts from "highcharts";
import store from "../../../store/Store";
import ReactEcharts from 'echarts-for-react';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get getAlertText() {
    let result = ''
    let value = this.props.pickupEstCFU
    if (value <= 4.3) {
      result = 'Excellent'
    } else if (value > 4.3 && value <= 4.7) {
      result = 'Low risk'
    } else if (value > 4.7 && value <= 5) {
      result = 'Medium risk'
    } else if (value > 5 && value <= 6) {
      result = 'High risk'
    } else {
      result = 'Do not collect'
    }
    return result
  }

  @computed get getOptions() {

    let pickupEstCFU = this.props.pickupEstCFU
    if (!pickupEstCFU) {
      pickupEstCFU = 3
    }

    let option = {
      series: [
        {
          type: 'gauge',
          radius: '95%',
          startAngle: 180,
          endAngle: 0,
          splitNumber: 4,
          grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
          },
          min: 3,
          max: 6.2,
          axisLine: {
            lineStyle: {
              color: [
                [0.40, global.constants.color.fonterra.NORISK],
                [0.53, global.constants.color.fonterra.LOW],
                [0.62, global.constants.color.fonterra.MEDIUM],
                [0.93, global.constants.color.fonterra.HIGH],
                [1, global.constants.color.fonterra.CRITICAL],
              ],
              width: 20,
            }
          },
          splitLine: {
            show: true,
            length: 25,
            lineStyle: {
              color: 'auto'
            },
          },
          axisTick: {
            splitNumber: 10,
            length: 22,
            lineStyle: {
              color: 'auto'
            }
          },
          axisLabel: {
            textStyle: {
              color: 'auto'
            }
          },
          pointer: {
            width: 5
          },
          detail: {
            show: false,
            offsetCenter: [0, 30],
            // formatter: '{value}',
            formatter: value => {
              return value
            },
            textStyle: {
              color: 'auto',
              // fontWeight: 'bolder'
            }
          },
          data: [{ value: pickupEstCFU }]
        }
      ]
    }

    return option
  }


  render() {
    return (
      <div>

        <div className='d-flex px-3' style={{ height: 38, borderBottom: '1px solid #ddd' }}>
          <div className='d-flex flex-grow-1 align-items-center text-muted'>
            Quality Indicator
        </div>
          <div className='d-flex align-items-center justify-content-end text-primary'>
            {this.getAlertText}
          </div>
        </div>

        <ReactEcharts ref={this.refChart}
          style={{
            height: 255,
            width: '100%',
          }} option={this.getOptions} />
          
      </div>
    )
  }


}

export default observer(Component)
