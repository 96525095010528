import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {

    if (!this.props.storageList || !this.props.storageList.length) {
      return
    }

    let listData = []
    for (let i = 0; i < this.props.storageList.length; i++) {
      let color
      switch (i) {
        case 0:
          color = this.props.typeYAxis === 'TEMP' ? '#b7a55f' : '#51b76c'
          break
        default:
          color = this.props.typeYAxis === 'TEMP' ? '#b74e48' : '#4293b7'
          break
      }
      let storageList = this.props.storageList[i]
      for (let storage of storageList) {
        let listStorage = []
        // storage temp throw away last empty data
        let length = storage.detailData.length - 1
        for (let j = 0; j < length; j++) {
          let item = storage.detailData[j]
          if (item.temp1 === undefined || item.temp1 === null) {
            continue
          }

          if (item.milk_volume === undefined || item.milk_volume === null) {
            continue
          }

          if (item.state_machine === 'startup') {
            continue;
          }

          listStorage.push({
            activity: storage.activity,
            x: moment.utc(item.published_at).local().toDate(),
            y: parseFloat(this.props.typeYAxis === 'TEMP' ? item.temp1 : item.milk_volume),
          })
        }
        listData.push({
          showInLegend: false,
          turboThreshold: 10000,
          color: color,
          data: listStorage,
        })
      }
    }

    // console.log(listData)

    let plotOptions = {
      // area: {colorByPoint: true},
      series: {
        // color: this.props.typeYAxis === 'TEMP' ? '#b74e48' : '#2594b7',
        trackByArea: true,
        stickyTracking: false,
        cursor: 'pointer',
        events: {
          click: event => {
            // console.log(event);
            if (event.point.activity && event.point.activity._id) {
              this.props.history.push("/dts/storage/" + event.point.activity._id);
            }
          }
        }
      },
    }

    let unit = this.props.typeYAxis === 'TEMP' ? '°C' : 'L'

    let result = {
      credits: false,
      chart: {
        type: 'area',
        height: 300,
        // zoomType: 'x',
        // renderTo: 'container',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        title: { text: null },
      },
      yAxis: {
        // title: {text: this.props.typeYAxis === 'TEMP' ? 'Celsius °C' : 'Milk volume (L)'},
        title: { text: '' },
        min: 0,
        gridLineWidth: 1,
        lineWidth: 1,
        labels: {
          formatter: e => {
            if (this.props.typeYAxis === 'TEMP') {
              return e.value + '°C'
            } else {
              return e.value / 1000 + 'k'
            }
          }
        }

      },
      plotOptions: plotOptions,
      series: listData,
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment(this.x).format(global.constants.date_format_week)
          switch (this.key) {
            default:
              let val = this.y
              if (unit == 'L') {
                val = DTS.getVolumeRound(val)
              }

              return `<div>${xx} / ${val} ${unit}</div>`;
          }
        }
      }
    }
    return result
  }


  render() {

    return (

      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />

    )
  }


}

export default observer(Component)
