import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store";
import DTS from "../../utility/DTS";
import Utils from "../../utility/Utils";

import ActivityStorageWestlands from './ActivityStorageWestlands';
import BaseDashboard from "../dashboard/BaseDashboard";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';

class Component extends BaseDashboard {

    constructor(props) {
        super(props)
        extendObservable(this, {
            westlandsFarms: [],
            supplyNumber: '',
            vatNumber: '1',
            fromDate: '',
            toDate: '',
            data: null,
            agitatorData: null,
        })

        this.state = {
            tab: 0,
            loadedData: false,
            displayAgitator: true,
            displayBattery: true,
            displayOutage: true
        }

        store.dashboard.menuActive = 'Westlands Data'
        store.dashboard.navbarLeft = 'Westlands Data'
        //store.showFarmList = false

        this.initData();
    }

    initData = () => {
        // Get Westlands Farms
        const params = new URLSearchParams()
        params.append('type', 'WESTLANDS_FARMS')

        Utils.httpPost(Utils.urlList.milkquality, params, data => {
            console.log(data)
            data.slice().map((farm,i) => {
                console.log(farm.fonterra_id)
                this.westlandsFarms.push(farm.fonterra_id)
            })
        })
    }

    view = () => {
        this.supplyNumber = this.westlandsFarms[this.state.tab]

        // Validate inputs
        if (this.supplyNumber && this.vatNumber && this.fromDate && this.toDate){
            this.setState({error: false})

            // Clear current graph
            this.data = null
    
            // Allow fonterra supply numbers without FON in front
            var westlandsSupplyNumber = this.supplyNumber
            if (westlandsSupplyNumber.length == 3) westlandsSupplyNumber = 'WES'+westlandsSupplyNumber;
    
            // Get data
            const params = new URLSearchParams()
            params.append('type', 'WESTLANDS_DATA')
            params.append('supplyNumber', westlandsSupplyNumber)
            params.append('vatNumber', this.vatNumber)
            params.append('fromDate', moment(this.fromDate,'YYYY-MM-DD'))
            params.append('toDate', moment(this.toDate,'YYYY-MM-DD'))
    
            Utils.httpPost(Utils.urlList.milkquality, params, data => {
                this.data = data
                this.agitatorData = this.getAgitatorStorage(data)
                this.setState({loadedData: true})
            })
        }
        else this.setState({error: true})
    }

    getAgitatorStorage = (item) => {
      let agitator = {}
      if (item.detailData) {
        agitator = DTS.getAgitatorStopStat(item.detailData)
      }
      return agitator
    }

    download = () => {
        if (this.data && this.data.detailData){ // If data has been downloaded, create a csv file, and download to browser
            this.supplyNumber = this.westlandsFarms[this.state.tab]

            var csvContent = "data:text/csv;charset=utf-8,Date/Time, Volume, Vat temperature, Vat in temperature, Stirrer,\r\n";

            for (let item of this.data.detailData) {
                // Convert UTC Date to NZT
                var utc = moment.utc(item.published_at)
                var date = utc.local().format()

                // CSV Format: Date/Time, Volume, Vat temperature, Vat in temperature, Stirrer
                csvContent += date+','+item.milk_volume+','+item.temp1+','+item.temp2+','+item.agitator_status+',\r\n'
            }

            // Download File
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', this.supplyNumber+'-'+this.vatNumber+'_'+this.fromDate.replace('/','-')+'_'+this.toDate.replace('/','-')+'.csv');
            document.body.appendChild(link);
            link.click();
        }
    }

    /*

                            <TextField
                                variant="outlined"
                                fullWidth
                                className="my-2"
                                name="supplyNumber"
                                label="Supply Number"
                                id="supplyNumber"
                                value={this.supplyNumber}
                                error={!this.supplyNumber && this.state.error}
                                onChange={e => {
                                    this.supplyNumber = e.target.value.toUpperCase().trim()
                                }}
                            />
    */

    render(){
        return(
            <div className='bg-white'>
                <Container component="main">
                    <Tabs
                        value={this.state.tab}
                        onChange={(e, newValue) => {
                            this.setState({ tab: newValue })
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                    {this.westlandsFarms.map((farm,i) =>
                        <Tab key={i} label={farm}/>
                    )}
                    </Tabs>
                    <div className="row">
                        <div className='container-fluid col-xl-4'>
                            <TextField
                                variant="outlined"
                                fullWidth
                                className="my-2"
                                name="vatNumber"
                                label="Vat Number"
                                id="vatNumber"
                                value={this.vatNumber}
                                error={!this.vatNumber && this.state.error}
                                onChange={e => {
                                    this.vatNumber = e.target.value.trim()
                                }}
                            />
                        </div>
                        <div className='container-fluid col-xl-4'>
                            <TextField
                                variant="outlined"
                                fullWidth
                                className="my-2"
                                name="fromDate"
                                label="From"
                                InputLabelProps={{ shrink: true }}
                                id="fromDate"
                                type="date"
                                value={this.fromDate}
                                error={!this.fromDate && this.state.error}
                                onChange={e => {
                                    this.fromDate = e.target.value
                                }}
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                className="my-2"
                                name="toDate"
                                label="To"
                                InputLabelProps={{ shrink: true }}
                                id="toDate"
                                type="date"
                                value={this.toDate}
                                error={!this.toDate && this.state.error}
                                onChange={e => {
                                    this.toDate = e.target.value
                                }}
                            />
                        </div>
                        <div className='container-fluid col-xl-4'>
                            <Button
                                type="button"
                                variant="contained"
                                fullWidth
                                className="my-3"
                                color="primary"
                                onClick={this.view}>
                                View
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                fullWidth
                                className="my-3"
                                color="primary"
                                disabled={!this.state.loadedData}
                                onClick={this.download}>
                                Download
                            </Button>
                        </div>
                    </div>
                </Container>
                {this.data && <Card className='my-3'>
                    <CardContent className='p-x-0'>
                        <div className='d-flex nowrap justify-content-center' style={{ fontSize: '1rem', border: '0px solid #ddd' }}>
                            <div className='pl-2 py-1 border-top border-left border-bottom'>Agitator Stopped</div>
                            <div className='p-1 border-top border-right border-bottom' >
                                {this.state.displayAgitator && <div style={{ width: 30, backgroundColor: global.constants.color.agitator_stop, borderStyle:'outset'}} onClick={() => {this.setState({displayAgitator: false})}}>&nbsp;</div>}
                                {!this.state.displayAgitator && <div style={{ width: 30, backgroundColor: global.constants.color.agitator_stop, borderStyle:'inset'}} onClick={() => {this.setState({displayAgitator: true})}}>&nbsp;</div>}
                            </div>
                            <div className='pl-2 py-1 border-top border-bottom'>On Battery</div>
                            <div className='p-1 border-top border-right border-bottom' >
                                {this.state.displayBattery && <div style={{ width: 30, backgroundColor: global.constants.color.fonterra.MEDIUM, borderStyle:'outset'}} onClick={() => {this.setState({displayBattery: false})}}>&nbsp;</div>}
                                {!this.state.displayBattery && <div style={{ width: 30, backgroundColor: global.constants.color.fonterra.MEDIUM, borderStyle:'inset'}} onClick={() => {this.setState({displayBattery: true})}}>&nbsp;</div>}
                            </div>
                            <div className='pl-2 py-1 border-top border-bottom'>Data Outage</div>
                            <div className='p-1 border-top border-right border-bottom' >
                                {this.state.displayOutage && <div style={{ width: 30, backgroundColor: global.constants.color.fonterra.CRITICAL, borderStyle:'outset'}} onClick={() => {this.setState({displayOutage: false})}}>&nbsp;</div>}
                                {!this.state.displayOutage && <div style={{ width: 30, backgroundColor: global.constants.color.fonterra.CRITICAL, borderStyle:'inset'}} onClick={() => {this.setState({displayOutage: true})}}>&nbsp;</div>}
                            </div>
                        </div>
                        <ActivityStorageWestlands detailData={this.data.detailData} coolerData={this.data.coolerData} agitatorData={this.agitatorData} batteryData={this.data.batteryData} displayAgitator={this.state.displayAgitator} displayBattery={this.state.displayBattery} displayOutage={this.state.displayOutage} />
                    </CardContent>
                </Card>}
            </div>
        )
    }
}

export default withRouter(observer(Component))