import React from 'react'
import BaseDashboard from "./BaseDashboard";
import store from "../../store/Store";
import {extendObservable} from "mobx";

// 继承
class Component extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      iOS: false,
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.iOS = true
    }, 500)
  }

}

export default Component
