import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import BaseDashboard from "./BasePromotion"

class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: {value: null, errorText: ''},
        password: {value: null, errorText: ''},
        firstName: {value: null, errorText: ''},
        lastName: {value: null, errorText: ''},
        phone: {value: null, errorText: ''},
        subscribed: {value: false, errorText: ''},
      },
      recaptcha: React.createRef(),
      gRecaptchaResponse: null,
    })
  }

  handleChange = (successCallback) => {
    this.user.email.errorText = ''
    this.user.phone.errorText = ''
    this.user.password.errorText = ''
    Validator.registor.validate({
      email: this.user.email.value,
      phone: this.user.phone.value,
      password: this.user.password.value
    }, (errors) => {
      if (errors != null) {
        for (let o of errors) {
          switch (o.field) {
            case "email":
              this.user.email.errorText = o.message
              break;
            case "phone":
              this.user.phone.errorText = o.message
              break;
            case "password":
              this.user.password.errorText = o.message
              break;
            default:
              break
          }
          break;
        }
      } else if (successCallback) {
        successCallback()
      }
    })
  }

  signUp = () => {
    this.handleChange(() => {
      const params = new URLSearchParams();
      params.append('type', 'USER_SIGN_UP');
      params.append('gRecaptchaResponse', this.gRecaptchaResponse);
      params.append('user', JSON.stringify(this.user));
      Utils.httpPost(Utils.urlList.user, params, data => {
        // console.log(data)
        Utils.authSet(data)
        window.location.href = "/dts/dashboard"
      })
    })
    this.recaptcha.current.updateToken()
  }


  render() {

    return (

      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">

          <CssBaseline/>
          <div>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>

            <div>

              <div className="mt-3">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      onChange={e => {
                        this.user.firstName.value = e.target.value
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={e => {
                        this.user.lastName.value = e.target.value
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      helperText={this.user.email.errorText}
                      error={this.user.email.errorText !== ''}
                      onChange={e => {
                        this.user.email.value = e.target.value.trim()
                        this.handleChange()
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="phone"
                      label="Phone"
                      name="phone"
                      helperText={this.user.phone.errorText}
                      error={this.user.phone.errorText !== ''}
                      onChange={e => {
                        this.user.phone.value = e.target.value
                        this.handleChange()
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      helperText={this.user.password.errorText}
                      error={this.user.password.errorText !== ''}
                      onChange={e => {
                        this.user.password.value = e.target.value
                        this.handleChange()
                      }}
                      onKeyUp={e => {
                        if (e.keyCode === 13) {
                          this.signUp()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={this.user.subscribed.value} color="primary" onChange={e => {
                        this.user.subscribed.value = !this.user.subscribed.value
                      }}/>}
                      label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                  </Grid>
                </Grid>
                <ReCAPTCHA ref={this.recaptcha} verifyCallback={v => {
                  this.gRecaptchaResponse = v
                }}/>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="my-3"
                  onClick={this.signUp}
                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/dts/login" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

        </Container>
      </div>
    )
  }


}

export default observer(Login)
