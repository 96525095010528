import React from 'react'
import {observer} from "mobx-react"
import {extendObservable} from "mobx"
import {withRouter} from "react-router-dom"
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {DatePicker, Select} from "antd";
import moment from "moment";
import Card from "@material-ui/core/Card";
import BaseDashboardFarmer from "./BaseDashboardFarmer";
import store from "../../store/Store";

const {RangePicker} = DatePicker;
const {Option} = Select;


class Component extends BaseDashboardFarmer {

  constructor(props) {
    super(props)
    extendObservable(this, {
      listMilking: [
        {date: '1 hours ago', open: false,},
        {date: '1 day ago', open: false,},
        {date: '2 days ago', open: false,},
        {date: '17/08/2019 15:30', open: false,},
        {date: '16/08/2019 15:30', open: false,},
        {date: '15/08/2019 15:30', open: false,},
        {date: '14/08/2019 15:30', open: false,},
        {date: '13/08/2019 15:30', open: false,},
        {date: '12/08/2019 15:30', open: false,},
        {date: '11/08/2019 15:30', open: false,},
      ]
    })
    this.initData()
    const history = this.props.history

    store.dashboard.menuActive = 'Collections'
    store.dashboard.navbarLeft = 'Collections'
  }

  initData = () => {

  }

  render() {
    return (
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col'>
            <Card className='mb-3 p-3 text-right'>

              <RangePicker
                defaultValue={[moment('2019-08-01', global.constants.date_format_utc_date), moment('2019-08-31', global.constants.date_format_utc_date)]}
                format={global.constants.date_format_utc_date} onChange={this.onRangeChange}/>
            </Card>


            {this.listMilking.map((item, index) => <ExpansionPanel key={index} expanded={item.open} onChange={() => {
              item.open = !item.open
            }}>
              <ExpansionPanelSummary
                expandIcon={<i className='material-icons font-size-base'>keyboard_arrow_down</i>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>{item.date}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className='row'>
                <div className='col'>
                  <strong>Collection Amount:</strong> 120 L
                </div>
                <div className='col'>
                  <strong>Vat Avarege Temperature:</strong> 12 °C
                </div>
                <div className='col'>

                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>)}

          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(observer(Component))
