import React from 'react'
import { observer } from "mobx-react";
import { computed, extendObservable } from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Highcharts from "highcharts";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

class Component extends React.Component {

  constructor(props) {
    super(props)
    Highcharts.setOptions({
      time: {
        useUTC: false
      },
    })
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {

    let storageList = []
    let listPlotX = []
    let listDataVat = []
    let listDataInlet = []
    let start, end
    let start4 = null
    let start6 = null
    let stop2 = null

    if (this.props.storageObject.detailData && this.props.storageObject.detailData.length) {
      storageList = this.props.storageObject.detailData
      // after milking start 4 hours
      start = this.props.storageObject.activity.start.published_at
      end = this.props.storageObject.activity.end.published_at
      start4 = moment.utc(start).add(4, 'hours');
      start6 = moment.utc(start).add(6, 'hours');
      stop2 = moment.utc(end).add(2, 'hours');
    }

    for (let item of storageList) {

      if (item.temp1 === undefined || item.temp1 === null) {
        continue
      }

      if (item.state_machine === 'empty') {
        break
      }

      let x = moment.utc(item.published_at)
      let itemData = {
        x: x.toDate(),
        y: parseFloat(item.temp1),
        marker: global.constants.highcharts.marker,
      }
      // milking start
      if (moment.utc(item.published_at).isSame(moment.utc(start))) {
        listPlotX.push({
          color: '#918d91',
          dashStyle: 'longdashdot',
          width: 1,
          value: x,
          zIndex: 5,
        })
        itemData.name = '<h5>Milking start</h5>'
      }
      // milking stop
      else if (moment.utc(item.published_at).isSame(moment.utc(end))) {
        listPlotX.push({
          color: '#918d91',
          dashStyle: 'longdashdot',
          width: 1,
          value: x,
          zIndex: 5,
        })
        let duration = moment.duration(x.diff(moment.utc(start)))
        let humanize = duration.humanize(true)
        itemData.name = `<h5>Milking end</h5><h5>${humanize}</h5>`
      }
      // start 4
      else if (start4 && start4.isSameOrBefore(x)) {
        listPlotX.push({
          color: '#1300ab',
          dashStyle: 'longdashdot',
          width: 1,
          value: x,
          zIndex: 5,
        })
        start4 = null
        itemData.name = `<h5>4 hours after milking start</h5>`
      }
      // start 6
      else if (start6 && start6.isSameOrBefore(x)) {
        listPlotX.push({
          color: '#1300ab',
          dashStyle: 'longdashdot',
          width: 1,
          value: x,
          zIndex: 5,
        })
        start6 = null
        itemData.name = '<h5>6 hours after milking start</h5>'
      }
      // // stop 2
      // else if (stop2 && stop2.isSameOrBefore(x)) {
      //   listPlotX.push({
      //     color: '#1300ab',
      //     dashStyle: 'longdashdot',
      //     width: 1,
      //     value: x,
      //   })
      //   stop2 = null
      // }
      listDataVat.push(itemData)
      listDataInlet.push({
        x: x.toDate(),
        y: parseFloat(item.temp2),
        marker: global.constants.highcharts.marker,
      })
    }

    let listSeries = [{
      name: 'Milking cooling trends',
      data: listDataVat,
    }]

    // ------------------ agitator
    let listAgitator = [], listBandX = []
    if (this.props.agitator && this.props.agitator.listStop && this.props.agitator.listStop.length) {
      listAgitator = this.props.agitator.listStop
    }
    for (let item of listAgitator) {
      listBandX.push({
        from: moment.utc(item.start.published_at).local().toDate(),
        to: moment.utc(item.end.published_at).local().toDate(),
        color: global.constants.color.agitator_stop,
        events: {
          click: function (e) {
          },
          mouseover: function (e) {
          },
          mouseout: function (e) {
          },
          mousemove: function (e) {
          }
        }

      })
    }

    // ------------------
    let plotOptions = {
      // column: {colorByPoint: true},
      series: {
        color: '#2594b7',
        // cursor: 'pointer',
        point: {
          events: {
            click: () => {
              // this.props.history.push("/dts/milking/info");
            }
          }
        }
      },
    }

    // ------------------
    let listPlotY = [{
      color: '#FF0000',
      dashStyle: 'longdash',
      width: 1,
      value: 10,
    }, {
      color: '#FF0000',
      dashStyle: 'longdash',
      width: 1,
      value: 6,
    }]

    let result = {
      credits: false,
      chart: {
        type: 'line',
        height: 300,
        zoomType: 'x',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        plotLines: listPlotX,
        plotBands: listBandX,
        crosshair: true,
        ordinal: false,
        title: { text: null },
        labels: {},
      },
      yAxis: {
        title: { text: null },
        plotLines: listPlotY,
        tickInterval: 2,
        gridLineWidth: 0,
        lineWidth: 1,
        min: 0,
        max: 15,
        labels: {
          formatter: function () {
            if (this.value === 10 || this.value === 6) {
              return this.value + ' °C';
            } else {
              return null
            }
          }
        }
      },
      plotOptions: plotOptions,
      series: listSeries,
      tooltip: {
        useHTML: true,
        valueDecimals: 4,
        formatter: function () {
          let duration = moment.duration(moment(this.x).diff(moment.utc(start)))
          let humanize = duration.humanize(true)
          let result = this.point.name ? this.point.name : ''
          result += '<strong>' + DTS.getTemperature(this.y) + ' °C</strong> ' + humanize;
          return result
        },
      }
    }
    return result
  }


  render() {
    return (
      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />
    )
  }


}

export default observer(Component)
