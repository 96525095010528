import React from 'react'
import { observer } from "mobx-react"
import { extendObservable } from "mobx"
import { withRouter } from "react-router-dom"

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {})
    this.initData()
  }

  initData = () => {

  }

  render() {
    return (
      <div className='d-flex nowrap justify-content-center' style={{ fontSize: '1rem', border: '0px solid #ddd' }}>
        <div className='pl-2 py-1 border-top border-bottom border-left'>Agitator Outage:</div>
        <div className='pl-1 pr-2 py-1 border-top border-right border-bottom text-primary'>
          {this.props.agitator.periodStop} ({this.props.agitator.percent}%)
        </div>
        <div className='pl-2 py-1 border-top border-bottom'>Stopped</div>
        <div className='p-1 border-top border-right border-bottom' >
          <div style={{ width: 30, backgroundColor: global.constants.color.agitator_stop }}>&nbsp;</div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Component))
