import React from 'react'
import {observer} from "mobx-react";
import {computed, extendObservable} from "mobx";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import * as d3 from "d3"
import {withRouter} from "react-router-dom";

class Component extends React.Component {

  constructor(props) {
    super(props)
    extendObservable(this, {
      refChart: React.createRef(),
    })
  }

  @computed get optionsLevel() {
    let plotOptions = {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: event => {
              let url = "/dts/milking/" + event.point._id
              this.props.history.push(url)
              this.props.reloadData(event.point._id)
            }
          }
        }
      }
    }
    let listData = []
    if (this.props.milkingListBrief && this.props.milkingListBrief.length) {
      let speedMax = Number.MIN_SAFE_INTEGER, speedMin = Number.MAX_SAFE_INTEGER
      for (let item of this.props.milkingListBrief) {
        if (!item.end || !item.end.published_at) {
          continue
        }
        let start = moment.utc(item.start.published_at)
        let end = moment.utc(item.end.published_at)
        let seconds = moment.duration(end.diff(start)).asSeconds()
        let volume = parseFloat(item.end.milk_volume) - parseFloat(item.start.milk_volume)
        let speed = volume / seconds

        let color = '#507f87'
        if (this.props.storageObject.activity._id === item._id) {
          color = '#997431'
        }

        listData.push({
          x: start.toDate(),
          y: volume,
          color: color,
          marker: {radius: 15,},
          speed: speed,
          _id: item._id,
        })
        if (speed > speedMax) {
          speedMax = speed
        }
        if (speed < speedMin) {
          speedMin = speed
        }
      }
      let scale = d3.scaleLinear().domain([speedMin, speedMax]).range([10, 20]);
      listData.map(item => {
        item.marker = {
          radius: scale(item.speed),
        }
      })
    }

    let result = {
      credits: false,
      chart: {
        type: 'scatter',
        height: 300,
        zoomType: 'x',
        marginLeft: 50,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        title: {text: null},
      },
      yAxis: {
        title: {text: ''},
        min: 0,
      },
      plotOptions: plotOptions,
      series: [{
        name: "Milking volume comparison",
        data: listData,
      }],
      tooltip: {
        useHTML: true,
        formatter: function () {
          let xx = moment(this.x).format(global.constants.date_format_week)
          switch (this.key) {
            default:
              return `<div>${xx} / <strong>${Math.round(this.y)} L</strong></div>`;
          }
        }
      }
    }
    return result
  }


  render() {

    return (

      <HighchartsReact
        ref={this.refChart}
        options={this.optionsLevel}
      />

    )
  }


}

export default withRouter(observer(Component))
