global.constants = {
  appname: 'MilkHub',
  // timezone: 'Pacific/Auckland',
  api_server_prod: '',
  doamin_dtslive: 'login.herdman.co.nz',
  doamin_vatman: 'login.herdman.co.nz',
  doamin_knode: 'app.herdman.co.nz',
  api_server_resource: 'https://static.herdman.co.nz',
  google_recaptcha_key: '6Lfrd7EUAAAAAJdYrJGPsVhgifC9RpdBOLbOZPMX',
  google_login_clientid: '905371953717-qfh3eqjk0kvojvmk47if111e757li8pr.apps.googleusercontent.com',
  google_tag_manager: { gtmId: 'GTM-K4JN7ML' },
  // YYYY-MM-DDTHH:mm:ss.SSSSZ
  date_format_utc: 'YYYY-MM-DD HH:mm:ss',
  date_format_utc_date: 'YYYY-MM-DD',
  date_format_short: 'DD MMM YYYY HH:mm',
  date_format_date: 'DD MMM YYYY',
  date_format_week: 'ddd, Do MMMM HH:mm',
  color: {
    agitator_stop: '#ff9e77',
    temperature: '#ff5631',
    volume: '#ff91af',
    fonterra: {
      Indicator: '#62BD18',
      Alerts: '#DFF8CA',
      NORISK: '#70AD47',
      LOW: '#00B0F0',
      MEDIUM: '#FFDB01',
      HIGH: '#FF9900',
      CRITICAL: '#FF6666',
    }
  },
  highcharts: {
    marker: { enabled: false, enabledThreshold: 5, },
  },
  wind_direction: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
};
