import React from 'react'
import { observer } from "mobx-react";
import { extendObservable, computed } from "mobx";
import Utils from "../../utility/Utils";
import ReactTable from "react-table";

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import moment from "moment";
import { Link, withRouter } from "react-router-dom";

import ReactPlaceholder from 'react-placeholder';
import store from "../../store/Store";
import BaseDashboardAdmin from "./BaseDashboardAdmin";
import DTS from "../../utility/DTS";
import Button from "@material-ui/core/Button";
import ReactResizeDetector from 'react-resize-detector';
import jquery from 'jquery'
import EventEmitter from '../../utility/EventEmitter';
import Pagination from '@material-ui/lab/Pagination';
import { TextField } from '@material-ui/core';


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);


class StaffDeviceList extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    extendObservable(this, {
      isReady: false,
      httpCancel: null,
      sortDescAsc: false,
      sortFieldName: 'Last Seen',
      refTable: React.createRef(),
      isRetired: false,
      supply_id: '',
    })
    this.initData()

    store.dashboard.menuActive = 'Devices'
    store.dashboard.navbarLeft = 'Devices'
    store.dashboard.navbar_type = 'DEVICE_STATUS_ONLINE'
  }

  componentDidMount() {
    this.eventDeviceStatusChanged = EventEmitter.addListener("DEVICE_STATUS_CHANGED", (data) => {
      this.isRetired = data
      this.initData()
    })
  }

  componentWillUnmount() {
    if (this.httpCancel) {
      this.httpCancel.cancel()
    }
  }

  @computed get getEditTitle() {
    return this.isRetired ? 'Active' : 'Inactive'
  }

  initData = () => {
    this.sortDescAsc = false
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_LIST');
    params.append('isRetired', this.isRetired ? 2 : 1);
    this.httpCancel = Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      data.map(item => {
        if (!item.last_updated) {
          item.last_updated = {}
        }
        return item
      })
      store.listDevice = data
      this.listSortLastSeen()
      this.isReady = true
    })
  }

  deviceEdit = (device) => {
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_EDIT');
    params.append('device_id', device._id);
    params.append('status', this.isRetired ? 1 : 2);
    this.httpCancel = Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      this.initData()
    })
  }

  loadNameFromParticle = () => {
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_LOAD_NAME');
    this.httpCancel = Utils.httpPost(Utils.urlList.admin_device, params, data => {
      console.log(data)
      window.location.reload()
    })
  }

  // sort data by string
  listSortString = (fieldName, secondName) => {
    // debugger
    let list = store.listDevice.slice()
    list.sort((a, b) => {
      let bol
      if (this.sortDescAsc) {
        if (a[fieldName] === b[fieldName] && secondName) {
          bol = ('' + a[secondName]).localeCompare(b[secondName]);
        } else {
          bol = ('' + a[fieldName]).localeCompare(b[fieldName]);
        }
      } else {
        if (a[fieldName] === b[fieldName] && secondName) {
          bol = ('' + b[secondName]).localeCompare(a[secondName]);
        } else {
          bol = ('' + b[fieldName]).localeCompare(a[fieldName]);
        }
      }
      return bol
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  // sort data by number
  listSortNumber = (fieldName) => {
    let list = store.listDevice.slice()
    list.sort((a, b) => {
      let aa = parseFloat(a[fieldName])
      let bb = parseFloat(b[fieldName])
      if (!aa) aa = Number.MIN_VALUE
      if (!bb) bb = Number.MIN_VALUE
      let bol
      if (this.sortDescAsc) {
        bol = aa > bb
      } else {
        bol = bb > aa
      }
      return bol ? 1 : -1
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  // sort data by number
  listSortVersion = (fieldName) => {
    let list = store.listDevice.slice()

    // let arr = ['5.5.1', '4.21.0', '4.22.0', '6.1.0', '5.1.0', '4.5.0'];
    // arr = arr
    //   .map(a => a.split('.').map(n => +n + 100000).join('.')).sort()
    //   .map(a => a.split('.').map(n => +n - 100000).join('.'))
    // console.log(arr)

    list.sort(
      (a, b) => {
        if (this.sortDescAsc) {
          return a[fieldName].replace(/\d+/g, n => +n + 100000)
            .localeCompare(b[fieldName].replace(/\d+/g, n => +n + 100000)
            )
        } else {
          return b[fieldName].replace(/\d+/g, n => +n + 100000)
            .localeCompare(a[fieldName].replace(/\d+/g, n => +n + 100000)
            )
        }
      }
    )

    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  // fonterra sort
  listSortFonterra = () => {
    let list = store.listDevice.slice()
    // debugger
    list.sort((a, b) => {
      // debugger
      let bol
      let fonterra_id_a = DTS.getFonterraId(a.farm_id)
      let fonterra_id_b = DTS.getFonterraId(b.farm_id)
      if (this.sortDescAsc) {
        if (fonterra_id_a === fonterra_id_b) {
          bol = ('' + a.vat_number).localeCompare(b.vat_number);
        } else {
          bol = ('' + fonterra_id_a).localeCompare(fonterra_id_b);
        }
      } else {
        if (fonterra_id_a === fonterra_id_b) {
          bol = ('' + b.vat_number).localeCompare(a.vat_number);
        } else {
          bol = ('' + fonterra_id_b).localeCompare(fonterra_id_a);
        }
      }
      return bol
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }


  // last seen sort
  listSortLastSeen = () => {
    let list = store.listDevice.slice()
    list.sort((a, b) => {
      // debugger
      let bol
      let published_at_a = a.last_updated.published_at
      let published_at_b = b.last_updated.published_at
      published_at_a = moment.utc(published_at_a).unix()
      published_at_b = moment.utc(published_at_b).unix()

      if (a.last_updated.published_at === undefined) {
        published_at_a = Number.MIN_SAFE_INTEGER;
      }
      if (b.last_updated.published_at === undefined) {
        published_at_b = Number.MIN_SAFE_INTEGER;
      }

      if (this.sortDescAsc) {
        bol = published_at_a > published_at_b
      } else {
        bol = published_at_a < published_at_b
      }
      return bol ? 1 : -1
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  // last seen sort
  listSortLastUpdatedString = (lastUpdatedFieldName) => {
    let list = store.listDevice.slice()
    list.sort((a, b) => {
      // debugger
      let bol
      let field_a = a.last_updated[lastUpdatedFieldName]
      let field_b = b.last_updated[lastUpdatedFieldName]
      field_a = !field_a ? '' : field_a
      field_b = !field_b ? '' : field_b

      if (this.sortDescAsc) {
        bol = ('' + field_a).localeCompare(field_b);
      } else {
        bol = ('' + field_b).localeCompare(field_a);
      }
      return bol
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  // last seen sort
  listSortLastUpdatedNumber = (lastUpdatedFieldName) => {
    let list = store.listDevice.slice()
    list.sort((a, b) => {
      // debugger
      let bol
      let field_a = a.last_updated[lastUpdatedFieldName]
      let field_b = b.last_updated[lastUpdatedFieldName]
      field_a = !field_a ? 0 : field_a
      field_b = !field_b ? 0 : field_b

      if (this.sortDescAsc) {
        bol = field_a > field_b
      } else {
        bol = field_a < field_b
      }
      return bol ? 1 : -1
    })
    this.sortDescAsc = !this.sortDescAsc
    store.listDevice = list
  }

  alertDecimalToHex = (num) => {
    // console.log(num)
    if (num === undefined) {
      return '';
    }
    num = parseInt(num)
    let hexString = num.toString(16)
    hexString = hexString.padStart(8, '0')
    hexString = hexString.replace(/(.{2})/g, '$1 ').trim()
    return hexString
  }

  numberScale = (value, max, min) => {
    let result = value * 100 / (max - min)
    // console.log(result)
    if (result > 100) result = 100
    return result
  }

  numberColor = (value, red, orange, green, blue) => {
    let className = "progressGray"
    if (blue != null) {
      if (value > blue) className = '#progressBlue';
    }
    if (green != null) {
      if (value > green) className = 'progressGreen';
    }
    if (orange != null) {
      if (value > orange) className = 'progressOrange';
    }
    if (red != null) {
      if (value > red) className = 'progressRed';
    }
    return className
  }

  getColumnsSort = () => {
    return this.sortDescAsc ? '-desc' : '-asc'
  }

  getColumns = () => {
    return [
      {
        id: 'Name',
        Header: 'Name',
        accessor: item => <span>
          <Link to={`/dts/device/${item._id}`}>{item.name ? item.name : 'NONAME'}</Link>
          {/* <br /> */}
          {/* {item.particle_id} */}
        </span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Name' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Fonterra',
        Header: props => <div>Farm ID</div>,
        accessor: item => <span> {DTS.getFonterraId(item.farm_id)}- {item.vat_number ? item.vat_number : 'N/A'}</span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Fonterra' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Last Seen',
        Header: 'Last Seen',
        accessor: item => item.last_updated.published_at ? moment.utc(item.last_updated.published_at).local().fromNow() : '-',
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Last Seen' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Milk Level',
        Header: 'Milk Level',
        accessor: item => <span>
          {item.last_updated.milk_level}mm
                <BorderLinearProgress variant="determinate"
            className={this.numberColor(item.last_updated.milk_level, 180, 150, 0)}
            value={this.numberScale(item.last_updated.milk_level, 2000, 0)} />
        </span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Milk Level' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Vat Temp',
        Header: 'Vat Temp',
        accessor: item => <span>
          {item.last_updated.temp1}°C
                <BorderLinearProgress variant="determinate"
            className={this.numberColor(item.last_updated.temp1, 10, 6, 0, -10)}
            value={this.numberScale(item.last_updated.temp1, 20, -10)} />
        </span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Vat Temp' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Inlet Temp',
        Header: 'Inlet Temp',
        accessor: item => <span>
          {item.last_updated.temp2}°C
                <BorderLinearProgress variant="determinate"
            className="progressGray"
            value={this.numberScale(item.last_updated.temp2, 40, -10)} />
        </span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Inlet Temp' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'Shaft RPM',
        Header: 'Vibration',
        accessor: item => item.last_updated.shaft_rpm,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Shaft RPM' ? '-sort' + this.getColumnsSort() : '',
      },
      // {
      //   id: 'Firmware',
      //   Header: 'Firmware',
      //   accessor: item => <span>{item.firmware_version}</span>,
      //   className: 'text-center',
      //   headerClassName: this.sortFieldName === 'Firmware' ? '-sort' + this.getColumnsSort() : '',
      // },
      {
        id: 'Particle',
        Header: 'Particle OS',
        accessor: item => <span>OS {item.system_version}</span>,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'Particle' ? '-sort' + this.getColumnsSort() : '',
      },
      {
        id: 'State Machine',
        Header: 'State Machine',
        accessor: item => item.last_updated.state_machine,
        className: 'text-center',
        headerClassName: this.sortFieldName === 'State Machine' ? '-sort' + this.getColumnsSort() : '',
      },
      // {
      //   id: 'Last Alert',
      //   Header: 'Last Alert',
      //   accessor: item => this.alertDecimalToHex(item.last_updated.alert_status),
      //   className: 'text-center',
      //   headerClassName: this.sortFieldName === 'Last Alert' ? '-sort' + this.getColumnsSort() : '',
      // },
      {
        id: 'Edit',
        Header: `${this.getEditTitle}`,
        accessor: item => <i className='material-icons material-icons-align cursor-pointer' onClick={() => { }}>
          {item.status === 2 ? 'undo' : 'close'}
        </i>,
        className: 'text-center',
        maxWidth: 60,
      },
    ]
  }

  getData = () => {
    return store.listDevice.slice()
  }

  @computed get getDataFilter() {
    // return store.listDevice.slice()
    let listDeviceFilter = store.listDevice.slice().filter((device) => {
      // console.log(device)

      // Check for fault check string else return name filter
      if (this.supply_id.toLowerCase() == 'down' || this.supply_id.toLowerCase() == 'boards' || this.supply_id.toLowerCase() == 'sensors'){
        
        // Check if farm connection is down
        var down = (device.last_updated.published_at == null || moment.utc(device.last_updated.published_at).isBefore(moment().subtract(1,"days")))
        if (this.supply_id.toLowerCase() == 'down') return down
        else if (down) return false

        // Check if any boards are down
        var boards = (device.last_updated.milk_level == null || device.last_updated.temp1 == null || device.last_updated.temp2 == null || device.last_updated.temp5 == null || device.last_updated.shaft_rpm == null)
        if (this.supply_id.toLowerCase() == 'boards') return boards
        else if (boards) return false
        
        // Check if sensor values are out of expected bounds
        var sensors = (device.last_updated.temp1 < -5 || device.last_updated.temp2 < -5 || device.last_updated.temp5 < -5 || device.last_updated.shaft_rpm < 100)
        if (this.supply_id.toLowerCase() == 'sensors') return sensors
        else if (sensors) return false
      }
      else return device.name && device.name.toLowerCase().includes(this.supply_id.toLowerCase())
    });
    return listDeviceFilter
  }

  onResize = (e) => {
    jquery('.rt-thead').width(e - 2)
  }

  render() {
    // console.log('kdksfaddsf')

    return (

      <div className='container-fluid'>

        <div className='border my-3 bg-white p-1 text-right'>
          <TextField
            id="txt-supply-number"
            label="Supply Number"
            placeholder="Supply Number"
            variant="outlined"
            value={this.supply_id}
            onChange={e => this.supply_id = e.target.value.trim()}
          />
        </div>

        <div className='my-3 bg-white'>

          {/* <ReactPlaceholder ready={this.isReady} type='textRow' rows={10} showLoadingAnimation> */}
          {/* </ReactPlaceholder> */}

          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>

            <ReactTable
              ref={this.refTable}
              className='react-table-device-list'
              // data={store.listDevice.slice()}
              data={this.getDataFilter}
              columns={this.getColumns()}
              // pageSize={store.listDevice.slice().length < 4 ? 4 : store.listDevice.slice().length}
              // pageSize={20}
              // showPagination={null}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  },
                  onClick: (e, handleOriginal) => {
                    switch (column.id) {
                      case 'Edit':
                        // let item = store.listDevice.slice()[rowInfo.index]
                        let item = this.getDataFilter[rowInfo.index]
                        // console.log(item)
                        this.deviceEdit(item)
                        break
                      default:
                        if (handleOriginal) {
                          handleOriginal()
                        }
                        break
                    }
                  }
                }
              }}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                onClick: (e, handleOriginal) => {
                  column.headerClassName = '-sort-desc'
                  this.sortFieldName = column.id
                  switch (column.id) {
                    case 'Name':
                      this.listSortString('name')
                      break
                    case 'Fonterra':
                      this.listSortFonterra()
                      break
                    case 'Last Seen':
                      this.listSortLastSeen()
                      break
                    case 'Milk Level':
                      this.listSortLastUpdatedNumber('milk_level')
                      break
                    case 'Vat Temp':
                      this.listSortLastUpdatedNumber('temp1')
                      break
                    case 'Inlet Temp':
                      this.listSortLastUpdatedNumber('temp2')
                      break
                    case 'Shaft RPM':
                      this.listSortLastUpdatedNumber('shaft_rpm')
                      break
                    case 'Firmware':
                      this.listSortVersion('firmware_version')
                      break
                    case 'Particle':
                      this.listSortVersion('system_version')
                      break
                    case 'State Machine':
                      this.listSortLastUpdatedString('state_machine')
                      break
                    case 'Last Alert':
                      this.listSortLastUpdatedNumber('alert_status')
                      break
                  }
                  if (handleOriginal) {
                    handleOriginal()
                  }
                }
              })}
            />
          </ReactResizeDetector>

          {/* <div className='p-3 border-left border-right'>

            <Pagination count={10} variant="outlined" />

          </div> */}

          <div className='text-center p-5 border'>
            <Button color="primary" onClick={this.loadNameFromParticle}>
              Load name from Particle
            </Button>
          </div>

        </div>
      </div>
    )
  }


}

export default withRouter(observer(StaffDeviceList))
