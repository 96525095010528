import React from 'react'
import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import DeviceCurrentLevels from "./staff/DeviceCurrentLevels";
import DeviceCurrentDetail from "./staff/DeviceCurrentDetail";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import Utils from "../../utility/Utils";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FileDownload from "js-file-download"
import BaseDashboardAdmin from "./BaseDashboardAdmin";
import store from "../../store/Store";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class StaffDeviceInfo extends BaseDashboardAdmin {

  constructor(props) {
    super(props)
    extendObservable(this, {
      isLeft: false,
      isRight: false,
      listDays: [],
      dataRaws: {
        device: {},
        raws: [],
      },
    })
    this.initData()
  }

  initData = () => {
    let list = []
    for (let i = 0; i < 30; i++) {
      list.push({ days: i, name: 'RAW ' + moment().subtract(i, 'd').format(global.constants.date_format_utc_date) })
    }
    this.listDays = list
  }
  getRawData = (days) => {
    this.dataRaws = {
      device: {},
      raws: [],
    }
    let device_id = this.props.match.params.device_id
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_ONE_RAWS');
    params.append('device_id', device_id);
    params.append('days', days);
    Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      this.dataRaws = data
    })

  }

  downloadRawData = () => {
    let csvContent = this.dataRaws.raws.map(item => moment.utc(item.published_at).local().format(global.constants.date_format_utc) + ',' + this.dataRaws.device.name + ',' + JSON.stringify(item.raw)).join('\n');
    let csvFilename = moment.utc(this.dataRaws.raws[0].published_at).local().format(global.constants.date_format_utc_date) + '_' + this.dataRaws.device.name
    FileDownload(csvContent, 'RAW_' + csvFilename + '.csv');
  }

  setPageTitle = (title, farmId) => {
    store.dashboard.menuActive = 'Devices'
    store.dashboard.navbarLeft = (title ? title : 'NONAME') + ` / ${farmId}`
    store.dashboard.navbarRight = <Button color="primary" onClick={() => {
      this.isLeft = true
    }}>
      Raw Dumps
    </Button>
  }


  render() {

    return (
      <div className={`container-fluid` + (!this.iOS ? ' d-none' : '')}>

        <div className='my-3'>

          <DeviceCurrentLevels {...this.props} setPageTitle={this.setPageTitle}></DeviceCurrentLevels>
          <DeviceCurrentDetail {...this.props}></DeviceCurrentDetail>

        </div>

        <Drawer anchor="left" open={this.isLeft} onClose={() => {
          this.isLeft = false
        }}>
          <List style={{ width: '300px' }}>
            {this.listDays.map((text, index) => (
              <ListItem button key={index} className='text-center' onClick={() => {
                this.isRight = true
                this.getRawData(index)
              }}>
                <ListItemText primary={text.name} />
              </ListItem>
            ))}
          </List>
        </Drawer>

        <Dialog fullScreen open={this.isRight} onClose={() => {
          this.isRight = false
        }} TransitionComponent={Transition}>
          <AppBar>
            <Toolbar variant="dense">
              <IconButton edge="start" color="inherit" onClick={() => {
                this.isRight = false
              }} aria-label="close">
                <i className="material-icons">close</i>
              </IconButton>
              <Typography className='flex-grow-1'>

              </Typography>
              <Button color="inherit" onClick={this.downloadRawData}>
                Download
              </Button>
            </Toolbar>
          </AppBar>
          <ul style={{ marginTop: '50px' }}>
            {this.dataRaws.raws.map((text, index) => (
              <li key={index} style={{ maxWidth: '100%' }}>
                {moment.utc(text.published_at).local().format(global.constants.date_format_utc) + ' || ' + this.dataRaws.device.name + '||' + JSON.stringify(text.raw)}
              </li>
            ))}
          </ul>
        </Dialog>

      </div>
    )
  }


}

export default withRouter(observer(StaffDeviceInfo))
