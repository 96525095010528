import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Card} from "@material-ui/core";
import BaseDashboard from "./BasePromotion"


class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {})
  }


  render() {


    return (

      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">
          <div>
            <Typography component="h1" variant="h5">
              Find my password
            </Typography>
            <Card className='my-4 p-3 border border-primary'>
              We have sent you an email to reset your password, please check your email to complete the operation.
            </Card>
          </div>
        </Container>
      </div>

    )
  }


}

export default observer(Login)
