import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, toJS } from "mobx"
import { withRouter } from "react-router-dom"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import BaseDashboard from "./BaseDashboard";
import BasePromotion from "../promotion/BasePromotion";
import store from "../../store/Store";
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MuiThemeProvider, Dialog, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Input } from '@material-ui/core';
import theme from "../common/theme";
import Utils from "../../utility/Utils";
import emitter from "../../utility/EventEmitter"
//import MaskedInput from 'react-text-mask'
//import VerificationInput from 'react-verification-input'

class Component extends BasePromotion {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        subscribed: false,
        email_verify: 2,
        phone_verify: 2,
      },
      farms: [],
      products: {
        water: false,
        effluent: false,
        soil: false,
        weather: false,
        grain: false,
        asset: false,
        dairy: false,
      },
      password: '',
      password1: '',
      password2: '',
      isVerifyEmail: false,
      isVerifyPhone: false,
      verifyPhoneCode: '',
    })

    store.dashboard.menuActive = 'Dashboard'
    store.dashboard.navbarLeft = 'Enquiry'
    this.initData();
  }

  initData = () => {
    const params = new URLSearchParams();
    params.append('type', 'USER_PROFILE_DATAILS');
    Utils.httpPost(Utils.urlList.farmer_survey, params, data => {
      console.log(data)
      this.user = data.user
      this.farms = data.farms
    })
  }

  submit = () => {
    if (this.products.water || this.products.effluent || this.products.soil || this.products.weather || this.products.grain || this.products.asset || this.products.dairy) {
      const params = new URLSearchParams();
      params.append('type', 'SURVEY_SUBMIT');
      params.append('products', JSON.stringify(this.products));
      Utils.httpPost(Utils.urlList.farmer_survey, params, data => {
        // console.log(data)
        // if (data === 'Email verify success') {
        //   emitter.emit("NOTIFY_SNACKBAR", 'Email verification succeeded')
        // }
        emitter.emit("NOTIFY_SNACKBAR", 'Submit was successful, we will contact you soon.')
      })
    } else {
      emitter.emit("NOTIFY_SNACKBAR", 'Please select at least one option.')
    }
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='my-3'>
          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Profile"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="First Name"
                    margin="normal"
                    value={this.user.firstName}
                    onChange={e => { this.user.firstName = e.target.value }}
                  />
                </div>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="Last Name"
                    margin="normal"
                    value={this.user.lastName}
                    onChange={e => { this.user.lastName = e.target.value }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="Email Address"
                    margin="normal"
                    value={this.user.email}
                  />
                  <div className='text-button-right'>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                    fullWidth
                    disabled
                    label="Phone"
                    margin="normal"
                    value={this.user.phone}
                    onChange={e => {
                      this.user.phone = e.target.value
                      this.user.phone_verify = 1
                    }}
                  />
                  <div className='text-button-right'>
                  </div>
                </div>
              </div>

            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              subheader="Supply Number"
              className="pb-0"
            />
            <CardContent className='pt-0' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  {this.farms.map((item) => <FormControlLabel
                    key={item._id}
                    control={
                      <Checkbox
                        disabled
                        checked={true}
                        name="checkedF"
                      />
                    }
                    label={item.fonterra_id}
                  />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className='mb-3'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title='Choose and submit:'
              subheader="Interested in other monitoring solutions?"
              className="pb-0"
            />
            <CardContent className='pt-3' style={{ maxWidth: '600px' }}>
              <div className='row'>
                <div className='col'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.water}
                        onChange={e => this.products.water = !this.products.water}
                        name="checkedF"
                      />
                    }
                    label="Water"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.effluent}
                        onChange={e => this.products.effluent = !this.products.effluent}
                        name="checkedF"
                      />
                    }
                    label="Effluent"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.soil}
                        onChange={e => this.products.soil = !this.products.soil}
                        name="checkedF"
                      />
                    }
                    label="Soil"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.weather}
                        onChange={e => this.products.weather = !this.products.weather}
                        name="checkedF"
                      />
                    }
                    label="Weather"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.grain}
                        onChange={e => this.products.grain = !this.products.grain}
                        name="checkedF"
                      />
                    }
                    label="Grain Silo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.asset}
                        onChange={e => this.products.asset = !this.products.asset}
                        name="checkedF"
                      />
                    }
                    label="Asset Tracking"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.products.dairy}
                        onChange={e => this.products.dairy = !this.products.dairy}
                        name="checkedF"
                      />
                    }
                    label="Dairy Automation"
                  />
                </div>
              </div>
            </CardContent>
            <CardActions style={{ maxWidth: '600px' }}>
              <Button fullWidth variant="contained" color="secondary" onClick={this.submit}> Submit </Button>
            </CardActions>
          </Card>
        </div>

        <Dialog fullWidth open={this.isVerifyEmail} onClose={() => { this.isVerifyEmail = false }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Verify my Email</DialogTitle>
          <DialogContent className='pt-2 pb-5'>
            <DialogContentText>
              {this.user.email}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.isVerifyEmail = false }} variant="contained" color="secondary">
              Not now
            </Button>
            <Button onClick={this.sendVerifyEmail} variant="contained" color="primary">
              Send a Email to me
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={this.isVerifyPhone} onClose={() => { this.isVerifyPhone = false }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Verify my Phone Number</DialogTitle>
          <DialogContent className='pt-2 pb-5'>
            <DialogContentText>
              {this.user.phone}
            </DialogContentText>

            <div className='text-center'>
              <TextField
                type="number"
                fullWidth
                placeholder="000000"
                margin="normal"
                value={this.verifyPhoneCode}
                onChange={e => {
                  if (e.target.value < 0 || e.target.value.length > 6) { return }
                  this.verifyPhoneCode = e.target.value

                  if (this.verifyPhoneCode.length === 6) {
                    this.checkVerifyPhone()
                  }
                }}
                className='text-verify-code'
              />
              <div>Please enter verification code</div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.isVerifyPhone = false }} variant="contained" color="secondary">
              Not now
            </Button>
            <Button onClick={this.sendVerifyPhone} variant="contained" color="primary">
              Send a Code to me
            </Button>
          </DialogActions>
        </Dialog>


      </div >
    )
  }

}

export default withRouter(observer(Component))
