import React from 'react'
import { observer } from "mobx-react"
import { extendObservable, computed } from "mobx"
import { withRouter } from "react-router-dom"
import moment from "moment";
import store from "../../store/Store"
import Utils from "../../utility/Utils"

import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { TrendingUpRounded } from '@material-ui/icons'

class Component extends React.Component {

    constructor(props) {
        super(props)
        store.dashboard.menuActive = 'Watchman'
        store.dashboard.navbarLeft = 'Watchman'
        store.showFarmList = false
        extendObservable(this, {

            watchmanActivity: [],

            onStatus: false,

            // Farm Check
            recentInstallWeeks: '',
            driedOffDays: '',
            faultCheckDays: '',
            minimumDataPoints: '',
        
            // Fault Thresholds
            minimumSignalStrengthAlone: '',
            minimumSignalStrength: '',
            minimumSignalQuality: '',
            maximumDisconnects: '',
        
            minimumTempProbeTemp: '',
            maximumTempProbeTemp: '',

            tempProbeAccuracyThreshold: '',
        
            minimumAgitatorProbeFaultThreshold: '',
            maximumAgitatorProbeFaultThreshold: '',
        
            // Whether Watchman can set Fonterra sync status on and/or off
            autoTurnFonterraSyncOn: false,
            autoTurnFonterraSyncOff: false,

            // Calibration
            calibrationDays: '',
            matchingPickupMinutes: '',
            minimumMatchedCollections: '',

            // Save Changes Result
            result: ''
        })
        this.state = {
            tab: 0,
            error: false,
            resultDialog: false
        }

        this.getActivity()
        this.getSettings()
    }

    getActivity = () => {
        this.watchmanActivity = []

        const params = new URLSearchParams()
        params.append('type', 'GET_ACTIVITY')
    
        // Post
        Utils.httpPost(Utils.urlList.watchman, params, data => {
            console.log(data)
            if (data){
                data.slice().map((activity,i) => {
                    activity.started_at = moment.utc(activity.started_at).local().format(global.constants.date_format_utc)
                    activity.finished_at = moment.utc(activity.finished_at).local().format(global.constants.date_format_utc)
                    this.watchmanActivity.push(activity)
                })
            }
        })
    }

    getSettings = () => {
        const params = new URLSearchParams()
        params.append('type', 'GET_SETTINGS')
    
        // Post
        Utils.httpPost(Utils.urlList.watchman, params, data => {
            console.log(data)
            if (data){
                if (data['key'] == 'watchman'){
                    let settings = data['settings']

                    
                    this.onStatus = settings['on_status'] == 'true' ? true : false
                    this.recentInstallWeeks = settings['recent_install_weeks']
                    this.driedOffDays = settings['dried_off_days']
                    this.faultCheckDays = settings['fault_check_days']
                    this.minimumDataPoints = settings['minimum_data_points']

                    this.minimumSignalStrengthAlone = settings['minimum_signal_strength_alone']
                    this.minimumSignalStrength = settings['minimum_signal_strength']
                    this.minimumSignalQuality = settings['minimum_signal_quality']
                    this.maximumDisconnects = settings['maximum_disconnects']

                    this.minimumTempProbeTemp = settings['minimum_temp_probe_temp']
                    this.maximumTempProbeTemp = settings['maximum_temp_probe_temp']
                    this.tempProbeAccuracyThreshold = settings['temp_accuracy_threshold']

                    this.minimumAgitatorProbeFaultThreshold = settings['minimum_agitator_probe_fault_threshold']
                    this.maximumAgitatorProbeFaultThreshold = settings['maximum_agitator_probe_fault_threshold']

                    this.autoTurnFonterraSyncOn = settings['auto_turn_fonterra_sync_on'] == 'true' ? true : false
                    this.autoTurnFonterraSyncOff = settings['auto_turn_fonterra_sync_off'] == 'true' ? true : false

                    this.calibrationDays = settings['calibration_days']
                    this.matchingPickupMinutes = settings['matching_pickup_minutes']
                    this.minimumMatchedCollections = settings['minimum_matched_collections']
                }
            }
        })
    }

    saveChanges = () => {

        // Check values
        if (this.recentInstallWeeks == '' || this.driedOffDays == '' || this.faultCheckDays == '' || this.minimumDataPoints == '' || this.minimumSignalStrengthAlone == '' || this.minimumSignalStrength == '' || this.minimumSignalQuality == '' || this.maximumDisconnects == '' || this.minimumTempProbeTemp == '' || this.maximumTempProbeTemp == '' || this.minimumTempProbeTemp == '' || this.maximumTempProbeTemp == '' || this.tempProbeAccuracyThreshold == '' || this.minimumAgitatorProbeFaultThreshold == '' || this.maximumAgitatorProbeFaultThreshold == '' || this.calibrationDays == '' || this.matchingPickupMinutes == '' || this.minimumMatchedCollections == ''){
            this.setState({error: true});
            return;
        }
        this.setState({error: false});

        // Params
        const params = new URLSearchParams()
        params.append('type', 'SAVE_SETTINGS')

        params.append('onStatus', this.onStatus)
        params.append('recentInstallWeeks', this.recentInstallWeeks)
        params.append('driedOffDays', this.driedOffDays)
        params.append('faultCheckDays', this.faultCheckDays)
        params.append('minimumDataPoints', this.minimumDataPoints)
        
        params.append('minimumSignalStrengthAlone', this.minimumSignalStrengthAlone)
        params.append('minimumSignalStrength', this.minimumSignalStrength)
        params.append('minimumSignalQuality', this.minimumSignalQuality)
        params.append('maximumDisconnects', this.maximumDisconnects)
        
        params.append('minimumTempProbeTemp', this.minimumTempProbeTemp)
        params.append('maximumTempProbeTemp', this.maximumTempProbeTemp)
        params.append('tempProbeAccuracyThreshold', this.tempProbeAccuracyThreshold)
        
        params.append('minimumAgitatorProbeFaultThreshold', this.minimumAgitatorProbeFaultThreshold)
        params.append('maximumAgitatorProbeFaultThreshold', this.maximumAgitatorProbeFaultThreshold)
        
        params.append('autoTurnFonterraSyncOn', this.autoTurnFonterraSyncOn)
        params.append('autoTurnFonterraSyncOff', this.autoTurnFonterraSyncOff)
        
        params.append('calibrationDays', this.calibrationDays)
        params.append('matchingPickupMinutes', this.matchingPickupMinutes)
        params.append('minimumMatchedCollections', this.minimumMatchedCollections)
    
        // Post
        Utils.httpPost(Utils.urlList.watchman, params, data => {
            console.log(data)
            if (data == 'success') this.result = 'Success'
            else this.result = 'Error'
            this.setState({resultDialog: true})
        })
    }

    handleDialogClose = () => {
        this.setState({resultDialog: false})
    }

    render() {
        return (
            <div className='container-fluid bg-white'>
                <Dialog
                    open={this.state.resultDialog}
                    onClose={this.handleDialogClose}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{this.result}</DialogContentText>
                    </DialogContent>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogClose}>
                        Ok
                    </Button>
                </Dialog>
                <Tabs
                    value={this.state.tab}
                    onChange={(e, newValue) => {
                        this.setState({ tab: newValue })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Activity" />
                    <Tab label="Settings" />
                </Tabs>
                {this.state.tab == 0 && 
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Started At</TableCell>
                                    <TableCell>Finished At</TableCell>
                                    <TableCell>Farms Total</TableCell>
                                    <TableCell>Farms Milking</TableCell>
                                    <TableCell>Farms Dry</TableCell>
                                    <TableCell>Farms Okay</TableCell>
                                    <TableCell>Farms Faulty</TableCell>
                                    <TableCell>Syncs Turned On</TableCell>
                                    <TableCell>Syncs Turned Off</TableCell>
                                    <TableCell>Total Syncs On</TableCell>
                                    <TableCell>Total Syncs Off</TableCell>
                                    <TableCell>Vats Within Spec</TableCell>
                                    <TableCell>Vats Out Of Spec</TableCell>
                                    <TableCell>Cases Created</TableCell>
                                    <TableCell>Cases Closed</TableCell>
                                    <TableCell>Errors Fonterra API</TableCell>
                                    <TableCell>Errors MiLog API</TableCell>
                                    <TableCell>Errors Particle API</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.watchmanActivity.map((activity,i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{activity.started_at}</TableCell>
                                            <TableCell>{activity.finished_at}</TableCell>
                                            <TableCell>{activity.farms_total}</TableCell>
                                            <TableCell>{activity.farms_milking}</TableCell>
                                            <TableCell>{activity.farms_dry}</TableCell>
                                            <TableCell>{activity.farms_okay}</TableCell>
                                            <TableCell>{activity.farms_faulty}</TableCell>
                                            <TableCell>{activity.syncs_turned_on}</TableCell>
                                            <TableCell>{activity.syncs_turned_off}</TableCell>
                                            <TableCell>{activity.syncs_on}</TableCell>
                                            <TableCell>{activity.syncs_off}</TableCell>
                                            <TableCell>{activity.vats_within_spec}</TableCell>
                                            <TableCell>{activity.vats_out_of_spec}</TableCell>
                                            <TableCell>{activity.cases_created}</TableCell>
                                            <TableCell>{activity.cases_closed}</TableCell>
                                            <TableCell>{activity.error_fonterra_api}</TableCell>
                                            <TableCell>{activity.error_milog_api}</TableCell>
                                            <TableCell>{activity.error_particle_api}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {this.state.tab == 1 &&
                    <Container component="main" maxWidth="xs">
                        <Typography>On Status</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.onStatus}
                                    onChange={e => {
                                        this.onStatus = e.target.checked
                                    }}
                                />}
                            label="Turn Watchman on or off. Turning off will end current and future sessions until turned back on."
                        />
                        <Typography>Farm Check</Typography>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="recentInstallWeeks"
                                label="Recent Install (Weeks)"
                                type="number"
                                id="recentInstallWeeks"
                                value={this.recentInstallWeeks}
                                helperText="How many weeks until we no longer flag the site a recent install (wont auto calibrate as waiting for initial manual calibration and onboarding). Default: 2"
                                error={this.recentInstallWeeks == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.recentInstallWeeks = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="driedOffDays"
                                label="Dried Off (Days)"
                                type="number"
                                id="driedOffDays"
                                value={this.driedOffDays}
                                helperText="How many days without a Fonterra API collection until we flag the site dried off (this flag is used in APC send alert logic). Default: 4"
                                error={this.driedOffDays == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.driedOffDays = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="faultCheckDays"
                                label="Fault Check (Days)"
                                type="number"
                                id="faultCheckDays"
                                value={this.faultCheckDays}
                                helperText="How many days do we check site data (at 1 hour intervals) for fault checking (note for a sensor value to be considered constant, it must be constant over this time period). Default: 3"
                                error={this.faultCheckDays == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.faultCheckDays = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumDataPoints"
                                label="Minimum Data Points"
                                type="number"
                                id="minimumDataPoints"
                                value={this.minimumDataPoints}
                                helperText="How many (hourly) data points are required within the fault check period to do a board / sensor fault check. Default: 12"
                                error={this.minimumDataPoints == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumDataPoints = e.target.value
                                }}
                        />
                        <Typography>Connectivity Faults</Typography>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumSignalStrengthAlone"
                                label="Minimum Signal Strength (Alone)"
                                type="number"
                                id="minimumSignalStrengthAlone"
                                value={this.minimumSignalStrengthAlone}
                                helperText="We consider the site as having low signal if it is below this threshold. Default: 15"
                                error={this.minimumSignalStrengthAlone == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumSignalStrengthAlone = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumSignalStrength"
                                label="Minimum Signal Strength"
                                type="number"
                                id="minimumSignalStrength"
                                value={this.minimumSignalStrength}
                                helperText="We consider the site as having low signal if it is below this threshold and the signal quality threshold. Default: 20"
                                error={this.minimumSignalStrength == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumSignalStrength = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumSignalQuality"
                                label="Minimum Signal Quality"
                                type="number"
                                id="minimumSignalQuality"
                                value={this.minimumSignalQuality}
                                helperText="Default: 55"
                                error={this.minimumSignalQuality == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumSignalQuality = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="maximumDisconnects"
                                label="Maximum Disconnects"
                                type="number"
                                id="maximumDisconnects"
                                value={this.maximumDisconnects}
                                helperText="We consider the site as having low signal if it has disconnected over this threshold. Default: 200. Currently unused until average per day over last two data points is used."
                                error={this.maximumDisconnects == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.maximumDisconnects = e.target.value
                                }}
                        />
                        <Typography>Probe Faults</Typography>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumTempProbeTemp"
                                label="Minimum Temp Probe Temp (C)"
                                type="number"
                                id="minimumTempProbeTemp"
                                value={this.minimumTempProbeTemp}
                                helperText="We consider a temp probe as faulty if it is under this threshold. Default: -20"
                                error={this.minimumTempProbeTemp == '' && this.state.error}
                                onChange={e => {
                                    this.minimumTempProbeTemp = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="maximumTempProbeTemp"
                                label="Maximum Temp Probe Temp (C)"
                                type="number"
                                id="maximumTempProbeTemp"
                                value={this.maximumTempProbeTemp}
                                helperText="We consider a temp probe as faulty if it is over this threshold. Default: 100"
                                error={this.maximumTempProbeTemp == '' && this.state.error}
                                onChange={e => {
                                    this.maximumTempProbeTemp = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="tempProbeAccuracyThreshold"
                                label="Temp Probe Accuracy Threshold (C)"
                                type="number"
                                id="tempProbeAccuracyThreshold"
                                value={this.tempProbeAccuracyThreshold}
                                helperText="The milk temp should be within this threshold at collection (Fonterra only), otherwise a case is created. Default: 1.5"
                                error={this.tempProbeAccuracyThreshold == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.tempProbeAccuracyThreshold = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumAgitatorProbeFaultThreshold"
                                label="Minimum Agitator Probe Fault"
                                type="number"
                                id="minimumAgitatorProbeFaultThreshold"
                                value={this.minimumAgitatorProbeFaultThreshold}
                                helperText="We consider an agitator probe as faulty if it is over this threshold and below the maximum. Default: 30"
                                error={this.minimumAgitatorProbeFaultThreshold == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumAgitatorProbeFaultThreshold = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="maximumAgitatorProbeFaultThreshold"
                                label="Maximum Agitator Probe Fault"
                                type="number"
                                id="maximumAgitatorProbeFaultThreshold"
                                value={this.maximumAgitatorProbeFaultThreshold}
                                helperText="We consider an agitator probe as faulty if it is below this threshold and over the minimum. Default: 100"
                                error={this.maximumAgitatorProbeFaultThreshold == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.maximumAgitatorProbeFaultThreshold = e.target.value
                                }}
                        />
                        <Typography>Fonterra Sync</Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.autoTurnFonterraSyncOn}
                                        onChange={e => {
                                            this.autoTurnFonterraSyncOn = e.target.checked
                                        }}
                                    />}
                                label="Watchman will turn on farm Fonterra syncs if not dried off, no cases, and no fault detected"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.autoTurnFonterraSyncOff}
                                        onChange={e => {
                                            this.autoTurnFonterraSyncOff = e.target.checked
                                        }}
                                    />}
                                label="Watchman will turn off farm Fonterra syncs if dried off, an open case exists, or a is fault detected"
                            />
                            <Typography>Calibration</Typography>
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="calibrationDays"
                                label="Calibration Days"
                                type="number"
                                id="calibrationDays"
                                value={this.calibrationDays}
                                helperText="How many days of collections should we compare to for auto calibration. Default: 30"
                                error={this.calibrationDays == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.calibrationDays = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="matchingPickupMinutes"
                                label="Matching Pickup Minutes"
                                type="number"
                                id="matchingPickupMinutes"
                                value={this.matchingPickupMinutes}
                                helperText="We match Fonterra API collections to Vat-Man collections if within this threshold at the same vat. Default: 30"
                                error={this.matchingPickupMinutes == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.matchingPickupMinutes = e.target.value
                                }}
                        />
                        <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="minimumMatchedCollections"
                                label="Minimum Matched Collections"
                                type="number"
                                id="minimumMatchedCollections"
                                value={this.minimumMatchedCollections}
                                helperText="Minimum number of Fonterra collections that match Vat-Man before we calibrate and check temp accuracy. Default: 3"
                                error={this.minimumMatchedCollections == '' && this.state.error}
                                onChange={e => {
                                    if (e.target.value >= 0) this.minimumMatchedCollections = e.target.value
                                }}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="my-3"
                            onClick={this.saveChanges}>
                            Save Changes
                        </Button>
                    </Container>
                }
            </div>
        )
    }
}

export default withRouter(observer(Component))
