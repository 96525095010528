import AsyncValidator from 'async-validator'

class Validator {

    static descriptor = {
        email: {
            type: "email",
            required: true,
            message: 'Please enter a correct email address'
        },
        phone: [{
            type: "string",
            required: true,
            message: 'Please enter your phone number'
        }, {
            min: 9,
            message: 'Phone number at least 9'
        }],
        password_register: [{
            type: "string",
            required: true,
            message: 'Please enter password'
        }, {
            min: 8,
            max: 50,
            message: 'Password must be 8-50'
        }],
        password_login: [{
            type: "string",
            required: true,
            message: 'Please enter password'
        }]

    }

    static login = new AsyncValidator({
        email: this.descriptor.email,
        password: this.descriptor.password_login,
    })

    static registor = new AsyncValidator({
        email: this.descriptor.email,
        phone: this.descriptor.phone,
        password: this.descriptor.password_register,
    })

    static forgot = new AsyncValidator({
        email: this.descriptor.email,
    })

    static reset = new AsyncValidator({
        password: this.descriptor.password_register,
    })
}

export default Validator;

