import React from 'react'
import {observer} from "mobx-react";
import {extendObservable} from "mobx";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import emitter from "../../utility/EventEmitter";
import Validator from '../../utility/Validator'
import ReCAPTCHA from "../common/ReCAPTCHA";
import Utils from "../../utility/Utils";
import {Card} from "@material-ui/core";
import BaseDashboard from "./BasePromotion"


class Login extends BaseDashboard {

  constructor(props) {
    super(props)
    extendObservable(this, {
      user: {
        email: {value: null, errorText: ''},
      },
      isSend: false,
      gRecaptchaResponse: null,
    })
  }

  handleChange = (successCallback) => {
    this.user.email.errorText = ''
    Validator.forgot.validate({
      email: this.user.email.value,
    }, (errors) => {
      // console.log(errors)
      if (errors != null) {
        for (let o of errors) {
          switch (o.field) {
            case "email":
              this.user.email.errorText = o.message
              break;
            default:
              break
          }
          break;
        }
      } else if (successCallback) {
        successCallback()
      }
    })
  }

  forgot = e => {
    this.handleChange(() => {
      const params = new URLSearchParams();
      params.append('type', 'USER_FORGOT_PASSWORD');
      params.append('user', JSON.stringify(this.user));
      Utils.httpPost(Utils.urlList.user, params, data => {
        console.log(data)
        this.props.history.push("/dts/password/forgot/finished");
      })
    })
  }


  render() {


    return (

      <div className='container-fluid bg-white container-login'>
        <Container component="main" maxWidth="xs">

          <CssBaseline/>
          <div>
            <Typography component="h1" variant="h5">
              Find my password
            </Typography>

            {!this.isSend ? <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                helperText={this.user.email.errorText}
                error={this.user.email.errorText !== ''}
                onChange={e => {
                  this.user.email.value = e.target.value
                  this.handleChange()
                }}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    this.forgot()
                  }
                }}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className="my-3"
                onClick={this.forgot}
              >
                Find my password
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/dts/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </div> : <Card className='my-5 p-3 border border-primary'>
              We have sent you an email to reset your password, please check your email to complete the operation.
            </Card>}


          </div>

        </Container>
      </div>

    )
  }


}

export default observer(Login)
