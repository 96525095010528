import React from 'react'
import { observer } from "mobx-react";
import { extendObservable } from "mobx";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as moment from "moment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import emitter from "../../../utility/EventEmitter";
import Utils from "../../../utility/Utils";
import Card from "@material-ui/core/Card";
import Switch from '@material-ui/core/Switch';
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import store from "../../../store/Store";
import DTS from '../../../utility/DTS';

const chartOptions = {
  credits: false,
  chart: {
    type: 'line',
    height: 300,
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
    resetZoomButton: {
      position: {
        x: -10,
        y: 10
      },
      relativeTo: 'chart'
    },
  },
}

// sleep time expects milliseconds
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class DeviceCurrentDetail extends React.Component {

  constructor(props) {
    super(props)

    Highcharts.setOptions({
      time: {
        useUTC: false
      },
    })
    extendObservable(this, {
      refChartLevel: null,
      refChartTemperature: null,
      refChartAgitator: null,
      listNavigation: [
        { name: '1 h', days1: 0, hours1: -1, days2: 0, hours2: 0, isCurrent: false },
        { name: '2 h', days1: 0, hours1: -2, days2: 0, hours2: 0, isCurrent: false },
        { name: '3 h', days1: 0, hours1: -3, days2: 0, hours2: 0, isCurrent: false },
        { name: '6 h', days1: 0, hours1: -6, days2: 0, hours2: 0, isCurrent: false },
        { name: '1 d', days1: -1, hours1: 0, days2: 0, hours2: 0, isCurrent: true },
        { name: '3 d', days1: -3, hours1: 0, days2: 0, hours2: 0, isCurrent: false },
        { name: '1 w', days1: -7, hours1: 0, days2: 0, hours2: 0, isCurrent: false },
      ],
      currentNavigation: { days1: -1, hours1: 0, days2: 0, hours2: 0 },
      timeRange: { start: 0, end: 0, },
      optionsLevel: chartOptions,
      optionsTemp: chartOptions,
      optionsShaft: chartOptions,
      raw: '',
      showplot: true,
      listPlot: [],
      listPlotData: [],
    })
    this.initData()
  }


  initData = () => {
    this.raw = ''
    let device_id = this.props.match.params.device_id
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_ONE_SERIES');
    params.append('device_id', device_id);
    params.append('days1', this.currentNavigation.days1);
    params.append('hours1', this.currentNavigation.hours1);
    params.append('days2', this.currentNavigation.days2);
    params.append('hours2', this.currentNavigation.hours2);
    Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      this.dataAction(data.vat, data.cooler)
      this.showplot = true
    })
  }

  getOneRaw = (_id) => {
    // debugger
    this.raw = 'Loading...'
    let device_id = this.props.match.params.device_id
    const params = new URLSearchParams();
    params.append('type', 'DEVICE_ONE_RAW');
    params.append('_id', _id);
    Utils.httpPost(Utils.urlList.admin_device, params, data => {
      if (data) {
        this.raw = JSON.stringify(data.raw)
      }
    })

  }

  naviClick = (item) => {
    // debugger
    for (let i of this.listNavigation) {
      i.isCurrent = false
    }
    item.isCurrent = true
    this.currentNavigation.days1 = item.days1
    this.currentNavigation.days2 = item.days2
    this.currentNavigation.hours1 = item.hours1
    this.currentNavigation.hours2 = item.hours2
    this.initData()
  }

  // test function
  testTimeDuration = (start, end) => {
    let start1 = moment(start)
    let end1 = moment(end)
    let duration = moment.duration(end1.diff(start1));
    console.log(duration.asMinutes())
  }

  naviTimeRange = (flag) => {
    let start = parseInt(this.timeRange.start)
    let end = parseInt(this.timeRange.end)
    // calculation gap
    let config = 600000
    let gap = end - start
    gap = Math.ceil(gap / config) * config
    if (gap < config) gap = config

    if (flag === 'PREVIOUS') {
      end = start
      start -= gap
    } else if (flag === 'NEXT') {
      start = end
      end += gap
      if (start + gap / 2 >= moment().valueOf()) {
        emitter.emit("NOTIFY_SNACKBAR", 'No more data!')
        return
      }
    }

    // console.log(start, end)
    if (isNaN(start) || isNaN(end)) {
      return
    }
    // this.testTimeDuration(start, end)
    this.raw = ''

    let device_id = this.props.match.params.device_id
    const params = new URLSearchParams()
    params.append('type', 'DEVICE_ONE_SERIES_TIME_RANGE')
    params.append('device_id', device_id)
    params.append('start', start)
    params.append('end', end)
    Utils.httpPost(Utils.urlList.admin_device, params, data => {
      // console.log(data)
      if (data && Array.isArray(data) && data.length) {
        this.dataAction(data)
        this.showplot = true
      }
    })
  }

  naviOffset = (flag) => {
    // debugger
    let item
    for (let i of this.listNavigation) {
      if (i.isCurrent) {
        item = i
        break
      }
    }
    if (flag === 'PREVIOUS') {
      if (item.days1 !== 0) {
        this.currentNavigation.days1 += item.days1
        this.currentNavigation.days2 += item.days1
      } else if (item.hours1 !== 0) {
        this.currentNavigation.hours1 += item.hours1
        this.currentNavigation.hours2 += item.hours1
      }
    } else if (flag === 'NEXT') {
      if (item.days1 !== 0) {
        if (this.currentNavigation.days2 >= 0) {
          return
        }
        this.currentNavigation.days1 -= item.days1
        this.currentNavigation.days2 -= item.days1
      } else if (item.hours1 !== 0) {
        if (this.currentNavigation.hours2 >= 0) {
          return
        }
        this.currentNavigation.hours1 -= item.hours1
        this.currentNavigation.hours2 -= item.hours1
      }

    }
    this.initData()
  }

  dataAction = (data, cooler_data) => {

    // console.log(cooler_data)

    this.refChartLevel.zoomOut()
    this.refChartTemperature.zoomOut()
    this.refChartAgitator.zoomOut()

    let listLevel = [];
    let listTemp1 = [], listTemp2 = [], listTemp3 = [], listTemp4 = [], listTemp5 = [], listTemp6 = [];
    let listTempCooler1 = [], listTempCooler2 = [];
    let listShaft = [];
    let listBandX = [] // agitator stopped
    let agitator = DTS.getAgitatorStopStat(data)
    let listAgitator = agitator.listStop
    // console.log(agitator)
    for (let item of listAgitator) {
      listBandX.push({
        from: moment.utc(item.start.published_at).local().toDate(),
        to: moment.utc(item.end.published_at).local().toDate(),
        color: global.constants.color.agitator_stop,
      })
    }
    // console.log(data)
    // console.log(agitator)

    for (let o of data) {

      if (o.state_machine === 'startup') {
        continue;
      }

      if (o && o.milk_level !== undefined && o.milk_level !== null) {
        listLevel.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.milk_level),
          name: `<h6>${moment.utc(o.published_at).local().format(global.constants.date_format_utc)}<br />${o.message_type}/Level:${o.milk_level}/Vat:${o.temp1}/Inlet:${o.temp2}<br />Plate:${o.temp3}/Ambient:${o.temp4}/CIP:${o.temp5}<br />Pressure:${o.pressure}/Volume:${o.milk_volume}</h6>`,
          _id: o._id,
        })
      }

      if (o.temp1 !== undefined && o.temp1 !== null) {
        listTemp1.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp1),
          _id: o._id,
        })
      }

      if (o.temp2 !== undefined && o.temp2 !== null) {
        listTemp2.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp2),
          _id: o._id,
        })
      }

      if (o.temp3 !== undefined && o.temp3 !== null) {
        listTemp3.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp3),
          _id: o._id,
        })
      }

      if (o.temp4 !== undefined && o.temp4 !== null) {
        listTemp4.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp4),
          _id: o._id,
        })
      }

      if (o.temp5 !== undefined && o.temp5 !== null) {
        listTemp5.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp5),
          _id: o._id,
        })
      }

      if (o.temp6 !== undefined && o.temp6 !== null) {
        listTemp6.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.temp6),
          _id: o._id,
        })
      }

      if (o.shaft_rpm !== undefined && o.shaft_rpm !== null) {
        listShaft.push({
          x: moment.utc(o.published_at).local().toDate(),
          y: parseFloat(o.shaft_rpm),
          _id: o._id,
        })
      }

      if (['AM', 'EM', 'TEST', 'EM1', 'EM2', 'EM3'].includes(o.message_type)) {
        this.listPlot.push({
          color: o.message_type === 'AM' ? '#FF0000' : '#FF9418',
          width: 1,
          value: moment.utc(o.published_at).local().toDate(),
          events: {
            click: event => {
              this.getOneRaw(o._id)
            }
          },
          id: o._id
        })
        this.listPlotData.push({
          color: o.message_type === 'AM' ? '#FF0000' : '#FF9418',
          width: 1,
          value: moment.utc(o.published_at).local().toDate(),
          events: {
            click: event => {
              this.getOneRaw(o._id)
            }
          },
          id: o._id
        })
      }

    }

    if (cooler_data) {
      for (let o of cooler_data) {
        if (o.milk_in !== undefined && o.milk_in !== null) {
          listTempCooler1.push({
            x: moment.utc(o.published_at).local().toDate(),
            y: parseFloat(o.milk_in),
            _id: o._id,
          })
        }
        if (o.water_in !== undefined && o.water_in !== null) {
          listTempCooler2.push({
            x: moment.utc(o.published_at).local().toDate(),
            y: parseFloat(o.water_in),
            _id: o._id,
          })
        }
      }
    }
    
    let tempSeries = []
  
    if (listTemp1.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Vat",
        data: listTemp1,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTemp2.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Inlet",
        data: listTemp2,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTemp3.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Plate",
        data: listTemp3,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTemp4.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Ambient",
        data: listTemp4,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTemp5.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "CIP",
        data: listTemp5,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTemp6.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Unknow",
        data: listTemp6,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }
  
    if (listTempCooler1.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Milk In",
        data: listTempCooler1,
        point: {
          events: {
            click: (event) => {

            }
          }
        },
      })
    }
  
    if (listTempCooler2.length) {
      tempSeries.push({
        turboThreshold: 10000,
        name: "Water In",
        data: listTempCooler2,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      })
    }

    this.optionsLevel = {
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        plotLines: this.listPlot,
        events: {
          afterSetExtremes: e => {
            this.timeRange.start = e.min
            this.timeRange.end = e.max
            this.refChartTemperature.xAxis[0].setExtremes(e.min, e.max)
            this.refChartAgitator.xAxis[0].setExtremes(e.min, e.max)
          }
        },
      },
      yAxis: { title: { text: 'mm' } },
      series: [{
        turboThreshold: 10000,
        name: "Milk Level",
        data: listLevel,
        point: {
          events: {
            click: (event) => {
              // debugger
              this.getOneRaw(event.point._id)
            }
          }
        },
      }],
      tooltip: { useHTML: true },
      plotOptions: {
        series: {
          marker: {
            // enabled: true
            enabledThreshold: 1
            // chart.series[index].data[index].graphic.show()
          }
        }
      },
    }

    this.optionsTemp = {
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        plotLines: this.listPlot,
        events: {
          afterSetExtremes: e => {
            this.timeRange.start = e.min
            this.timeRange.end = e.max
            this.refChartLevel.xAxis[0].setExtremes(e.min, e.max)
            this.refChartAgitator.xAxis[0].setExtremes(e.min, e.max)
          }
        },
      },
      yAxis: { title: { text: 'Degrees Celsius (°C)' } },
      series: tempSeries,
      events: {
        load: function () {
          var options = this.options.tooltip;
          console.log(options)
          this.myTooltip = new Highcharts.Tooltip(this, options);
        }
      }
    }
    this.optionsShaft = {
      xAxis: {
        type: 'datetime',
        crosshair: true,
        ordinal: false,
        plotLines: this.listPlot,
        plotBands: listBandX,
        events: {
          afterSetExtremes: e => {
            this.timeRange.start = e.min
            this.timeRange.end = e.max
            this.refChartLevel.xAxis[0].setExtremes(e.min, e.max)
            this.refChartTemperature.xAxis[0].setExtremes(e.min, e.max)
          }
        },
      },
      yAxis: { title: { text: 'Vibration' } },
      series: [{
        turboThreshold: 10000,
        name: "Vibration",
        data: listShaft,
        point: {
          events: {
            click: (event) => {
              this.getOneRaw(event.point._id)
            }
          }
        },
      }]
    }

  }


  render() {
    return (
      <div>

        <Card className='mt-3 border-bottom text-center d-block'>
          <div className='row'>
            <div className='col-1'></div>
            <div className='col-10'>
              <Button size='small' variant="outlined" className='m-3' onClick={e => {
                this.naviTimeRange('PREVIOUS')
              }}>
                Previous
              </Button>
              <ButtonGroup size="small" color="primary" aria-label="outlined primary button group"
                className='naviGroup'>
                {this.listNavigation.map((item, index) => (
                  <Button key={index} className={item.isCurrent ? 'current' : ''}
                    onClick={e => {
                      this.naviClick(item)
                    }}>{item.name}</Button>
                ))}
              </ButtonGroup>
              <Button size='small' variant="outlined" className='m-3' onClick={e => {
                this.naviTimeRange('NEXT')
              }}>
                Next
              </Button>
            </div>
            <div className='col-1'>
              <Switch
                className='m-3'
                checked={this.showplot}
                color="primary"
                onChange={() => {
                  this.showplot = !this.showplot
                  // while (this.refChartLevel.series.length > 0) this.refChartLevel.series[0].remove();
                  // console.log(this.showplot)
                  if (this.showplot) {
                    this.listPlotData.map(item => {
                      // Usage!
                      sleep(500).then(() => {
                        this.refChartLevel.xAxis[0].addPlotLine({
                          color: item.color,
                          width: item.width,
                          value: item.value,
                          events: item.events,
                          id: item.id,
                        });
                        this.refChartTemperature.xAxis[0].addPlotLine({
                          color: item.color,
                          width: item.width,
                          value: item.value,
                          events: item.events,
                          id: item.id,
                        });
                        this.refChartAgitator.xAxis[0].addPlotLine({
                          color: item.color,
                          width: item.width,
                          value: item.value,
                          events: item.events,
                          id: item.id,
                        });
                      });
                    })
                  } else {
                    this.listPlot.map(item => {
                      // Usage!
                      sleep(500).then(() => {
                        this.refChartLevel.xAxis[0].removePlotLine(item.id);
                        this.refChartTemperature.xAxis[0].removePlotLine(item.id);
                        this.refChartAgitator.xAxis[0].removePlotLine(item.id);
                      });
                    })
                  }
                }}
                name="Show Vertical Line"
              />
            </div>
          </div>
        </Card>

        <Card className='mb-3 py-3'>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">
                L
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
              </IconButton>
            }
            title="Milk Level"
          />
          <HighchartsReact
            options={this.optionsLevel}
            callback={chart => {
              this.refChartLevel = chart
            }}

          />
        </Card>

        {this.raw !== '' ? <Card className='text-center'>
          <Chip
            label={this.raw}
            color="primary"
            variant="outlined"
            className='my-3'
          />
        </Card> : null}

        <Card className='my-3 py-3'>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">
                T
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
              </IconButton>
            }
            title="Temperatures"
          />
          <HighchartsReact
            options={this.optionsTemp}
            callback={chart => {
              this.refChartTemperature = chart
            }}
          />
        </Card>

        {this.raw !== '' ? <Card className='text-center'>
          <Chip
            label={this.raw}
            color="primary"
            variant="outlined"
            className='my-3'
          />
        </Card> : null}

        <Card className='my-3 py-3'>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">
                S
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
              </IconButton>
            }
            title="Vibration"
          />
          <HighchartsReact
            options={this.optionsShaft}
            callback={chart => {
              this.refChartAgitator = chart
            }}
          />
        </Card>

      </div>
    )
  }


}

export default observer(DeviceCurrentDetail)
